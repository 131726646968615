import React, { Component } from "react";
import { useHistory } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  // faAngleLeft,
  faEnvelope,
  faUnlockAlt,
} from "@fortawesome/free-solid-svg-icons";
// import {
//   faFacebookF,
//   faGithub,
//   faTwitter,
// } from "@fortawesome/free-brands-svg-icons";
import {
  Col,
  Row,
  Form,
  Card,
  Image,
  Button,
  FormCheck,
  Container,
  InputGroup,
  Popover,
  OverlayTrigger,
} from "@themesberg/react-bootstrap";
import { Link, withRouter } from "react-router-dom";

import { Routes } from "../../routes";
import BgImage from "../../assets/img/illustrations/signin.svg";
import { compose } from "recompose";

import ReactHero from "../../assets/img/logo.png";

import { withFirebase } from "../../components/Firebase";

function BackButton() {
  const history = useHistory();

  // function handleClick() {
  //   history.push("/account/hotels");
  // }

  return (
    <Button
      variant="secondary"
      type="submit"
      className="w-100"
      // onClick={handleClick}
    >
      Sign in <FontAwesomeIcon icon={faUnlockAlt} className="me-1" />
    </Button>
  );
}

const SignInPage = () => <SignInForm />;

const INITIAL_STATE = {
  email: "",
  password: "",
  error: null,
};

class SignInFormBase extends Component {
  constructor(props) {
    super(props);

    this.state = { ...INITIAL_STATE };
  }

  onSubmit = (event) => {
    const { email, password } = this.state;

    this.props.firebase
      .doSignInWithEmailAndPassword(email, password)
      .then((authUser) => {
        console.log(authUser);
        this.setState({ ...INITIAL_STATE });
        // this.props.history.push(Routes.Account.path);
      })
      .catch((error) => {
        this.setState({ error });
      });

    event.preventDefault();
  };

  onChange = (event) => {
    this.setState({ [event.target.name]: event.target.value });
  };

  render() {
    const { email, password, error } = this.state;

    const isInvalid = password === "" || email === "";

    return (
      <main>
        <section className="d-flex align-items-center my-5 mt-lg-6 mb-lg-5">
          <Container>
            <Row
              className="justify-content-center form-bg-image"
              style={{ backgroundImage: `url(${BgImage})` }}
            >
              <Col
                xs={12}
                className="d-flex align-items-center justify-content-center"
              >
                <div className="bg-white shadow-soft border rounded border-light p-4 p-lg-5 w-100 fmxw-500">
                  <div className="text-center text-md-center mb-4 mt-md-0">
                  <Image src={ReactHero} className="navbar-brand-light" height="120" />
                    <h3 className="mb-0">Sign in to our portal</h3>
                  </div>
                  <form className="mt-4" onSubmit={this.onSubmit}>
                    <Form.Group id="email" className="mb-4">
                      <Form.Label>Your Email</Form.Label>
                      <InputGroup>
                        <InputGroup.Text>
                          <FontAwesomeIcon icon={faEnvelope} />
                        </InputGroup.Text>
                        <Form.Control
                          autoFocus
                          name="email"
                          value={email}
                          onChange={this.onChange}
                          type="text"
                          placeholder="example@company.com"
                        />
                        {/* <Form.Control.Feedback type="invalid">Please enter an email.</Form.Control.Feedback> */}
                      </InputGroup>
                    </Form.Group>
                    <Form.Group>
                      <Form.Group id="password" className="mb-4">
                        <Form.Label>Your Password</Form.Label>

                        <InputGroup>
                          <InputGroup.Text>
                            <FontAwesomeIcon icon={faUnlockAlt} />
                          </InputGroup.Text>
                          <Form.Control
                            required
                            name="password"
                            value={password}
                            onChange={this.onChange}
                            type="password"
                            placeholder="Password"
                          />
                          {/* <OverlayTrigger
                            placement="right"
                            trigger="hover"
                            overlay={
                              <Popover>
                                <Popover.Title>Password Guide</Popover.Title>
                                <Popover.Content>
                                  <small>
                                    <small>
                                      Note: do not use in your password, as both
                                      can cause problems in Web browsers
                                    </small>
                                  </small>
                                </Popover.Content>
                              </Popover>
                            }
                          >
                            <Button variant="secondary">?</Button>
                          </OverlayTrigger> */}
                        </InputGroup>
                      </Form.Group>
                      <div className="d-flex justify-content-between align-items-center mb-4">
                        <Form.Check type="checkbox">
                          <FormCheck.Input
                            id="defaultCheck5"
                            className="me-2"
                          />
                          <FormCheck.Label
                            htmlFor="defaultCheck5"
                            className="mb-0"
                          >
                            Remember me
                          </FormCheck.Label>
                        </Form.Check>
                        <Card.Link className="small text-end">
                          Lost password?
                        </Card.Link>
                      </div>
                    </Form.Group>

                    <BackButton />
                  </form>
                  <div className="mt-3 mb-4 text-center">
                    <span className="fw-normal">
                      {" "}
                      {error && <p>{error.message}</p>}
                    </span>
                  </div>

                  {/*<div className="d-flex justify-content-center my-4">
                  <Button
                    variant="outline-light"
                    className="btn-icon-only btn-pill text-facebook me-2"
                  >
                    <FontAwesomeIcon icon={faFacebookF} />
                  </Button>
                  <Button
                    variant="outline-light"
                    className="btn-icon-only btn-pill text-twitter me-2"
                  >
                    <FontAwesomeIcon icon={faTwitter} />
                  </Button>
                  <Button
                    variant="outline-light"
                    className="btn-icon-only btn-pil text-dark"
                  >
                    <FontAwesomeIcon icon={faGithub} />
                  </Button>
                </div> */}
                  <div className="d-flex justify-content-center align-items-center mt-4">
                    <span className="fw-normal">
                      Not registered?
                      <Card.Link
                        as={Link}
                        to={Routes.Signup.path}
                        className="fw-bold"
                      >
                        {` Create account `}
                      </Card.Link>
                    </span>
                  </div>
                </div>
              </Col>
            </Row>
          </Container>
        </section>
      </main>
    );
  }
}

const SignInForm = compose(withRouter, withFirebase)(SignInFormBase);

export default SignInPage;

export { SignInForm };
