import React, { useState, useEffect } from "react";
import { useHistory } from "react-router-dom";

import { withFirebase } from "../../../Firebase";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

import {
  faDatabase,
  faEye,
  faEyeSlash,
} from "@fortawesome/free-solid-svg-icons";

import {
  Alert,
  Form,
  InputGroup,
  Button,
  FormControl,
  OverlayTrigger, Popover
} from "@themesberg/react-bootstrap";

function UpdatesPage(props) {
  const [authUser, setAuth] = useState(null);
  const [showPassword, setshowPassword] = useState(false);

  const onShowPassword = () => setshowPassword(!showPassword);

  const [copyStatus, setEmailConfigStatus] = useState(
    "Sending STK Push, please wait ..."
  );

  useEffect(() => {
    document.title = `My Payments - Bunicom Studio`;

    const unsubscribe = props.firebase.auth.onAuthStateChanged((authUser) => {
      if (authUser) {
        setAuth(authUser);
      } else {
        setAuth(null);
        //save null business account
      }
    });

    return () => {
      unsubscribe();
    };
  }, []);

  return (
    <div className="container-fluid mt--6">
      <div className="row justify-content-center">
        <div className="col-lg-10 card-wrapper">
          <div className="d-block mb-4 mb-md-0">
            <Alert variant="secondary" show={true}>
              <div className="d-flex justify-content-between">
                <div>
                  <FontAwesomeIcon icon={faDatabase} className="me-1" />
                  <strong>Rest API Credentials!</strong> You can use the api key
                  below for your REST api calls and integrating our APIs with
                  your systems. Visit our documentation page{" "}
                  <a href="https://bunicom.com/documentation" target="_blank">
                    here
                  </a>{" "}
                  for more information
                </div>
              </div>
            </Alert>
          </div>

          <div className="card mt-2">
            <div className="card-body">
              <div className="row">
                <h5>Your API Key</h5>
                <hr />
                {authUser ? (
                  <Form.Group id="password" className="mb-4">
                    <InputGroup>
                      <FormControl
                        type={showPassword ? "text" : "password"}
                        value={authUser.uid}
                        disabled
                      />
                      <Button
                        variant="light"
                        size="md"
                        onClick={onShowPassword}
                      >
                        {showPassword ? (
                          <FontAwesomeIcon icon={faEyeSlash} className="me-1" />
                        ) : (
                          <FontAwesomeIcon icon={faEye} className="me-1" />
                        )}
                      </Button>
                      <OverlayTrigger
                        trigger="click"
                        overlay={
                          <Popover>
                            <Popover.Content>API Key Copied!</Popover.Content>
                          </Popover>
                        }
                      >
                        <Button
                          variant="secondary"
                          size="md"
                          onClick={() => {
                            navigator.clipboard.writeText(authUser.uid);
                          }}
                        >
                          Copy
                        </Button>
                      </OverlayTrigger>
                    </InputGroup>
                  </Form.Group>
                ) : (
                  "No API key available"
                )}
              </div>
            </div>
            {/* {JSON.stringify(authUser.uid)} */}
          </div>
        </div>
      </div>
    </div>
  );
}

export default withFirebase(UpdatesPage);
