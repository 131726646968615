import React, { useState, useEffect } from "react";
import { useHistory } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEnvelope, faPlus, faUnlockAlt } from "@fortawesome/free-solid-svg-icons";
import {
  faFacebookF,
  faGithub,
  faTwitter,
} from "@fortawesome/free-brands-svg-icons";
import {
  Col,
  Row,
  Form,
  Card,
  Button,
  FormCheck,
  Container,
  InputGroup,
  Image,
  Popover,
  OverlayTrigger,
} from "@themesberg/react-bootstrap";
import { Link, withRouter } from "react-router-dom";

import { Routes } from "../../routes";
import BgImage from "../../assets/img/illustrations/signin.svg";
import { compose } from "recompose";

import ReactHero from "../../assets/img/main-logo.png";
import { withFirebase } from "../../components/Firebase";

function SignUpFormBase(props) {
  const username = useFormInput("");
  const email = useFormInput("");
  const phone = useFormInput("");
  const passwordOne = useFormInput("");
  const passwordTwo = useFormInput("");

  const [error, setError] = React.useState(null);
  const [isInvalid, setisInvalid] = React.useState(true);

  useEffect(() => {
    setisInvalid(
      passwordOne.value !== passwordTwo.value ||
        passwordOne.value === "" ||
        email.value === "" ||
        username.value === ""
    );
  });

  const CreateNewBusinessAccount = (user_id) => {
    let fullname_arr = username.value.split(" ");
    let f_name = username.value;
    let l_name = username.value;

    if (fullname_arr.length > 1) {
      f_name = fullname_arr[0];
      l_name = fullname_arr[1];
    }
    let businessAccount = {
      uid: user_id,
      fullName: username.value,
      emailAddress: email.value,
      phone: phone.value,
      firstName: f_name,
      lastName: l_name,
      credits: 10
    };
    props.firebase.db
      .collection("business_accounts")
      .add(businessAccount)
      .then((docRef) => {
        console.log("Document written with ID: ", docRef.id);
        props.history.push("/");
        // props.firebase.doSignOut()
        // props.history.push("/signin")
      })

      .catch((error) => {
        console.error("Error writing document: ", error);
      });
  };

  const handleSubmit = (event) => {
    event.preventDefault();

    // alert(
    //   "Sorry, Register function has been disabled. If you already have an account with us, you can still login"
    // );

    props.firebase
      .doCreateUserWithEmailAndPassword(email.value, passwordOne.value)
      .then((authUser) => {
        console.log(authUser);

        if(authUser.user){
          authUser.user.updateProfile({
            displayName:  username.value
          }).then((s)=> {
            CreateNewBusinessAccount(authUser.user.uid);
          })
        }
        email.onReset();
        phone.onReset();
        username.onReset();
        passwordOne.onReset();
        passwordTwo.onReset();
      })
      .catch((error) => {
        console.log(error);
        setError(error);
      });
  };

  return (
    <>
      <main>
        <section className="d-flex align-items-center my-5 mt-lg-6 mb-lg-5">
          <Container>
            <Row
              className="justify-content-center form-bg-image"
              style={{ backgroundImage: `url(${BgImage})` }}
            >
              <Col
                xs={12}
                className="d-flex align-items-center justify-content-center"
              >
                <div className="mb-4 mb-lg-0 bg-white shadow-soft border rounded border-light p-4 p-lg-5 w-100 fmxw-500">
                  <div className="text-center text-md-center mb-4 mt-md-0">
                  <Image src={ReactHero} className="navbar-brand-light" height="120" />
                    
                    <h3 className="mb-0">Create an account</h3>
                  </div>
                  <form className="mt-4" onSubmit={handleSubmit}>
                    <Form.Group id="usename" className="mb-4">
                      <Form.Label>Your Name</Form.Label>
                      <InputGroup>
                        <InputGroup.Text>
                          <FontAwesomeIcon icon={faEnvelope} />
                        </InputGroup.Text>
                        <Form.Control
                          autoFocus
                          type="text"
                          name="username"
                          {...username}
                          placeholder="John Doe"
                        />
                      </InputGroup>
                    </Form.Group>
                    <Form.Group id="email" className="mb-4">
                      <Form.Label>Your Email</Form.Label>
                      <InputGroup>
                        <InputGroup.Text>
                          <FontAwesomeIcon icon={faEnvelope} />
                        </InputGroup.Text>
                        <Form.Control
                          name="email"
                          required
                          {...email}
                          type="text"
                          placeholder="Email Address"
                        />
                      </InputGroup>
                    </Form.Group>
                    <Form.Group id="email" className="mb-4">
                      <Form.Label>Your Phone Number</Form.Label>
                      <InputGroup>
                        <InputGroup.Text>
                          <FontAwesomeIcon icon={faPlus} />254
                        </InputGroup.Text>
                        <Form.Control
                          name="phone"
                          required
                          {...phone}
                          type="number"
                          placeholder="7XXXXXXXX"
                        />
                      </InputGroup>
                    </Form.Group>
                    <Form.Group id="password" className="mb-4">
                      <Form.Label>Your Password</Form.Label>

                      <InputGroup>
                        <InputGroup.Text>
                          <FontAwesomeIcon icon={faUnlockAlt} />
                        </InputGroup.Text>
                        <Form.Control
                          required
                          name="passwordOne"
                          {...passwordOne}
                          type="password"
                          // pattern="^(?=.*[0-9])(?=.*[a-z])(?=.*[A-Z])(?=\S+$).{6,}$"
                          placeholder="Password"
                        />
                        <OverlayTrigger
                          placement="right"
                          trigger="hover"
                          overlay={
                            <Popover>
                              <Popover.Title>Strong Password Guide</Popover.Title>
                              <Popover.Content>
                                <small>
                                  <ol>
                                    <li>
                                      <small>
                                        At least 8 characters—the more
                                        characters, the better 
                                      </small>
                                    </li>
                                    <li>
                                      <small>
                                      A mixture of both
                                        uppercase and lowercase letters
                                      </small>
                                    </li>
                                    <li>
                                      <small>
                                        A mixture of letters and numbers
                                        Inclusion of at least one special
                                        character, e.g., ! @ # ? ]
                                      </small>
                                    </li>
                                  </ol>
                                  <small>
                                    Note: do not use &#60; or &#62; in your password, as both
                                    can cause problems in Web browsers
                                  </small>
                                </small>
                              </Popover.Content>
                            </Popover>
                          }
                        >
                          <Button>?</Button>
                        </OverlayTrigger>
                      </InputGroup>
                    </Form.Group>
                    <Form.Group id="confirmPassword" className="mb-4">
                      <Form.Label>Confirm Password</Form.Label>
                      <InputGroup>
                        <InputGroup.Text>
                          <FontAwesomeIcon icon={faUnlockAlt} />
                        </InputGroup.Text>
                        <Form.Control
                          required
                          name="passwordTwo"
                          {...passwordTwo}
                          type="password"
                          placeholder="Confirm Password"
                        />
                      </InputGroup>
                    </Form.Group>
                    <FormCheck type="checkbox" className="d-flex mb-4">
                      <FormCheck.Input required id="terms" className="me-2" />
                      <FormCheck.Label htmlFor="terms">
                        I agree to the{" "}
                        <Card.Link>terms and conditions</Card.Link>
                      </FormCheck.Label>
                    </FormCheck>

                    <Button
                      variant="primary"
                      disabled={isInvalid}
                      type="submit"
                      className="w-100"
                    >
                      Sign up
                    </Button>

                    {error && <p>{error.message}</p>}
                  </form>

                  {/* <div className="mt-3 mb-4 text-center">
                    <span className="fw-normal">or</span>
                  </div>
                  <div className="d-flex justify-content-center my-4">
                    <Button
                      variant="outline-light"
                      className="btn-icon-only btn-pill text-facebook me-2"
                    >
                      <FontAwesomeIcon icon={faFacebookF} />
                    </Button>
                    <Button
                      variant="outline-light"
                      className="btn-icon-only btn-pill text-twitter me-2"
                    >
                      <FontAwesomeIcon icon={faTwitter} />
                    </Button>
                    <Button
                      variant="outline-light"
                      className="btn-icon-only btn-pil text-dark"
                    >
                      <FontAwesomeIcon icon={faGithub} />
                    </Button>
                  </div> */}
                  <div className="d-flex justify-content-center align-items-center mt-4">
                    <span className="fw-normal">
                      Already have an account?
                      <Card.Link
                        as={Link}
                        to={Routes.Signin.path}
                        className="fw-bold"
                      >
                        {` Login here `}
                      </Card.Link>
                    </span>
                  </div>
                </div>
              </Col>
            </Row>
          </Container>
        </section>
      </main>
    </>
  );
}

const useFormInput = (initialValue) => {
  const [value, setValue] = useState(initialValue);

  const handleChange = (e) => {
    setValue(e.target.value);
  };

  function handleReset() {
    setValue("");
  }
  return {
    value,
    onChange: handleChange,
    onReset: handleReset,
  };
};

export default compose(withRouter, withFirebase)(SignUpFormBase);
