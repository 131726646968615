import React from "react";
import * as _ from "lodash";

import { TrayItemWidget } from "../../../../Workflow/TrayItemWidget";
import { TrayWidget } from "../../../../Workflow/TrayWidget";

function ToolPallette(props) {
  return (
    <div className="pt-2">
      <div className="tools-scroll py-0 px-0">
        <TrayWidget>
          <TrayItemWidget
            model={{ type: "DataEntry" }}
            name="Data Entry"
            icon="table"
            color="#0096EF"
          />
          <TrayItemWidget
            model={{ type: "Entity" }}
            name="Data Sources"
            icon="database"
            color="#0096EF"
          />
          <TrayItemWidget
            model={{ type: "Request" }}
            name="HTTP"
            icon="globe"
            color="#0096EF"
          />
          <TrayItemWidget
            model={{ type: "Form" }}
            name="API"
            icon="clipboard-list"
            color="#02aab0"
          />

          <div className="separator-div"></div>

          <TrayItemWidget
            model={{ type: "Peek" }}
            name="Peek"
            icon="eye"
            color="#009688"
          />

          <TrayItemWidget
            model={{ type: "Output" }}
            name="Output"
            icon="file-export"
            color="#009688"
          />
          <div className="separator-div"></div>
          <TrayItemWidget
            model={{ type: "SMS" }}
            name="Send SMS"
            icon="comments"
            color="linear-gradient(to right,#03658c,#00fad9 "
          />

          <TrayItemWidget
            model={{ type: "Email" }}
            name="Send Email"
            icon="envelope"
            color="linear-gradient(to right,#03658c,#00fad9 "
          />

          <TrayItemWidget
            model={{ type: "Sheets" }}
            name="Google Sheets"
            icon="file-excel"
            color="#00A568"
          />
          <div className="separator-div"></div>

          <TrayItemWidget
            model={{ type: "Select" }}
            name="Select"
            icon="tasks"
            color="#7b4397"
          />
          <TrayItemWidget
            model={{ type: "Cleanup" }}
            name="Cleanup"
            icon="broom"
            color="#7b4397"
          />
          <TrayItemWidget
            model={{ type: "Unique" }}
            name="Unique"
            icon="code-branch"
            color="#7b4397"
          />
          <TrayItemWidget
            model={{ type: "Union" }}
            name="Union"
            icon="layer-group"
            color="#7b4397"
          />
          <TrayItemWidget
            model={{ type: "Filter" }}
            name="Filter"
            icon="filter"
            color="linear-gradient(to right, #de6262, #ffb88c"
          />
          <TrayItemWidget
            model={{ type: "Join" }}
            name="Join"
            icon="columns"
            color="linear-gradient(to right, #de6262, #ffb88c"
          />
          <TrayItemWidget
            model={{ type: "Group" }}
            name="GroupBy"
            icon="object-group"
            color="linear-gradient(to right, #de6262, #ffb88c"
          />
          <TrayItemWidget
            model={{ type: "Formula" }}
            name="Function"
            icon="square-root-alt"
            color="linear-gradient(to right, #de6262, #ffb88c"
          />
        </TrayWidget>
      </div>
    </div>
  );
}

export default ToolPallette;
