import React, { useState, useEffect } from "react";

import { useHistory } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faPlus,
  faArrowLeft,
  faDatabase,
  faSms,
  faEnvelope,
} from "@fortawesome/free-solid-svg-icons";
import {
  Col,
  Row,
  Button,
  Modal,
  Card,
  Alert,
  InputGroup,
  FormControl,
  Form,
} from "@themesberg/react-bootstrap";


import { connect } from "react-redux";
import { withFirebase } from "../../../Firebase";

import { compose } from "recompose";


import axios from "axios";

import shortid from "shortid";
shortid.characters('0123456789abcdefghijklmnopqrstuvwxyzABCDEFGHIJKLMNOPQRSTUVWXYZ$@');

const vendorConfigs = {
  at: {
    bulk: "bulk",
    username: "michaelngugin",
    apiKey: "e7d0887a58ef17a69cdc8b63e9d474f1a98f97fb64c431eec7d5223f33fc0437",
    phoneColumn: "",
    message: "",
    disableSend: "true",
  },
};

function SettingsPage(props) {
  const [authorization, setAuthorization] = useState(false);
  const [sms_credentials, setSMSCredentials] = useState(false);
  const [own_smtp_server, setOwnSmtpServer] = useState(false);
  const [BusinessAccount, setBusinessAccount] = useState(null);
  const [SenderIds, setSenderIds] = useState(null);
  const [authUser, setAuth] = useState(null);

  const sender_name = useFormInput("");
  const sender_email = useFormInput("");
  const smtp_server = useFormInput("");
  const port = useFormInput("465");
  const security = useFormInput("ssl");
  const username = useFormInput("");
  const password = useFormInput("");
  const sms_csp = useFormInput("BuniComSMS");
  const sms_username = useFormInput("");
  const sms_api_key = useFormInput("");

  const [showDefault, setShowDefault] = useState(false);
  const [ModaRef, setModalRef] = useState(React.createRef());
  const [emailConfigStatus, setEmailConfigStatus] = useState(
    "Saving template, please wait ..."
  );
  const handleClose = () => setShowDefault(false);

  
  useEffect(() => {
    const unsubscribe = props.firebase.auth.onAuthStateChanged((authUser) => {
      if (authUser) {
        setAuth(authUser);

        // Get business account and save details

        let current_bsa = props.firebase.db
          .collection("business_accounts")
          .where("uid", "==", authUser.uid)
          .get()
          .then((querySnapshot) => {
            let bsa = {};
            querySnapshot.forEach((doc) => {
              // doc.data() is never undefined for query doc snapshots
              //   console.log(doc.id, " => ", doc.data());
              bsa = { ...doc.data(), id: doc.id };
            });

            if (BusinessAccount == null) {
              setBusinessAccount(bsa);
              let current_senderIds = props.firebase.db
                .collection("organisation_senderids")
                .where("orgId", "==", authUser.uid)
                .get()
                .then((querySnapshot) => {
                  let senderId = [];
                  querySnapshot.forEach((doc) => {
                    // doc.data() is never undefined for query doc snapshots
                    console.log(doc.id, " => ", doc.data());
                    senderId.push({ ...doc.data(), id: doc.id });
                  });

                  setSenderIds(senderId);
                })
                .catch((error) => {
                  console.log("Error getting documents: ", error);
                });
            }

            
          })
          .catch((error) => {
            console.log("Error getting documents: ", error);
          });
      } else {
        setAuth(null);
        //save null business account
      }
    });

    return () => {
      unsubscribe();
    };
  });

  useEffect(() => {
    // Your code here
    document.title = `My Settings - Bunicom Studio`;


    getConfigs(props.match.params.org_id);
  }, []);
  const getConfigs = (app_id) => {
    axios
      .get(
        "https://api.bunicom.com/get-configs?application_id=" +
          app_id
      )
      .then((response) => {
        console.log(response.data);

        if (response.data.length > 0) {
          let configs = response.data[0];

          updateFields(configs);
        }
        // history.push("/org/" + props.match.params.org_id + "/EmailConfigs")
      })
      .catch((e) => {
        console.log(e.message);
      });
  };

  function updateFields(configs) {
    sender_name.onReset(configs["sender_name"]);
    sender_email.onReset(configs["sender_email"]);
    smtp_server.onReset(configs["smtp_server"]);
    port.onReset(configs["port"]);
    security.onReset(configs["security"]);
    username.onReset(configs["username"]);
    password.onReset(configs["password"]);
    setAuthorization(configs["authorization"]);

    if (configs.hasOwnProperty("sms_csp")) {
      sms_csp.onReset(configs["sms_csp"]);
    }
    if (configs.hasOwnProperty("sms_username")) {
      sms_username.onReset(configs["sms_username"]);
    }
    if (configs.hasOwnProperty("sms_api_key")) {
      sms_api_key.onReset(configs["sms_api_key"]);
    }

    if (configs.hasOwnProperty("sms_credentials")) {
      setSMSCredentials(configs["sms_credentials"]);
    }

    if (configs.hasOwnProperty("own_smtp_server")) {
      setOwnSmtpServer(configs["own_smtp_server"]);
    }
  }

  const saveEmailConfigs = (e) => {
    e.preventDefault();
    const _id = shortid.generate();

    let payload = {
      sender_name: sender_name.value,
      sender_email: sender_email.value,
      smtp_server: smtp_server.value,
      port: port.value,
      security: security.value,
      username: username.value,
      password: password.value,
      sms_csp: sms_csp.value,
      sms_api_key: sms_api_key.value,
      sms_username: sms_username.value,
      authorization: authorization,
      sms_credentials: sms_credentials,
      own_smtp_server: own_smtp_server,
      application_id: props.match.params.org_id,
      _id: _id,
    };

    setEmailConfigStatus("Saving Confingurations, please wait ...");

    setShowDefault(true);

    axios
      .post(
        "https://api.bunicom.com/save-emailconfigs",
        payload
      )
      .then((response) => {
        console.log(response.data);
        // history.push("/org/" + props.match.params.org_id + "/EmailConfigs")
        setEmailConfigStatus("Configurations saved successfully");

        setTimeout(() => {
          setShowDefault(false);
        }, 1000);
      })
      .catch((e) => {
        setShowDefault(false);
        setEmailConfigStatus("Failed to save Configurations. Please try again");
      });

    console.log(payload);
  };

  const handleInputChange = (event, label) => {
    const target = event.target;
    const value = target.type === "checkbox" ? target.checked : target.value;
    const name = target.name;

    console.log(label);
    console.log(value);
    if (label == "sms_credentials") {
      setSMSCredentials(value);
    }
    if (label == "authorization") {
      setAuthorization(value);
    }
    if (label == "own_smtp_server") {
      setOwnSmtpServer(value);
    }
    console.log(name);
  };

  return (
    <div className="container-fluid mt--6">
      <Modal
        as={Modal.Dialog}
        centered
        show={showDefault}
        ref={ModaRef}
        onHide={handleClose}
      >
        <Modal.Header>
          <Modal.Title className="h6">{emailConfigStatus}</Modal.Title>
          <Button variant="close" aria-label="Close" onClick={handleClose} />
        </Modal.Header>
      </Modal>
      <div className="row justify-content-center">
        <div className="col-lg-10 card-wrapper">
          <div className="d-block mb-4 mb-md-0">
            <Alert variant="secondary" show={true}>
              <div className="d-flex justify-content-between">
                <div>
                  <FontAwesomeIcon icon={faDatabase} className="me-1" />
                  <strong>Global SMS &amp; Email Configuration!</strong> You can
                  use our predefined SMS &amp; EMail vendors. You can overwrite
                  any vendor by entering your configurations
                </div>
              </div>
            </Alert>
          </div>

          <div className="card mt-2">
            <div className="card-body">
              <div className="row" id="recordsTable">
                <form className="mt-1" onSubmit={saveEmailConfigs}>
                  <div className="row">
                    <h5>Email Configurations</h5>
                    <hr />
                    <Col xs={12} md={12} lg={12} xl={12}>
                      <Form.Group className="mb-3">
                        <Form.Check
                          label="I have my own SMTP Server"
                          id="custom"
                          htmlFor="checkbox1"
                          name="sms_credentials"
                          checked={own_smtp_server}
                          onChange={(e) =>
                            handleInputChange(e, "own_smtp_server")
                          }
                        />
                      </Form.Group>
                    </Col>
                  </div>
                  {own_smtp_server ? (
                    <Row className="justify-content-between align-items-center p-2">
                      <Col xs={12} md={6} lg={6} xl={6}>
                        <Form.Group size="sm" className="mb-3">
                          <Form.Label>
                            {" "}
                            Sender name <span style={{ color: "red" }}>*</span>
                          </Form.Label>
                          <FormControl
                            type="text"
                            name="sender_name"
                            {...sender_name}
                            required
                          />
                        </Form.Group>
                      </Col>
                      <Col xs={12} md={6} lg={6} xl={6}>
                        <Form.Group size="sm" className="mb-3">
                          <Form.Label>
                            {" "}
                            Sender Email <span style={{ color: "red" }}>*</span>
                          </Form.Label>
                          <FormControl
                            type="text"
                            name="sender_email"
                            {...sender_email}
                            required
                          />
                        </Form.Group>
                      </Col>
                      <Col xs={12} md={6} lg={6} xl={6}>
                        <Form.Group size="sm" className="mb-3">
                          <Form.Label>
                            {" "}
                            SMTP address<span style={{ color: "red" }}>*</span>
                          </Form.Label>
                          <FormControl
                            type="text"
                            placeholder=""
                            name="smtp_server"
                            {...smtp_server}
                            required
                          />
                        </Form.Group>
                      </Col>
                      <Col xs={12} md={6} lg={6} xl={6}>
                        <Form.Group size="sm" className="mb-3">
                          <Form.Label>
                            {" "}
                            SMTP port<span style={{ color: "red" }}>*</span>
                          </Form.Label>
                          <FormControl
                            type="number"
                            name="port"
                            {...port}
                            required
                          />
                          <small>25, 2525, 465, 587</small>
                        </Form.Group>
                      </Col>
                      <Col xs={12} md={6} lg={6} xl={6}>
                        <Form.Group size="sm" className="mb-3">
                          <Form.Label>
                            {" "}
                            Security<span style={{ color: "red" }}>*</span>
                          </Form.Label>

                          <Form.Control
                            as="select"
                            name="security"
                            {...security}
                            required
                          >
                            <option value="auto">Auto</option>
                            <option value="ssl">SSL</option>
                            <option value="Ecommerce">TLS</option>
                          </Form.Control>
                        </Form.Group>
                      </Col>
                      <Col xs={12} md={6} lg={6} xl={6}>
                        <Form.Group className="mb-3">
                          <Form.Check
                            label="SMTP server requires authorization."
                            id="requires_authorization"
                            htmlFor="checkbox1"
                            name="authorization"
                            checked={authorization}
                            onChange={(e) =>
                              handleInputChange(e, "authorization")
                            }
                          />
                        </Form.Group>
                      </Col>
                      <Col xs={12} md={6} lg={6} xl={6}>
                        <Form.Group size="sm" className="mb-3">
                          <Form.Label>
                            {" "}
                            Username<span style={{ color: "red" }}>*</span>
                          </Form.Label>

                          <FormControl
                            type="text"
                            name="username"
                            {...username}
                            required
                          />
                        </Form.Group>
                      </Col>
                      <Col xs={12} md={6} lg={6} xl={6}>
                        <Form.Group size="sm" className="mb-3">
                          <Form.Label>
                            {" "}
                            Password<span style={{ color: "red" }}>*</span>
                          </Form.Label>

                          <FormControl
                            type="password"
                            name="password"
                            {...password}
                            required
                          />
                        </Form.Group>
                      </Col>
                    </Row>
                  ) : (
                    ""
                  )}
                  <div className="row">
                    <h5>SMS Configurations</h5>
                    <hr />
                    <Col xs={12} md={12} lg={12} xl={12}>
                      <Form.Group className="mb-3">
                        <Form.Check
                          label="I have my own SenderID"
                          id="custom"
                          htmlFor="checkbox1"
                          name="sms_credentials"
                          checked={sms_credentials}
                          onChange={(e) =>
                            handleInputChange(e, "sms_credentials")
                          }
                        />
                      </Form.Group>
                    </Col>
                  </div>
                  {sms_credentials ? (
                    <div className="row mb-4">
                      <Col xs={12} md={6} lg={6} xl={6}>
                        <Form.Group size="sm" className="mb-3">
                          <Form.Label>
                            {" "}
                            Choose your preferred Sender ID
                            <span style={{ color: "red" }}>*</span>
                          </Form.Label>

                          <Form.Control
                            as="select"
                            name="sms_csp"
                            {...sms_csp}
                            required
                          >
                            <option value="BuniComSMS">BuniComSMS</option>

                            {SenderIds
                            ? SenderIds.map((item, key) => (
                                <option key={key} value={item.sender_name}>
                                  {item.sender_name}
                                </option>
                              ))
                            : ""}
                            {/* <option value="ssl">MobileSasa</option> */}
                          </Form.Control>
                        </Form.Group>
                      </Col>

                      {/* <Col xs={12} md={6} lg={6} xl={6}>
                        <Form.Group size="sm" className="mb-3">
                          <Form.Label>
                            {" "}
                            Username<span style={{ color: "red" }}>*</span>
                          </Form.Label>

                          <FormControl
                            type="text"
                            name="sms_username"
                            {...sms_username}
                            required
                          />
                        </Form.Group>
                      </Col>
                      <Col xs={12} md={6} lg={6} xl={6}>
                        <Form.Group size="sm" className="mb-3">
                          <Form.Label>
                            {" "}
                            API Key<span style={{ color: "red" }}>*</span>
                          </Form.Label>

                          <FormControl
                            type="password"
                            name="sms_api_key"
                            {...sms_api_key}
                            required
                          />
                        </Form.Group>
                      </Col> */}
                    </div>
                  ) : (
                    ""
                  )}

                  <div className="row">
                    <Col md={12}>
                      <Button variant="secondary" size="md" type="submit">
                        <FontAwesomeIcon icon={faEnvelope} className="me-1" />{" "}
                        Save Configuration
                      </Button>
                    </Col>
                    {/* <Col md={6}>SMTP encryption protocol</Col> */}
                  </div>
                </form>
              </div>
            </div>
          </div>

          {/* <div className="card">
            <div className="card-body">
              <div className="row" id="recordsTable">
                <h5>SMS Vendor Configurations</h5>
                <hr />

                <Form.Group>
                  <Form.Label>SMS Bulk SMS providers</Form.Label>
                  <Form.Control
                    as="select"
                    name="sheet"
                    value="Default"
                    className="mb-3"
                  >
                    <option value="at">AfricasTalking</option>
                  </Form.Control>
                  <Form.Label>Username</Form.Label>
                  <Form.Control
                    type="text"
                    disabled
                    value={vendorConfigs["at"].username}
                    placeholder="Text"
                    className="mb-3"
                  />
                  <Form.Label>API Key</Form.Label>
                  <Form.Control
                    type="password"
                    disabled
                    value={vendorConfigs["at"].apiKey}
                    placeholder="Text"
                    className="mb-3"
                  />
                </Form.Group>

                <Row className="pt-4">
                  <h5>Test</h5>
                  <Col>
                    <Form.Control placeholder="Enter Phone number" />
                  </Col>
                  <Col>
                    <Button variant="success" size="lg">
                      <FontAwesomeIcon icon={faSms} className="me-1" /> Send
                      test SMS
                    </Button>
                  </Col>
                </Row>
              </div>
            </div>
          </div> */}
        </div>
      </div>
    </div>
  );
}

const useFormInput = (initialValue) => {
  const [value, setValue] = useState(initialValue);

  const handleChange = (e) => {
    setValue(e.target.value);
  };

  function handleReset(val) {
    setValue(val);
  }
  return {
    value,
    onChange: handleChange,
    onReset: handleReset,
  };
};


const mapStateToProps = (state) => ({});

const mapDispatchToProps = (dispatch) => {
  return {};
};

export default compose(
  connect(mapStateToProps, mapDispatchToProps),
  withFirebase
)(SettingsPage);
