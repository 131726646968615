import * as React from "react";
import * as SRD from "storm-react-diagrams";
import { RequestNodeModel } from "./RequestNodeModel";
import { RequestNodeWidget } from "./RequestNodeWidget";


export class RequestNodeFactory extends SRD.AbstractNodeFactory {
    constructor() {
        super("Request");
    }

    generateReactWidget(diagramEngine, node) {
        return <RequestNodeWidget node={node} />;
    }

    getNewInstance() {
        return new RequestNodeModel();
    }
}


