// import { takeLatest, call, put } from "redux-saga/effects";
import { call, fork, put, take, takeEvery, all } from "redux-saga/effects";
import axios from "axios";
// import { getToken, setUserSession } from "../Utils/Common";
// import history from "../Utils/history";

import { createBrowserHistory } from "history";
import * as _ from "lodash";
import { trim } from "jquery";



const history = createBrowserHistory({forceRefresh:true});
// import Firebase from './firebase';
// watcher saga: watches for actions dispatched to the store, starts worker saga


//= ====================================
//  TEMPLATES
//-------------------------------------

function fetchTemplate(id) {
  return axios.get("https://api.bunicom.com//get-entity?_id=" + id);
}

function fetchAllTemplates() {
  return axios
  .get(
    "https://api.bunicom.com/get-online-templates?privacy=public"
  )
}

function fetchTemplates(app_id) {
  return axios
  .get(
    "https://api.bunicom.com/get-templates?application_id=" +
    app_id
  )
}


function handleTemplateCreate(template) {
  let payload = template.template;

  return axios.post("https://api.bunicom.com//save-entity", payload);
}


function* fetchtemplateSaga(data) {
  try {
    console.log("=====================Template===============");
    console.log(data);
    const response = yield call(fetchTemplate, data._id);

    console.log(response.data);
    let template = response.data;

    yield put({
      type: "GET_TEMPLATE_SUCCESS",
      template: template,
    });

    // yield history.push("/app/"+TABLE._id);
    //     yield history.go();
  } catch (error) {
    yield put({ type: "GET_TEMPLATE_FAILURE", error });
  }
}

function* fetchtemplatesSaga(data) {
  try {
    console.log("=====================Collection===============");
    console.log(data);
    const response = yield call(fetchTemplates, data.application_id);

    console.log(response.data);
    let templatesData = response.data;

    yield put({
      type: "GET_TEMPLATES_SUCCESS",
      templatesData: templatesData,
    });

    // yield history.push("/app/"+collection._id);
    //     yield history.go();
  } catch (error) {
    yield put({ type: "GET_TEMPLATES_FAILURE", error });
  }
}

function* fetchalltemplatesSaga(data) {
  try {
    const response = yield call(fetchAllTemplates);

    console.log(response.data);
    let AlltemplatesData = response.data;

    yield put({
      type: "GET_ALL_TEMPLATES_SUCCESS",
      AlltemplatesData: AlltemplatesData,
    });

    // yield history.push("/app/"+collection._id);
    //     yield history.go();
  } catch (error) {
    yield put({ type: "GET_ALL_TEMPLATES_FAILURE", error });
  }
}

function* templateSaga(table) {
  try {
    const response = yield call(handleTemplateCreate, table);

    console.log(response.data);

    let status = response.data.message;
    let error = {
      message: "Template with same title found!",
    };

    switch (status) {
      case "EXISTING_TITLE":
        yield put({ type: "NEW_TABLE_FAILURE", error });
        break;

      case "OK":
        let template = response.data.template;

        yield put({
          type: "NEW_TEMPLATE_SUCCESS",
          template: template,
        });
     

        break;
      default:
        error.message = "Something went wrong. Please login again";

        yield put({ type: "NEW_TEMPLATE_FAILURE", error });
    }

  } catch (error) {
    yield put({ type: "NEW_TEMPLATE_FAILURE", error });
  }
}




//= ====================================
//  TABLES
//-------------------------------------

function fetchTable(id) {
  return axios.get("https://api.bunicom.com//get-entity?_id=" + id);
}

function fetchTables(apps_id) {
  return axios.get(
    "https://api.bunicom.com//get-entities?application_id=" + apps_id
  );
}


function handleTableCreate(table) {
  let payload = table.table;

  return axios.post("https://api.bunicom.com//save-entity", payload);
}


function* fetchtableSaga(data) {
  try {
    console.log("=====================Collection===============");
    console.log(data);
    const response = yield call(fetchTable, data._id);

    console.log(response.data);
    let table = response.data;

    yield put({
      type: "GET_TABLE_SUCCESS",
      table: table,
    });

    yield put({
      type: "GET_FIELDS_REQUEST",
      _id: table._id 
    });

    yield put({
      type: "GET_TABLE_RECORDS_REQUEST",
      entity_title: table.application_id + table.title +
      "_records",
    });

    // yield history.push("/app/"+TABLE._id);
    //     yield history.go();
  } catch (error) {
    yield put({ type: "GET_TABLE_FAILURE", error });
  }
}

function* fetchtablesSaga(data) {
  try {
    console.log("=====================Collection===============");
    console.log(data);
    const response = yield call(fetchTables, data.application_id);

    console.log(response.data);
    let tableData = response.data;

    yield put({
      type: "GET_TABLES_SUCCESS",
      tableData: tableData,
    });

    // yield history.push("/app/"+collection._id);
    //     yield history.go();
  } catch (error) {
    yield put({ type: "GET_TABLES_FAILURE", error });
  }
}

function* tableSaga(table) {
  try {
    const response = yield call(handleTableCreate, table);

    console.log(response.data);

    let status = response.data.message;
    let error = {
      message: "Table with same title found!",
    };

    switch (status) {
      case "EXISTING_TITLE":
        yield put({ type: "NEW_TABLE_FAILURE", error });
        break;

      case "OK":
        let table = response.data.table;

        yield put({
          type: "NEW_TABLE_SUCCESS",
          table: table,
        });
        //Close Dialog afte r a successful add
        yield put({
          type: "SHOW_TABLE_DIALOG",
          dialog: false,
        });

        break;
      default:
        error.message = "Something went wrong. Please login again";

        yield put({ type: "NEW_TABLE_FAILURE", error });
    }

  } catch (error) {
    yield put({ type: "NEW_TABLE_FAILURE", error });
  }
}

//= ====================================
//  TABLE RECORDS
//-------------------------------------

function fetchTableRecord(id) {
  return axios.get("https://api.bunicom.com//get-entities?_id=" + id);
}

function fetchTableRecords(entity_title) {
  return axios.get(
    "https://api.bunicom.com///get-records?entity_title=" + entity_title
  );
}

function fetchMessageLogs(appid) {
  return axios.get(
    "https://api.bunicom.com/get-message-logs-all?application_id=" + appid
  );
}

function handleTableRecordUpdate(load) {

  return axios.post(
    "https://api.bunicom.com///update-entity-records", load
  );

}


function handleTableRecordCreate(table) {
  // let payload = table.table;
  let payload = {
        dataset: table.records,
        entity: table.entity,
      };

  return axios.post("https://api.bunicom.com///save-entity-records", payload);
}


function* fetchtableRecordSaga(data) {
  try {
    console.log("=====================Collection===============");
    console.log(data);
    const response = yield call(fetchTableRecord, data._id);

    console.log(response.data);
    let table_records = response.data;

    yield put({
      type: "GET_TABLE_RECORD_SUCCESS",
      table_records: table_records,
    });

    // yield history.push("/app/"+TABLE._id);
    //     yield history.go();
  } catch (error) {
    yield put({ type: "GET_TABLE_RECORD_FAILURE", error });
  }
}


function* fetchlogsSaga(data) {
  try {
    

    const response = yield call(fetchMessageLogs, data.appid);
    let message_logs = response.data;
   
    console.log("GET MESSAGE_LOGS");
    console.log(message_logs);
    if(Array.isArray(message_logs)){
      message_logs = response.data;
    }else {
      message_logs = JSON.parse(response.data.replaceAll("NaN", 0));
    }

    yield put({
      type: "GET_MESSAGE_LOGS_SUCCESS",
      message_logs: message_logs,
    });

    // yield history.push("/app/"+collection._id);
    //     yield history.go();
  } catch (error) {

    console.log(error);
    yield put({ type: "GET_MESSAGE_LOGS_FAILURE", error });
  }
}
function* fetchtableRecordsSaga(data) {
  try {
    console.log("=====================dATA ENTITY RECORDS===============");
    console.log(data);

    const response = yield call(fetchTableRecords, data.entity_title);
    let table_records = response.data;
   
    console.log("GET TABLE RECORDS");
    console.log(table_records);
    if(Array.isArray(table_records)){
      table_records =response.data;
    }else {
      table_records = JSON.parse(response.data.replaceAll("NaN", 0));
    }

    yield put({
      type: "GET_TABLE_RECORDS_SUCCESS",
      table_records: table_records,
    });

    // yield history.push("/app/"+collection._id);
    //     yield history.go();
  } catch (error) {

    console.log(error);
    yield put({ type: "GET_TABLE_RECORDS_FAILURE", error });
  }
}

function* tableRecordSaga(payload) {
  try {
    const response = yield call(handleTableRecordCreate, payload.payload);

    console.log(response.data);

    let status = response.data.status;
    let error = {
      message: "Table records with same title found!",
    };

    switch (status) {
      case "EXISTING_TITLE":
        yield put({ type: "NEW_TABLE_RECORD_FAILURE", error });
        break;

      case "OK":

        yield put({
          type: "GET_TABLE_RECORDS_REQUEST",
          entity_title: payload.payload.entity.application_id + payload.payload.entity.title +
          "_records",
        });
      
      
        //Close Dialog afte r a successful add
        // yield put({
        //   type: "SHOW_TABLE_RECORD_DIALOG",
        //   dialog: false,
        // });

        break;
      default:
        error.message = "Something went wrong. Please login again";

        yield put({ type: "NEW_TABLE_RECORD_FAILURE", error });
    }

  } catch (error) {
    yield put({ type: "NEW_TABLE_RECORD_FAILURE", error });
  }
}



function* tableRecordUpdateSaga(load) {
  try {
    const response = yield call(handleTableRecordUpdate, load.load);

    console.log(response.data);

    let status = response.data.status;
    let error = {
      message: "Something went wrong.",
    };

    switch (status) {
      case "EXISTING_TITLE":
        yield put({ type: "NEW_TABLE_RECORD_FAILURE", error });
        break;

      case "OK":
        // let table_records = response.data.table_records;

        yield put({
          type: "UPDATE_TABLE_RECORD_SUCCESS",
          // table_records: table_records,
        });

        console.log("Fetch new records");

        yield put(
          { type: "GET_TABLE_RECORDS_REQUEST",
            entity_title: load.load.entity.application_id + load.load.entity.title + "_records" }
        );

        
        //Close Dialog afte r a successful add
        // yield put({
        //   type: "SHOW_TABLE_RECORD_DIALOG",
        //   dialog: false,
        // });

        break;
      default:
        error.message = "Something went wrong. Please login again";

        yield put({ type: "NEW_TABLE_RECORD_FAILURE", error });
    }

  } catch (error) {
    yield put({ type: "NEW_TABLE_RECORD_FAILURE", error });
  }
}



//= ====================================
//  FIELDS RECORDS
//-------------------------------------

function fetchfield(entity_id) {
  return axios.get("https://api.bunicom.com//get-fields?entity_id=" + entity_id);
}

function fetchfields(entity_title) {
  return axios.get(
    "https://api.bunicom.com///get-fields?entity_id=" + entity_title
  );
}


function handlefieldCreate(field) {
  let payload = field;

  return axios.post("https://api.bunicom.com//save-fields", payload);
}


function* fetchfieldSaga(data) {
  try {
    const response = yield call(fetchfield, data._id);

    console.log(response.data);
    let field = response.data;

    yield put({
      type: "GET_FIELD_SUCCESS",
      field: field,
    });

    // yield history.push("/app/"+TABLE._id);
    //     yield history.go();
  } catch (error) {
    yield put({ type: "GET_FIELD_FAILURE", error });
  }
}

function* fetchfieldsSaga(data) {
  try {
    console.log("=====================dATA ENTITY RECORDS===============");
    console.log(data);
    const response = yield call(fetchfields, data._id);

    console.log(response.data);
    let fields = response.data;

    let fields_arr = fields.map((field) => {
      return {
        dataField: field.field_name,
        text: field.field_name,
        // validator: (newValue, row, column) => {
        //   if (isNaN(newValue)) {
        //     return {
        //       valid: false,
        //       message: 'Price should be numeric'
        //     };
        //   }
        //   if (newValue < 2000) {
        //     return {
        //       valid: false,
        //       message: 'Price should bigger than 2000'
        //     };
        //   }
        //   return true;
        // }
      };
    });

    yield put({
      type: "GET_FIELDS_SUCCESS",
      fields:[ {
        dataField: "_id",
        text: "ID",
      },...fields_arr],
    });

    // yield history.push("/app/"+collection._id);
    //     yield history.go();
  } catch (error) {
    yield put({ type: "GET_FIELDS_FAILURE", error });
  }
}

function* fieldSaga(field) {
  try {
    const response = yield call(handlefieldCreate, field.field.data);

    console.log(response.data);

    let status = response.data.status;
    let error = {
      message: "Field records with same title found!",
    };

    switch (status) {
      case "EXISTING_TITLE":
        yield put({ type: "NEW_FIELD_FAILURE", error });
        break;

      case "OK":
        // let fields = response.data.fields;

        // yield put({
        //   type: "NEW_FIELD_SUCCESS",
        //   fields: fields,
        // });
        //Close Dialog afte r a successful add
        yield put({
          type: "SHOW_FIELD_DIALOG",
          dialog: false,
        });
        yield put({
          type: "GET_FIELDS_REQUEST",
          _id: field.field.entity._id 
        });

        break;
      default:
        error.message = "Something went wrong. Please login again";

        yield put({ type: "NEW_FIELD_FAILURE", error });
    }

  } catch (error) {
    yield put({ type: "NEW_FIELD_FAILURE", error });
  }
}

//= ====================================
//  HANDLE LOGIN
//-------------------------------------


function handleLogin(usr) {
  console.log("=============USER===================");
  console.log(usr);

  return [];
}

function handleGetMenuData(data) {
  console.log("=============USER===================");
  let payload_temp = { ...data.data };
  let payload = { ...payload_temp, file: payload_temp.file_name };

  return axios.post(
    "https://api.bunicom.com/inputconfigs",
    payload
  );
}


function handleSaveMenuData(data) {
  console.log("=============DATA===================");
  let payload_temp = { ...data.data.csvParams };
  let payload = { ...payload_temp, file: payload_temp.file_name, file_name: "Test_Entity", application_id: data.data.application_id };

  console.log(payload);

  return axios.post("https://api.bunicom.com/savefromexcel",payload)
}


function handleRegister(usr) {
  // console.log("=============USER===================");
  // console.log(usr);
  let user = usr.user;
  let payload = {
    username: user.email.value,
    password: user.password.value,
    firstname: user.firstname.value,
    Lastname: user.lastname.value,
    email: user.email.value,
    phone: user.phone.value,
    source: user.source.value,
    company: user.role.value,
    role: user.role.value,
  };

  return axios({
    method: "post",
    url: "https://api.bunicom.com//register-user",
    timeout: 30000, // Wait for 30 seconds
    headers: {
      "Content-Type": "application/json",
    },
    data: payload,
  });
  // post("", payload);
}

function* loginSaga(user) {
  try {
    // console.log();
    const response = yield call(handleLogin, user);

    console.log(response);
    let status = response.data.message;
    let error = {
      message: "Could not find user",
    };

    switch (status) {
      case "NOT_FOUND":
        yield put({ type: "LOGIN_FAILURE", error });
        break;

      case "OK":
        let user = response.data.user;
        // user = setUserSession(user._id, user.firstname + " " + user.Lastname);
        yield put({ type: "LOGIN_SUCCESS", user });
        yield history.push("/apps");
        yield history.go();
        break;
      default:
        error.message = "Something went wrong. Please login again";

        yield put({ type: "LOGIN_FAILURE", error });
    }
  } catch (error) {
    yield put({ type: "LOGIN_FAILURE", error });
  }
}

function* menuSaga(user) {
  try {
    // console.log();
    const response = yield call(handleLogin, user);

    console.log(response);
    let status = response.data.message;
    let error = {
      message: "Could not find user",
    };

    switch (status) {
      case "NOT_FOUND":
        yield put({ type: "LOGIN_FAILURE", error });
        break;

      case "OK":
        let user = response.data.user;
        // user = setUserSession(user._id, user.firstname + " " + user.Lastname);
        yield put({ type: "LOGIN_SUCCESS", user });
        yield history.push("/apps");
        yield history.go();
        break;
      default:
        error.message = "Something went wrong. Please login again";

        yield put({ type: "LOGIN_FAILURE", error });
    }
  } catch (error) {
    yield put({ type: "LOGIN_FAILURE", error });
  }
}
function arraysEqual(a, b) {
  if (a === b) return true;
  if (a == null || b == null) return false;
  if (a.length !== b.length) return false;

  // If you don't care about the order of the elements inside
  // the array, you should sort both arrays here.
  // Please note that calling sort on an array will modify that array.
  // you might want to clone your array first.

  for (var i = 0; i < a.length; ++i) {
    if (a[i] !== b[i]) return false;
  }
  return true;
}

function* registerSaga(user) {
  try {
    // console.log();
    const response = yield call(handleRegister, user);
    let status = response.data.message;
    let error = {
      message: "Could not register user",
    };

    switch (status) {
      case "NOT_FOUND":
        yield put({ type: "REGISTER_WITH_EMAIL_FAILURE", error });
        break;

      case "OK":
        let user = response.data.user;
        // user = setUserSession(user._id, user.firstname + " " + user.Lastname);
        yield put({ type: "REGISTER_WITH_EMAIL_SUCCESS", user });
        yield history.push("/apps");
        yield history.go();
        break;
      default:
        error.message = "Something went wrong. Please register again";

        yield put({ type: "REGISTER_WITH_EMAIL_FAILURE", error });
    }
  } catch (error) {
    yield put({ type: "REGISTER_WITH_EMAIL_FAILURE", error });
  }
}

//Import Data from CSV
function* menuDataSaga(data) {
  try {
    // console.log();
    const response = yield call(handleGetMenuData, data);

    console.log(response.status);
    console.log("I AM HERE");
    let status = response.status;
    let error = {
      message: "Could not find Data",
    };
    // yield put({ type: "SAVED_IMPORT" });
   
    // user = setUserSession(user._id, user.firstname + " " + user.Lastname);
   

    switch (status) {
      case "NOT_FOUND":
        yield put({ type: "LOGIN_FAILURE", error });
        break;

      case 200:
        let response_data = response.data.preview_data;
        console.log(response_data);
        let column_list = response.data.metaData.column_list
        // let required_columns = ['name','description',"price","category","subcategory"]
        let trimmed_columns = column_list.map((e)=> { return trim(e) })
        
        yield put({ type: "SAVE_DATA", bulk_data:{ columns: trimmed_columns, data: response_data} });

        //TODO Save columns to store
        break;
      default:
        error.message = "Something went wrong. Please check the column row";
        yield put({ type: "SAVE_DATA", bulk_data: null});

        yield put({ type: "LOGIN_FAILURE", error });
    }
  } catch (error) {
    error = {
      message: "Failed to set data. Please chek if you have selected a sheet and column row - Columns must be unique",
    };
    yield put({ type: "SAVE_DATA", bulk_data: null});

    yield put({ type: "LOGIN_FAILURE", error });
  }
}

//Import Data from CSV
function* saveDataSaga(data) {
  try {
    // console.log();
    const response = yield call(handleSaveMenuData, data);

    console.log(data.data.application_id);
    let status = response.status
    let entity_id = response.data.entityid
    switch (status) {
      case 200:
        yield put({ type: "SAVED_IMPORT" });
        yield history.push("/org/"+data.data.application_id+"/table/"+entity_id);
        break;
        default:
          let error = {
            message: "Something went wrong. Please check your connection",
          };
  
          yield put({ type: "IMPORT_FAILURE", error });
    }
 
  } catch (error) {
    yield put({ type: "IMPORT_FAILURE", error });
  }
}

function getWorkflow(id) {
  return axios.get("https://api.bunicom.com//get-workflow?_id=" + id);
}

function handleWorkflowCreate(workflow) {
  let payload = workflow.workflow;

  return axios.post("https://api.bunicom.com//new-workflow", payload);
}

function* workflowSaga(workflow) {
  try {
    const response = yield call(handleWorkflowCreate, workflow);

    console.log(response.data);

    let status = response.data.message;
    let error = {
      message: "Workflow with same title found!",
    };

    switch (status) {
      case "EXISTING_TITLE":
        yield put({ type: "NEW_WORKFLOW_FAILURE", error });
        break;

      case "OK":
        let workflow = response.data.workflow;

        yield put({
          type: "NEW_WORKFLOW_SUCCESS",
          workflow: workflow,
        });
       
        break;
      default:
        error.message = "Something went wrong. Please login again";

        yield put({ type: "NEW_WORKFLOW_FAILURE", error });
    }

    // let WORKFLOW = app WORKFLOW
  } catch (error) {
    yield put({ type: "NEW_WORKFLOW_FAILURE", error });
  }
}
function* getworkflowSaga(data) {
  try {
    console.log("=====================APP===============");
    console.log(data);
    const response = yield call(getWorkflow, data.id);

    console.log(response.data);
    let workflow = response.data;

    yield put({
      type: "GET_WORKFLOW_SUCCESS",
      workflow: workflow,
    });

    // yield history.push("/app/"+application._id);
    //     yield history.go();
  } catch (error) {
    yield put({ type: "GET_WORKFLOW_FAILURE", error });
  }
}



//= ====================================
//  WATCHERS
//-------------------------------------

function* watcherSaga() {
  yield all([
    // takeEvery("API_CALL_REQUEST", workerSaga),
    takeEvery("LOGIN_REQUEST", loginSaga),
    takeEvery("MENUS_REQUEST", menuSaga),
    takeEvery("GET_DATA_REQUEST", menuDataSaga),
    takeEvery("SAVE_DATA_REQUEST", saveDataSaga),
    takeEvery("REGISTER_WITH_EMAIL_REQUEST", registerSaga),


    takeEvery("NEW_TABLE_REQUEST", tableSaga),
    takeEvery("GET_TABLES_REQUEST", fetchtablesSaga),
    takeEvery("GET_TABLE_REQUEST", fetchtableSaga),

    takeEvery("NEW_TEMPLATE_REQUEST", templateSaga),
    takeEvery("GET_TEMPLATES_REQUEST", fetchtemplatesSaga),
    takeEvery("GET_TEMPLATE_REQUEST", fetchtemplateSaga),
    takeEvery("GET_ALL_TEMPLATES_REQUEST", fetchalltemplatesSaga),


    takeEvery("NEW_TABLE_RECORD_REQUEST", tableRecordSaga),
    takeEvery("UPDATE_TABLE_RECORD_REQUEST", tableRecordUpdateSaga),
    takeEvery("GET_TABLE_RECORDS_REQUEST", fetchtableRecordsSaga),
    takeEvery("GET_TABLE_RECORD_REQUEST", fetchtableRecordSaga),

    takeEvery("GET_MESSAGE_LOGS_REQUEST", fetchlogsSaga),

    takeEvery("NEW_FIELD_REQUEST", fieldSaga),
    takeEvery("GET_FIELDS_REQUEST", fetchfieldsSaga),
    takeEvery("GET_FIELD_REQUEST", fetchfieldSaga),


    takeEvery("GET_WORKFLOW_REQUEST", getworkflowSaga),
    takeEvery("NEW_WORKFLOW_REQUEST", workflowSaga),
  ]);
}

const watchers = [fork(watcherSaga)];

export default function* sagas() {
  yield all([...watchers]);
}
