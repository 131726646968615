import React from "react";
import ReactDOM from "react-dom";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";

import ReactTextareaAutocomplete from "@webscopeio/react-textarea-autocomplete";
import "@webscopeio/react-textarea-autocomplete/style.css";

import Axios from "axios";
// import SelectTable from "./SelectTable"
import {
  Row,
  Col,
  Card,
  Button,
  InputGroup,
  Form,
  Alert,
} from "react-bootstrap";
import "./styles.css";

const Item = ({ entity: { name, char } }) => <div>{`${name}`}</div>;

class SMSNodeComponent extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      // Set initial files, type 'local' means this is a file
      // that has already been uploaded to the server (see docs)
      node: null,
      message_value: "",
    };

    this.handleChangeVendor = this.handleChangeVendor.bind(this);
    this.handleChangeUsername = this.handleChangeUsername.bind(this);
    this.handleChangeAPIkey = this.handleChangeAPIkey.bind(this);
    this.handleChangePhoneColumn = this.handleChangePhoneColumn.bind(this);
    this.handleChangeMode = this.handleChangeMode.bind(this);
    this.handleChangeMessage = this.handleChangeMessage.bind(this);
    this.handleChangeDisableSend = this.handleChangeDisableSend.bind(this);
    this.handleDragOver = this.handleDragOver.bind(this);
    this.handleDragStart = this.handleDragStart.bind(this);
    this.handleDrop = this.handleDrop.bind(this);
    this.appendHeader = this.appendHeader.bind(this);

    this.changeMessageText = this.changeMessageText.bind(this);
  }

  handleDragOver(event) {
    event.preventDefault();
    // setCreateDataStore(!createDataStore)
  }

  handleDragStart(e, name) {
    e.dataTransfer.setData("id", name);
  }

  appendHeader(e, header, msg) {
    let value = msg + "{{" + header + "}}";
    this.setState({ message_value: value });
    this.changeMessageText(value);
  }

  handleDrop(e, msg) {
    let id = e.dataTransfer.getData("id");

    let value = msg + "" + e.dataTransfer.getData("id");
    // message.onReset(value);
    // setMessageValue(value);
    // this.setState({message_value: value})
    this.changeMessageText(value);
  }

  handleChangeVendor(event) {}

  handleAnnotationChange(event, label) {
    const target = event.target;
    const value = target.value;
    const name = target.name;

    console.log(label);
    console.log(value);
    console.log(name);

    let node = this.props.node;
    let model = this.props.model;
    let currNode = model.getNode(node.id);

    currNode.properties[name] = value;

    this.props.updateNode();
    this.forceUpdate();
  }

  handleChangeVendor(event) {
    console.log(event.target.value);

    let node = this.props.node;
    let model = this.props.model;
    let currNode = model.getNode(node.id);

    if (event.target.value === "Default") {
      currNode.properties.message = "Please select a Vendor";
    } else {
      currNode.properties.smsConfigs.vendor = event.target.value;

      this.props.updateNode();
    }
  }

  handleChangeUsername(event) {
    console.log(event.target.value);

    let node = this.props.node;
    let model = this.props.model;
    let currNode = model.getNode(node.id);

    currNode.properties.smsConfigs.vendorConfigs[
      currNode.properties.smsConfigs.vendor
    ].username = event.target.value;

    this.props.updateNode();
  }
  handleChangeDisableSend(event) {
    console.log(event.target.value);

    let node = this.props.node;
    let model = this.props.model;
    let currNode = model.getNode(node.id);

    currNode.properties.smsConfigs.vendorConfigs[
      currNode.properties.smsConfigs.vendor
    ].disableSend = event.target.value;

    this.props.updateNode();
  }

  handleChangeAPIkey(event) {
    console.log(event.target.value);

    let node = this.props.node;
    let model = this.props.model;
    let currNode = model.getNode(node.id);

    currNode.properties.smsConfigs.vendorConfigs[
      currNode.properties.smsConfigs.vendor
    ].apiKey = event.target.value;

    this.props.updateNode();
  }

  handleChangePhoneColumn(event) {
    console.log(event.target.value);

    let node = this.props.node;
    let model = this.props.model;
    let currNode = model.getNode(node.id);

    if (event.target.value === "Default") {
      currNode.properties.message = "Please select a Phone";
    } else {
      currNode.properties.smsConfigs.vendorConfigs[
        currNode.properties.smsConfigs.vendor
      ].phoneColumn = event.target.value;
      this.props.updateNode();
    }
  }

  handleChangeMode(event) {
    console.log(event.target.value);

    let node = this.props.node;
    let model = this.props.model;
    let currNode = model.getNode(node.id);

    currNode.properties.smsConfigs.vendorConfigs[
      currNode.properties.smsConfigs.vendor
    ].bulk = event.target.value;

    this.props.updateNode();
  }

  handleChangeMessage(event) {
    console.log(event.target.value);

    this.changeMessageText(event.target.value);
  }

  changeMessageText(message) {
    let node = this.props.node;
    let model = this.props.model;
    let currNode = model.getNode(node.id);

    currNode.properties.smsConfigs.vendorConfigs[
      currNode.properties.smsConfigs.vendor
    ].message = message;

    this.props.updateNode();
  }

  componentDidMount = function () {
    console.log("Mounted");
    console.log(this.props.selected_node);
  };
  renderVendorConfigs(vendor, vendorConfigs) {
    console.log("VENDOR CONFIGS");
    console.log(vendorConfigs);
    console.log(vendor);

    return (
      <Col md={12}>
        <h5 className="mt-2">SMS Send Configurations</h5>
        <hr />
        <Form>
          <Row>
            <Col md={12} sm={12}>
              <Form.Group className="mb-3">
                <Form.Label>Select Phone Column</Form.Label>
                <Form.Control
                  as="select"
                  name="sheet"
                  value={
                    vendorConfigs[vendor].phoneColumn == ""
                      ? "Default"
                      : vendorConfigs[vendor].phoneColumn
                  }
                  onChange={this.handleChangePhoneColumn}
                >
                  <option value="Default">Default</option>
                  {this.props.selected_node.properties.metaData.hasOwnProperty(
                    "columns"
                  )
                    ? this.props.selected_node.properties.metaData.columns.map(
                        (item, key) => (
                          <option key={key} value={item.column_name}>
                            {item.column_name}
                          </option>
                        )
                      )
                    : ""}
                </Form.Control>
              </Form.Group>
            </Col>
            {/* <Col md={12} sm={12}>
              <Form.Group className="mb-3">
                <Form.Check
                  custom
                  type="radio"
                  value="bulk"
                  label="Broadcast SMS"
                  checked={vendorConfigs[vendor].bulk === "bulk"}
                  onChange={this.handleChangeMode}
                  name="mode"
                  id="mode1"
                />
                <Form.Check
                  custom
                  type="radio"
                  value="single"
                  label="Individual"
                  checked={vendorConfigs[vendor].bulk === "single"}
                  onChange={this.handleChangeMode}
                  name="mode"
                  id="mode2"
                />
              </Form.Group>
            </Col> */}
            <Col md={12} sm={12}>
              <Form.Group className="mb-3">
                <Form.Label>Compose Message</Form.Label>
                <br />
                <small>
                  Try to type something starting with <b>colon(:)</b> to view
                  available columns
                </small>

                <p className="pt-1">
                  {this.props.selected_node.properties.metaData.hasOwnProperty(
                    "columns"
                  )
                    ? this.props.selected_node.properties.metaData.columns.map(
                        (header, i) => {
                          return (
                            <span
                              className="dragg"
                              key={i}
                              //
                              draggable
                              onDragStart={(e) =>
                                this.handleDragStart(
                                  e,
                                  "{{" + header.column_name + "}}"
                                )
                              }
                            >
                              <span
                                onClick={(e) =>
                                  this.appendHeader(
                                    e,
                                    header.column_name,
                                    vendorConfigs[vendor].message
                                  )
                                }
                              >
                                {header.column_name}
                              </span>
                            </span>
                          );
                        }
                      )
                    : ""}
                </p>
                <ReactTextareaAutocomplete
                  className="form-control my-textarea mt-2"
                  value={vendorConfigs[vendor].message}
                  onChange={this.handleChangeMessage}
                  onDragOver={this.handleDragOver}
                  onDrop={(e) => {
                    this.handleDrop(e, vendorConfigs[vendor].message);
                  }}
                  loadingComponent={() => <span>Loading</span>}
                  // afterWhitespace={true}
                  // allowWhitespace={true}
                  rows="6"
                  trigger={{
                    ":": {
                      dataProvider: (token) => {
                        return this.props.selected_node.properties.metaData.hasOwnProperty(
                          "columns"
                        )
                          ? this.props.selected_node.properties.metaData.columns.map(
                              (item, key) => ({
                                name: item.column_name,
                                char: "{{" + item.column_name + "}}",
                              })
                            )
                          : [
                              { name: "Firstname", char: "[Firstname]" },
                              { name: "Lastname", char: "[Lastname]" },
                            ];
                      },
                      component: Item,
                      output: (item, trigger) => item.char,
                    },
                  }}
                />
                <span>
                  Message Length: {vendorConfigs[vendor].message.length} / 145
                  Characters
                </span>

                {vendorConfigs[vendor].message.length > 145 ? (
                  <Alert variant="danger" className="p-0">
                    <p>
                      Text messages longer than 145 characters cost two credits
                    </p>
                  </Alert>
                ) : (
                  ""
                )}
              </Form.Group>
            </Col>
            <Col md={12} sm={12}>
              <Form.Group className="mb-3">
                <Form.Label>SMS Configurations</Form.Label>
                <Form.Check
                  custom
                  type="radio"
                  value="true"
                  label="Test Mode - Deactivate Message Send"
                  checked={vendorConfigs[vendor].disableSend === "true"}
                  onChange={this.handleChangeDisableSend}
                  name="disable"
                  id="mode11"
                />
                <Form.Check
                  custom
                  type="radio"
                  value="false"
                  label="Go Live - Activate Message Send"
                  checked={vendorConfigs[vendor].disableSend === "false"}
                  onChange={this.handleChangeDisableSend}
                  name="disable"
                  id="mode21"
                />
              </Form.Group>
            </Col>
          </Row>
        </Form>
      </Col>
    );
  }
  renderVendorUI(node) {
    if (node.properties.hasOwnProperty("smsConfigs")) {
      let smsConfigs = node.properties.smsConfigs;

      let _vendorConfigs = this.renderVendorConfigs(
        smsConfigs.vendor,
        smsConfigs.vendorConfigs
      );

      return (
        <Row>
          <Col md={12}>
            {/* <h5 className="mt-0">Choose SMS Vendor</h5>
            <hr />
            <Form.Group controlId="exampleForm.ControlSelect1">
              <Form.Control
                as="select"
                name="sheet"
                value={
                  smsConfigs.vendor == "" ? "Default" : smsConfigs.vendor
                }
                onChange={this.handleChangeVendor}
              >
                <option value="Default">Default</option>
                {smsConfigs.vendors.map((item, key) => (
                  <option key={key} value={item.var_key}>
                    {item.name}
                  </option>
                ))}
              </Form.Control>
            </Form.Group> */}
          </Col>
          {_vendorConfigs}
        </Row>
      );
    }

    return (
      <Col md={12}>
        <h5 className="mt-0">No Vendors Available</h5>
        <hr />
      </Col>
    );
  }
  render() {
    return (
      <div className="modal-body">
        <div className="text-left">
          {/* <h6 className="heading mt-1">Select Node Configs</h6> */}
          <Form>
            {this.renderVendorUI(this.props.selected_node)}
            <Row>
              <Col md={12}>
                <Form.Group controlId="exampleForm.ControlTextarea1">
                  <Form.Label>Comment</Form.Label>
                  <Form.Control
                    as="textarea"
                    rows="3"
                    name="annotation"
                    value={
                      this.props.selected_node["properties"].hasOwnProperty(
                        "annotation"
                      )
                        ? this.props.selected_node["properties"]["annotation"]
                        : ""
                    }
                    onChange={(e) => this.handleAnnotationChange(e, "password")}
                  />
                </Form.Group>
              </Col>
            </Row>
          </Form>
          <p>{/* {node.properties.headers.join(',')} */}</p>
          <p>
            {/* <span id="coderea">{ JSON.stringify(this.props.node) }</span> */}
          </p>
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {};
};

const mapDispatchToProps = (dispatch) => {
  return {};
};

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(SMSNodeComponent)
);
