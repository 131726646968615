import { NodeModel, DefaultPortModel, Toolkit } from "storm-react-diagrams";
import SheetsPortModel from './SheetsPortModel'
import * as _ from "lodash";

export class SheetsNodeModel extends NodeModel {
    constructor() {
        super("Sheets");
        this.name = "Sheets";
        this.properties = {
            type: 'single',
            input: [],
            message: 'Sheets Input',
            name: 'Sheets Tool',
            outputData: [],
            headers: [],
            messages: [],
            annotation: "",
            metaData: {},
            env_variable: "Default",
            sheetConfigs: {
                sheet_name: ''
            }
          }
    }
   
    addOutPort(label) {
        return this.addPort(new  SheetsPortModel(false, label, label));
    }

    printResults(node, port){

        // console.log(node);
        // console.log(port);
        return node.properties.outputData
    
      }

    updateName = (e) => {
         this.name = e.target.innerHTML;
         console.log(this.name);
    };

    serialize() {
        return _.merge(super.serialize(), {
            scene_name: this.name
        });
    }
    deSerialize(object, engine) {
        super.deSerialize(object, engine);
        this.name = object.scene_name;
    }

    getOutPorts() {
        return _.filter(this.ports, portModel => {
            return (!portModel.in && !portModel.next);
        });
    }
}
