import React, { Component } from "react";
import ReactDOM from "react-dom";
import jexcel from "jexcel";
import Axios from "axios";

import { connect } from "react-redux";
import { withRouter } from "react-router-dom";

import "./styles.css";
import "../../../../../node_modules/jexcel/dist/jexcel.css";


import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPlusCircle } from "@fortawesome/free-solid-svg-icons";
import {
  Container,
  Col,
  Row,
  Button,
  Alert,
} from "@themesberg/react-bootstrap";




class EnvTable extends React.Component {
  constructor(props) {
    super(props);

    let that = this;

    this.state = {
      entity: null,
      fields_data: [],
    };

    this.options = {
      data: [[""]],
      columns: [
        { type: "text", title: "Variable name", width: 100 },
        { type: "text", title: "Value", width: 180 },
        {
          type: "dropdown",
          title: "Type",
          width: 100,
          source: ["text", "Number", "Double", "Float", "Boolean", "Date"],
        },
      ],
      ondeleterow: function (instance) {
        console.log(that.saveVars());
      },
      onchange: function (instance, cell, x, y, value) {
        console.log("Changed");

        that.saveVars()
      },
      columnDrag: true,
      allowManualInsertRow: false,
      allowManualInsertColumn: false,
      oninsertrow: this.insertedRow,
    };
  }

  componentDidMount = function () {
    this.el = jexcel(ReactDOM.findDOMNode(this).children[0], this.options);
    console.log("Thers are the Env Variables");
    console.log(this.props.env_variables);

    if (this.props.env_variables.length > 0) {
      let fields_arr = this.props.env_variables.map((foo) => {
        return Object.values(foo);
      });
      this.el.setData(fields_arr);
    }
  };

  componentDidUpdate(prevProps, prevState) {
    if (prevProps.env_variables !== this.props.env_variables) {
      let fields_arr = this.props.env_variables.map((foo) => {
        return Object.values(foo);
      });
      console.log(fields_arr);

      if(fields_arr.length == 0){
        fields_arr = [["", "", "text"]]
      }

      this.el.setData(fields_arr);
    }
  }

  addRow = function () {
    this.el.insertRow(["", "", "text"]);
  };
  saveVars = function () {
    let records = this.el.getData();
    let headers = ["var_name", "value", "type"];
    let arr_objs_records = [];

    records.forEach((record) => {
      let obj_rec = {};
      let count = 0;
      headers.forEach((item) => {
        obj_rec[item] = record[count];
        count++;
      });
      if (obj_rec["var_name"] !== "") {
        arr_objs_records.push(obj_rec);
      }
    });

    console.log(arr_objs_records);

    if(arr_objs_records.length == 0){
      arr_objs_records = [{"var_name":"", "value":"", "type":"text"}]
    }

    this.props.onSaveVars(arr_objs_records);
  };

  insertedRow = function (instance) {
    console.log(instance);
  };

 
  render() {
    return (
      <div className="row">
        <div className="col-12" ></div>

        <div className="col-12">
         
          <Button variant="outline-info"
                        className="m-1"
                        size="sm"
                        onClick={() =>  this.addRow()}
                      >
                        <FontAwesomeIcon icon={faPlusCircle} className="me-2" />{" "}
                        New Variable
                      </Button>
        </div>
       

        <br />
        <br />
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {};
};

const mapDispatchToProps = (dispatch) => {
  return {};
};

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(EnvTable)
);
