import React, { useState, useEffect } from "react";

import { useHistory } from "react-router-dom";

import { Route } from "react-router-dom";
import { NavLink } from "react-router-dom";
// reactstrap components

import { connect } from "react-redux";
import { Toolkit } from "storm-react-diagrams";
import {
  Row,
  Col,
  Button,
  Form,
  Modal,
  InputGroup,
  FormControl,
  ProgressBar,
  Table,
  Alert,
} from "react-bootstrap";

import { withFirebase } from "../../../Firebase";

import { AuthUserContext } from "../../../Session";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

import {
  faAngleDoubleRight,
  faDatabase,
  faHome,
  faPlus,
  faTable,
} from "@fortawesome/free-solid-svg-icons";

import "./styles.css";

import { compose } from "recompose";

function DataStoresPage(props) {
  let history = useHistory();
  const title = useFormInput("");
  const w_name = useFormInput("");
  const w_collection = useFormInput("Default");
  const w_description = useFormInput("");
  const {
    loading,
    showTableDialog,
    onShowDialog,
    tableData,
    clearMessage,
    message,
    onRequestTable,
    getTables,
  } = props;
  // const { , setBusinessAccount } = props;
  const [authUser, setAuth] = useState(null);

  const handleHideModal = () => {
    onShowDialog(false);
  };

  
  const openBulkPage = () => {
    history.push("/org/"+authUser.uid+"/importfile");
  };

  // useEffect(() => {})[props.firebase];

  useEffect(() => {

    document.title = `My Data Stores - Bunicom Studio`;

    const unsubscribe = props.firebase.auth.onAuthStateChanged((authUser) => {
      if (authUser) {
        setAuth(authUser);
        getTables(authUser.uid);
      } else {
        setAuth(null);
        //save null business account
      }
    });

    return () => {
      unsubscribe();
    };
  }, []);

  const handleSaveTable = () => {
    let dateobj = new Date();

    let table = {
      title: title.value,
      created_at: dateobj.toISOString(),
      modified_at: dateobj.toISOString(),
      application_id: authUser.uid,
    };
    onRequestTable(table);

    console.log(table);
  };

  const handleShowModal = () => {
    title.onReset();

    clearMessage();
    onShowDialog(true);
  };

  const renderTables = () => {
    let tables = tableData.map((res, i) => {
      let items = [];
      items.push(
        <tr key={res._id}>
          <td className="border-0">
            <div>
              <span className="h6">{res.title}</span>
            </div>
          </td>
          <td className="border-0 text-danger">
            <Route
              render={({ history }) => (
                <Button
                  onClick={() => {
                    history.push(
                      "/org/" + props.match.params.org_id + "/table/" + res._id
                    );
                  }}
                  variant="outline-secondary"
                  className="m-1 float-end"
                >
                  <FontAwesomeIcon icon={faAngleDoubleRight} className="me-2" />
                  View table
                </Button>
              )}
            />
          </td>
        </tr>
      );

      return items;
    });

    return tables;
  };

  return (
    <>
      <AuthUserContext.Consumer>
        {(authUser) =>
          authUser != null ? (
            <>
            {loading ? <ProgressBar animated now={100} /> : ""}
              {/* <div className="d-flex justify-content-between flex-wrap flex-md-nowrap align-items-center py-4">
                <div className="d-block mb-4 mb-md-0">
                  <h4>Sent Messages</h4>
                  <p className="mb-0">Your web analytics dashboard template.</p>
                </div>
              </div> */}

              <Modal
                show={showTableDialog}
                size="lg"
                aria-labelledby="contained-modal-title-vcenter"
                centered
                backdrop="static"
                keyboard={false}
                onHide={handleHideModal}
              >
                <Modal.Header>
                  <Modal.Title className="h6">Create new Table</Modal.Title>
                  <Button
                    variant="close"
                    aria-label="Close"
                    onClick={handleHideModal}
                  />
                </Modal.Header>
                <Modal.Body>
                  <Row>
                    {/* {JSON.stringify()} */}

                    {message ? (
                      <div className="alert alert-warning" role="alert">
                        <span className="alert-icon">
                          <i className="fa fa-exclamation-triangle"></i>
                        </span>
                        <span className="alert-text">
                          <strong>Message!</strong> {message}{" "}
                        </span>
                      </div>
                    ) : (
                      ""
                    )}
                    <Col md={12}>
                      <Form.Group controlId="exampleForm.ControlTextarea1">
                        <Form.Label>
                          Let's start with a title for your Table
                        </Form.Label>

                        <FormControl
                          type="text"
                          className="form-control"
                          {...title}
                          required
                          placeholder="My Awesome Table"
                        />
                      </Form.Group>
                    </Col>
                  </Row>
                </Modal.Body>
                <Modal.Footer>
                  {loading ? (
                    <button className="btn btn-piniterest my-4" disabled>
                      Creating Table...
                    </button>
                  ) : (
                    <button
                      type="button"
                      className="btn btn-pinterest  float-right"
                      onClick={handleSaveTable}
                      disabled={title.value == ""}
                      // onClick={() => this.props.onWorkflowClose()}
                    >
                      <span className="text-white mr-2">
                        <i className="fa fa-plus-circle"></i>
                      </span>
                      Create Table
                    </button>
                  )}
                  {/* <Button disabled={title.value === ''} onClick={handleSaveApplication}>Save</Button> */}
                </Modal.Footer>
              </Modal>

              <div className="container-fluid mt--6">
                <div className="row justify-content-center">
                  <div className="col-lg-10 card-wrapper ct-example">
                    <div className="d-block mb-4 mb-md-0">
                      <h4>My Data stores</h4>
                      <Alert variant="secondary" show={true}>
                        <div className="d-flex justify-content-between">
                          <div>
                            <FontAwesomeIcon
                              icon={faDatabase}
                              className="me-1"
                            />
                            <strong>Get started with Data Stores!</strong>{" "}
                            A data store is a repository for persistently storing and managing collections of data which include not
                             just repositories like databases, but also simpler store types such as simple files, emails etc. 
                          </div>
                        </div>
                      </Alert>
                    </div>
                    <div className="card">
                      <div className="card-header">
                        <div className="row align-items-center">
                          <div className="col">
                            <h5 className="h3 mb-0">My Data Stores</h5>
                          </div>
                          <div className="col">
                            <div className="justify-content-end">
                              <Button
                                variant="primary"
                                className="float-end m-1"
                                onClick={openBulkPage}
                              >
                                <FontAwesomeIcon
                                  icon={faTable}
                                  className="me-2"
                                />
                                Import from Excel/CSV
                              </Button>
                              <Button
                                variant="secondary"
                                className="float-end m-1"
                                onClick={handleShowModal}
                              >
                                <FontAwesomeIcon
                                  icon={faPlus}
                                  className="me-2"
                                />
                                New Table
                              </Button>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="card-body">
                        <div className="row align-items-center">
                          <Table className="user-table align-items-center">
                            {/* <thead className="thead-light">
                              <tr>
                                <th className="border-0">Title</th>
                                <th className="border-0 float-end">Action</th>
                              </tr>
                            </thead> */}
                            <tbody>{renderTables()}</tbody>
                          </Table>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </>
          ) : (
            <p>Unable to get data stores</p>
          )
        }
      </AuthUserContext.Consumer>
    </>
  );
}

const useFormInput = (initialValue) => {
  const [value, setValue] = useState(initialValue);

  const handleChange = (e) => {
    setValue(e.target.value);
  };

  function handleReset() {
    setValue("");
  }
  return {
    value,
    onChange: handleChange,
    onReset: handleReset,
  };
};

const mapStateToProps = (state) => {
  return {
    message: state.message,
    loading: state.loading,
    tableData: state.tableData,
    showTableDialog: state.showTableDialog,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    getTables: (id) =>
      dispatch({ type: "GET_TABLES_REQUEST", application_id: id }),
    onRequestTable: (col) =>
      dispatch({ type: "NEW_TABLE_REQUEST", table: col }),
    onShowDialog: (flag) =>
      dispatch({ type: "SHOW_TABLE_DIALOG", dialog: flag }),
    clearMessage: () => dispatch({ type: "CLEAR_MSG", clear: true }),
  };
};

// export default connect(mapStateToProps, mapDispatchToProps)(DataStoresPage);
export default compose(
  connect(mapStateToProps, mapDispatchToProps),
  withFirebase
)(DataStoresPage);
// export default DataStoresPage
