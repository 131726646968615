import React, { useRef, useState, useEffect } from "react";

import { useHistory } from "react-router-dom";
import EmailEditor from "react-email-editor";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faCheck,
  faCog,
  faDatabase,
  faArrowLeft,
  faSearch,
  faMailBulk,
  faUserAlt,
} from "@fortawesome/free-solid-svg-icons";
import styled from "styled-components";

import sample from "./default.json";

import axios from "axios";

import "./hide.css";

import {
  Col,
  Container,
  Row,
  Form,
  Button,
  ButtonGroup,
  Breadcrumb,
  InputGroup,
  Modal,
  Dropdown,
  Alert,
} from "@themesberg/react-bootstrap";

import { ReactMultiEmail, isEmail } from "react-multi-email";
import "react-multi-email/style.css";

function BackButton(props) {
  const history = useHistory();

  function handleClick() {
    history.push("/org/" + props.appid + "/outbox");
  }

  return (
    <Button variant="light" size="sm" onClick={handleClick}>
      <FontAwesomeIcon icon={faArrowLeft} className="me-1" /> Go back to outbox
    </Button>
  );
}

const Example = (props) => {

  const emailEditorRef = useRef(null);
  const [value, setValue] = useState(null);
  const [emails, setEmails] = useState([]);
  const [cc_setup, setCC] = useState([]);
  const [showDefault, setShowDefault] = useState(false);
  const [ModaRef, setModalRef] = useState(React.createRef());
  const [emailStatus, setemailStatus] = useState(
    "Sending Email, please wait ..."
  );
  const handleClose = () => setShowDefault(false);

  const sender = useFormInput("");
  const to_list = useFormInput("");
  const cc = useFormInput("");
  const bcc = useFormInput("");
  const subject = useFormInput("");

  const saveDesign = () => {
    emailEditorRef.current.editor.saveDesign((design) => {

      setShowDefault(true);
      console.log(JSON.stringify(design));

    });
  };

  const exportHtml = (e) => {
    e.preventDefault();
    setShowDefault(true);
    emailEditorRef.current.editor.exportHtml((data) => {
      const { design, html } = data;
      console.log(html);

      let payload = {
        ...data,
        to_list: emails,
        sender: "info@zmc.co.ke",
        cc: "micqualngugi96@gmail.com",
        bcc: "micqualngugi96@gmail.com",
        subject: subject.value,
        html: html,
        appid: props.match.params.org_id,
      };

      setemailStatus("Sending Email, please wait ...");
      
      axios
        .post("https://api.bunicom.com/send-email", payload)
        .then((response) => {
          console.log(response.data);
          setemailStatus("Email sent successfully");
        })
        .catch((e) => {
          setemailStatus("Failed to send email. Please try again");
        });
    });
  };

  const onDesignLoad = (data) => {
    console.log("onDesignLoad", data);
  };

  const onDesignUpdated = (data) => {
    console.log("onDesignUpdated", data);
  };

  function refreshPage() {
    window.location.reload();
    console.log("Refresh Page");
  }

  const onLoad = () => {
    if (emailEditorRef.current) {
      emailEditorRef.current.editor.addEventListener(
        "onDesignLoad",
        onDesignLoad
      );
      emailEditorRef.current.editor.addEventListener(
        "design:updated",
        onDesignUpdated
      );

      emailEditorRef.current.editor.loadDesign(sample);
    } else {
      refreshPage();
    }
  };

  return (
    <>
      <Modal
        as={Modal.Dialog}
        centered
        show={showDefault}
        ref={ModaRef}
        onHide={handleClose}
      >
        <Modal.Header>
          <Modal.Title className="h6">{emailStatus}</Modal.Title>
          <Button variant="close" aria-label="Close" onClick={handleClose} />
        </Modal.Header>
      </Modal>
      <div className="container-fluid mt--6">
        <div className="row justify-content-center">
          <div className="col-lg-12 card-wrapper">
            <div className="table-settings mb-4">
            <form className="mt-1" onSubmit={exportHtml}>
            <Row className="justify-content-between align-items-center p-2">
                <h6>Compose new email</h6>
              </Row>
              <Row className="justify-content-between align-items-center p-2">
                <Col xs={8} md={6} lg={6} xl={6}>
                  <>
                    <h6>Recipients</h6>
                    <ReactMultiEmail
                      placeholder="Email To: "
                      emails={emails}
                      onChange={(_emails) => {
                        setEmails(_emails);
                      }}
                      validateEmail={(email) => {
                        return isEmail(email); // return boolean
                      }}
                      getLabel={(email, index, removeEmail: (index) => {}) => {
                        return (
                          <div data-tag key={index}>
                            {email}
                            <span
                              data-tag-handle
                              onClick={() => removeEmail(index)}
                            >
                              ×
                            </span>
                          </div>
                        );
                      }}
                    />
                  </> 
                </Col>
                <Col xs={8} md={6} lg={6} xl={6}>
                  <>
                    <h6>CC</h6>
                    <ReactMultiEmail
                      placeholder="CC"
                      emails={cc_setup}
                      onChange={(_cc) => {
                        setCC(_cc);
                      }}
                      validateEmail={(email) => {
                        return isEmail(email); // return boolean
                      }}
                      getLabel={(email, index, removeEmail: (index) => {}) => {
                        return (
                          <div data-tag key={index}>
                            {email}
                            <span
                              data-tag-handle
                              onClick={() => removeEmail(index)}
                            >
                              ×
                            </span>
                          </div>
                        );
                      }}
                    />
                  </>
                </Col>
                <Col xs={8} md={6} lg={6} xl={6} className="pt-2">
                  <>
                  <InputGroup>
                      <InputGroup.Text>
                        <FontAwesomeIcon icon={faMailBulk} />
                      </InputGroup.Text>
                      <Form.Control
                        type="text"
                        placeholder="Subject:"
                        {...subject}
                        required
                      />
                    </InputGroup>
                  </>
                  </Col>
                  <Col xs={8} md={6} lg={6} xl={6} className="pt-2">
                    <Button
                      variant="info"
                      size="md"
                      className="w-100"
                      type="submit"
                    >
                      Save &amp; Send
                    </Button>
                  </Col>
              </Row>
              <Row className="justify-content-between align-items-center">
                <Col xs={12} md={12} lg={12} xl={12}>
                  <ButtonGroup className=" float-end">
                    <Button
                      variant="outline-success"
                      size="md"
                      onClick={saveDesign}
                    >
                      Save as Template
                    </Button>
                  </ButtonGroup>
                </Col>
              </Row>
              
             
                <Row className="justify-content-between align-items-center">
                
                  {/* <Col xs={8} md={3} lg={3} xl={3}>
                    <InputGroup>
                      <InputGroup.Text>
                        <FontAwesomeIcon icon={faUserAlt} />
                      </InputGroup.Text>
                      <Form.Control
                        type="text"
                        placeholder="Recipient:"
                        {...to_list}
                        required
                      />
                    </InputGroup>
                  </Col>
                  <Col xs={8} md={3} lg={3} xl={3}>
                    <InputGroup>
                      <InputGroup.Text>
                        <FontAwesomeIcon icon={faUserAlt} />
                      </InputGroup.Text>
                      <Form.Control type="text" placeholder="CC:" {...cc} />
                    </InputGroup>
                  </Col> */}
                  
                </Row> 
              </form>

              <Row>
                <Col xs={12} md={12} lg={12} xl={12}>
                  <React.StrictMode>
                    <EmailEditor
                      ref={emailEditorRef}
                      tools={{
                        menu: {
                          enabled: true,
                        },
                        form: {
                          enabled: true,
                          properties: {
                            fields: {
                              value: [
                                {
                                  name: "first_name",
                                  type: "text",
                                  label: "First Name",
                                  placeholder_text: "Enter first name here",
                                  show_label: true,
                                  required: true,
                                },
                                {
                                  name: "last_name",
                                  type: "text",
                                  label: "Last Name",
                                  placeholder_text: "Enter last name here",
                                  show_label: true,
                                  required: false,
                                },
                                {
                                  name: "email",
                                  type: "email",
                                  label: "Email",
                                  placeholder_text: "Enter email here",
                                  show_label: true,
                                  required: true,
                                },
                              ],
                            },
                          },
                        },
                      }}
                      appearance={{ theme: "dark" }}
                      style={{ height: "80vh" }}
                      onLoad={onLoad}
                    />
                  </React.StrictMode>
                </Col>
              </Row>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

const useFormInput = (initialValue) => {
  const [value, setValue] = useState(initialValue);

  const handleChange = (e) => {
    setValue(e.target.value);
  };

  function handleReset() {
    setValue("");
  }
  return {
    value,
    onChange: handleChange,
    onReset: handleReset,
  };
};

export default Example;
