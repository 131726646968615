import React from "react";
import { connect } from "react-redux";

import { useHistory } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faArrowDown,
  faArrowLeft,
  faArrowUp,
} from "@fortawesome/free-solid-svg-icons";
import { Col, Row, Card, Button, Table } from "@themesberg/react-bootstrap";

import { pageVisits } from "../../../../data/tables";

import BootstrapTable from "react-bootstrap-table-next";
import cellEditFactory from "react-bootstrap-table2-editor";
import paginationFactory from "react-bootstrap-table2-paginator";

import { compose } from "recompose";
import { withFirebase } from "../../../../components/Firebase";

function CSVResultsTable(next) {
  const TableRow = (props) => {
    // const { name, description, price, category, subcategory } = props;

    return (
      <tr>
        {next.data
          ? next.data.columns.map((t,i) => <th key={i} scope="row">{props[t]}</th>)
          : ""}
      </tr>
    );
  };

  return (
    

    <Table responsive className="align-items-center table-flush">
      
      <thead className="thead-light">
        <tr>
          {next.data
            ? next.data.columns.map((t, i) => <th key={i} scope="row">{t}</th>)
            : ""}
        </tr>
      </thead>
      <tbody>
        {next.data
          ? next.data.data.map((pv, i) => <TableRow key={i} {...pv} />)
          : "No Data"}
      </tbody>
    </Table>
  );
}

const mapStateToProps = (state) => ({
  message: state.message
});

const mapDispatchToProps = (dispatch) => {
  return {};
};

// export default connect(mapStateToProps, mapDispatchToProps)(withFirebase(HotelsPage));
export default compose(
  connect(mapStateToProps, mapDispatchToProps),
  // withStateHandlers,
  // withHandler,
  withFirebase
)(CSVResultsTable);
