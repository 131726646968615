import React, { Component } from "react";
import ReactDOM from "react-dom";
import jexcel from "jexcel";
import Axios from "axios";

import { connect } from "react-redux";
import { withRouter } from "react-router-dom";

import "./styles.css";
import "../../../../node_modules/jexcel/dist/jexcel.css";

import { Toolkit } from "storm-react-diagrams";

import * as actionTypes from "./../../../store/actions";

class GroupTable extends React.Component {
  constructor(props) {
    super(props);

    let that = this;

    this.state = {
      entity: null,
      fields_data: [],
    };

    this.options = {
      data: [[""]],
      minDimensions: [2, 5],
      defaultColWidth: 100,
      tableWidth: "520px",
      tableOverflow: true,
      columns: [
        {
          type: "dropdown",
          source: ["Quantity", "Price"],
          title: "Select Column",
          width: 80,
        },
        {
          type: "dropdown",
          title: "Filter Condition",
          filter: that.dropdownFilter,
          source: [
            "GroupBy",
            "sum",
            "count",
            "mean",
            "median",
            "max",
            "min",
          ],
          width: 120,
        }
      ],
      ondeleterow: function (instance) {
        // console.log(that.saveDtypes());
        that.saveDtypesNode();
      },
      updateTable: function (instance, cell, col, row, val, label, cellName) {
        
       
      },

      onchange: function (instance, cell, x, y, value) {
       
        that.saveDtypesNode();
      },
      columnDrag: true,
      selectionCopy: false,
      allowManualInsertRow: false,
      allowManualInsertColumn: false,
      allowInsertColumn: false,
      allowDeleteColumn: false,
      allowRenameColumn: false,
      allowComments: false,
      allowExport: false,
      oninsertrow: this.insertedRow,
    };
  }

  componentDidMount = function () {
    // this.el = jexcel(ReactDOM.findDOMNode(this).children[0], this.options);

    this.saveDtypes();

    // let fields_arr = resp.data.map((foo) => {

    //     return Object.values(foo)
    //   })
  };

  componentDidUpdate(prevProps, prevState) {
    this.saveDtypes();

    // if(prevProps.env_variables !== this.props.env_variables){
    //     console.log("ENVIRONMENT VARIABLES");
    //     // console.log(prevProps.dtypes);
    //     // console.log(this.props.dtypes);

    // }
  }
  saveDtypesNode = function () {
    let selectNode = this.props.g_node;
    let model = this.props.model;
    let currNode = model.getNode(selectNode.id);

    if(selectNode){

      if (selectNode.properties.hasOwnProperty("conditions")) {
        let records = this.el.getData();
        let headers = [
          "column_name",
          "action",
        ];
        let arr_objs_records = [];
        let selected_columns = [];
  
        records.forEach((record) => {
          let obj_rec = {};
          let count = 0;
  
          headers.forEach((item) => {
            obj_rec[item] = record[count];
  
            count++;
          });
          if (obj_rec["column_name"] !== "") {
            arr_objs_records.push(obj_rec);
          }
        });
  
        // console.log(arr_objs_records);
        // dataArr.push([column.column_name,column.condition,column.filter_by,column.x_value,column.env_variable,column.chain]);
  
        currNode.properties.conditions = arr_objs_records;
      }

    }

    

    // currNode.properties.metaData
  };

  saveDtypes = function () {
    let selectNode = this.props.g_node;

    let model = this.props.model;
    let currNode = model.getNode(selectNode.id);
    document.getElementById("group-sheet").innerHTML = "";
 
    if(currNode){
      if (currNode.properties.metaData.hasOwnProperty("columns")) {
        let columns = currNode.properties.metaData.columns;
        let env_vars = this.props.env_variables;
        let source_cols = [];
        let env_cols = [""];
        columns.forEach((element) => {
          source_cols.push(element.column_name + "-" + element.data_type);
        });

        if(env_vars){
          env_vars.forEach((element) => {
            if (element.var_name != "") {
              env_cols.push(element.var_name);
            }
          });
        }
  
        

        this.options.columns = [
          {
            type: "dropdown",
            source: source_cols,
            title: "Select Column",
            width: 110,
          },
          {
            type: "dropdown",
            title: "Action",
            filter: this.dropdownFilter,
            source: [
                "GroupBy",
                "sum",
                "count",
                "mean",
                "median",
                "max",
                "min",
            ],
            width: 100,
          },
          
          // { type: "dropdown", title: "Rename column", width: 100, source:['==','>=','<=','!='], filter: that.dropdownFilter },
        ];
        // this.options.tableWidth = width > 100 ? width + "px" : "520px";
  
        // this.el.se
        // this.el.setData(0, { type: 'dropdown', source: source_cols,title:'Select Column', width:100 },)
      }
      this.el = jexcel(ReactDOM.findDOMNode(this).children[0], this.options);
      // this.el.setData(dataArr)
  
      if (currNode.properties.hasOwnProperty("conditions")) {
        let conditions = currNode.properties.conditions;
        if (conditions.length > 0) {
          let dataArr = [];
  
          conditions.forEach((column) => {
            dataArr.push([
              column.column_name,
              column.action,
            ]);
          });

          this.el.setData(dataArr);
        } else {
          this.el.setData([[]]);
        }
      }

    }

    
  };

  dropdownFilter = function (instance, cell, c, r, source) {
    var value = instance.jexcel.getValueFromCoords(c - 1, r);
    // console.log("I am logging the instance");
    // alert(value);

    // return ['Java','Apples','Bananas','Oranges'];

    if (value.includes("object")) {
      return ["GroupBy", "concat", "count", "sum"];
    } else {
      return [
        "GroupBy",
        "sum",
        "count",
        "mean",
        "median",
        "max",
        "min",
      ];
    }
  };

  addRow = function () {
    this.el.insertRow(["", "GroupBy"]);
  };

  insertedRow = function (instance) {
    console.log(instance);
  };

  render() {
    return (
      <div>
        <div id="group-sheet"></div>

        <div>
          <input
            type="button"
            value="+ Add Condition"
            onClick={() => this.addRow()}
          ></input>
        </div>

        <br />
        <br />
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
   
  };
};

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(GroupTable)
);
