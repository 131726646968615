import React from "react";
import ReactDOM from "react-dom";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";

import Axios from "axios";
import * as actionTypes from "./../../../store/actions";

import { Row, Col, Card, Button, Form } from "react-bootstrap";
import { Tabs, Tab } from "react-bootstrap";

class EntityNodeComponent extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      // Set initial files, type 'local' means this is a file
      // that has already been uploaded to the server (see docs)
      node: null,
      entities: []
    };

    this.handleChangeSelectedEntity = this.handleChangeSelectedEntity.bind(
      this
    );
  }

  handleChangeSelectedEntity(event) {
    console.log();
    console.log(this.state.entities);
    let node = this.props.node;
    let model = this.props.model;
    let currNode = model.getNode(node.id);

    // currNode.properties.outputData = event.target.value.split(',')
    currNode.properties.entity = {
      title: this.state.entities[event.target.value].title,
      _id: this.state.entities[event.target.value]._id,
      application_id: this.props.match.params.org_id
    };

    console.log(currNode.properties.entity);

    if (event.target.value === "Default") {
      currNode.properties.message = "Please select an Entity";
    }

    this.props.updateNode();
  }

  handleAnnotationChange(event, label) {
    const target = event.target;
    const value = target.value;
    const name = target.name;

    console.log(label);
    console.log(value);
    console.log(name);

    let node = this.props.node;
    let model = this.props.model;
    let currNode = model.getNode(node.id);

    currNode.properties[name] = value;

    this.props.updateNode();
    this.forceUpdate();
  }

  componentDidMount() {
    let that = this;

    Axios.get("https://api.bunicom.com///get-entities?application_id="+this.props.match.params.org_id).then((response) => {
      console.log("====================");
      console.log(response.data);
      console.log("====================");

      let entities = response.data;

      that.setState({entities: entities});
      //TODO return get entities here
    });
  }

  render() {
    return (
      <div className="modal-body">
        <div className="text-left">
          <h6 className="heading mt-1">Data Sources Properties</h6>
          <Form>
            <Row>
              <Col md={12}>
                {/* {JSON.stringify(this.state.entities)} */}
                <Form.Group controlId="exampleForm.ControlSelect1">
                  <Form.Label>Pick a Data Source</Form.Label>
                  <Form.Control
                    as="select"
                    name="sheet"
                    value={
                      this.props.node.properties.entity.title == "Default"
                        ? "Default"
                        : this.state.entities
                            .map(function (e) {
                              return e.title;
                            })
                            .indexOf(this.props.node.properties.entity.title)
                    }
                    onChange={this.handleChangeSelectedEntity}
                  >
                    <option value="Default">Default</option>
                    {this.state.entities.map((item, key) => (
                      <option key={key} value={key}>
                        {item.title}
                      </option>
                    ))}
                  </Form.Control>
                </Form.Group>
              </Col>
              <Col md={12}>
                <Form.Group controlId="exampleForm.ControlTextarea1">
                  <Form.Label>Add Comment</Form.Label>
                  <Form.Control
                    as="textarea"
                    rows="3"
                    name="annotation"
                    value={
                      this.props.node["properties"].hasOwnProperty("annotation")
                        ? this.props.node["properties"]["annotation"]
                        : ""
                    }
                    onChange={(e) =>
                      this.handleAnnotationChange(e, "annotation")
                    }
                  />
                </Form.Group>
              </Col>
            </Row>
          </Form>
          <p>{/* {node.properties.headers.join(',')} */}</p>
          <p>
            {/* <span id="coderea">{ JSON.stringify(this.props.node) }</span> */}
          </p>
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
   
    // onGetEntities: (entities) =>
    //   dispatch({ type: actionTypes.SAVE_ENTITIES, entities: entities }),
  };
};

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(EntityNodeComponent)
);
