import { NodeModel, DefaultPortModel, Toolkit } from "storm-react-diagrams";
import * as _ from "lodash";

export class OutputNodeModel extends NodeModel {
    constructor() {
        super("Output");
        this.name = "";
        this.properties = {
            input: [],
            name: 'Output Widget',
            type: 'multiple',
            maxLinks: 1,
            metaData: {},
            configuration:{
                title: '',
                appid: "1",
                _id: '5f45666257903b8823b479e9'
            },
            otherConfigs:{
                operation: "upsert", //delete
                retrieval_mode: "updated",
                disabled: "false"
            },
            createDataStore: false,
            outputData: [],
            message: "",
            messages: [],
            annotation: "Save data/Commit records to BuniComms datastore"
        }
    }
   
    addOutPort(label) {
        return this.addPort(new DefaultPortModel(false, label, label));
    }
    addInPort(label) {
         
        return this.addPort(new DefaultPortModel(true, label, label));
    }
    printResults(node, port){

        switch(port){
            case 'input':
              return node.properties.input == null ? []: node.properties.input
            case 'output':
              return node.properties.outputData == null ? []: node.properties.outputData
          }
          
      }

    updateName = (e) => {
         this.name = e.target.innerHTML;
         console.log(this.name);
    };

    serialize() {
        return _.merge(super.serialize(), {
            scene_name: this.name
        });
    }
    deSerialize(object, engine) {
        super.deSerialize(object, engine);
        this.name = object.scene_name;
    }
    getInPorts() {
        return _.filter(this.ports, portModel => {
            return portModel.in;
        });
    }

    getOutPorts() {
        return _.filter(this.ports, portModel => {
            return (!portModel.in && !portModel.next);
        });
    }
}
