import React, { useState, useEffect, useCallback } from "react";

import { useHistory } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faAt,
  faCheck,
  faCog,
  faDatabase,
  faEye,
  faHome,
  faMailBulk,
  faSearch,
  faSms,
} from "@fortawesome/free-solid-svg-icons";

import {
  Col,
  Row,
  Form,
  Button,
  ButtonGroup,
  Breadcrumb,
  InputGroup,
  Dropdown,
  Alert,
} from "@themesberg/react-bootstrap";

import { withRouter } from "react-router-dom";

import { connect } from "react-redux";

import ReactDataGrid from "@inovua/reactdatagrid-community";
import "@inovua/reactdatagrid-community/index.css";

import '@inovua/reactdatagrid-community/base.css'
import '@inovua/reactdatagrid-community/theme/blue-light.css'
// import { TransactionsTable } from "./MessagesTable";

const gridStyle = { minHeight: 550, marginTop: 10 };

const filterValue = [
  { name: 'Recipients', operator: 'startsWith', type: 'string', value: "" },
  { name: 'messageid', operator: 'startsWith', type: 'string', value: '' },
  { name: 'Message', operator: 'startsWith', type: 'string', value: '' },
  { name: 'Network', operator: 'startsWith', type: 'string', value: '' },
  { name: 'send-time', operator: 'startsWith', type: 'string', value: '' },
  { name: 'delivery-time', operator: 'startsWith', type: 'string', value: '' },
  { name: 'TAT', operator: 'startsWith', type: 'string', value: '' },
  { name: 'Sender', operator: 'startsWith', type: 'string', value: '' },
  {
    name: 'type',
    operator: 'startsWith',
    type: 'string',
    value: ''
  },
  { name: 'delivery-description', operator: 'startsWith', type: 'string', value: '' }
];

const columns = [
    { name: "_id",maxWidth: 70, defaultVisible: false
},
  // { name: "_id", maxWidth: 40, header: "_id", defaultVisible: false },
  { name: "messageid",   header: "MessageId", render: ({ value }) => value == "-" ? "EMAIL-AUTO" : value },

  { name: "Recipients",   header: "Recipients" },
  { name: "Message", header: "Message" },
  { name: "Sender",  header: "Sender" },
  { name: "delivery-description",  header: "Description" },
  { name: "Network",  header: "Network",
  render: ({ value }) => value == "1" ? "Safaricom" : (value == "2" ? "Airtel": value)
},
  { name: "type",  header: "type" },
  { name: "send-time",  header: "Sent On" },
  { name: "delivery-time",  header: "Delivered On" },
  { name: "delivery-tat",  header: "T.A.T" },
  { name: "Message_Credits",  header: "Credits" },
  { name: "Message_Length",  header: "Length" },
 
];

const loadData = ({ skip, limit, sortInfo, groupBy, filterValue  }) => {
  const url =
    "https://api.bunicom.com/get-email-logs?" +
    'skip='+skip+'&limit='+limit+(groupBy && groupBy.length?'&groupBy='+groupBy:'')+'&sortInfo='+JSON.stringify(sortInfo) + '&filterBy='+JSON.stringify(filterValue)
    // "&skip=" +
    // skip +
    // "&limit=" +
    // limit +
    // "&sortInfo=" +
    // JSON.stringify(sortInfo);

  return fetch(url).then((response) => {
    const totalCount = response.headers.get("X-Total-Count");
    //   for (var pair of response.headers.entries()) {
    //     console.log(pair[0]+ ': '+ pair[1]);
    //  }
    return response.json().then((data) => {
      return Promise.resolve({ data, count: parseInt(totalCount) });
    });
  });
};

function ComposeButton(props) {
  const history = useHistory();

  function handleClick() {
    history.push("/org/" + props.appid + "/outbox/"+props.id);
    console.log("Soon");
  }

  return (
    <Button variant="light" size="md" onClick={handleClick}>
      <FontAwesomeIcon icon={faEye} className="me-2" /> 
    </Button>
  );
}

function SMSButton(props) {
  const history = useHistory();

  function handleClick() {
    history.push("/org/" + props.appid + "/sms");
  }

  return (
    <Button variant="outline-secondary" size="md" onClick={handleClick}>
      <FontAwesomeIcon icon={faMailBulk} className="me-2" />
      Send SMS
    </Button>
  );
}

function OutboxPage(props) {
  const history = useHistory();
  const [defaultSortInfo, setdefaultSortInfo] = useState({
    app_id: props.match.params.org_id,
  });
  const [sortInfo, setSortInfo] = useState({
    app_id: props.match.params.org_id,
  });

  const [columnData,setColumnData] = useState(columns)

  const dataSource = useCallback(loadData, []);

  const saveSortInfos = (data) => {
    setSortInfo({ ...data, app_id: props.match.params.org_id });
  };

  useEffect(() => {

    let cols =  [
      { name: "_id",maxWidth: 70, defaultVisible: false
    },
      // { name: "_id", maxWidth: 40, header: "_id", defaultVisible: false },
      { name: "messageid",   header: "MessageId", render: ({ value }) => value == "-" ? "EMAIL-AUTO" : value },
    
      { name: "Recipients",   header: "Recipients" },
      { name: "Message", header: "Message" },
      { name: "Sender",  header: "Sender" },
      { name: "delivery-description",  header: "Description" },
      { name: "Network",  header: "Network",
      render: ({ value }) => value == "1" ? "Safaricom" : (value == "2" ? "Airtel": value)
    },
      { name: "type",  header: "type" },
      { name: "send-time",  header: "Sent On" },
  { name: "delivery-time",  header: "Delivered On" },
      { name: "delivery-tat",  header: "T.A.T" },
      { name: "Message_Credits",  header: "Credits" },
      { name: "Message_Length",  header: "Length" },
     
    ];

    setColumnData(cols)

  }, []);
  

  return (
    <>
      <div className="container-fluid mt--6">
        <div className="row justify-content-center">
          <div className="col-lg-12 card-wrapper">
            <div className="d-block mb-4 mb-md-0">
              <Alert variant="secondary" show={true}>
                <div className="d-flex justify-content-between">
                  <div>
                    <FontAwesomeIcon icon={faDatabase} className="me-1" />
                    <strong>Email Logs!</strong> All Emails sent
                  </div>
                </div>
              </Alert>
            </div>

            <ReactDataGrid
              idProperty="id"
              style={gridStyle}
              columns={columnData}
              pagination
              theme="blue-light"
            //   defaultFilterValue={filterValue}
              defaultSortInfo={defaultSortInfo}
              sortInfo={sortInfo}
              onSortInfoChange={saveSortInfos}
              dataSource={dataSource}
              defaultLimit={10}
            />
          </div>
        </div>
      </div>
      {/* <div className="d-flex justify-content-between flex-wrap flex-md-nowrap align-items-center py-4">
        <div className="d-block mb-4 mb-md-0">
          <Breadcrumb
            className="d-none d-md-inline-block"
            listProps={{ className: "breadcrumb-dark breadcrumb-transparent" }}
          >
            <Breadcrumb.Item>
              <FontAwesomeIcon icon={faHome} />
            </Breadcrumb.Item>
            <Breadcrumb.Item>BuniComms</Breadcrumb.Item>
            <Breadcrumb.Item active>Transactions</Breadcrumb.Item>
          </Breadcrumb>
          <h4>Sent Messages</h4>
          <p className="mb-0">Your web analytics dashboard template.</p>
        </div>
        <div className="btn-toolbar mb-2 mb-md-0">
          <ButtonGroup>
            <Button variant="outline-primary" size="sm">
              Share
            </Button>
            <Button variant="outline-primary" size="sm">
              Export
            </Button>
          </ButtonGroup>
        </div>
      </div> */}
    </>
  );
}
const mapStateToProps = (state) => {
  return {
    message_logs: state.message_logs,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {};
};

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(OutboxPage)
);
