import * as SRD from "storm-react-diagrams";
import * as React from "react";
import * as _ from "lodash";
import PropTypes from "prop-types";
import { Button, Popover, OverlayTrigger } from '@themesberg/react-bootstrap';

import { SMSPortLabel } from "./SMSPortLabelWidget";
import "./sms-node.css";

export class SMSNodeWidget extends React.Component {
  static propTypes = {
    node: PropTypes.any,
  };

  constructor(props) {
    super(props);
    console.log("this is the orios");
    console.log(props);
    this.headerRef = React.createRef();
  }

  _clickHeader = () => {
    this.headerRef.current.contentEditable = true;
  };
  _blurHeader = (e) => {
    this.headerRef.current.contentEditable = false;
    this.props.node.updateName(e);
  };

  generatePort(port) {
    return <SMSPortLabel model={port} key={port.id} />;
  }
  createMarkup() {
    return (
      <div>
        {/* <span style={{
              fontSize: "1.5em",
              fontWeight: "bolder"
            }}>
                at
            </span> */}
        <i
          className="fas fa-comments"
          style={{
            fontSize: "1.5em",
          }}
        />
      </div>
    );
  }

  renderMessage(status) {
    switch (status) {
      case "error":
      case "credit":
        return (
          <p className="label--desc">
            {" "}
            {this.props.node.properties.hasOwnProperty("messages")
              ? this.props.node.properties.messages.join(",")
              : ""}
          </p>
        );
      default:
        return this.props.node.properties.hasOwnProperty("annotation") &&
          this.props.node.properties["annotation"] != "" ? (
          <p className="label--annotate">
            {this.props.node.properties["annotation"]}
            {/* {JSON.stringify(this.props.node)} */}
          </p>
        ) : (
          ""
        );
    }
  }

  render() {
    return (

      <OverlayTrigger
  trigger={['hover', 'focus']}
  placement="top"
  overlay={
    <Popover>
      <Popover.Title>{this.props.node.properties.smsConfigs.vendorConfigs['at'].disableSend == 'true'? "Sandbox/Test Mode": "Production mode - live"}</Popover.Title>
      <Popover.Content>
        <textarea disabled rows="4" style={{border: "none", backgroundColor: "#ffffff"}} value={this.props.node.properties.smsConfigs.vendorConfigs['at'].message !== '' ? this.props.node.properties.smsConfigs.vendorConfigs['at'].message : "No Message yet"}></textarea>
        </Popover.Content>
    </Popover>
  }>
     <div className="sms-node">
        {this.props.node.selected ? (
          <div class="rotating-dashed">
            <span class="dashing">
              <i></i>
            </span>
            <span class="dashing">
              <i></i>
            </span>
            <span class="dashing">
              <i></i>
            </span>
            <span class="dashing">
              <i></i>
            </span>
            {/* <strong>&#x1f6d1;</strong> */}
          </div>
        ) : (
          ""
        )}

        <div className="left-port">
          {_.map(this.props.node.getInPorts(), this.generatePort.bind(this))}
        </div>
        <div className="scene">{this.createMarkup()}</div>
        <div className="right-port">
          {_.map(this.props.node.getOutPorts(), this.generatePort.bind(this))}
        </div>
        {this.renderMessage(this.props.node.properties.message)}
      </div>
    
</OverlayTrigger>
   );
  }
}
