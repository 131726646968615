import * as SRD from "storm-react-diagrams";
import * as React from "react";
import * as _ from "lodash";
import PropTypes from "prop-types";

import { FormulaPortLabel } from "./FormulaPortLabelWidget";
import "./formula-node.css";

export class FormulaNodeWidget extends React.Component {
  static propTypes = {
    node: PropTypes.any,
  };

  constructor(props) {
    super(props);
    console.log(props);
    this.headerRef = React.createRef();
  }

  _clickHeader = () => {
    this.headerRef.current.contentEditable = true;
  };
  _blurHeader = (e) => {
    this.headerRef.current.contentEditable = false;
    this.props.node.updateName(e);
  };

  generatePort(port) {
    return <FormulaPortLabel model={port} key={port.id} />;
  }
  createMarkup() {
    return (
      <div>
        <i
          className="fas fa-square-root-alt"
          style={{
            fontSize: "1.5em",
          }}
        />
      </div>
    );
  }

  renderMessage(status) {
    switch (status) {
      case "error":
        return (
          <p className="label--desc">
            {" "}
            {this.props.node.properties.hasOwnProperty("messages")
              ? this.props.node.properties.messages.join(",")
              : ""}
          </p>
        );
      case "empty":
        return (
          <p className="label--desc--warning">
            {this.props.node.properties.hasOwnProperty("messages")
              ? "Empty downstream data"
              : ""}
          </p>
        );
      default:
        return this.props.node.properties.hasOwnProperty("annotation") &&
          this.props.node.properties["annotation"] != "" ? (
          <p className="label--annotate">
            {this.props.node.properties["annotation"]}
          </p>
        ) : (
          ""
        );
    }
  }

  render() {
    return (
      <div className="formula-node">
        {this.props.node.selected ? (
          <div class="rotating-dashed">
            <span class="dashing">
              <i></i>
            </span>
            <span class="dashing">
              <i></i>
            </span>
            <span class="dashing">
              <i></i>
            </span>
            <span class="dashing">
              <i></i>
            </span>
            {/* <strong>&#x1f6d1;</strong> */}
          </div>
        ) : (
          ""
        )}
        <div className="left-port">
          {_.map(this.props.node.getInPorts(), this.generatePort.bind(this))}
        </div>
        <div className="scene">{this.createMarkup()}</div>
        <div className="right-port">
          {_.map(this.props.node.getOutPorts(), this.generatePort.bind(this))}
        </div>
        {this.renderMessage(this.props.node.properties.message)}
        {/* {(this.props.node.properties.message != 'error') ? '':
                 <p className="label--desc">{this.props.node.properties.message}</p>
                 } */}
        {/* {this.props.node.properties.message != "error" ? (
          this.props.node.properties.hasOwnProperty("annotation") ? (
            <p className="label--annotate">
              {this.props.node.properties["annotation"]}
            </p>
          ) : (
            ""
          )
        ) : (
          <p className="label--desc">
            {this.props.node.properties.messages.join(",")}
          </p>
        )}

        {this.props.node.properties.message != "empty" ? (
          this.props.node.properties.hasOwnProperty("annotation") &&
          this.props.node.properties["annotation"] != "" ? (
            <p className="label--annotate">
              {this.props.node.properties["annotation"]}
            </p>
          ) : (
            ""
          )
        ) : (
          <p className="label--desc--warning">
            {this.props.node.properties.hasOwnProperty("messages")
              ? "Empty downstream data"
              : ""}
          </p>
        )} */}
      </div>
    );
  }
}
