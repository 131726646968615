import React, { useState, useEffect } from "react";

import { connect } from "react-redux";
import { withFirebase } from "../../../Firebase";
import { useHistory } from "react-router-dom";
import { AuthUserContext } from "../../../Session";
import { Button, ProgressBar } from "@themesberg/react-bootstrap";
import Axios from "axios";
import { compose } from "recompose";
import { faArrowCircleLeft } from "@fortawesome/free-solid-svg-icons";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

function OrderPage(props) {
  const [authUser, setAuth] = useState(null);

  const [BusinessAccount, setBusinessAccount] = useState(null);
  const [message, setMessage] = useState(null);

  useEffect(() => {
    const unsubscribe = props.firebase.auth.onAuthStateChanged((authUser) => {
      if (authUser) {
        setAuth(authUser);

        CheckMessage(props.match.params.message_id)

        let current_bsa = props.firebase.db
          .collection("business_accounts")
          .where("uid", "==", authUser.uid)
          .get()
          .then((querySnapshot) => {
            let bsa = {};
            querySnapshot.forEach((doc) => {
              // doc.data() is never undefined for query doc snapshots
              //   console.log(doc.id, " => ", doc.data());
              bsa = { ...doc.data(), id: doc.id };
            });

            setBusinessAccount(bsa);
          })
          .catch((error) => {
            console.log("Error getting documents: ", error);
          });
      } else {
        setAuth(null);
        //save null business account
      }
    });

    return () => {
      unsubscribe();
    };
  }, []);

  const CheckMessage = (id) => {
 
    Axios.get(
      "https://api.bunicom.com/get-message-by-id?_id="+ 
      id
    )
      .then((response) => {
        let message = response.data;

        setMessage(message)

        // console.log(response.data);
      })
      .catch((e) => {
       
        console.log(e);
      });
  };
  // updateOrderStatus
  function BackButton(props) {
    const history = useHistory();

    function handleClick() {
      history.push("/org/" + props.appid + "/sms/outbox");
    }

    return (
      <Button variant="himenu-accent" size="sm" onClick={handleClick}>
        <FontAwesomeIcon icon={faArrowCircleLeft} className="me-1" /> Back to
        All Messages
      </Button>
    );
  }
  return (
    <AuthUserContext.Provider value={authUser}>
      <div className="container-fluid mt--6">
        <div className="row justify-content-center">
          <div className="col-lg-12 card-wrapper">
            <div className="d-flex justify-content-between flex-wrap flex-md-nowrap align-items-center py-4">
              <BackButton appid={props.match.params.org_id} />
            </div>
          </div>

         
          <div className="col-lg-12 card-wrapper">
            <div className="card mt-2">
              <div className="card-header">
                <div className="row align-items-center">
                  <div className="col">
                    <h5 className="h3 mb-0">Message Details</h5>
                    <small>
                      Review the fine details of the single message that has
                      been sent out
                    </small>
                  </div>
                  <div className="col">
                    <div className="justify-content-end"></div>
                  </div>
                </div>
              </div>
              <div className="card-body">
                <div className="row" id="recordsTable">
                  <div className="col-lg-12 ">
                  {
              message ? 
              <div class="padding">
                      <div class="row">
                        <div class="col-sm-12 col-md-8">
                          <div class="card">
                            <div class="card-header">
                              <span class="text-success">
                                <svg
                                  xmlns="http://www.w3.org/2000/svg"
                                  width="16"
                                  height="16"
                                  viewBox="0 0 24 24"
                                  fill="none"
                                  stroke="currentColor"
                                  stroke-width="2"
                                  stroke-linecap="round"
                                  stroke-linejoin="round"
                                  class="feather feather-check-circle"
                                >
                                  <path d="M22 11.08V12a10 10 0 1 1-5.93-9.14"></path>
                                  <polyline points="22 4 12 14.01 9 11.01"></polyline>
                                </svg>
                              </span>{" "}
                              Message Content
                            </div>
                            <div class="card-body">
                              <p class="card-text">
                                {message.hasOwnProperty("Message") ? message.Message : "No Message"}
                              </p>
                            </div>
                            <div class="card-footer text-muted">
                              <span> {message.hasOwnProperty("Message_Length") ? message.Message_Length : "0"} characters</span>
                             
                              <span>
                                {" "}
                                <i>•</i> Cost <strong> {message.hasOwnProperty("Message_Credits") ? message.Message_Credits : "0"} </strong>{" "}
                                <small>Credits</small>
                              </span>
                            </div>
                          </div>
                          <div class="card mt-4">
                            <div class="card-header">Message Details</div>
                            <ul class="list-group list-group-sm">
                              <li class="list-group-item">
                                Recipient: <strong>{message.hasOwnProperty("Recipients") ? message.Recipients : "+254"}</strong>
                              </li>
                              <li class="list-group-item">
                                SenderID: <strong>{message.hasOwnProperty("Sender") ? message.Sender : "Bunicom"}</strong>
                              </li>
                              <li class="list-group-item">
                                Status:{" "}
                                <strong class="text-success">{message.hasOwnProperty("Status") ? message.Status : "Pending"}</strong>
                              </li>
                              <li class="list-group-item">
                                Created: <strong>{message.hasOwnProperty("datetime") ? message.datetime : "Pending"}</strong>
                              </li>
                            </ul>
                          </div>
                        </div>
                        <div class="col-sm-12 col-md-4">
                          <div class="card">
                            <div class="card-header">Message Meta</div>
                            <ul class="list-group list-group-sm">
                              <li class="list-group-item">
                                ID: <strong>{message.hasOwnProperty("messageid") ? message.messageid : "..."}</strong>
                              </li>
                              <li class="list-group-item">
                              
                                Network: <strong>{message.hasOwnProperty("delivery-networkid") ? message["delivery-networkid"] == "1" ? "Safaricom" : (message["delivery-networkid"] == "2" ? "Airtel": "Safaricom") : "Pending"}</strong>
                              </li>
                              <li class="list-group-item">
                                Time to send:{" "}
                                <strong>{message.hasOwnProperty("send-time") ? message["send-time"] : "..."}</strong>
                              </li>
                              <li class="list-group-item">
                                Delivered On:{" "}
                                <strong>{message.hasOwnProperty("delivery-time") ? message["delivery-time"] : "..."}</strong>
                              </li>
                              <li class="list-group-item">
                                Time Taken: <strong>{message.hasOwnProperty("delivery-tat") ? message["delivery-tat"] : "..."}</strong>
                              </li>
                              <li class="list-group-item">
                                Delivery Description:{" "}
                                <strong>{message.hasOwnProperty("delivery-description") ? message["delivery-description"] : "..."}</strong>
                              </li>
                              <li class="list-group-item">
                                Sent By:{" "}
                                <a href="#">
                                  <strong>{message.hasOwnProperty("Sender") ? message.Sender : "..."} </strong>
                                </a>
                              </li>
                             
                            </ul>
                          </div>
                        </div>
                      </div>
                    </div>
              
              : <ProgressBar variant="success" animated now={100} />
          }
                    
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </AuthUserContext.Provider>
  );
}

const mapStateToProps = (state) => ({});

const mapDispatchToProps = (dispatch) => {
  return {};
};

export default compose(
  connect(mapStateToProps, mapDispatchToProps),
  withFirebase
)(OrderPage);
