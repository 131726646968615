import React from 'react';
 
import { withFirebase } from '../Firebase';

import {  Dropdown } from '@themesberg/react-bootstrap';
import { faSignOutAlt } from '@fortawesome/free-solid-svg-icons';
 
import { createBrowserHistory } from "history";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";



const history = createBrowserHistory({forceRefresh:true});

function SignOutButton({ firebase }){

  function signOut(){
    firebase.doSignOut()

    history.push("/")
  }
  
  return (
    <div class="nav-item">
      <Dropdown.Item className="fw-bold nav-link text-light" onClick={signOut}>
  <FontAwesomeIcon icon={faSignOutAlt} className="text-danger me-2" /> Logout
</Dropdown.Item>
    </div>

  )
    // variant="secondary" size="xs" to={Routes.Signin.path} className="text-dark"
  // <button type="button" >
  //   Sign Out
  // </button>
  }
 
export default withFirebase(SignOutButton);