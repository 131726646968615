import React, { Component } from "react";
import ReactDOM from "react-dom";
import jexcel from "jexcel";
import Axios from "axios";

import { connect } from "react-redux";
import { withRouter } from "react-router-dom";

import "./styles.css";
import "../../../../node_modules/jexcel/dist/jexcel.css";

import { Toolkit } from "storm-react-diagrams";

import * as actionTypes from "./../../../store/actions";

class DataEntryTable extends React.Component {
  constructor(props) {
    super(props);

    let that = this;

    this.state = {
      entity: null,
      fields_data: [],
    };

    this.options = {
      data: [[""]],
      columns: [
        { type: "text", title: "A", width: 100 },
        { type: "text", title: "B", width: 180 },
        { type: "text", title: "C", width: 180 },
      ],
      ondeleterow: function (instance) {
        // console.log(that.saveVars());
      },
      updateTable: function (instance, cell, col, row, val, label, cellName) {

        console.log("Some thing changes");
      },
      onchange: function (instance, cell, x, y, value) {
        console.log("Changed");
        that.saveVars()
      },
      
      columnDrag: true,
      allowManualInsertRow: true,
      allowManualInsertColumn: true,
      selectionCopy: true,
      allowInsertColumn: true,
      allowDeleteColumn: true,
      allowRenameColumn: true,
      allowComments: true,
      allowExport: true,
      oninsertrow: this.insertedRow,
    };
  }

  componentDidMount = function () {
    this.el = jexcel(ReactDOM.findDOMNode(this).children[0], this.options);

    console.log("*****************DATA ENTRY************");
    this.refreshUITable()
    // console.log(this.props.node);

    // let fields_arr = resp.data.map((foo) => {

    //     return Object.values(foo)
    //   })
  };

  resetTable() {
    document.getElementById("data-sheet").innerHTML = "";
    this.options.columns = [
      { type: "text", title: "A", width: 100 },
      { type: "text", title: "B", width: 180 },
      { type: "text", title: "C", width: 180 },
    ];
    //Honestly, I have no idea what i did here
  }

  // refreshUITable() {
  //   this.resetTable();
  // }

  componentDidUpdate(prevProps, prevState) {
    this.refreshUITable();
    if (prevProps.node.properties.input !== this.props.node.properties.input) {
      console.log("ENVIRONMENT VARIABLES");
      console.log(prevProps.node.properties.input);
      console.log(this.props.node.properties.input);
      //   let fields_arr =this.props.env_variables.map((foo) => {

      //   return Object.values(foo)
      // })
      // console.log(fields_arr);
      // this.el.setData(fields_arr)
    }
  }

  addRow = function () {
    this.el.insertRow(["", "", ""]);
  };

  refreshUITable() {
    this.resetTable();

    let headers = ["A", "B", "C"];
    let output = [["", "", ""]];

    if (this.props.node.properties.input.length > 0) {
      let cols = [];

      let input_arr = this.props.node.properties.input;
      headers = Object.keys(input_arr[0]);
      output = input_arr.map(function (obj) {
        return Object.keys(obj)
          .map(function (key) {
            return obj[key];
          });
      });

      var element = document.getElementById("recordDataTable");
      var positionInfo = element.getBoundingClientRect();
      var height = positionInfo.height;
      var width = positionInfo.width;

      headers.forEach((col) => {
        // if(col.dataField != "_id"){
        cols.push({ type: "text", title: col, width: 180 });
        // }
      });


      this.options.columns = cols;

      this.options.minDimensions = [
        headers.length > 1 ? headers.length - 1 : 1,
        5,
      ];
      this.options.tableWidth = width > 100 ? width + "px" : "820px";

      this.el = jexcel(ReactDOM.findDOMNode(this).children[0], this.options);
      console.log(headers);
      console.log(output);
      this.el.setData(output);

    }

    // console.log(headers);
    // console.log(output);
  }
  saveVars = function () {
    let records = this.el.getData();
    let headers = this.el.getHeaders().split(",");

    console.log(headers);
    let arr_objs_records = [];

    records.forEach((record) => {
      let obj_rec = {};
      let count = 0;
      headers.forEach((item) => {
        obj_rec[item] = record[count];
        count++;
      });
      arr_objs_records.push(obj_rec);
    });

    console.log(arr_objs_records);

    let node = this.props.node;
    let model = this.props.model;
    let currNode = model.getNode(node.id);

    currNode.properties.input = arr_objs_records;
    this.props.onSaveVars();
  };

  insertedRow = function (instance) {
    console.log(instance);
  };

  render() {
    return (
      <div>
        <div id="data-sheet"></div>

        {/* <div>
          <input
            type="button"
            value="+ Add Row"
            onClick={() => this.saveVars()}
          ></input>
        </div> */}

        <div>
        <button
            className="btn-square btn btn-info"
            type="button"
            
            onClick={() => this.addRow()}
          >
            Add Row
          </button>
          <button
            className="btn-square btn btn-info"
            type="button"
            onClick={() => this.saveVars()}
          >
            Save Records &amp; Headers
          </button>
         
        </div>

        <br />
        <br />
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
   
  };
};

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(DataEntryTable)
);
