import React, { useState } from "react";
import { connect } from "react-redux";
import { useHistory } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPlus, faArrowLeft, faDatabase, faExclamationTriangle } from "@fortawesome/free-solid-svg-icons";
import { Col, Row, Button, Modal, ProgressBar, Card,Alert } from "@themesberg/react-bootstrap";
import shortid from "shortid";

import FilePondExcel from "./FilePondExcel.js";
import CSVResultsTable from "./CSVResultsTable";

import { compose } from "recompose";
import { withFirebase } from "../../../Firebase";

function BackButton(props) {
  const history = useHistory();

  function handleClick() {
    history.push("/org/"+props.appid+"/datastores");
  }

  return (
    <Button variant="himenu-accent" size="sm" onClick={handleClick}>
      <FontAwesomeIcon icon={faArrowLeft} className="me-1" /> Go back to datastores
    </Button>
  );
}

const BulkUpload = (props) => {
  const { csvFile, bulk_data, setCsv, message, loading } = props;

  function handleClick() {}

  return (
    <>
      <div className="container-fluid mt--6">
      {loading ? <ProgressBar animated now={100} /> : ""}
        <div className="row justify-content-center">
          <div className="col-lg-10 card-wrapper">
            <div className="d-block mb-md-0">
              <Alert variant="success" show={true}>
                <div className="d-flex justify-content-between">
                  <div>
                    <FontAwesomeIcon icon={faDatabase} className="me-1" />
                    <strong>Import Bulk Data!</strong> A table is a
                    collection of related data held in a table format within a
                    Data store
                  </div>
                </div>
              </Alert>
            </div>
            <div className="d-flex justify-content-between flex-wrap flex-md-nowrap align-items-center">
              <BackButton appid={props.match.params.appid} />
            </div>
            <div className="card">
             <div className="card-body">
                <div className="row" id="recordsTable">
                  <h5>Drag and drop or Browse excel/CSV file </h5>
                  <FilePondExcel appid={props.match.params.appid} />
                </div>
              </div>
              <div className="card-body">
                <div className="row" id="recordsTable">
                   <Alert variant="danger" show={!loading && message}>
                <div className="d-flex justify-content-between">
                  <div>
                    <FontAwesomeIcon icon={faExclamationTriangle} className="me-1" />
                    {message}
                  </div>
                </div>
              </Alert>
              {/* {message} */}
                  <h5>Data preview</h5>
                  <CSVResultsTable data={bulk_data} />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

   </>
  );
};

const mapStateToProps = (state) => ({
  csvFile: state.csvFile,
  bulk_data: state.bulk_data,
  message: state.message,
  loading: state.loading
});

const mapDispatchToProps = (dispatch) => {
  return {
    setCsv: (csvFile) =>
      dispatch({ type: "UPDATE_CSV_FILE", csvFile: csvFile }),
  };
};

// export default connect(mapStateToProps, mapDispatchToProps)(withFirebase(HotelsPage));
export default compose(
  connect(mapStateToProps, mapDispatchToProps),
  // withStateHandlers,
  // withHandler,
  withFirebase
)(BulkUpload);
