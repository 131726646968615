import React from "react";
import * as _ from "lodash";
import { connect } from "react-redux";
import {
  Col,
  Row,
  Button,
  Dropdown,
  ButtonGroup,
  Tabs,
  Nav,
  Tab,
} from "@themesberg/react-bootstrap";

import { TrayItemWidget } from "../../../../Workflow/TrayItemWidget";
import { TrayWidget } from "../../../../Workflow/TrayWidget";

function ToolPallette(props) {
  return (
    <Row>
      <Col md={12} className="playground">
        <Tabs defaultActiveKey="in" id="connectorstabs">
          <Tab eventKey="in" style={{ paddingTop: 15 }} title="Data Source">
            <div className="row">
              <div className="tools-scroll py-0 px-1">
                <TrayWidget>
                  <TrayItemWidget
                    model={{ type: "Form" }}
                    name="Form"
                    icon="clipboard-list"
                    color="#02aab0"
                  />
                  <TrayItemWidget
                    model={{ type: "File" }}
                    name="Input"
                    icon="file-import"
                    color="#02aab0"
                  />
                  <TrayItemWidget
                    model={{ type: "Entity" }}
                    name="Entity"
                    icon="table"
                    color="#02aab0"
                  />
                  <TrayItemWidget
                    model={{ type: "DataEntry" }}
                    name="Data Entry"
                    icon="grip-horizontal"
                    color="#02aab0"
                  />
                </TrayWidget>
              </div>
            </div>
          </Tab>

          <Tab eventKey="out" style={{ padding: 15 }} title="Data Output">
            <div className="row">
              <div className="tools-scroll py-0 px-0">
                <TrayWidget>
                  <TrayItemWidget
                    model={{ type: "Peek" }}
                    name="Peek"
                    icon="eye"
                    color="#009688"
                  />
                  <TrayItemWidget
                    model={{ type: "Output" }}
                    name="Output"
                    icon="file-export"
                    color="#009688"
                  />
                </TrayWidget>
              </div>
            </div>
          </Tab>
          <Tab
            eventKey="preparation"
            style={{ padding: 15 }}
            title="Data Wrangling"
          >
            <div className="row">
              <div className="tools-scroll py-0 px-0">
                <TrayWidget>
                  <TrayItemWidget
                    model={{ type: "Select" }}
                    name="Select"
                    icon="tasks"
                    color="#7b4397"
                  />
                  <TrayItemWidget
                    model={{ type: "Join" }}
                    name="Join"
                    icon="columns"
                    color="#7b4397"
                  />
                  <TrayItemWidget
                    model={{ type: "Unique" }}
                    name="Unique"
                    icon="code-branch"
                    color="#7b4397"
                  />

                  <TrayItemWidget
                    model={{ type: "Filter" }}
                    name="Filter"
                    icon="filter"
                    color="linear-gradient(to right, #de6262, #ffb88c"
                  />
                  <TrayItemWidget
                    model={{ type: "Group" }}
                    name="GroupBy"
                    icon="object-group"
                    color="linear-gradient(to right, #de6262, #ffb88c"
                  />
                  <TrayItemWidget
                    model={{ type: "Formula" }}
                    name="Function"
                    icon="square-root-alt"
                    color="linear-gradient(to right, #de6262, #ffb88c"
                  />

                  <TrayItemWidget
                    model={{ type: "Union" }}
                    name="Union"
                    icon="layer-group"
                    color="#7b4397"
                  />

                  <TrayItemWidget
                    model={{ type: "Sort" }}
                    name="Sort"
                    icon="sort"
                    color="#7b4397"
                  />

                  <TrayItemWidget
                    model={{ type: "Cleanup" }}
                    name="Cleanup"
                    icon="broom"
                    color="#7b4397"
                  />

                  <div
                    style={{
                      float: "left",
                      textAlign: "center",
                      padding: "7px",
                    }}
                    className="shortcut-item"
                  >
                    <div
                      style={{
                        background: "#666666",
                      }}
                      className={
                        "tray-item icon icon-shape text-white shadow shortcut-media avatar rounded-circle"
                      }
                    >
                      <i
                        className={"fa fa-vials"}
                        style={{
                          fontSize: "1.5em",
                          fontWeight: "bolder",
                        }}
                      />
                    </div>
                    <p className="ls-1 mb-1">
                      <small className="mt-0 text-white"> Sample</small>
                    </p>
                  </div>

                  <div
                    style={{
                      float: "left",
                      textAlign: "center",
                      padding: "7px",
                    }}
                    className="shortcut-item"
                  >
                    <div
                      style={{
                        background: "#666666",
                      }}
                      className={
                        "tray-item icon icon-shape text-white shadow shortcut-media avatar rounded-circle"
                      }
                    >
                      <i
                        className={"fa fa-search"}
                        style={{
                          fontSize: "1.5em",
                          fontWeight: "bolder",
                        }}
                      />
                    </div>
                    <p className="ls-1 mb-1">
                      <small className="mt-0 text-white">
                        {" "}
                        Match &amp; Replace
                      </small>
                    </p>
                  </div>

                  <div
                    style={{
                      float: "left",
                      textAlign: "center",
                      padding: "7px",
                    }}
                    className="shortcut-item"
                  >
                    <div
                      style={{
                        background: "#666666",
                      }}
                      className={
                        "tray-item icon icon-shape text-white shadow shortcut-media avatar rounded-circle"
                      }
                    >
                      <i
                        className={"fa fa-columns"}
                        style={{
                          fontSize: "1.5em",
                          fontWeight: "bolder",
                        }}
                      />
                    </div>
                    <p className="ls-1 mb-1">
                      <small className="mt-0 text-white"> Text To Column</small>
                    </p>
                  </div>
                </TrayWidget>
              </div>
            </div>
          </Tab>

          <Tab
            eventKey="exploration"
            style={{ padding: 15 }}
            title="Data Exploration"
          >
            <div className="row">
              <div className="tools-scroll py-0 px-0">
                <TrayWidget>
                  <div
                    style={{
                      float: "left",
                      textAlign: "center",
                      padding: "7px",
                    }}
                    className="shortcut-item"
                  >
                    <div
                      style={{
                        background: "#666666",
                      }}
                      className={
                        "tray-item icon icon-shape text-white shadow shortcut-media avatar rounded-circle"
                      }
                    >
                      <i
                        className={"fa fa-grin-beam-sweat"}
                        style={{
                          fontSize: "1.5em",
                          fontWeight: "bolder",
                        }}
                      />
                    </div>
                    <p className="ls-1 mb-1">
                      <small className="mt-0 text-white"> Coming soon</small>
                    </p>
                  </div>
                </TrayWidget>
              </div>
            </div>
          </Tab>
          <Tab
            eventKey="modeling"
            style={{ padding: 15 }}
            title="Data Modeling"
          >
            <div className="row">
              <div className="tools-scroll py-0 px-0">
                <TrayWidget>
                  <div
                    style={{
                      float: "left",
                      textAlign: "center",
                      padding: "7px",
                    }}
                    className="shortcut-item"
                  >
                    <div
                      style={{
                        background: "#666666",
                      }}
                      className={
                        "tray-item icon icon-shape text-white shadow shortcut-media avatar rounded-circle"
                      }
                    >
                      <i
                        className={"fa fa-grin-beam-sweat"}
                        style={{
                          fontSize: "1.5em",
                          fontWeight: "bolder",
                        }}
                      />
                    </div>
                    <p className="ls-1 mb-1">
                      <small className="mt-0 text-white"> Coming soon</small>
                    </p>
                  </div>
                </TrayWidget>
              </div>
            </div>
          </Tab>
          <Tab eventKey="custom" style={{ padding: 15 }} title="Addons">
            <div className="row">
              <div className="tools-scroll py-0 px-0">
                <TrayWidget>
                  <TrayItemWidget
                    model={{ type: "SMS" }}
                    name="BuniSMS"
                    icon="comments"
                    color="linear-gradient(to right,#03658c,#00fad9 "
                  />

                  <TrayItemWidget
                    model={{ type: "Email" }}
                    name="BuniMail"
                    icon="envelope"
                    color="linear-gradient(to right,#03658c,#00fad9 "
                  />

                  <TrayItemWidget
                    model={{ type: "Sheets" }}
                    name="Google Sheets"
                    icon="table"
                    color="linear-gradient(to right,#038484, #02aab0"
                  />
                </TrayWidget>
              </div>
            </div>
          </Tab>
        </Tabs>
      </Col>
    </Row>
  );
}

export default ToolPallette;
