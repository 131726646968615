import React, { useState, useEffect } from "react";

import { connect } from "react-redux";
import { withFirebase } from "../../../Firebase";
import { AuthUserContext } from "../../../Session";

import { compose } from "recompose";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faPlus,
  faArrowLeft,
  faDatabase,
  faSms,
  faEnvelope,
} from "@fortawesome/free-solid-svg-icons";
import {
  Col,
  Row,
  Button,
  Modal,
  Card,
  Alert,
  InputGroup,
  FormControl,
  Form,
} from "@themesberg/react-bootstrap";

import { useHistory } from "react-router-dom";

import Axios from "axios";

import shortid from "shortid";

import CurrencyInput from "react-currency-input-field";

shortid.characters(
  "0123456789abcdefghijklmnopqrstuvwxyzABCDEFGHIJKLMNOPQRSTUVWXYZ$@"
);

function PaymentPage(props) {
  const history = useHistory();

  const [authUser, setAuth] = useState(null);
  const [topup_amount, setTopupAmt] = useState(100);
  const [BusinessAccount, setBusinessAccount] = useState(null);
  const [SenderIds, setSenderIds] = useState(null);
  const [orderNumber, setorderNumber] = useState(null);
  const [showDefault, setShowDefault] = useState(false);
  const [ModaRef, setModalRef] = useState(React.createRef());
  const [emailConfigStatus, setEmailConfigStatus] = useState(

    "Sending STK Push, please wait ..."

  );

  const handleClose = () => setShowDefault(false);

  const [appCredits, setappCredits] = useState(14);

  const amount = useFormInput(100);
  const phone_number = useFormInput("");

  useEffect(() => {
    // for (let i = 0; i < 100; i++) {
    //   window.clearInterval(i);
    // }
    if (BusinessAccount) {
      console.log("I AM HERE");
    }
  }, [BusinessAccount]);

  useEffect(() => {
    const unsubscribe = props.firebase.auth.onAuthStateChanged((authUser) => {
      if (authUser) {
        setAuth(authUser);

        // Get business account and save details

        let current_bsa = props.firebase.db
          .collection("business_accounts")
          .where("uid", "==", authUser.uid)
          .get()
          .then((querySnapshot) => {
            let bsa = {};
            querySnapshot.forEach((doc) => {
              // doc.data() is never undefined for query doc snapshots
              //   console.log(doc.id, " => ", doc.data());
              bsa = { ...doc.data(), id: doc.id };
            });

            if (BusinessAccount == null) {
              setBusinessAccount(bsa);
              let current_senderIds = props.firebase.db
                .collection("organisation_senderids")
                .where("orgId", "==", authUser.uid)
                .get()
                .then((querySnapshot) => {
                  let senderId = [];
                  querySnapshot.forEach((doc) => {
                    // doc.data() is never undefined for query doc snapshots
                    console.log(doc.id, " => ", doc.data());
                    senderId.push({ ...doc.data(), id: doc.id });
                  });

                  setSenderIds(senderId);
                })
                .catch((error) => {
                  console.log("Error getting documents: ", error);
                });
            }

            if (bsa) {
              if (bsa.hasOwnProperty("phone")) {
                phone_number.onReset(bsa.phone);
              }
            }
          })
          .catch((error) => {
            console.log("Error getting documents: ", error);
          });
      } else {
        setAuth(null);
        //save null business account
      }
    });

    return () => {
      unsubscribe();
    };
  });

  const onSubmit = (event) => {
    event.preventDefault();
    CreateNewOrder();
  };
  const confirmOrder = () => {
    // CreateNewOrder()
    history.push("/org/" + authUser.uid + "/order/" + orderNumber);
  };

  const CreateNewOrder = () => {
    let dateobj = new Date();

    let amount_v = topup_amount;
    let phone_v = phone_number.value;

    if (authUser) {
      console.log(authUser);
      setShowDefault(true);
      setEmailConfigStatus("Sending STK Push, please wait ...");

      var docRef = props.firebase.db
        .collection("business_accounts")
        .where("uid", "==", authUser.uid)
        .get()
        .then((querySnapshot) => {
          querySnapshot.forEach((doc) => {
            console.log("Document data:", doc.data());

            // if(authUser.emailAddress != undefined){

            // }

            let payments_data = {
              status: "pending",
              orgId: authUser.uid,
              amount: amount_v,
              transaction_date: dateobj.toISOString(),
              name: authUser.displayName,
              email: "info@zmc.co.ke",
              phone_number: phone_v,
              credits: amount_v / 0.8,
              order_id: shortid.generate(),
            };

            console.log(payments_data);

            props.firebase.db
              .collection("organisation_payments")
              .add(payments_data)
              .then((docRef) => {
                console.log("Document written with ID: ", docRef.id);

                setorderNumber(payments_data.order_id);

                let payload = {
                  live: "1",
                  oid: payments_data.order_id,
                  inv: payments_data.order_id,
                  amount: payments_data.amount,
                  tel: payments_data.phone_number,
                  eml: "info@zmc.co.ke",
                  curr: "KES",
                  p1: BusinessAccount.id,
                  p2: docRef.id,
                  p3: BusinessAccount.credits,
                  p4: payments_data.credits,
                };

                Axios.post("https://api.bunicom.com/transact-mpesa", payload)
                  .then((response) => {
                    setEmailConfigStatus(response.data.text);
                    // setShowDefault(false);
                    setTimeout(() => {
                      history.push(
                        "/org/" +
                          authUser.uid +
                          "/order/" +
                          payments_data.order_id
                      );
                    }, 2000);
                    console.log(response.data);
                  })
                  .catch((e) => {
                    setShowDefault(false);
                    setEmailConfigStatus("Something went wrong");
                    console.log(e);
                  });
              })
              .catch((error) => {
                console.error("Error writing document: ", error);
              });
          });
        })
        .catch((error) => {
          console.log("Error getting documents: ", error);
        });
    }
  };

  return (
    <AuthUserContext.Provider value={authUser}>
      <div className="container-fluid mt--6">
        <Modal
          as={Modal.Dialog}
          centered
          show={showDefault}
          ref={ModaRef}
          onHide={handleClose}
        >
          <Modal.Header>
            <Modal.Title className="h6">Payment Status</Modal.Title>
            <Button variant="close" aria-label="Close" onClick={handleClose} />
          </Modal.Header>
          <Modal.Body>
            <p>{emailConfigStatus}</p>
            <div className="d-flex justify-content-between flex-wrap flex-md-nowrap align-items-center py-4">
              {/* <Button variant="danger" onClick={handleClose}>
                  Close
                </Button> */}
              <Button
                variant="success"
                className="ms-auto"
                disabled={orderNumber == null}
                onCLick={confirmOrder}
                type="submit"
              >
                Confirm Order
              </Button>
            </div>
          </Modal.Body>
        </Modal>
        <div className="row justify-content-center">
          <div className="col-lg-8 card-wrapper">
            <div className="d-block mb-4 mb-md-0">
              {/* <Alert variant="secondary" show={true}>
                <div className="d-flex justify-content-between">
                  <div>
                    <FontAwesomeIcon icon={faDatabase} className="me-1" />
                    <strong>Global SMS &amp; Email Configuration!</strong> You
                    can use our predefined SMS &amp; Mail vendors. You cna
                    overwrite any vendor by entering you configurations
                  </div>
                </div>
              </Alert> */}
            </div>

            <div className="card mt-2">
              <div className="card-body">
                <div className="row" id="recordsTable">
                  <div className="col-lg-12 ">
                    <form className="mt-1" onSubmit={onSubmit}>
                      {/* {JSON.stringify(BusinessAccount)}
                      {JSON.stringify(SenderIds)} */}
{/* 
                      <Form.Group className="mb-3">
                        <Form.Label>SenderID</Form.Label>
                        <Form.Select name="method">
                          <option value="BuniComSMS">BuniComSMS</option>
                          {SenderIds
                            ? SenderIds.map((item, key) => (
                                <option key={key} value={item.sender_name}>
                                  {item.sender_name}
                                </option>
                              ))
                            : ""}
                        </Form.Select>
                      </Form.Group> */}

                      <Form.Group id="email" className="mb-4">
                        <Form.Label>
                          How Much would you like to Top-up? (in KES)
                        </Form.Label>
                        <CurrencyInput
                          id="input-example"
                          name="input-name"
                          placeholder=""
                          step={10}
                          className="form-control"
                          prefix="Ksh "
                          defaultValue={topup_amount}
                          allowNegativeValue={false}
                          decimalsLimit={2}
                          onValueChange={(value, name) => setTopupAmt(value)}
                        />
                        {/* <InputGroup>
                          <InputGroup.Text>KES</InputGroup.Text>
                          <Form.Control
                            autoFocus
                            name="amount"
                            {...amount}
                            type="number"
                            placeholder="1000.00"
                            required
                          />
                        </InputGroup> */}
                      </Form.Group>

                     

                      <Form.Group id="credit" className="mb-4">
                        <Col md={12} sm={12} className="mt-2">
                          <Row className="align-items-center py-2 bg-light-gray">
                            <Col md={6} sm={6} className="mt-2 text-left">
                              <h1 style={{ fontSize: "5em", fontWeight: "fantasy"}}>{topup_amount / 0.8}</h1>
                              <br />
                              <small>CREDITS (@Ksh {} Per Credit)</small>
                            </Col>
                            {/* <Col md={6} sm={6} className="mt-2">
                            <Form.Group as={Row}>
                       
                        <Col sm={10}>
                          <Form.Check
                            type="radio"
                            label="Standard Plan (Ksh 0.8)"
                            name="formHorizontalRadios"
                            id="formHorizontalRadios1"
                          />
                          <Form.Check
                            type="radio"
                            label="Plus Plan (Ksh 0.6)"
                            name="formHorizontalRadios"
                            id="formHorizontalRadios2"
                          />
                          <Form.Check
                            type="radio"
                            label="Premium Plan (Ksh 0.6)"
                            name="formHorizontalRadios"
                            id="formHorizontalRadios3"
                          />
                        </Col>
                      </Form.Group>
                            </Col> */}
                          </Row>
                        </Col>
                        {/* <div className="d-flex justify-content-between flex-wrap flex-md-nowrap align-items-center py-4">

                       
                        
                        </div> */}

                        {/* <InputGroup>
                          <InputGroup.Text>
                            CREDITS (@Ksh 0.8 Per Credit)
                          </InputGroup.Text>
                          <Form.Control
                            autoFocus
                            name="amount"
                            type="number"
                            // placeholder="1000.00"
                            disabled
                            value={amount.value / 0.8}
                            Credits
                          />
                        </InputGroup> */}
                      </Form.Group>
                      <Form.Group id="credit" className="mb-4">
                        <Form.Label>
                          M-PESA Online <br />
                          Safaricom Kenya Phone Number
                        </Form.Label>
                        <InputGroup>
                          <InputGroup.Text>Kenya</InputGroup.Text>
                          <Form.Control
                            autoFocus
                            name="phone_number"
                            type="text"
                            placeholder="+2547xxxxxxxx"
                            required
                            {...phone_number}
                          />
                        </InputGroup>
                      </Form.Group>
                      <div className="d-flex justify-content-between flex-wrap flex-md-nowrap align-items-center py-4">
                        {/* <Button variant="danger" onClick={handleClose}>
                  Close
                </Button> */}
                        <Button
                          variant="success"
                          className="ms-auto"
                          type="submit"
                        >
                          Pay Now
                        </Button>
                      </div>
                    </form>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </AuthUserContext.Provider>
  );
}

const useFormInput = (initialValue) => {
  const [value, setValue] = useState(initialValue);

  const handleChange = (e) => {
    setValue(e.target.value);
  };

  function handleReset(val) {
    setValue(val);
  }
  return {
    value,
    onChange: handleChange,
    onReset: handleReset,
  };
};

const mapStateToProps = (state) => ({});

const mapDispatchToProps = (dispatch) => {
  return {};
};

export default compose(
  connect(mapStateToProps, mapDispatchToProps),
  withFirebase
)(PaymentPage);
