import React, { useState, useEffect } from "react";
import { useHistory } from "react-router-dom";
import {
  Button,
  Dropdown,
  ButtonGroup,
  Badge,
} from "@themesberg/react-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import BootstrapTable from "react-bootstrap-table-next";
import cellEditFactory from "react-bootstrap-table2-editor";
import paginationFactory from "react-bootstrap-table2-paginator";
import {
  faArrowCircleRight,
  faBullseye,
  faChartLine,
  faCodeBranch,
  faEdit,
  faEllipsisH,
  faEye,
  faMoneyBillAlt,
  faTrashAlt,
} from "@fortawesome/free-solid-svg-icons";

import Axios from "axios";


const columns = [
    { dataField: "transaction_date", text: "transaction_date" },
  { dataField: "amount", text: "Name" },
  //   { dataField: "description", text: "Description" },

  // { dataField: "cost", text: "Cost" },
  { dataField: "status", text: "Status" },
  // { dataField: "type", text: "Type" },

  // { dataField: "lastRun", text: "Last Run Time" },
  // { dataField: "nextRun", text: "Next Run Time" },
  // { dataField: "workflow", text: "Workflow" },
  { dataField: "action", text: "Action" },
];

const data = [
  {
    id: 1,
    name: "Send Fee Reminders",
    description: "Fetch student records and send SMS to all parents",
    operations: "2 Runs",
    cost: "KES 200",
    type: "Scheduled",
    status: "Active",
    lastRun: "10/10/2021 10:00:00",
    nextRun: "10/10/2021 10:00:00",
  },
];


const defaultSorted = [{
  dataField: 'transaction_date', // if dataField is not match to any column you defined, it will be ignored.
  order: 'desc' // desc or asc
}];

function CampaignsTable(props) {
  const [myDataColumns, setDataColumns] = useState(columns);

  let history = useHistory();

  useEffect(() => {
    // Your code here
    document.title = `Campaigns - My Campaigns`;

    let columns = [
      { dataField: "transaction_date", text: "Date", formatter: viewText, sort: true },
      { dataField: "name", text: "Name", formatter: viewText },
      { dataField: "amount", text: "Amount" },
      { dataField: "credits", text: "Credits" },
      //   {
    //     dataField: "operations",
    //     text: "Operations",
    //     formatter: viewText,
    //   },
      // { dataField: "cost", text: "Cost",  formatter: viewText  },
      { dataField: "status", text: "Status", formatter: statusBadge },
      // { dataField: "type", text: "Type",  formatter: viewText  },

      // { dataField: "lastRun", text: "Last Run Time",  formatter: viewText  },
      //   { dataField: "nextRun", text: "Next Run Time",  formatter: viewText  },
      // {
      //   dataField: "workflow",
      //   text: "Workflow",
      //   formatter: viewButton,
      //   sort: true,
      // },
      {
        dataField: "viewButton",
        text: "Action",
        formatter: viewButton,
      },
    ];


    setDataColumns(columns);
  }, []);
  const viewButton = (cell, row, rowIndex, formatExtraData) => {
    function handleClick() {
    //   history.push("/campaigns/" + props.org_id + "/" + row.campaign_id);
    history.push("/org/"+ props.org_id+"/order/"+ row.order_id);
    // console.log("Campaign");
    }
    return (
      <Button variant="success" className="m-1" onClick={handleClick}>
        <FontAwesomeIcon icon={faArrowCircleRight} className="me-2" /> 
      </Button>
    );
  };

 

  const viewOperations = (cell, row, rowIndex, formatExtraData) => {
    return <p className="mt-3">{cell}</p>;
  };

  const viewIcon = (cell, row, rowIndex, formatExtraData) => {

    return (
      <Button variant="white">
        <FontAwesomeIcon icon={faCodeBranch} />
      </Button>
    );
  };
  const viewText = (cell, row, rowIndex, formatExtraData) => {
    return <p className="mt-3">{cell}</p>;
  };

  const statusBadge = (cell, row, rowIndex, formatExtraData) => {
    return (
      <Badge bg="primary" className="m-3">
        {cell}
      </Badge>
    );
  };

  return (
      <>
      { props.data ? (
        <BootstrapTable
        keyField="transaction_date"
        // style={{ overflowX: "scroll" }}
        className="user-table align-items-center"
        data={props.data}
        columns={myDataColumns}
        defaultSorted={defaultSorted}
            defaultSortDirection="desc"
        pagination={paginationFactory()}
      />
      ):""}
      </>
      
    
  );
}

export default CampaignsTable;
