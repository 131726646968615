import { NodeModel, DefaultPortModel, Toolkit } from "storm-react-diagrams";
import DataEntryPortModel from './DataEntryPortModel'
import * as _ from "lodash";

export class DataEntryNodeModel extends NodeModel {
    constructor() {
        super("DataEntry");
        this.name = "DataEntry";
        this.properties = {
            type: 'single',
            input: [
                {"A":"","B":"","C":""}
            ],
            
            message: 'DataEntry Input',
            name: 'DataEntry Tool',
            outputData: [],
            headers: [],
            messages: [],
            metaData: {},
            annotation: "Manually type text to create small dataset",
            entry_data: [],
            
          }
    }
   
    addOutPort(label) {
        return this.addPort(new  DataEntryPortModel(false, label, label));
    }

    updateNode() {
        return null
    }

    printResults(node, port){

        // console.log(node);
        // console.log(port);
        return node.properties.outputData
    
      }

    updateName = (e) => {
         this.name = e.target.innerHTML;
         console.log(this.name);
    };

    serialize() {
        return _.merge(super.serialize(), {
            scene_name: this.name
        });
    }
    deSerialize(object, engine) {
        super.deSerialize(object, engine);
        this.name = object.scene_name;
    }

    getOutPorts() {
        return _.filter(this.ports, portModel => {
            return (!portModel.in && !portModel.next);
        });
    }
}
