import { NodeModel } from "storm-react-diagrams";
import RequestPortModel from "./RequestPortModel";
import * as _ from "lodash";

export class RequestNodeModel extends NodeModel {
  constructor() {
    super("Request");
    this.name = "request";
    this.properties = {
      type: "single",
      input: [],
      message: "Request Input",
      name: "Request Tool",
      outputData: [],
      headers: [],
      messages: [],
      metaData: {},
      annotation: "Make HTTP Request",
      toolConfig: {
        method: "GET",
        url:
          "https://datausa.io/api/data?drilldowns=Nation&measures=Population",
        params: {},
        auth_type: "no_auth",
        authorization_data: {
          username: "",
          password: "",
        },
        headers: { "Content-Type": "application/json" },
        body: {},
        body_string: "",
        body_type: "form-data",
        normalize: false,
        normalize_field: ["data"],
      },
    };
  }

  addOutPort(label) {
    return this.addPort(new RequestPortModel(false, label, label));
  }

  printResults(node, port) {
    // console.log(node);
    // console.log(port);
    return node.properties.outputData;
  }

  updateName = (e) => {
    this.name = e.target.innerHTML;
    console.log(this.name);
  };

  serialize() {
    return _.merge(super.serialize(), {
      scene_name: this.name,
    });
  }
  deSerialize(object, engine) {
    super.deSerialize(object, engine);
    this.name = object.scene_name;
  }

  getOutPorts() {
    return _.filter(this.ports, (portModel) => {
      return !portModel.in && !portModel.next;
    });
  }
}
