import { NodeModel, DefaultPortModel, Toolkit } from "storm-react-diagrams";
import * as _ from "lodash";

export class FormulaNodeModel extends NodeModel {
  constructor() {
    super("Formula");
    this.properties = {
      input: null,
      headers: [],
      type: "multiple",
      maxLinks: 1,
      name: "Function Widget",
      outputData: null,
      message: "Function Tool",
      messages: [],
      metaData: {},
      annotation: "Create/Update columns, use expressions to perform calculations and operations",
      mode: "expression",
      sampleData: [
        {
          Name: "Apple",
          Price: 20000,
          Quantity: 2,
          _id: "5f4d031fe8514e540ba19a2f",
        },
      ],
      columnFormular:   
        {
          column_name: "new_col",
          expression: "",
          preview: "",
        }
    };
  }

  addOutPort(label) {
    return this.addPort(new DefaultPortModel(false, label, label));
  }
  addInPort(label) {
    return this.addPort(new DefaultPortModel(true, label, label));
  }
  printResults(node, port) {
    // console.log(node);
    // console.log(port);
    switch(port){
      case 'input':
        return node.properties.input == null ? []: node.properties.input
      case 'output':
        return node.properties.outputData == null ? []: node.properties.outputData
    }
  }

  updateName = (e) => {
    this.name = e.target.innerHTML;
    console.log(this.name);
  };

  serialize() {
    return _.merge(super.serialize(), {
      scene_name: this.name,
    });
  }
  deSerialize(object, engine) {
    super.deSerialize(object, engine);
    this.name = object.scene_name;
  }
  getInPorts() {
    return _.filter(this.ports, (portModel) => {
      return portModel.in;
    });
  }

  getOutPorts() {
    return _.filter(this.ports, (portModel) => {
      return !portModel.in && !portModel.next;
    });
  }
}
