import React, { useState, useEffect, useCallback } from "react";

import { useHistory } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faAt,
  faCheck,
  faCog,
  faDatabase,
  faEye,
  faHome,
  faMailBulk,
  faSearch,
  faSms,
} from "@fortawesome/free-solid-svg-icons";

import {
  Col,
  Row,
  Form,
  Button,
  ButtonGroup,
  Breadcrumb,
  InputGroup,
  Dropdown,
  Alert,
} from "@themesberg/react-bootstrap";

import moment from "moment-timezone";

import { withRouter } from "react-router-dom";



import { connect } from "react-redux";
import { withFirebase } from "../../../Firebase";

import { compose } from "recompose";

import ReactDataGrid from "@inovua/reactdatagrid-community";
import "@inovua/reactdatagrid-community/index.css";

import "@inovua/reactdatagrid-community/base.css";
import "@inovua/reactdatagrid-community/theme/blue-light.css";

import SelectFilter from "@inovua/reactdatagrid-community/SelectFilter";
import DateFilter from "@inovua/reactdatagrid-community/DateFilter";
import NumberFilter from '@inovua/reactdatagrid-community/NumberFilter'
import CheckBox from "@inovua/reactdatagrid-community/packages/CheckBox";

const gridStyle = { minHeight: 550, marginTop: 10 };

const SENDER_IDS = {
  "CHANNAH-SPA": "CHANNAH-SPA",
  BuniComSMS: "BuniComSMS",
};

const STATUS = {
  "CHANNAH-SPA": "CHANNAH-SPA",
  BuniComSMS: "BuniComSMS",
};

const senderIds = [
  // {
  //   id: "CHANNAH-SPA",
  //   label: "CHANNAH-SPA",
  // },
  // {
  //   id: "CENTRAL-NET",
  //   label: "CHANNAH-SPA",
  // },
  {
    id: "BuniComSMS",
    label: "BuniComSMS",
  },
];

const message_status = [
  {
    id: "DeliveredToTerminal",
    label: "DeliveredToTerminal",
  },
  {
    id: "SentToTerminal",
    label: "SentToTerminal",
  },
  {
    id: "SenderName Blacklisted",
    label: "SenderName Blacklisted",
  },
];

const telco_network = [
  {
    id: "1",
    label: "Safaricom",
  },
  {
    id: "2",
    label: "Airtel",
  },
  {
    id: "3",
    label: "Telcom",
  },
  {
    id: "4",
    label: "Equitel",
  },
];
const filterValue = [
  { name: "Recipients", operator: "contains", type: "string", value: "" },
  { name: "messageid", operator: "eq", type: "number", value: null },
  { name: "Message", operator: "contains", type: "string", value: "" },
  { name: "Network", operator: "contains", type: "string", value: "" },
  { name: "send-time", operator: "contains", type: "string", value: "" },
  { name: "delivery-time", operator: "contains", type: "string", value: "" },
  { name: "TAT", operator: "contains", type: "string", value: "" },
  { name: "Sender", operator: "contains", type: "string", value: "" },
  // {
  //   name: 'type',
  //   operator: 'contains',
  //   type: 'string',
  //   value: ''
  // },
  {
    name: "delivery-description",
    operator: "contains",
    type: "string",
    value: "",
  },
  { name: 'Message_Credits', operator: 'gte', type: 'number', value: 1 },
];

const columns = [
  { name: "_id", header: "#", render: ({ value }) => <span>---</span> },
  // { name: "_id", maxWidth: 40, header: "_id", defaultVisible: false },
  {
    name: "messageid",
    header: "MessageId",
    render: ({ value }) => (value == "-" ? "EMAIL-AUTO" : value),
  },

  { name: "Recipients", header: "Recipients" },
  { name: "Message", header: "Message" },
  { name: "Sender", header: "Sender" },
  { name: "delivery-description", header: "Description" },
  {
    name: "Network",
    header: "Network",
    render: ({ value }) =>
      value == "1" ? "Safaricom" : value == "2" ? "Airtel" : value,
  },
  {
    name: "send-time",
    header: "Sent On",
    filterEditor: DateFilter,
    filterEditorProps: (props, { index }) => {
      // for range and notinrange operators, the index is 1 for the after field
      return {
        dateFormat: "MM-DD-YYYY",
        cancelButton: false,
        highlightWeekends: false,
        placeholder:
          index == 1 ? "Created date is before..." : "Created date is after...",
      };
    },
    render: ({ value, cellProps }) => {
      return moment(value).format("MM-DD-YYYY");
    },
  },
  { name: "delivery-time", header: "Delivered On" },
  { name: "delivery-tat", header: "T.A.T" },
  { name: "Message_Credits", header: "Credits" },
  { name: "Message_Length", header: "Length" },
];

const loadData = ({ skip, limit, sortInfo, groupBy, filterValue }) => {
  const url =
    "https://api.bunicom.com/get-msg-logs?" +
    "skip=" +
    skip +
    "&limit=" +
    limit +
    (groupBy && groupBy.length ? "&groupBy=" + groupBy : "") +
    "&sortInfo=" +
    JSON.stringify(sortInfo) +
    "&filterBy=" +
    JSON.stringify(filterValue);

  return fetch(url).then((response) => {
    const totalCount = response.headers.get("X-Total-Count");
    //   for (var pair of response.headers.entries()) {
    //     console.log(pair[0]+ ': '+ pair[1]);
    //  }
    return response.json().then((data) => {
      return Promise.resolve({ data, count: parseInt(totalCount) });
    });
  });
};


const SEPARATOR = ',';

function ComposeButton(props) {
  const history = useHistory();

  function handleClick() {
    history.push("/org/" + props.appid + "/sms/msg/" + props.id);
    console.log("Soon");
  }

  return (
    <Button variant="light" size="md" onClick={handleClick}>
      View SMS
    </Button>
  );
}

function SMSButton(props) {
  const history = useHistory();

  function handleClick() {
    history.push("/org/" + props.appid + "/sms");
  }

  return (
    <Button variant="outline-secondary" size="md" onClick={handleClick}>
      <FontAwesomeIcon icon={faMailBulk} className="me-2" />
      Send SMS
    </Button>
  );
}

function OutboxPage(props) {
  const history = useHistory();

  const [gridRef, setGridRef] = useState(null);
  const [defaultSortInfo, setdefaultSortInfo] = useState({
    app_id: props.match.params.org_id,
  });
  const [sortInfo, setSortInfo] = useState({
    app_id: props.match.params.org_id,
  });

  const [columnData, setColumnData] = useState(columns);
  
  const [SenderIds, setSenderIds] = useState([{
    id: "BuniComSMS",
    label: "BuniComSMS",
  }]);

  const [BusinessAccount, setBusinessAccount] = useState(null);
  const [authUser, setAuth] = useState(null);

  const dataSource = useCallback(loadData, []);

  const saveSortInfos = (data) => {
    setSortInfo({ ...data, app_id: props.match.params.org_id });
  };

  const [enableFiltering, setEnableFiltering] = useState(true);

  useEffect(() => {
    let cols = [
      {
        name: "_id",
        header: "#",
        render: ({ value }) => (
          <ComposeButton id={value} appid={props.match.params.org_id} />
        ),
      },
      // { name: "_id", maxWidth: 40, header: "_id", defaultVisible: false },
      {
        name: "messageid",
        header: "MessageId",
        render: ({ value }) => (value == "-" ? "EMAIL-AUTO" : value),
      },

      { name: "Recipients", header: "Recipients" },
      { name: "Message", header: "Message" },
      {
        name: "Sender",
        header: "Sender",
        filterEditor: SelectFilter,
        filterEditorProps: {
          placeholder: "Select SenderID",
          dataSource: SenderIds,
        },
        render: ({ value }) => value,
      },
      { name: "delivery-description", header: "Description",
      filterEditor: SelectFilter,
      filterEditorProps: {
        placeholder: "Filter By Status",
        dataSource: message_status,
      },
      render: ({ value }) => value
     },
      {
        name: "Network",
        header: "Network",
        filterEditor: SelectFilter,
        filterEditorProps: {
          placeholder: "Select Telco",
          dataSource: telco_network,
        },
        render: ({ value }) =>
          value == "1" ? "Safaricom" : value == "2" ? "Airtel" : value,
      },
      {
        name: "send-time",
        header: "Sent On",
        filterEditor: DateFilter,
        filterEditorProps: (props, { index }) => {
          // for range and notinrange operators, the index is 1 for the after field
          return {
            dateFormat: "YYYY-MM-DD",
            cancelButton: false,
            highlightWeekends: false,
            placeholder:
              index == 1
                ? "Created date is before..."
                : "Created date is after...",
          };
        },
        render: ({ value, cellProps }) => {
          return moment(value).format("YYYY-MM-DD");
        },
      },
      {
        name: "delivery-time",
        header: "Delivered On",
        filterEditor: DateFilter,
        filterEditorProps: (props, { index }) => {
          // for range and notinrange operators, the index is 1 for the after field
          return {
            dateFormat: "YYYY-MM-DD",
            cancelButton: false,
            highlightWeekends: false,
            placeholder:
              index == 1
                ? "Created date is before..."
                : "Created date is after...",
          };
        },
        render: ({ value, cellProps }) => {
          return moment(value).format("YYYY-MM-DD");
        },
      },
      { name: "delivery-tat", header: "T.A.T" },
      { name: "Message_Credits", header: "Credits", type: 'number', filterEditor: NumberFilter  },
      { name: "Message_Length", header: "Length" },
    ];

    setColumnData(cols);
   

  }, [SenderIds]);

  useEffect(() => {
    const unsubscribe = props.firebase.auth.onAuthStateChanged((authUser) => {
      if (authUser) {
        setAuth(authUser);

        // Get business account and save details

        let current_bsa = props.firebase.db
          .collection("business_accounts")
          .where("uid", "==", authUser.uid)
          .get()
          .then((querySnapshot) => {
            let bsa = {};
            querySnapshot.forEach((doc) => {
              // doc.data() is never undefined for query doc snapshots
              //   console.log(doc.id, " => ", doc.data());
              bsa = { ...doc.data(), id: doc.id };
            });

            if (BusinessAccount == null) {
              setBusinessAccount(bsa);
              let current_senderIds = props.firebase.db
                .collection("organisation_senderids")
                .where("orgId", "==", authUser.uid)
                .get()
                .then((querySnapshot) => {
                  let senderId = [
                    {
                      id: "BuniComSMS",
                      label: "BuniComSMS",
                    }
                  ];
                  querySnapshot.forEach((doc) => {
                    // doc.data() is never undefined for query doc snapshots
                    console.log(doc.id, " => ", doc.data());
                   
                    let data = doc.data()

                    senderId.push({ id: data.sender_name, label: data.sender_name });

                  });

                  setSenderIds(senderId);

                })
                .catch((error) => {
                  console.log("Error getting documents: ", error);
                });
            }
          })
          .catch((error) => {
            console.log("Error getting documents: ", error);
          });

      } else {
        setAuth(null);
        //save null business account
      }
    });

    return () => {
      unsubscribe();
    };
  });


  const exportCSV = () => {
    const columns = gridRef.current.visibleColumns;
  
    const header = columns.map((c) => c.name).join(SEPARATOR);
    const rows = gridRef.current.data.map((data) => columns.map((c) => data[c.id]).join(SEPARATOR));
  
    const contents = [header].concat(rows).join('\n');
    const blob = new Blob([contents], { type: 'text/csv;charset=utf-8;' });
  
    downloadBlob(blob);
  };
  
  
  
  const downloadBlob = (blob, fileName = 'grid-data.csv') => {
    const link = document.createElement('a');
    const url = URL.createObjectURL(blob);
  
    link.setAttribute('href', url);
    link.setAttribute('download', fileName);
    link.style.position = 'absolute';
    link.style.visibility = 'hidden';
  
    document.body.appendChild(link);
  
    link.click();
  
    document.body.removeChild(link);
  };
  

  return (
    <>
      <div className="container-fluid mt--6">
        <div className="row justify-content-center">
          <div className="col-lg-12 card-wrapper">
            <div className="d-block mb-4 mb-md-0">
              {/* {JSON.stringify(SenderIds)} */}
              {/* <Alert variant="secondary" show={true}>
                <div className="d-flex justify-content-between">
                  <div>
                    <FontAwesomeIcon icon={faDatabase} className="me-1" />
                    <strong>Message Logs!</strong> All messages sent
                  </div>
                </div>
              </Alert> */}
            </div>
            <Button style={{ marginTop: 20 }} onClick={exportCSV}>
        Export CSV
      </Button>


            <ReactDataGrid
              idProperty="id"
              handle={setGridRef}
              style={gridStyle}
              columns={columnData}
              pagination
              theme="blue-light"
              defaultFilterValue={filterValue}
              defaultSortInfo={defaultSortInfo}
              sortInfo={sortInfo}
              onSortInfoChange={saveSortInfos}
              dataSource={dataSource}
              defaultLimit={20}
            />
          </div>
        </div>
      </div>
      {/* <div className="d-flex justify-content-between flex-wrap flex-md-nowrap align-items-center py-4">
        <div className="d-block mb-4 mb-md-0">
          <Breadcrumb
            className="d-none d-md-inline-block"
            listProps={{ className: "breadcrumb-dark breadcrumb-transparent" }}
          >
            <Breadcrumb.Item>
              <FontAwesomeIcon icon={faHome} />
            </Breadcrumb.Item>
            <Breadcrumb.Item>BuniComms</Breadcrumb.Item>
            <Breadcrumb.Item active>Transactions</Breadcrumb.Item>
          </Breadcrumb>
          <h4>Sent Messages</h4>
          <p className="mb-0">Your web analytics dashboard template.</p>
        </div>
        <div className="btn-toolbar mb-2 mb-md-0">
          <ButtonGroup>
            <Button variant="outline-primary" size="sm">
              Share
            </Button>
            <Button variant="outline-primary" size="sm">
              Export
            </Button>
          </ButtonGroup>
        </div>
      </div> */}
    </>
  );
}
const mapStateToProps = (state) => {
  return {
    message_logs: state.message_logs,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {};
};

export default compose(
  connect(mapStateToProps, mapDispatchToProps),
  withFirebase
)(OutboxPage);