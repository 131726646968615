import React from "react";
import { useHistory } from "react-router-dom";

import './invoice.css'


function InvoicePage(props = { user: "John Doe", date: "01/01/1970", order_no: "#1", sms_rate: 0.8, credits: 0, amount: 0}) {

    
 return (
    <div className="container mt-5">
    <div className="d-flex justify-content-center row">
        <div className="col-md-8">
            <div className="p-3 bg-white rounded">
                <div className="row">
                    <div className="col-md-6">
                        <h1 className="text-uppercase">Invoice</h1>
                        <div className="billed"><span className="font-weight-bold text-uppercase">Billed: </span><span className="ml-1">{props.user}</span></div>
                        <div className="billed"><span className="font-weight-bold text-uppercase">Date: </span><span className="ml-1">{props.date}</span></div>
                        <div className="billed"><span className="font-weight-bold text-uppercase">Order ID: </span><span className="ml-1">{props.order_no}</span></div>
                    </div>
                    <div className="col-md-6 text-right mt-3">
                        <h4 className="text-primary mb-0">BuniFlow Inc</h4><span>info@bunicom.com</span>
                    </div>
                </div>
                <div className="mt-3">
                    <div className="table-responsive">
                        <table className="table">
                            <thead>
                                <tr>
                                    <th>Item</th>
                                    <th>Rate</th>
                                    <th>Credits</th>
                                    <th>Amount</th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr>
                                    <td>Bunicom Credit Recharge</td>
                                    <td>Ksh {props.sms_rate}</td>
                                    <td>{props.credits}</td>
                                    <td>Ksh {props.amount}</td>
                                </tr>
                               
                                <tr>
                                    <td></td>
                                    <td></td>
                                    <td>Total</td>
                                    <td>Ksh {props.amount}</td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
 )
}

export default InvoicePage