import React, { Component } from "react";
import ReactDOM from "react-dom";
import jexcel from "jexcel";
import Axios from "axios";

import { connect } from "react-redux";
import { withRouter } from "react-router-dom";

import "./styles.css";
import "../../../../node_modules/jexcel/dist/jexcel.css";

import { Toolkit } from "storm-react-diagrams";

import * as actionTypes from "./../../../store/actions";

class HeaderTable extends React.Component {
  constructor(props) {
    super(props);

    let that = this;

    this.state = {
      entity: null,
      fields_data: [],
    };

    this.options = {
      data: [[""]],
      minDimensions: [2, 1],
      columns: [
        { type: "text", title: "Key", width: 200 },
        { type: "text", title: "Value", width: 200 },
      ],
      ondeleterow: function (instance) {
        // console.log(that.saveDtypes());
        that.saveDtypesNode();
      },
      onchange: function (instance, cell, x, y, value) {
        console.log("Changed");
        that.saveDtypesNode();
      },
      columnDrag: true,
      allowManualInsertRow: false,
      allowManualInsertColumn: false,
      allowInsertRow: true,
      allowInsertColumn: false,
      allowDeleteRow: true,
      allowDeleteColumn: false,
      allowRenameColumn: false,
      allowComments: false,
      allowExport: false,
      oninsertrow: this.insertedRow,
    };
  }

  componentDidMount = function () {
    this.el = jexcel(ReactDOM.findDOMNode(this).children[0], this.options);
    // this.saveDtypes()
    this.refreshUITable();
  };

  objectify(array) {
    return array.reduce(function (p, c) {
      p[c[0]] = c[1];
      return p;
    }, {});
  }

  refreshUITable() {
    // Get headers object
    if (this.props.node.properties.toolConfig.hasOwnProperty("headers")) {
      let headerObject = this.props.node.properties.toolConfig.headers;

      console.log("*****************Request Data Table************");

      console.log(this.props.node.properties);

      if (typeof headerObject === "object" && headerObject !== null) {
        let entries = Object.entries(headerObject);

        console.log(entries);

        this.el.setData(entries);
      }
    }
    //Convert header object to array of values
    // set Jexcel data with values
  }

  componentDidUpdate(prevProps, prevState) {
    // this.saveDtypes()
  }

  addRow = function () {
    this.el.insertRow(["", ""]);
  };

  saveDtypesNode = function () {
    let records = this.el.getData();
    let headers = this.el.getHeaders().split(",");

    let headerObj = this.objectify(records)

    console.log("Objectified object");

    console.log(headerObj);

    if (this.props.node.properties.toolConfig.hasOwnProperty("headers")) {
      let node = this.props.node;
      let model = this.props.model;
      let currNode = model.getNode(node.id);

      currNode.properties.toolConfig.headers = headerObj;
      this.props.onSaveHeader(headerObj);
    }

    


  };

  saveDtypes = function () {};

  insertedRow = function (instance) {
    console.log(instance);
  };

  render() {
    return (
      <div>
        <div></div>

        <div>
          <input
            type="button"
            value="+ Add header"
            onClick={() => this.addRow()}
          ></input>
        </div>

        <br />
        <br />
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {};
};

const mapDispatchToProps = (dispatch) => {
  return {};
};

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(HeaderTable)
);
