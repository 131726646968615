import React from "react";
import ReactDOM from "react-dom";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";

import Axios from "axios";
import * as actionTypes from "./../../../store/actions";
import FilterTable from "./FilterTable";
import { Row, Col, Card, Button, Form } from "react-bootstrap";

class FilterComponent extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      // Set initial files, type 'local' means this is a file
      // that has already been uploaded to the server (see docs)
      node: null,
    };

    //   this.handleChangeSelectedEntity = this.handleChangeSelectedEntity.bind(this);
  }

  handleAnnotationChange(event, label) {
    const target = event.target;
    const value = target.value;
    const name = target.name;

    console.log(label);
    console.log(value);
    console.log(name);

    let node = this.props.node;
    let model = this.props.model;
    let currNode = model.getNode(node.id);

    currNode.properties[name] = value;

    this.props.updateNode();
    this.forceUpdate();
  }

 
  componentDidMount = function () {
    console.log("Mounted");
    console.log(this.props.selected_node);
  };

  render() {
    return (
      <div className="modal-body">
        <div className="text-left">
          {/* <h6 className="heading mt-1">Select Node Configs</h6> */}
          <Form>
            <Row>
              <Col md={12}>
                <FilterTable
                  f_node={this.props.selected_node}
                  model={this.props.model}
                  env_variables={this.props.env_variables} 
                />
              
              </Col>
              <Col md={12}>
                <Form.Group controlId="exampleForm.ControlTextarea1">
                  <Form.Label>Comment</Form.Label>
                  <Form.Control
                    as="textarea"
                    rows="3"
                    name="annotation"
                    value={
                      this.props.selected_node["properties"].hasOwnProperty(
                        "annotation"
                      )
                        ? this.props.selected_node["properties"]["annotation"]
                        : ""
                    }
                    onChange={(e) => this.handleAnnotationChange(e, "password")}
                  />
                </Form.Group>
              </Col>
            </Row>
          </Form>
          <p>{/* {node.properties.headers.join(',')} */}</p>
          <p>
            {/* <span id="coderea">{ JSON.stringify(this.props.node) }</span> */}
          </p>
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    
  };
};

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(FilterComponent)
);
