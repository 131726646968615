import React, { Component } from "react";
import ReactDOM from "react-dom";
import jexcel from "jexcel";
import Axios from "axios";

import { connect } from "react-redux";
import { withRouter } from "react-router-dom";

import "./styles.css";
import "../../../../node_modules/jexcel/dist/jexcel.css";

import { Toolkit } from "storm-react-diagrams";

import * as actionTypes from "./../../../store/actions";

class FilterTable extends React.Component {
  constructor(props) {
    super(props);

    let that = this;

    this.state = {
      entity: null,
      fields_data: [],
    };

    this.options = {
      data: [[""]],
      minDimensions: [7, 5],
      defaultColWidth: 100,
      tableWidth: "520px",
      tableOverflow: true,
      columns: [
        {
          type: "dropdown",
          source: ["Quantity", "Price"],
          title: "Select Column",
          width: 80,
        },
        {
          type: "dropdown",
          title: "Filter Condition",
          filter: that.dropdownFilter,
          source: [
            "isnull",
            "notnull",
            "isnumeric",
            "is equal to",
            "is empty",
            "is notempty",
            "is less than",
            "is less than or equal",
            "is greater than",
            "is greater than or equal to",
            "is null",
            "contains",
            "startswith",
            "endswith",
          ],
          width: 120,
        },
        {
          type: "dropdown",
          title: "Filter By",
          source: ["Value(x)", "Column", "Env_variable"],
          width: 80,
        },
        { type: "text", title: "Value", width: 80 },
        {
          type: "dropdown",
          title: "Column",
          source: ["Quantity", "Price"],
          width: 80,
        },
        { type: "dropdown", title: "Variable", source: ["Price"], width: 80 },
        { type: "dropdown", title: "Chain", width: 50, source: ["and", "or"] },
        // { type: "dropdown", title: "Column datatype", width: 50, source: ["object"]},
        // { type: "dropdown", title: "Rename column", width: 100, source:['==','>=','<=','!='], filter: that.dropdownFilter },
      ],
      ondeleterow: function (instance) {
        // console.log(that.saveDtypes());
        that.saveDtypesNode();
      },
      updateTable: function (instance, cell, col, row, val, label, cellName) {
        // var cellName = jexcel.getColumnNameFromId([col,row]);
        if (cellName.includes("A")) {
          if (val !== "") {
            // if(parseInt(row) > 0){
            // //  let name_col = that.el.getCell('A'+parseInt(row))
            if (that.el) {
              var elem = that.el;
              console.log("Haitus");
              let sum = parseInt(row) + 1;
              // let name_col = elem.getValue('A'+sum)

              let chain_col = elem.getCell("G" + sum);
              let chain_value = elem.getValue("G" + sum);
              console.log(sum);
              console.log(chain_value);
              chain_col.style.backgroundColor = "#fff";
              if (chain_value == "") {
                chain_col.style.backgroundColor = "#ff0b1b";
              }
            }

            // }
          }

          // IF(val)
        }
        // if (cellName.includes("B")) {
        //   console.log("Cell I dentiew");

        //   if (val == "notnull") {
        //     let sum = parseInt(row) + 1;
        //     console.log("E" + sum);
        //     var cell_c = that.el.getCell("C" + sum);
        //     var cell_d = that.el.getCell("D" + sum);
        //     var cell_e = that.el.getCell("E" + sum);
        //     var cell_f = that.el.getCell("F" + sum);
            
        //     cell_c.classList.add("readonly");
        //     cell_c.style.backgroundColor = "#666";
            
        //     cell_d.classList.add("readonly");
        //     cell_d.style.backgroundColor = "#666";

        //     cell_e.classList.add("readonly");
        //     cell_e.style.backgroundColor = "#666";

        //     cell_f.classList.add("readonly");
        //     cell_f.style.backgroundColor = "#666";
        //   }else{
        //     let sum = parseInt(row) + 1;
        //     console.log("E" + sum);
        //     var cell_c = that.el.getCell("C" + sum);
        //     var cell_d = that.el.getCell("D" + sum);
        //     var cell_e = that.el.getCell("E" + sum);
        //     var cell_f = that.el.getCell("F" + sum);
        //     cell_c.classList.remove("readonly");
        //     cell_c.style.backgroundColor = "#ffffff";
            
        //     cell_d.classList.remove("readonly");
        //     cell_d.style.backgroundColor = "#ffffff";

        //     cell_e.classList.remove("readonly");
        //     cell_e.style.backgroundColor = "#ffffff";

        //     cell_f.classList.remove("readonly");
        //     cell_f.style.backgroundColor = "#ffffff";

        //   }

          
        // }
        if (cellName.includes("C")) {
          console.log("Cell I dentiew");

          if (val == "Value(x)") {
            let sum = parseInt(row) + 1;
            console.log("E" + sum);
            var cell_d = that.el.getCell("D" + sum);
            var cell_e = that.el.getCell("E" + sum);
            var cell_f = that.el.getCell("F" + sum);

            // console.log();
            cell_d.classList.remove("readonly");
            cell_d.style.backgroundColor = "#90EE90";

            cell_e.classList.add("readonly");
            cell_e.style.backgroundColor = "#666";

            cell_f.classList.add("readonly");
            cell_f.style.backgroundColor = "#666";
          }

          if (val == "Column") {
            let sum = parseInt(row) + 1;
            console.log("E" + sum);
            var cell_d = that.el.getCell("D" + sum);
            var cell_e = that.el.getCell("E" + sum);
            var cell_f = that.el.getCell("F" + sum);

            // console.log();
            cell_d.classList.add("readonly");
            cell_d.style.backgroundColor = "#666";

            cell_e.classList.remove("readonly");
            cell_e.style.backgroundColor = "#90EE90";

            cell_f.classList.add("readonly");
            cell_f.style.backgroundColor = "#666";
          }

          if (val == "Env_variable") {
            let sum = parseInt(row) + 1;
            // console.log('E'+sum);
            var cell_d = that.el.getCell("D" + sum);
            var cell_e = that.el.getCell("E" + sum);
            var cell_f = that.el.getCell("F" + sum);

            // console.log();
            cell_d.classList.add("readonly");
            cell_d.style.backgroundColor = "#666";
            cell_e.classList.add("readonly");
            cell_e.style.backgroundColor = "#666";

            cell_f.classList.remove("readonly");
            cell_f.style.backgroundColor = "#90EE90";
          }
        }

        
      },

      onchange: function (instance, cell, x, y, value) {
        var cellName = jexcel.getColumnNameFromId([x, y]);

        if (cellName.includes("C")) {
          console.log("Cell I dentiew");

          if (value == "Value(x)") {
            let sum = parseInt(y) + 1;
            console.log("E" + sum);
            var cell_d = that.el.getCell("D" + sum);
            var cell_e = that.el.getCell("E" + sum);
            var cell_f = that.el.getCell("F" + sum);

            // console.log();
            cell_d.classList.remove("readonly");
            cell_d.style.backgroundColor = "#90EE90";

            cell_e.classList.add("readonly");
            cell_e.style.backgroundColor = "#666";

            cell_f.classList.add("readonly");
            cell_f.style.backgroundColor = "#666";
          }

          if (value == "Column") {
            let sum = parseInt(y) + 1;
            // console.log('E'+sum);
            var cell_d = that.el.getCell("D" + sum);
            var cell_e = that.el.getCell("E" + sum);
            var cell_f = that.el.getCell("F" + sum);

            // console.log();
            cell_d.classList.add("readonly");
            cell_d.style.backgroundColor = "#666";

            cell_e.classList.remove("readonly");
            cell_e.style.backgroundColor = "#90EE90";

            cell_f.classList.add("readonly");
            cell_f.style.backgroundColor = "#666";
          }

          if (value == "Env_variable") {
            let sum = parseInt(y) + 1;
            // console.log('E'+sum);
            var cell_d = that.el.getCell("D" + sum);
            var cell_e = that.el.getCell("E" + sum);
            var cell_f = that.el.getCell("F" + sum);

            // console.log();
            cell_d.classList.add("readonly");
            cell_d.style.backgroundColor = "#666";
            cell_e.classList.add("readonly");
            cell_e.style.backgroundColor = "#666";

            cell_f.classList.remove("readonly");
            cell_f.style.backgroundColor = "#90EE90";
          }
        }
        // var cell_e = that.el.getCell(cellName)
        // cell_e.classList.add('readonly');

        // console.log(cellName);
        // console.log(cell);
        // console.log(value);
        that.saveDtypesNode();
      },
      columnDrag: true,
      selectionCopy: false,
      allowManualInsertRow: false,
      allowManualInsertColumn: false,
      allowInsertColumn: false,
      allowDeleteColumn: false,
      allowRenameColumn: false,
      allowComments: false,
      allowExport: false,
      oninsertrow: this.insertedRow,
    };
  }

  componentDidMount = function () {
    // this.el = jexcel(ReactDOM.findDOMNode(this).children[0], this.options);

    this.saveDtypes();

    // let fields_arr = resp.data.map((foo) => {

    //     return Object.values(foo)
    //   })
  };

  componentDidUpdate(prevProps, prevState) {
    this.saveDtypes();

    // if(prevProps.env_variables !== this.props.env_variables){
    //     console.log("ENVIRONMENT VARIABLES");
    //     // console.log(prevProps.dtypes);
    //     // console.log(this.props.dtypes);

    // }
  }
  saveDtypesNode = function () {
    let selectNode = this.props.f_node;
    let model = this.props.model;
    let currNode = model.getNode(selectNode.id);

    if(selectNode){

      if (selectNode.properties.hasOwnProperty("conditions")) {
        let records = this.el.getData();
        let headers = [
          "column_name",
          "condition",
          "filter_by",
          "x_value",
          "column",
          "env_variable",
          "chain",
        ];
        let arr_objs_records = [];
        let selected_columns = [];
  
        records.forEach((record) => {
          let obj_rec = {};
          let count = 0;
  
          headers.forEach((item) => {
            obj_rec[item] = record[count];
  
            count++;
          });
          if (obj_rec["column_name"] !== "") {
            arr_objs_records.push(obj_rec);
          }
        });
  
        // console.log(arr_objs_records);
        // dataArr.push([column.column_name,column.condition,column.filter_by,column.x_value,column.env_variable,column.chain]);
  
        currNode.properties.conditions = arr_objs_records;
      }

    }

    

    // currNode.properties.metaData
  };

  saveDtypes = function () {
    let selectNode = this.props.f_node;

    let model = this.props.model;
    let currNode = model.getNode(selectNode.id);
    document.getElementById("filter-sheet").innerHTML = "";
    // currNode.properties.outputData = event.target.value.split(',')
    console.log(
      "=================Workflow Configs on Filter MOUNTED CYCLE================="
    );

    // var element = document.getElementById("aside-tracker");
    // var positionInfo = element.getBoundingClientRect();
    // var height = positionInfo.height;
    // var width = positionInfo.width;
    // this.options.tableWidth = width > 100 ? width + "px" : "520px";
    // console.log(this.props.env_variables);
    if(currNode){
      if (currNode.properties.metaData.hasOwnProperty("columns")) {
        let columns = currNode.properties.metaData.columns;
        let env_vars = this.props.env_variables;
        let source_cols = [];
        let env_cols = [""];
        columns.forEach((element) => {
          source_cols.push(element.column_name + "-" + element.data_type);
        });
  
          if(env_vars){
            env_vars.forEach((element) => {
              if (element.var_name != "") {
                env_cols.push(element.var_name);
              }
            });
          }
        
        this.options.columns = [
          {
            type: "dropdown",
            source: source_cols,
            title: "Select Column",
            width: 110,
          },
          {
            type: "dropdown",
            title: "Filter Condition",
            filter: this.dropdownFilter,
            source: [
              "isnull",
            "notnull",
            "isnumeric",
            "is equal to",
            "is not equal to",
            "is empty",
            "is notempty",
            "is less than",
            "is less than or equal",
            "is greater than",
            "is greater than or equal to",
            "is null",
            "contains",
            "startswith",
            "endswith",
            ],
            width: 100,
          },
          {
            type: "dropdown",
            title: "Filter By",
            source: ["Value(x)", "Column", "Env_variable"],
            width: 80,
          },
          { type: "text", title: "Value", width: 80 },
          { type: "dropdown", title: "Column", source: source_cols, width: 80 },
          { type: "dropdown", title: "Variable", source: env_cols, width: 80 },
          { type: "dropdown", title: "Chain", width: 50, source: ["and", "or"] },
          // { type: "dropdown", title: "Rename column", width: 100, source:['==','>=','<=','!='], filter: that.dropdownFilter },
        ];
        // this.options.tableWidth = width > 100 ? width + "px" : "520px";
  
        // this.el.se
        // this.el.setData(0, { type: 'dropdown', source: source_cols,title:'Select Column', width:100 },)
      }
      this.el = jexcel(ReactDOM.findDOMNode(this).children[0], this.options);
      // this.el.setData(dataArr)
  
      if (currNode.properties.hasOwnProperty("conditions")) {
        let conditions = currNode.properties.conditions;
        if (conditions.length > 0) {
          let dataArr = [];
  
          conditions.forEach((column) => {
            dataArr.push([
              column.column_name,
              column.condition,
              column.filter_by,
              column.x_value,
              column.column,
              column.env_variable,
              column.chain,
            ]);
          });
  
          this.el.setData(dataArr);
        } else {
          this.el.setData([[]]);
        }
      }

    }

    
  };

  dropdownFilter = function (instance, cell, c, r, source) {
    var value = instance.jexcel.getValueFromCoords(c - 1, r);
    // console.log("I am logging the instance");
    // alert(value);

    // return ['Java','Apples','Bananas','Oranges'];

    if (value.includes("object")) {
      
      return ["isnull","notnull","is equal to","is not equal to","is empty","is notempty", "contains", "startswith", "endswith","islower","isupper","isalnum","istitle","isalpha","isnumeric"];
    } else {
      return [
        "is equal to",
        "is not equal to",
        "is less than",
        "is less than or equal",
        "is greater than",
        "is greater than or equal to",
        "isnull",
        "notnull",
        "isnumeric"
      ];
    }
  };

  addRow = function () {
    this.el.insertRow(["", "is equal to", "Value(x)", "14", "", "", "and"]);
  };

  insertedRow = function (instance) {
    console.log(instance);
  };

  render() {
    return (
      <div>
        <div id="filter-sheet"></div>

        <div>
          <input
            type="button"
            value="+ Add Condition"
            onClick={() => this.addRow()}
          ></input>
        </div>

        <br />
        <br />
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {

  };
};

const mapDispatchToProps = (dispatch) => {
  return {
  };
};

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(FilterTable)
);
