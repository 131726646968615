
export const Routes = {

    // pages
    Account: { path: "/account" },
    Signin: { path: "/" },
    DashboardOverview: { path: "/org/:org_id" },
    Campaigns: { path: "/org/:org_id/campaigns" },
    New: { path: "/org/:org_id/what-is-new" },
    Developers: { path: "/org/:org_id/developers" },
    Reports: { path: "/org/:org_id/reports" },
    NewTemplate: { path: "/org/:org_id/template/:template_id" },

    //Emails
    Sent: { path: "/org/:org_id/mail/sent" },
    MailOptions: { path: "/org/:org_id/mail/options" },
    Compose: { path: "/org/:org_id/mail/compose" },
    Templates: { path: "/org/:org_id/mail/templates" },

    //SMS
    Outbox: { path: "/org/:org_id/sms/outbox" },
    Inbox: { path: "/org/:org_id/sms/inbox" },
    Message: { path: "/org/:org_id/sms/msg/:message_id" },
    SMS: { path: "/org/:org_id/sms" },
    Activity: { path: "/org/:org_id/sms/activities" },
    SMSOptions: { path: "/org/:org_id/sms/options" },
    

    Topup: { path: "/org/:org_id/topup" },
    Order: { path: "/org/:org_id/order/:order_id" },
    Pay: { path: "/org/:org_id/pay" },
    DataStores: { path: "/org/:org_id/datastores" },
    Stores: { path: "/org/:appid/table/:table" },
    Import: { path: "/org/:appid/importfile" },
    Settings: { path: "/org/:org_id/settings" },
    Campaign: { path: "/campaigns/:org_id/:campaign_id" },
    Signup: { path: "/create-account" },
    ForgotPassword: { path: "/examples/forgot-password" },
    ResetPassword: { path: "/examples/reset-password" },
    Lock: { path: "/examples/lock" },
    NotFound: { path: "/examples/404" },
    ServerError: { path: "/examples/500" },
};