export const COLLAPSE_MENU = 'COLLAPSE_MENU';


//Login

export const LOGIN_REQUEST = 'LOGIN_REQUEST';
export const LOGIN_SUCCESS = 'LOGIN_SUCCESS';
export const LOGIN_FAILURE = 'LOGIN_FAILURE';

export const REGISTER_WITH_EMAIL_REQUEST = 'REGISTER_WITH_EMAIL_REQUEST';
export const REGISTER_WITH_EMAIL_SUCCESS = 'REGISTER_WITH_EMAIL_SUCCESS';
export const REGISTER_WITH_EMAIL_FAILURE = 'REGISTER_WITH_EMAIL_FAILURE';

export const PASSWORD_FORGET_REQUEST = 'PASSWORD_FORGET_REQUEST';
export const PASSWORD_FORGET_SUCCESS = 'PASSWORD_FORGET_SUCCESS';
export const PASSWORD_FORGET_FAILURE = 'PASSWORD_FORGET_FAILURE';

export const LOGOUT_REQUEST = 'LOGOUT_REQUEST';
export const LOGOUT_SUCCESS = 'LOGOUT_SUCCESS';
export const LOGOUT_FAILURE = 'LOGOUT_FAILURE';

export const CLEAR_MSG = 'CLEAR_MSG';
export const HIDE_MSG = 'HIDE_MSG';

//Menu Actions
export const SAVE_CAMPAIGNS = 'SAVE_CAMPAIGNS';

export const UPDATE_CSV_FILE = 'UPDATE_CSV_FILE';
export const GET_DATA_REQUEST = 'GET_DATA_REQUEST';
export const SAVE_DATA = 'SAVE_DATA';
export const SAVE_USER = 'SAVE_USER';
export const SAVE_BSA = 'SAVE_BSA';
export const SAVE_FILE = 'SAVE_FILE';
export const SAVE_ORDERS = 'SAVE_ORDERS';


export const SAVE_HOTEL_MENUS = 'SAVE_HOTEL_MENUS';
export const SAVE_MENU_ITEMS = 'SAVE_MENU_ITEMS';
// export const LOGOUT_SUCCESS = 'LOGOUT_SUCCESS';
// export const LOGOUT_FAILURE = 'LOGOUT_FAILURE';

//Workflow actions
export const NEW_WORKFLOW_REQUEST = 'NEW_WORKFLOW_REQUEST';
export const NEW_WORKFLOW_SUCCESS = 'NEW_WORKFLOW_SUCCESS';
export const NEW_WORKFLOW_FAILURE = 'NEW_WORKFLOW_FAILURE';

export const GET_WORKFLOW_REQUEST = 'GET_WORKFLOW_REQUEST';
export const GET_WORKFLOW_SUCCESS = 'GET_WORKFLOW_SUCCESS';
export const GET_WORKFLOW_FAILURE = 'GET_WORKFLOW_FAILURE';



//Table actions
export const NEW_TABLE_REQUEST = 'NEW_TABLE_REQUEST';
export const NEW_TABLE_SUCCESS = 'NEW_TABLE_SUCCESS';
export const NEW_TABLE_FAILURE = 'NEW_TABLE_FAILURE';
export const SHOW_TABLE_DIALOG = 'SHOW_TABLE_DIALOG';
export const GET_TABLE_REQUEST = 'GET_TABLE_REQUEST';
export const GET_TABLE_SUCCESS = 'GET_TABLE_SUCCESS';
export const GET_TABLE_FAILURE = 'GET_TABLE_FAILURE';


export const GET_TABLES_REQUEST = 'GET_TABLES_REQUEST';
export const GET_TABLES_SUCCESS = 'GET_TABLES_SUCCESS';
export const GET_TABLES_FAILURE = 'GET_TABLES_FAILURE';

//Template actions

export const NEW_TEMPLATE_REQUEST = 'NEW_TEMPLATE_REQUEST';
export const NEW_TEMPLATE_SUCCESS = 'NEW_TEMPLATE_SUCCESS';
export const NEW_TEMPLATE_FAILURE = 'NEW_TEMPLATE_FAILURE';

export const GET_TEMPLATE_REQUEST = 'GET_TEMPLATE_REQUEST';
export const GET_TEMPLATE_SUCCESS = 'GET_TEMPLATE_SUCCESS';
export const GET_TEMPLATE_FAILURE = 'GET_TEMPLATE_FAILURE';

export const GET_TEMPLATES_REQUEST = 'GET_TEMPLATES_REQUEST';
export const GET_TEMPLATES_SUCCESS = 'GET_TEMPLATES_SUCCESS';
export const GET_TEMPLATES_FAILURE = 'GET_TEMPLATES_FAILURE';

export const GET_ALL_TEMPLATES_REQUEST = 'GET_ALL_TEMPLATES_REQUEST';
export const GET_ALL_TEMPLATES_SUCCESS = 'GET_ALL_TEMPLATES_SUCCESS';
export const GET_ALL_TEMPLATES_FAILURE = 'GET_ALL_TEMPLATES_FAILURE';

//Table Records actions

export const NEW_TABLE_RECORD_REQUEST = 'NEW_TABLE_RECORD_REQUEST';
export const NEW_TABLE_RECORD_SUCCESS = 'NEW_TABLE_RECORD_SUCCESS';
export const NEW_TABLE_RECORD_FAILURE = 'NEW_TABLE_RECORD_FAILURE';

export const UPDATE_TABLE_RECORD_REQUEST = 'UPDATE_TABLE_RECORD_REQUEST';
export const UPDATE_TABLE_RECORD_SUCCESS = 'UPDATE_TABLE_RECORD_SUCCESS';
export const UPDATE_TABLE_RECORD_FAILURE = 'UPDATE_TABLE_RECORD_FAILURE';
// export const SHOW_TABLE_RECORD_DIALOG = 'SHOW_TABLE_RECORD_DIALOG';
export const GET_TABLE_RECORD_REQUEST = 'GET_TABLE_RECORD_REQUEST';
export const GET_TABLE_RECORD_SUCCESS = 'GET_TABLE_RECORD_SUCCESS';
export const GET_TABLE_RECORD_FAILURE = 'GET_TABLE_RECORD_FAILURE';


export const GET_TABLE_RECORDS_REQUEST = 'GET_TABLE_RECORDS_REQUEST';
export const GET_TABLE_RECORDS_SUCCESS = 'GET_TABLE_RECORDS_SUCCESS';
export const GET_TABLE_RECORDS_FAILURE = 'GET_TABLE_RECORDS_FAILURE';


//fIELDactions
export const NEW_FIELD_REQUEST = 'NEW_FIELD_REQUEST';
export const NEW_FIELD_SUCCESS = 'NEW_FIELD_SUCCESS';
export const NEW_FIELD_FAILURE = 'NEW_FIELD_FAILURE';
export const SHOW_FIELD_DIALOG = 'SHOW_FIELD_DIALOG';
export const GET_FIELD_REQUEST = 'GET_FIELD_REQUEST';
export const GET_FIELD_SUCCESS = 'GET_FIELD_SUCCESS';
export const GET_FIELD_FAILURE = 'GET_FIELD_FAILURE';


export const GET_FIELDS_REQUEST = 'GET_FIELDS_REQUEST';
export const GET_FIELDS_SUCCESS = 'GET_FIELDS_SUCCESS';
export const GET_FIELDS_FAILURE = 'GET_FIELDS_FAILURE';


export const GET_MESSAGE_LOGS_REQUEST = 'GET_MESSAGE_LOGS_REQUEST';
export const GET_MESSAGE_LOGS_SUCCESS = 'GET_MESSAGE_LOGS_SUCCESS';
export const GET_MESSAGE_LOGS_FAILURE = 'GET_MESSAGE_LOGS_FAILURE';

export const SHOW_TOOL_PROPERTIES = 'SHOW_TOOL_PROPERTIES';






