import React from "react";
import { Container } from "@themesberg/react-bootstrap";

import { Routes } from "../../routes";

import { AuthUserContext } from "../Session";

import DockLayout from "rc-dock";
import "rc-dock/dist/rc-dock.css";
import { Link } from "react-router-dom";

let tab = {
  content: <div> This panel will show workflow canvas</div>,
  closable: false,
};

const defaultLayout = {
  dockbox: {
    mode: 'horizontal',
    children: [
      {
        mode: 'vertical',
        size: 800,
        children: [
          {
            tabs: [{...tab, id: 't1', title: 'Campaign title goes here',  minWidth: 600, minHeight: 500, closable: false}],
          },
          {
            tabs: [{
              ...tab, id: 't3', title: 'Workflow results show up here', content: (
                <div>
                  <p> This panel will show workflow canvas</p>
                  
                </div>
              ), closable: false
            }],
          },
        ]
      },
      {
        size: 400,
        tabs: [
          {
            ...tab, id: 't5', title: 'Properties', content: (
              <div>
                This panel will show run results
              </div>
            ), closable: false
          },
         
        ],
        panelLock: {panelStyle: 'main'},
      },
      
    ]
  }
  
}
function AccountPage(props) {
  return (
    <>
      <section className="d-flex align-items-center my-5 mt-lg-4 mb-lg-5">
        <Container>
          <div>
            <DockLayout
              defaultLayout={defaultLayout}
              style={{position: 'absolute', left: 10, top: 10, right: 10, bottom: 10}}
            />
            {/* <p>
            Hi there! BuniFlow™ is a super simple, no-nonsense "no code" platform for building workflows and automating manual processes with little to no programming skills
            </p>
            <ul>
              <AuthUserContext.Consumer>
                {(authUser) =>
                  authUser != null ? (
                    <>
                      <li>
                        <Link to={"/org/"+authUser.uid}>Dashboard</Link>
                      </li>
                    </>
                  ) : (
                    <li>
                        <Link to={"/signin"}>Login</Link>
                      </li>
                  )
                }
              </AuthUserContext.Consumer>
            </ul> */}
          </div>
        </Container>
      </section>
    </>
  );
}

export default AccountPage;
