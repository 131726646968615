import * as React from "react";
import * as SRD from "storm-react-diagrams";
import { SheetsNodeModel } from "./SheetsNodeModel";
import { SheetsNodeWidget } from "./SheetsNodeWidget";


export class SheetsNodeFactory extends SRD.AbstractNodeFactory {
    constructor() {
        super("Sheets");
    }

    generateReactWidget(diagramEngine, node) {
        return <SheetsNodeWidget node={node} />;
    }

    getNewInstance() {
        return new SheetsNodeModel();
    }
}


