import { NodeModel, DefaultPortModel, Toolkit } from "storm-react-diagrams";
import EntityPortModel from './EntityPortModel'
import * as _ from "lodash";

export class EntityNodeModel extends NodeModel {
    constructor() {
        super("Entity");
        this.name = "entity";
        this.properties = {
            type: 'single',
            input: [],
            message: 'Entity Input',
            name: 'Entity Tool',
            outputData: [],
            headers: [],
            messages: [],
            metaData: {},
            annotation: "Get data records from BuniComms Data store",
            entity: {
                title: 'Default',
                _id: '5f45666257903b8823b479e9'
            }
          }
    }
   
    addOutPort(label) {
        return this.addPort(new  EntityPortModel(false, label, label));
    }

    printResults(node, port){

        // console.log(node);
        // console.log(port);
        return node.properties.outputData
    
      }

    updateName = (e) => {
         this.name = e.target.innerHTML;
         console.log(this.name);
    };

    serialize() {
        return _.merge(super.serialize(), {
            scene_name: this.name
        });
    }
    deSerialize(object, engine) {
        super.deSerialize(object, engine);
        this.name = object.scene_name;
    }

    getOutPorts() {
        return _.filter(this.ports, portModel => {
            return (!portModel.in && !portModel.next);
        });
    }
}
