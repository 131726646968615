import React, { useState, useEffect } from "react";
import { useHistory, Redirect } from "react-router-dom";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

import { connect } from "react-redux";

import { compose } from "recompose";

import { withFirebase } from "../../../Firebase";
import shortid from "shortid";
import { faBullhorn } from "@fortawesome/free-solid-svg-icons";

import { AuthUserContext } from "../../../Session";


import {
  Container,
  Col,
  Row,
  Button,
  Dropdown,
  ButtonGroup,
  Table,
  Card,
  Modal,
  Form,
  Alert,
  InputGroup,
} from "@themesberg/react-bootstrap";

import TopupTable from "./TopupTable";

// import Axios from "axios";

shortid.characters('0123456789abcdefghijklmnopqrstuvwxyzABCDEFGHIJKLMNOPQRSTUVWXYZ$@');


function CampaignsPage(props) {

  const history = useHistory();

  const { setOrders, organisation_payments } = props;
  const [authUser, setAuth] = useState(null);

  const amount = useFormInput(10);
  const phone_number = useFormInput("");

  let org_id = props.match.params.org_id;

  const onSubmit = (event) => {
    event.preventDefault();
    TopUpCredit();
  };

  useEffect(() => {

    document.title = `My Payments - Bunicom Studio`;

    const unsubscribe = props.firebase.auth.onAuthStateChanged((authUser) => {
      if (authUser) {
        setAuth(authUser);
        console.log(authUser);
      } else {
        setAuth(null);
        //save null business account
      }
    });

    return () => {
      unsubscribe();
    };
  }, []);

  useEffect(() => {
    if (org_id) {
      const unsubscribe = props.firebase.db
        .collection("organisation_payments")
        .where("orgId", "==", org_id)
        .onSnapshot((snapshot) => {
          if (snapshot.size) {
            console.log("we have something");
            // we have something
            let myDataArray = [];
            snapshot.forEach((doc) => {
              myDataArray.push({
                ...doc.data(),
                campaign_id: doc.id,
              });
            });

            setOrders(myDataArray);

            // setLoading(false);
          } else {
            // it's empty
            setOrders([]);
            console.log("Still Empty");
            // setLoading(false);
          }
        });
      return () => {
        // setLoading(true)
        unsubscribe();
      };
    }
  }, [props.firebase, org_id]);

  const TopUpCredit = () => {
    let dateobj = new Date();

    let amount_v = amount.value;
    let phone_v = phone_number.value;

    if (authUser) {
      console.log(authUser);

      let credits = 10

      var docRef = props.firebase.db
        .collection("business_accounts")
        .where("uid", "==", authUser.uid)
        .get()
        .then((querySnapshot) => {
          querySnapshot.forEach((doc) => {
            console.log("Document data:", doc.data());

            //TODO compare sms-rate with firebase
            

        let appData = doc.data()

            let payments_data = {
              status: "pending",
              orgId: props.match.params.org_id,
              amount: amount_v,
              transaction_date: dateobj.toISOString(),
              name: authUser.displayName,
              email: "info@zmc.co.ke",
              phone_number: phone_v,
              credits: amount_v / 0.8,
            };
            console.log(payments_data);

            props.firebase.db
              .collection("organisation_payments")
              .add(payments_data)
              .then((docRef) => {
                console.log("Document written with ID: ", docRef.id);

                if(appData.hasOwnProperty("credits")){
                    credits = appData.credits + payments_data.credits
                }
                
                props.firebase.db
                  .collection("business_accounts")
                  .doc(doc.id)
                  .update({
                    credits: credits,
                  })
                  .then(() => {
                    console.log("Document successfully updated!");
                  })
                  .catch((error) => {
                    // The document probably doesn't exist.
                    console.error("Error updating document: ", error);
                  });
              })
              .catch((error) => {
                console.error("Error writing document: ", error);
              });
          });
        })
        .catch((error) => {
          console.log("Error getting documents: ", error);
        });
    }
  };


  const rechargeCredit = () => {
    // CreateNewOrder()
    history.push("/org/"+org_id+"/pay");
  };
 
  return (
    <>
     
      <AuthUserContext.Consumer>
        {(authUser) =>
          authUser == null ? (
            <p>Please login</p>
          ) : (
            <>
              <div className="container-fluid mt--6">
                <div className="row justify-content-center">
                  <div className="col-lg-10 card-wrapper ct-example">
                    <div className="d-block mb-4 mb-md-0">
                      <h4>Payment History</h4>
                    </div>
                    <div className="card table-wrapper table-responsive shadow-sm">
                      <div className="card-header">
                        <div className="row align-items-center">
                          <div className="col">
                            <h5 className="h5 mb-0">All Orders</h5>
                          </div>
                          <div className="col">
                            <div className="justify-content-end">
                              <Button
                                variant="primary"
                                className="m-1 float-end"
                                onClick={rechargeCredit}
                              >
                                <FontAwesomeIcon
                                  icon={faBullhorn}
                                  className="me-2"
                                />{" "}
                                Recharge your account
                              </Button>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="card-body">
                        <TopupTable
                          data={organisation_payments}
                          org_id={props.match.params.org_id}
                          firebase={props.firebase}
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </>
          )
        }
      </AuthUserContext.Consumer>
    </>
  );
}

const useFormInput = (initialValue) => {
  const [value, setValue] = useState(initialValue);

  const handleChange = (e) => {
    setValue(e.target.value);
  };

  function handleReset() {
    setValue("");
  }
  return {
    value,
    onChange: handleChange,
    onReset: handleReset,
  };
};

const mapStateToProps = (state) => ({
  organisation_payments: state.organisation_payments,
});

const mapDispatchToProps = (dispatch) => {
  return {
    setOrders: (payments) =>
      dispatch({ type: "SAVE_ORDERS", payments: payments }),
  };
};

// export default connect(mapStateToProps, mapDispatchToProps)(withFirebase(HotelsPage));
export default compose(
  connect(mapStateToProps, mapDispatchToProps),
  // withStateHandlers,
  // withHandler,
  withFirebase
)(CampaignsPage);
