import * as SRD from "storm-react-diagrams";
import * as React from "react";
import * as _ from "lodash";
import PropTypes from "prop-types";

import { EmailPortLabel } from "./EmailPortLabelWidget";
import "./email-node.css";

export class EmailNodeWidget extends React.Component {
  static propTypes = {
    node: PropTypes.any,
  };

  constructor(props) {
    super(props);
    console.log(props);
    this.headerRef = React.createRef();
  }

  _clickHeader = () => {
    this.headerRef.current.contentEditable = true;
  };
  _blurHeader = (e) => {
    this.headerRef.current.contentEditable = false;
    this.props.node.updateName(e);
  };

  generatePort(port) {
    return <EmailPortLabel model={port} key={port.id} />;
  }

  createMarkup() {
    return (
      <div>
        <i
          className="fas fa-envelope"
          style={{
            fontSize: "1.5em",
          }}
        />
      </div>
    );
  }

  render() {
    return (
      <div className="email-node">
        {this.props.node.selected ? (
          <div class="rotating-dashed">
            <span class="dashing">
              <i></i>
            </span>
            <span class="dashing">
              <i></i>
            </span>
            <span class="dashing">
              <i></i>
            </span>
            <span class="dashing">
              <i></i>
            </span>
            {/* <strong>&#x1f6d1;</strong> */}
          </div>
        ) : (
          ""
        )}
        <div className="left-port">
          {_.map(this.props.node.getInPorts(), this.generatePort.bind(this))}
        </div>
        <div className="scene">{this.createMarkup()}</div>
        <div className="right-port">
          {_.map(this.props.node.getOutPorts(), this.generatePort.bind(this))}
        </div>
        {this.props.node.properties.message != "error" ? (
          this.props.node.properties.hasOwnProperty("annotation") &&
          this.props.node.properties["annotation"] != "" ? (
            <p className="label--annotate">
              {this.props.node.properties["annotation"]}
            </p>
          ) : (
            ""
          )
        ) : (
          <p className="label--desc">
            {this.props.node.properties.messages.join(",")}
          </p>
        )}
      </div>
    );
  }
}
