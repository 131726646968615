import React, { useState, useEffect } from "react";

import { connect } from "react-redux";
import { withFirebase } from "../../../Firebase";
import { AuthUserContext } from "../../../Session";
import { compose } from "recompose";


function OptionsPage(props) {
  const [authUser, setAuth] = useState(null);

  useEffect(() => {
    const unsubscribe = props.firebase.auth.onAuthStateChanged((authUser) => {
      if (authUser) {
        setAuth(authUser);

      } else {
        setAuth(null);
        //save null business account
      }
    });

    return () => {
      unsubscribe();
    };
  }, []);

  return (
    <AuthUserContext.Provider value={authUser}>
      <div className="container-fluid mt--6">
        <div className="row justify-content-center">
          <div className="col-lg-12 card-wrapper">
            <div className="d-flex justify-content-between flex-wrap flex-md-nowrap align-items-center py-4">
            </div>
          </div>
          <div className="col-lg-12 card-wrapper">
            <div className="card mt-2">
              <div className="card-header">
                <div className="row align-items-center">
                  <div className="col">
                    <h5 className="h3 mb-0">Email Configuration</h5>
                    <small>
                      Coming Soon
                    </small>
                  </div>
                  <div className="col">
                    <div className="justify-content-end"></div>
                  </div>
                </div>
              </div>
             </div>
          </div>
        </div>
      </div>
    </AuthUserContext.Provider>
  );
}

const mapStateToProps = (state) => ({});

const mapDispatchToProps = (dispatch) => {
  return {};
};

export default compose(
  connect(mapStateToProps, mapDispatchToProps),
  withFirebase
)(OptionsPage);
