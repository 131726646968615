import React from "react";
import ReactDOM from "react-dom";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";

import Axios from "axios";
import * as actionTypes from "./../../../store/actions";
import {
    Row,
    Col,
    Card,
    Form,
    Button,
    InputGroup,
    FormControl,
    DropdownButton,
    Dropdown,
  } from "react-bootstrap";

import Editor from "react-simple-code-editor";
import { highlight, languages } from "prismjs/components/prism-core";
import "prismjs/components/prism-clike";
import "prismjs/components/prism-javascript";
import "prismjs/components/prism-sql";
import "prismjs/themes/prism-funky.css";

const code = `IF 60 is less than 50 THEN 1100 ELSE [Price] ENDIF`;

class FormulaNodeComponent extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      // Set initial files, type 'local' means this is a file
      // that has already been uploaded to the server (see docs)
      node: null,
    };

    this.handleInputChange = this.handleInputChange.bind(this);
    this.handleColumnChange = this.handleColumnChange.bind(this);
  }

  handleColumnChange(event, label) {
    const target = event.target;
    const value = target.type === "checkbox" ? target.checked : target.value;
    const name = target.name;

    console.log(label);
    console.log(value);
    console.log(name);
    
    let node = this.props.node;
    let model = this.props.model;
    let currNode = model.getNode(node.id);

    currNode.properties["columnFormular"][name] = value;

    this.props.updateNode();
    // this.forceUpdate();
  }
  handleCodeChange(code) {
    
    let node = this.props.node;
    let model = this.props.model;
    let currNode = model.getNode(node.id);

    currNode.properties["columnFormular"]["expression"] = code;

    this.props.updateNode();
    // this.forceUpdate();
  }
  handleInputChange(event, label) {
    const target = event.target;
    const value = target.type === "checkbox" ? target.checked : target.value;
    const name = target.name;

    console.log(label);
    console.log(value);
    console.log(name);
    
    let node = this.props.node;
    let model = this.props.model;
    let currNode = model.getNode(node.id);

    currNode.properties[name] = value;

    this.props.updateNode();
    // this.forceUpdate();
  }

  handleAnnotationChange(event, label) {
    const target = event.target;
    const value = target.value;
    const name = target.name;

    console.log(label);
    console.log(value);
    console.log(name);

    let node = this.props.node;
    let model = this.props.model;
    let currNode = model.getNode(node.id);

    currNode.properties[name] = value;

    this.props.updateNode();
    // this.forceUpdate();
  }

  validateFormula = function (code) {
    console.log("Let the fun begin");

    let that = this;

    let payload = {
        column_name: this.props.selected_node.properties.columnFormular["column_name"],
        expression: code,
      sampleData: this.props.node.properties.sampleData,
    };

    Axios.post("https://api.bunicom.com///validate-formula", payload)
      .then((response) => {
        let node = that.props.node;
        let model = that.props.model;
        let currNode = model.getNode(node.id);

        let columnFormular = {
          column_name: that.props.selected_node.properties.columnFormular["column_name"],
          expression: code,
          preview: "",
        };

        currNode.properties.columnFormular = columnFormular;

        // console.log(currNode.properties);

        if (response.data.status === "Bad") {
            currNode.properties.message = "error";
            currNode.properties.messages.push("Invalid Syntax")
        } else {
          currNode.properties.columnFormular = columnFormular;
          currNode.properties.message = "success";
          currNode.properties.messages.push("Good")

        }

        that.props.updateNode();
      })
      .catch((e) => {
        console.log(e);
      });
  };

  componentDidMount = function () {
    console.log("Mounted");
    console.log(this.props.selected_node);
  };

  renderEmail(node) {
    return <Col md={12}></Col>;
  }

  render() {
    let selected_node = this.props.selected_node;
    return (
      <div className="modal-body">
        <div className="text-left">
          {/* <h6 className="heading mt-1">Select Node Configs</h6> */}
          <Form>
            <Row>
            <Col md="12">
                {/* <FormGroup>
                  <Input
                    type="text"
                    
                    value={selected_node["properties"]["columnFormular"]["column_name"]}
                    onChange={(e) => this.handleInputChange(e, "column_name")}
                  />
                </FormGroup> */}

                <InputGroup size="sm" className="mb-3">
                        <InputGroup.Prepend>
                          <InputGroup.Text id="inputGroup-sizing-sm">
                           Column Name
                          </InputGroup.Text>
                        </InputGroup.Prepend>
                        <FormControl
                          type="text"
                          name="column_name"
                          value={selected_node["properties"]["columnFormular"]["column_name"]}
                          onChange={(e) => this.handleColumnChange(e, "column_name")}
                        />
                      </InputGroup>
              </Col>
              <Col md={3}>
                      <Form.Check
                        custom
                        type="radio"
                        value="expression"
                        label="Expression"
                        name="mode"
                        id="mode11"
                        checked={
                          selected_node["properties"]["mode"] ===
                          "expression"
                        }
                        onChange={(e) => this.handleInputChange(e, "mode")}
                      />
                    </Col>
                    <Col md={3}>
                      <Form.Check
                        custom
                        type="radio"
                        value="custom"
                        label="Custom"
                        name="mode"
                        id="mode21"
                        checked={
                          selected_node["properties"]["mode"] ===
                          "custom"
                        }
                        onChange={(e) => this.handleInputChange(e, "mode")}
                      />
                    </Col>
              <Col md="12">
                <Editor
                  value={selected_node["properties"]["columnFormular"]["expression"]}
                  onValueChange={(code) => this.handleCodeChange(code)}
                  highlight={(code) => highlight(code, languages.javascript)}
                  padding={10}
                  style={{
                    background: "#f2f2f2",
                    fontFamily: '"Fira code", "Fira Mono", monospace',
                    fontSize: 12,
                  }}
                />

                <p>
                  Quick notes <br />
                  Rule #1: Columns - use input table columns as they are named
                  (no whitespaces)
                  <br />
                  Rule #2: For Attributes, make sure you know the datatype. Then
                  use methods available for Pandas Series
                  <br />
                  For example <code id="coderea">Product.str.len()</code> to get
                  the length of a string. For a quick reference, check out
                  https://pandas.pydata.org/pandas-docs/stable/reference/api/pandas.Series.html
                </p>
              </Col>
              <Col md={12}>
                <Form.Group controlId="exampleForm.ControlTextarea1">
                  <Form.Label>Add Comment</Form.Label>
                  <Form.Control
                    as="textarea"
                    rows="3"
                    name="annotation"
                    value={
                      selected_node["properties"].hasOwnProperty("annotation")
                        ? selected_node["properties"]["annotation"]
                        : ""
                    }
                    onChange={(e) => this.handleAnnotationChange(e, "password")}
                  />
                </Form.Group>
              </Col>
            </Row>
          </Form>
          <p>{/* {node.properties.headers.join(',')} */}</p>
          <p>
            {/* <span id="coderea">{ JSON.stringify(this.props.node) }</span> */}
          </p>
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
  };
};

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(FormulaNodeComponent)
);
