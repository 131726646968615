import React, { useRef, useState, useEffect } from "react";

import { useHistory } from "react-router-dom";
import EmailEditor from "react-email-editor";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faArrowLeft,
  faCogs,
  faEdit,
  faEye,
  faEyeSlash,
  faSave,
} from "@fortawesome/free-solid-svg-icons";
import sample from "./sample.json";


import axios from "axios";

import { saveAsPng, saveAsJpeg } from "save-html-as-image";
import {
  Col,
  Container,
  InputGroup,
  Row,
  Form,
  Modal,
  Button,
  ButtonGroup,
  Tab,
  Nav,
  FormControl,
  
  OverlayTrigger, Popover,
  Card,
} from "@themesberg/react-bootstrap";


function BackButton(props) {
  const history = useHistory();

  function handleClick() {
    history.goBack()
    // history.push("/org/" + props.appid + "/templates");
  }

  return (
    <Button variant="light" size="sm" onClick={handleClick}>
      <FontAwesomeIcon icon={faArrowLeft} className="me-1" /> Go back
    </Button>
  );
}

const Example = (props) => {
  const emailEditorRef = useRef(null);
  const history = useHistory();
  
  const [showPassword, setshowPassword] = useState(false);

  const [template, setTemplate] = useState(null);

  const [showDefault, setShowDefault] = useState(false);
  const [ModaRef, setModalRef] = useState(React.createRef());
  const [templateStatus, settemplateStatus] = useState(
    "Saving template, please wait ..."
  );
  const handleClose = () => setShowDefault(false);

  
  const onShowPassword = () => setshowPassword(!showPassword);

  const sender = useFormInput("");

  const saveDesign = () => {
    emailEditorRef.current.editor.exportHtml((data) => {
      const { design, html } = data;
      console.log(data);

      let payload = {
        ...data,
        application_id: props.match.params.org_id,
        type: "Email",
        description: template.description,
        title: template.title,
        previewUrl: template.hasOwnProperty("previewUrl")
          ? template.previewUrl
          : "Newsletter",
        usage: template.hasOwnProperty("usage") ? template.usage : "Newsletter",
        mode: template.hasOwnProperty("mode") ? template.mode : "free",
        privacy: template.hasOwnProperty("privacy") ? template.privacy : "private",
        _id: props.match.params.template_id,
      };
      settemplateStatus("Saving template, please wait ...");

      setShowDefault(true);

      axios
        .post(
          "https://api.bunicom.com/save-template",
          payload
        )
        .then((response) => {
          console.log(response.data);
          // history.push("/org/" + props.match.params.org_id + "/templates")
          settemplateStatus("Template saved successfully");

          setTimeout(() => {
            setShowDefault(false);
          }, 1000);
        })
        .catch((e) => {
          setShowDefault(false);
          settemplateStatus("Failed to save template. Please try again");
        });
    });
  };

  useEffect(() => {
    // Your code here
    document.title = `Data Tables Page`;

    getTemplate();
  }, []);

  const getTemplate = (event) => {
    axios
      .get(
        "https://api.bunicom.com/get-template?_id=" +
          props.match.params.template_id
      )
      .then((response) => {
        console.log(response.data);

        setTemplate(response.data);

        let res = response.data;

        if (res.hasOwnProperty("design")) {
          emailEditorRef.current.editor.loadDesign(res["design"]);
        }
        // setTemplate(response.data);
      });
  };

  const exportSnapshot = () => {
    const node = document.getElementById("u_body");
    saveAsPng(node, { filename: "Report", printDate: true });
  };

  const onDesignLoad = (data) => {
    console.log("onDesignLoad", data);
  };

  function refreshPage() {
    window.location.reload();
    console.log("Refresh Page");
  }

  const saveUpdate = (event) => {
    let value = event.target.value;
    let name = event.target.name;
    console.log(value);
    console.log(name);

    let template_update = {
      ...template,
    };

    template_update[name] = value;
    setTemplate(template_update);
  };

  const saveUploadedFile = (file) => {
    let template_update = {
      ...template,
    };

    template_update["previewUrl"] = file;
    setTemplate(template_update);
  };
  const onLoad = () => {
    if (emailEditorRef.current) {
      emailEditorRef.current.editor.addEventListener(
        "onDesignLoad",
        onDesignLoad
      );
      emailEditorRef.current.editor.loadDesign(sample);
    } else {
      refreshPage();
    }
  };

  return (
    <>
      <Modal
        as={Modal.Dialog}
        centered
        show={showDefault}
        ref={ModaRef}
        onHide={handleClose}
      >
        <Modal.Header>
          <Modal.Title className="h6">{templateStatus}</Modal.Title>
          <Button variant="close" aria-label="Close" onClick={handleClose} />
        </Modal.Header>
      </Modal>

      <div className="container-fluid mt--6">
        {/* <Row className="justify-content-between align-items-center">
      <BackButton appid={props.match.params.org_id} />
               </Row> */}
        <div className="row justify-content-center">
          <div className="col-lg-12 card-wrapper">
            <Row className="justify-content-between align-items-center">
              <Col xs={12} md={12} lg={12} xl={12}>
                <BackButton appid={props.match.params.org_id} />

                <ButtonGroup className=" float-end">
                  <Button variant="info" size="md" onClick={saveDesign}>
                    <FontAwesomeIcon icon={faSave} className="me-2" />
                    Save Template
                  </Button>
                </ButtonGroup>
              </Col>
            </Row>
            <div className="card">
              <Tab.Container defaultActiveKey="dashboard">
                <Row>
                  <Col lg={12}>
                    <Nav
                      // fill
                      className="p-4"
                      variant="pills"
                    >
                      <Nav.Item>
                        <Nav.Link eventKey="dashboard">
                          <FontAwesomeIcon icon={faEdit} className="me-2" />{" "}
                          Template Editor
                        </Nav.Link>
                      </Nav.Item>
                      <Nav.Item>
                        <Nav.Link eventKey="online" className="mb-sm-3 mb-md-0">
                          <FontAwesomeIcon icon={faCogs} className="me-2" />{" "}
                          Additional Configurations
                        </Nav.Link>
                      </Nav.Item>
                    </Nav>
                    <Tab.Content>
                      <Tab.Pane eventKey="dashboard">
                        <div className="card-body p-0">
                          <div className="row align-items-center">
                            <Col lg={12}>
                              <React.StrictMode>
                                <EmailEditor
                                  ref={emailEditorRef}
                                  appearance={{ theme: "dark" }}
                                  style={{ height: "80vh" }}
                                  onLoad={onLoad}
                                />
                              </React.StrictMode>
                            </Col>
                          </div>
                        </div>
                      </Tab.Pane>
                      <Tab.Pane eventKey="online">
                        <div className="card-body">
                          <div className="container-fluid mt--6">
                            <div className="row justify-content-center">
                              {/* <Col md={4} className="card-wrapper p-2">
                                <Card>
                                  <Card.Img
                                    variant="top"
                                    src={
                                      template
                                        ? template.hasOwnProperty("previewUrl")
                                          ? template.previewUrl
                                          : DefaultImg
                                        : DefaultImg
                                    }
                                  />
                                </Card>
                              </Col>
                              <Col md={4} className="card-wrapper">
                                <FileUploadWidget
                                  saveUploadedFile={saveUploadedFile}
                                  previewUrl={
                                    template
                                      ? template.hasOwnProperty("previewUrl")
                                        ? template.previewUrl
                                        : null
                                      : null
                                  }
                                />
                              </Col> */}
                              <div className="col-lg-12 card-wrapper">
                                {template ? (
                                  <Card className="pb-0">
                                    {/* <div id="screenshottemplate">
                                     <ExampleContainer  content={template.html} />
                                      
                                    </div> */}
                                    <div className="card-body pb-0 background-grey">
                                      <Row className="pt-2 align-items-center">
                                        <Col md={6}>
                                          <Form.Group
                                            id="title"
                                            className="mb-4"
                                          >
                                            <Form.Label>TITLE</Form.Label>
                                            <InputGroup>
                                              <Form.Control
                                                name="title"
                                                type="text"
                                                value={
                                                  template.hasOwnProperty(
                                                    "title"
                                                  )
                                                    ? template.title
                                                    : "Untitled"
                                                }
                                                onChange={saveUpdate}
                                              />
                                            </InputGroup>
                                          </Form.Group>
                                        </Col>
                                        <Col md={6}>
                                          <Form.Group
                                            id="desc"
                                            className="mb-4"
                                          >
                                            <Form.Label>DESCRIPTION</Form.Label>
                                            <InputGroup>
                                              <Form.Control
                                                autoFocus
                                                name="description"
                                                type="text"
                                                value={
                                                  template.hasOwnProperty(
                                                    "description"
                                                  )
                                                    ? template.description
                                                    : ""
                                                }
                                                required
                                                onChange={saveUpdate}
                                              />
                                            </InputGroup>
                                          </Form.Group>
                                        </Col>

                                        <Col md={4}>
                                          <Form.Group
                                            id="usage"
                                            className="mb-4"
                                          >
                                            <Form.Label>USAGE</Form.Label>
                                            <InputGroup>
                                              <Form.Control
                                                as="select"
                                                name="usage"
                                                value={
                                                  template.hasOwnProperty(
                                                    "usage"
                                                  )
                                                    ? template.usage
                                                    : "Newsletter"
                                                }
                                                onChange={saveUpdate}
                                              >
                                                <option value="Activation">
                                                  Activation
                                                </option>
                                                <option value="User-acquisition">
                                                  User Acquisition
                                                </option>
                                                <option value="Ecommerce">
                                                  E-commerce
                                                </option>
                                                <option value="Newsletter">
                                                  Newsletter
                                                </option>
                                                <option value="Notification">
                                                  Notication
                                                </option>
                                              </Form.Control>
                                            </InputGroup>
                                          </Form.Group>
                                        </Col>
                                        <Col md={4}>
                                          <Form.Group
                                            id="mode"
                                            className="mb-4"
                                          >
                                            <Form.Label>MODE</Form.Label>
                                            <InputGroup>
                                              <Form.Control
                                                as="select"
                                                name="mode"
                                                onChange={saveUpdate}
                                                value={
                                                  template.hasOwnProperty(
                                                    "mode"
                                                  )
                                                    ? template.mode
                                                    : "free"
                                                }
                                              >
                                                <option value="free">
                                                  FREE
                                                </option>
                                                <option value="premium">
                                                  PREMIUM
                                                </option>
                                              </Form.Control>
                                            </InputGroup>
                                          </Form.Group>
                                        </Col>
                                        <Col md={4}>
                                          <Form.Group
                                            id="privacy"
                                            className="mb-4"
                                          >
                                            <Form.Label>PRIVACY</Form.Label>
                                            <InputGroup>
                                              <Form.Control
                                                as="select"
                                                name="privacy"
                                                onChange={saveUpdate}
                                                value={
                                                  template.hasOwnProperty(
                                                    "privacy"
                                                  )
                                                    ? template.privacy
                                                    : "free"
                                                }
                                              >
                                                <option value="public">
                                                  PUBLIC
                                                </option>
                                                <option value="private">
                                                  PRIVATE
                                                </option>
                                              </Form.Control>
                                            </InputGroup>
                                          </Form.Group>
                                        </Col>
                                      
                                        <Col md={4}>
                                        <Form.Group id="password" className="mb-4">
                                        <Form.Label>Template ID (For API Requests)</Form.Label>
                    <InputGroup>
                      <FormControl
                        type={showPassword ? "text" : "password"}
                        value={props.match.params.template_id}
                        disabled
                      />
                      <Button
                        variant="light"
                        size="md"
                        onClick={onShowPassword}
                      >
                        {showPassword ? (
                          <FontAwesomeIcon icon={faEyeSlash} className="me-1" />
                        ) : (
                          <FontAwesomeIcon icon={faEye} className="me-1" />
                        )}
                      </Button>
                      <OverlayTrigger
                        trigger="click"
                        overlay={
                          <Popover>
                            <Popover.Content>Template <i class="fa fa-id-card-o" aria-hidden="true"></i> Key Copied!</Popover.Content>
                          </Popover>
                        }
                      >
                        <Button
                          variant="secondary"
                          size="md"
                          onClick={() => {
                            navigator.clipboard.writeText(props.match.params.template_id);
                          }}
                        >
                          Copy
                        </Button>
                      </OverlayTrigger>
                    </InputGroup>
                  </Form.Group>
                                        </Col>
                                     
                                      </Row>
                                    </div>
                                  </Card>
                                ) : (
                                  ""
                                )}
                              </div>
                            </div>
                          </div>
                        </div>
                      </Tab.Pane>
                    </Tab.Content>
                  </Col>
                </Row>
              </Tab.Container>
            </div>
            {/* <div className="d-block mb-4 mb-md-0">
              <Alert variant="success" show={true}>
                <div className="d-flex justify-content-between">
                  <div>
                    <FontAwesomeIcon icon={faDatabase} className="me-1" />
                    <strong>Message Logs!</strong> All messages sent
                  </div>
                </div>
              </Alert>
            </div> */}
            <div className="table-settings mb-4"></div>
          </div>
        </div>
      </div>
    </>
  );
};

const useFormInput = (initialValue) => {
  const [value, setValue] = useState(initialValue);

  const handleChange = (e) => {
    setValue(e.target.value);
  };

  function handleReset() {
    setValue("");
  }
  return {
    value,
    onChange: handleChange,
    onReset: handleReset,
  };
};

export default Example;
