import * as React from "react";
import * as SRD from "storm-react-diagrams";
import { EmailNodeModel } from "./EmailNodeModel";
import { EmailNodeWidget } from "./EmailNodeWidget";


export class EmailNodeFactory extends SRD.AbstractNodeFactory {
    constructor() {
        super("Email");
    }

    generateReactWidget(diagramEngine, node) {
        return <EmailNodeWidget node={node} />;
    }

    getNewInstance() {
        return new EmailNodeModel();
    }
}


