import React, { Component } from "react";
import ReactDOM from "react-dom";
import jexcel from "jexcel";
import Axios from "axios";

import { connect } from "react-redux";
import { withRouter } from "react-router-dom";

import "./styles.css";
import "../../../../node_modules/jexcel/dist/jexcel.css";

import { Toolkit } from "storm-react-diagrams";

import * as actionTypes from "../../../store/actions";

class UnionTable extends React.Component {
  constructor(props) {
    super(props);

    let that = this;

    this.state = {
      entity: null,
      fields_data: [],
      dtypes: null
    };

    this.options = {
      data: [[""]],
      columns: [
        { type: "checkbox", title: "Active", width: 50 },
        { type: "text", title: "Column name", width: 180 },
      ],
      ondeleterow: function (instance) {
        that.saveDtypesNode();
      },
      onchange: function (instance, cell, x, y, value) {
        console.log("Changed");
        that.saveDtypesNode();
      },
      columnDrag: true,
      allowManualInsertRow: false,
      allowManualInsertColumn: false,
      allowInsertRow: false,
      allowInsertColumn: false,
      allowDeleteRow: false,
      allowDeleteColumn: false,
      allowRenameColumn: false,
      allowComments: false,
      allowExport: false,
      oninsertrow: this.insertedRow,
    };
    
  }

  componentDidMount = function () {
    this.el = jexcel(ReactDOM.findDOMNode(this).children[0], this.options);
    this.saveDtypes();
  };

  componentDidUpdate(prevProps, prevState) {
    this.saveDtypes();

  }


  saveDtypesNode = function () {
    let selectNode = this.props.dtypes_;
    let model = this.props.model;
    let currNode = model.getNode(selectNode.id);
    if (selectNode.properties.hasOwnProperty("columns")) {
      if (selectNode.properties.columns.hasOwnProperty("columns")) {
        console.log(this.el.getData());
        let records = this.el.getData();
        let headers = ["column_name"];
        let arr_objs_records = [];
        let selected_columns = [];

        records.forEach((record) => {
          let obj_rec = {};
          let count = 1;
          if (record[0]) {
            selected_columns.push(record[1]);
          }
          headers.forEach((item) => {
            obj_rec[item] = record[count];
            count++;
          });
          // if (obj_rec["var_name"] !== "") {
          arr_objs_records.push(obj_rec);
          // }
        });

        // console.log(arr_objs_records);

        currNode.properties.columns.columns = arr_objs_records;

        console.log(currNode.properties.columns.columns);

        if (currNode.properties.hasOwnProperty("selected_columns")) {
          currNode.properties.selected_columns = selected_columns;
        }
      }
    }

    // currNode.properties.metaData
  };
  saveDtypes = function () {
    let selectNode = this.props.dtypes_;

    let model = this.props.model;
    let currNode = model.getNode(selectNode.id);
    console.log("Lets see if we have stuff");
    console.log(currNode);

    if (currNode) {
      if (currNode.properties.hasOwnProperty("columns")) {
        if (currNode.properties.columns.hasOwnProperty("columns")) {
          let columns = selectNode.properties.columns.columns;
          let dataArr = [];
          columns.forEach((column) => {
            let active = true;
            if (currNode.properties.hasOwnProperty("selected_columns")) {
              if (
                !currNode.properties.selected_columns.includes(
                  column.column_name
                )
              ) {
                active = false;
              }
            }

            dataArr.push([active, column.column_name]);
          });

          this.el.setData(dataArr);
        }
        console.log();

        console.log(currNode.properties);
      }
    }

    // let records = this.el.getData();
    // let headers = ['var_name','value','type']
    // let arr_objs_records = [];

    // console.log("==============RECORDS==============");
    // console.log(headers);

    // records.forEach((record) => {
    //   let obj_rec = {};
    //   let count = 0;
    //   headers.forEach((item) => {
    //     obj_rec[item] = record[count];
    //     count++;
    //   });
    //   if (obj_rec["var_name"] !== "") {
    //     arr_objs_records.push(obj_rec);
    //   }

    // });

    // console.log(arr_objs_records);

    // this.props.onSaveDtypes(arr_objs_records)
  };

  insertedRow = function (instance) {
    console.log(instance);
  };

  render() {
    return (
      <div>
        <div></div>

        <div>
        </div>

        <br />
        <br />
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    
  };
};

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(UnionTable)
);
