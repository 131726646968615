
import React from 'react';
import { Image } from '@themesberg/react-bootstrap';

// import ReactLogo from "../assets/img/technologies/react-logo-transparent.svg";

import Lottie from "lottie-react-web";

import animation from "./himenu/pages/Campaigns/lottie.json"

export default (props) => {

  const { show } = props;

  return (
    <div className={`preloader bg-soft flex-column justify-content-center align-items-center ${show ? "" : "show"}`}>
      <Lottie
                                    style={{width: "150px"}}
                                      options={{
                                        animationData: animation
                                      }}
                                    />
    </div>
  );
};
