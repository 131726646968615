import React, { useState, useEffect } from "react";
import { useHistory } from "react-router-dom";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

import { connect } from "react-redux";

import { compose } from "recompose";

import { withFirebase } from "../../../Firebase";
import shortid from "shortid";
import {
  faBullhorn,
  faCheck,
  faCog,
  faEdit,
  faHome,
  faSearch,
} from "@fortawesome/free-solid-svg-icons";

import {
  Container,
  Col,
  Row,
  Button,
  Dropdown,
  ButtonGroup,
  Table,
  Card,
  Modal,
  Form,
  Alert,
  InputGroup,
} from "@themesberg/react-bootstrap";

import CampaignsTable from "./CampaignsTable";

import Axios from "axios";

import "./campaign.css";

shortid.characters(
  "0123456789abcdefghijklmnopqrstuvwxyzABCDEFGHIJKLMNOPQRSTUVWXYZ$@"
);

function CampaignsPage(props) {
  let history = useHistory();

  const { setCampaigns, organisation_campaigns, loading, onRequestWorkflow } =
    props;

  const [showDefault, setShowDefault] = useState(false);
  const [ModaRef, setModalRef] = useState(React.createRef());
  const handleClose = () => setShowDefault(false);
  const name = useFormInput("");
  const description = useFormInput("");
  const status = useFormInput("active");

  let org_id = props.match.params.org_id;

  const onSubmit = (event) => {
    event.preventDefault();
    CreateNewCampaign();
  };

  useEffect(() => {
    document.title = `My Campaigns - Bunicom Studio`;

    if (org_id) {
      const unsubscribe = props.firebase.db
        .collection("organisation_campaigns")

        .where("orgId", "==", org_id)
        // .orderBy("orgId")
        .onSnapshot((snapshot) => {
          if (snapshot.size) {
            console.log("we have something");
            // we have something
            let myDataArray = [];
            snapshot.forEach((doc) => {
              myDataArray.push({
                ...doc.data(),
                campaign_id: doc.id,
              });
              // Axios.get(
              //   `https://api.bunicom.com///get-workflow?_id=${
              //     doc.data().workflowId
              //   }`
              // ).then((response) => {
              //   console.log(response.data);
              //   let workflow = response.data;

              //   if (workflow.hasOwnProperty("operations")) {
              //     operations = workflow.operations;
              //   }

              // });
            });

            setCampaigns(myDataArray);

            // setLoading(false);
          } else {
            // it's empty
            setCampaigns([]);
            console.log("Still Empty");
            // setLoading(false);
          }
        });
      return () => {
        // setLoading(true)
        unsubscribe();
      };
    }
  }, [props.firebase, org_id]);

  const goToCampaign = (row) => {
    history.push(
      "/campaigns/" + props.match.params.org_id + "/" + row.campaign_id
    );
  };

  const CreateNewCampaign = () => {
    let dateobj = new Date();

    let description_v = description.value;
    let name_v = name.value;
    const workflow_id = shortid.generate();
    let campaign_data = {
      description: description_v,
      name: name_v,
      status: "active",
      workflowId: workflow_id,
      orgId: props.match.params.org_id,
      operations: 0,
      cost: 0,
      lastRun: "Today",
      nextRun: "Tomorrow",
      dateCreated: dateobj.toISOString(),
    };

    props.firebase.db
      .collection("organisation_campaigns")
      .add(campaign_data)
      .then((docRef) => {
        console.log("Document written with ID: ", docRef.id);
        setShowDefault(false);

        let workflow = {
          _id: workflow_id,
          name: campaign_data.name,
          description: campaign_data.description,
          created_at: dateobj.toISOString(),
          modified_at: dateobj.toISOString(),
          application_id: campaign_data.orgId,
          collection_id: docRef.id,
          collection_name: "Campaign",
        };

        onRequestWorkflow(workflow);
      })
      .catch((error) => {
        console.error("Error writing document: ", error);
      });
  };

  const CardComponent = (props) => {
    const { data, org_id } = props;

    return (
      <div>
        <div className="container">
          {data
            .sort(function (a, b) {
              return new Date(b["dateCreated"]) - new Date(a["dateCreated"]);
            })
            .map((item, key) => (
              <div className="" key={key}>
                <div className="d-style btn btn-brc-tp border-2 bgc-white btn-outline-secondary btn-h-outline-blue btn-a-outline-blue w-100 my-2 py-3 shadow-sm">
                  {/* Basic Plan */}
                  <div className="row align-items-center">
                    <div className="col-12 col-md-8">
                      <h4 className="pt-3 text-170 text-600 text-primary-d1 letter-spacing">
                        {item.name}
                      </h4>
                      <div className="text-secondary-d1 text-120">
                        <span className="ml-n15 align-text-bottom"></span>
                        <span className="text-180">{item.operations}</span>{" "}
                        operations
                      </div>
                    </div>

                    <div className="col-12 col-md-4 text-center">
                      <a
                        onClick={() => goToCampaign(item)}
                        className="f-n-hover btn btn-secondary btn-raised px-4 py-25 mt-2 w-75 text-600"
                      >
                        <FontAwesomeIcon icon={faEdit} className="me-2" /> Edit
                      </a>
                    </div>
                  </div>
                </div>
              </div>
            ))}
        </div>
      </div>
    );
  };

  return (
    <>
      <Modal
        as={Modal.Dialog}
        centered
        show={showDefault}
        ref={ModaRef}
        onHide={handleClose}
      >
        <Modal.Header>
          <Modal.Title className="h6">Start new campaign</Modal.Title>
          <Button variant="close" aria-label="Close" onClick={handleClose} />
        </Modal.Header>
        <Modal.Body>
          <form className="mt-1" onSubmit={onSubmit}>
            <Form.Group id="email" className="mb-4">
              <Form.Label>Let's start with the campaign name</Form.Label>
              <InputGroup>
                {/* <InputGroup.Text>
                          <FontAwesomeIcon icon={faEnvelope} />
                        </InputGroup.Text> */}
                <Form.Control
                  autoFocus
                  name="name"
                  {...name}
                  type="text"
                  placeholder="Awesome Campaign"
                  required
                />
              </InputGroup>
            </Form.Group>
            <Form.Group id="email" className="mb-4">
              <Form.Label>Describe your campaign</Form.Label>
              <InputGroup>
                {/* <InputGroup.Text>
                          <FontAwesomeIcon icon={faEnvelope} />
                        </InputGroup.Text> */}
                <Form.Control
                  name="name"
                  {...description}
                  type="text"
                  placeholder="Enter description here ... "
                />
              </InputGroup>
            </Form.Group>

            <div className="d-flex justify-content-between flex-wrap flex-md-nowrap align-items-center py-4">
              <Button variant="danger" onClick={handleClose}>
                Close
              </Button>
              <Button variant="info" className="ms-auto" type="submit">
                Lets do this
              </Button>
            </div>
          </form>
        </Modal.Body>
      </Modal>
      <div className="container-fluid mt--6">
        <div className="row justify-content-center">
          <div className="col-lg-10 card-wrapper ct-example">
            <div className="d-block mb-4 mb-md-0">
              <h4>My Automated Campaigns</h4>
              <Alert variant="secondary" show={true}>
                <div className="d-flex justify-content-between">
                  <div>
                    <FontAwesomeIcon icon={faBullhorn} className="me-1" />
                    <strong>Get started with Campaigns!</strong> Campaigns allow
                    you to send SMS, email, notications or any message via
                    service provider addons.<br></br>
                    You can add logic and customize each message or use our
                    premade templates.
                  </div>
                </div>
              </Alert>
            </div>
            <div className="card table-wrapper table-responsive shadow-sm">
              <div className="card-header">
                <div className="row align-items-center">
                  <div className="col">
                    <h5 className="h5 mb-0">All active campaigns</h5>
                  </div>
                  <div className="col">
                    <div className="justify-content-end">
                      <Button
                        variant="secondary"
                        className="m-1 float-end"
                        onClick={() => setShowDefault(true)}
                      >
                        <FontAwesomeIcon icon={faBullhorn} className="me-2" />{" "}
                        Create new Campaign
                      </Button>
                    </div>
                  </div>
                </div>
              </div>
              <div className="card-body">
                {/* <CampaignsTable
                  data={organisation_campaigns}
                  org_id={props.match.params.org_id}
                  firebase={props.firebase}
                /> */}

                {/* {JSON.stringify(organisation_campaigns)} */}

                <CardComponent
                  org_id={props.match.params.org_id}
                  data={organisation_campaigns}
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

const useFormInput = (initialValue) => {
  const [value, setValue] = useState(initialValue);

  const handleChange = (e) => {
    setValue(e.target.value);
  };

  function handleReset() {
    setValue("");
  }
  return {
    value,
    onChange: handleChange,
    onReset: handleReset,
  };
};

const mapStateToProps = (state) => ({
  organisation_campaigns: state.organisation_campaigns,
  loading: state.loading,
});

const mapDispatchToProps = (dispatch) => {
  return {
    setCampaigns: (campaigns) =>
      dispatch({ type: "SAVE_CAMPAIGNS", campaigns: campaigns }),
    onRequestWorkflow: (wflw) =>
      dispatch({ type: "NEW_WORKFLOW_REQUEST", workflow: wflw }),
  };
};

// export default connect(mapStateToProps, mapDispatchToProps)(withFirebase(HotelsPage));
export default compose(
  connect(mapStateToProps, mapDispatchToProps),
  // withStateHandlers,
  // withHandler,
  withFirebase
)(CampaignsPage);
