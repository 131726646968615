import React, { Component } from "react";
import ReactDOM from "react-dom";
import jexcel from "jexcel";
import Axios from "axios";

import { connect } from "react-redux";
import { withRouter } from "react-router-dom";

import "./styles.css";
import "../../../node_modules/jexcel/dist/jexcel.css";

import { Toolkit } from "storm-react-diagrams";

import * as actionTypes from "./../../store/actions";

class DisplayResultsTable extends React.Component {
  constructor(props) {
    super(props);

    let that = this;


    this.state = {
      entity: null,
      fields_data: [],
    };

    this.options = {
      data: [['']],
      tableWidth: "820px",
      search: true,
      pagination: 10,
      tableOverflow: true,
      paginationOptions: [10, 25, 50],
      columns: [
      ],
      updateTable: function (instance, cell, col, row, val, label, cellName) {
        // if (col == 5 && !val) {
        //   instance.jexcel.setValue("F" + (row + 1), "=RATING(E" + (row + 1));
        // }
      },

      onchange: function (instance, cell, x, y, value) {
        console.log("The table has changed");
      },
      columnDrag: false,
      selectionCopy: false,
      allowManualInsertRow: false,
      allowManualInsertColumn: false,
      allowInsertColumn: false,
      allowDeleteColumn: false,
      allowRenameColumn: false,
      allowComments: false,
      allowExport: true,

    };
  }


  componentDidMount = function () {
    // this.el = jexcel(ReactDOM.findDOMNode(this).children[0], this.options);
   
    // console.log(this.props.rows);

    this.saveDtypes()

    // let fields_arr = resp.data.map((foo) => {

    //     return Object.values(foo)
    //   })
  };

 

  saveDtypes = function () {
    console.log("===============RESULTS DATA===============");
    console.log(this.props.columns);
    console.log(this.props.rows);
    var width = 820

    var element = document.getElementById('preview-datatable');
    var positionInfo = element.getBoundingClientRect();
    var height = positionInfo.height;
    width = positionInfo.width;
    document.getElementById("display-results-table").innerHTML = "";
    this.options.columns = [];  
    // this.el.setData([[]]);


    if (this.props.columns.length > 0) {
      let cols = [];

      this.props.columns.forEach((col) => {
        cols.push({ type: "text", title: col, width: 180, readOnly: true });
      });
      this.options.columns = cols;
      let final_w = (width - 20)
      this.options.tableWidth = final_w+"px"

      // this.el.se
      // this.el.setData(0, { type: 'dropdown', source: source_cols,title:'Select Column', width:100 },)

      this.el = jexcel(ReactDOM.findDOMNode(this).children[0], this.options);
      this.el.setData(this.props.rows);
    }
  };

  componentDidUpdate(prevProps, prevState) {
    if (prevProps.columns.length !== this.props.columns.length || prevProps.rows !== this.props.rows ) {
      this.saveDtypes()

    }
  
  }


  render() {
    return (
      <div>
        <div id="display-results-table"></div>

        <br />
        <br />
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    
  };
};

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(DisplayResultsTable)
);
