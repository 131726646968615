import React, { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
 
  faLaptopCode,
  faPalette,
  faBullhorn,
  faTrash,
  faSms,
  faEnvelope,
  
} from "@fortawesome/free-solid-svg-icons";
import { faUser } from "@fortawesome/free-regular-svg-icons";
import {
  Col,
  Row,
  Button,
  ListGroup,
  Card,
  Nav,
  Tab,
} from "@themesberg/react-bootstrap";
import moment from "moment";

import TopupTable from "./pages/Topup/TopupTable";

import BarChart from "./pages/Charts/BarChart";
import LineChart from "./pages/Charts/Chart";
// import SenderBar from "./pages/Charts/SenderBar";
// import SenderChart from "./pages/Charts/SenderChart";

import { connect } from "react-redux";
import { compose } from "recompose";
import { withFirebase } from "../Firebase";

import { AuthUserContext } from "../Session";

import "./menu.css";

function MainDashboard(props) {
  const {
    setCampaigns,
    organisation_campaigns,
    message_logs,
    getMessageLogs,
    setOrders,
    organisation_payments,
  } = props;

  let org_id = props.match.params.org_id;

  const [smsCount, setSMSCount] = useState(0);
  const [smsCost, setSMSCost] = useState(0);
  const [emailCount, setEmailCount] = useState(0);
  const [operations, setOperations] = useState(0);
  const [smsCostList, setsmsCostList] = useState([
    0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0,
  ]);
  const [smsCostAmount, setsmsCostAmount] = useState([
    0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0,
  ]);

  const [EmailCostList, setEmailCostList] = useState([
    0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0,
  ]);
  const [EmailCostAmount, setEmailCostAmount] = useState([
    0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0,
  ]);
  useEffect(() => {
    document.title = `My Lab - Bunicom Studio`;

    if (org_id) {
      const unsubscribe = props.firebase.db
        .collection("organisation_campaigns")
        .where("orgId", "==", org_id)
        .onSnapshot((snapshot) => {
          if (snapshot.size) {
            console.log("we have something");
            // we have something
            let myDataArray = [];

            snapshot.forEach(
              (doc) => myDataArray.push({ ...doc.data(), campaign_id: doc.id })

              // c_ops = parseFloat(doc.data().operations)

              // setOperations(operations + c_ops)
            );
            console.log(myDataArray);
            var c_ops = 0;

            myDataArray.forEach((campaign) => {
              c_ops = c_ops + parseFloat(campaign.operations);
            });

            setOperations(operations + c_ops);

            setCampaigns(myDataArray);

            // setLoading(false);
          } else {
            // it's empty
            setCampaigns([]);
            console.log("Still Empty");
            // setLoading(false);
          }
        });

      const unsubscribeorders = props.firebase.db
        .collection("organisation_payments")
        .where("orgId", "==", org_id)
        .onSnapshot((snapshot) => {
          if (snapshot.size) {
            console.log("we have something");
            // we have something
            let myDataArray = [];
            snapshot.forEach((doc) => {
              myDataArray.push({
                ...doc.data(),
                campaign_id: doc.id,
              });
            });

            setOrders(myDataArray);

            // setLoading(false);
          } else {
            // it's empty
            setOrders([]);
            // setLoading(false);
          }
        });

      getMessageLogs(org_id);

      return () => {
        // setLoading(true)
        unsubscribe();
        unsubscribeorders();
        getMessageLogs(null);
      };
    }
  }, [props.firebase, org_id]);

  useEffect(() => {
    if (message_logs.length > 0) {
      let dateObj = message_logs[0]["datetime"];
      let dateMomentObj = moment(dateObj, "YYYY-MM-DD");
      let myDate = dateMomentObj.format("DD-MM-YYYY");
      let month = dateMomentObj.format("MMM");
      let year = dateMomentObj.format("YYYY");

      console.log(month);
      console.log(year);
      console.log(myDate);

      let smsDates = message_logs
        .filter((log) => {
          return log.type == "SMS";
        })
        .map((log) => {
          let dateMomentObj = moment(log["datetime"], "YYYY-MM-DD");
          let month = dateMomentObj.format("MMM");
          let year = dateMomentObj.format("YYYY");

          let smsCostAmt = log.hasOwnProperty("Message_Credits")
            ? log["Message_Credits"] * 0.8
            : 0;

          return {
            month: month,
            year: year,
            credits: smsCostAmt,
          };
        });

      let emailDates = message_logs
        .filter((log) => {
          return log.type == "Email";
        })
        .map((log) => {
          let dateMomentObj = moment(log["datetime"], "YYYY-MM-DD");
          let month = dateMomentObj.format("MMM");
          let year = dateMomentObj.format("YYYY");

          let emailCostAmount1 = log.hasOwnProperty("Message_Credits")
            ? log["Message_Credits"] * 0.8
            : 0;

          return {
            month: month,
            year: year,
            credits: emailCostAmount1,
          };
        });

      let months = [
        "Jan",
        "Feb",
        "Mar",
        "Apr",
        "May",
        "Jun",
        "Jul",
        "Aug",
        "Sep",
        "Oct",
        "Nov",
        "Dev",
      ];
      let acc = [];
      let accCost = [];

      let accEmail = [];
      let accCostEmail = [];

      months.forEach((month) => {
        let cnt = smsDates.filter((log) => {
          return log.month == month;
        }).length;
        let cntCost = smsDates
          .filter((log) => {
            return log.month == month;
          })
          .reduce(function (acc, val) {
            return acc + val["credits"];
          }, 0);

        accCost.push(cntCost.toFixed(1));
        acc.push(cnt);

        let cntE = emailDates.filter((log) => {
          return log.month == month;
        }).length;
        let cntCostE = emailDates
          .filter((log) => {
            return log.month == month;
          })
          .reduce(function (acc, val) {
            return acc + val["credits"];
          }, 0);

        accCostEmail.push(cntCostE.toFixed(1));
        accEmail.push(cntE);
      });

      setsmsCostList(acc);
      setsmsCostAmount(accCost);

      setEmailCostList(accEmail);
      setEmailCostAmount(accCostEmail);

      let smsCount = message_logs.filter((log) => {
        return log.type == "SMS";
      });

      let smsCost = message_logs.filter((log) => {
        return log.hasOwnProperty("Message_Credits");
      });

      let cost = smsCost
        .map((credits) => credits["Message_Credits"])
        .reduce(function (acc, val) {
          return acc + val;
        }, 0);

      // console.log("***********This is the reducer*************");
      // console.log(smsCost);
      // console.log(numberWithCommas(cost));
      setSMSCost(cost * 0.8);
      let emailCount = message_logs.filter((log) => {
        return log.type == "Email";
      });

      setSMSCount(smsCount.length);
      setEmailCount(emailCount.length);
    }
  }, [message_logs]);

  let history = useHistory();

  const numberWithCommas = (x) => {
    return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
  };

  const ViewButton = () => {
    function handleClick() {
      history.push("/campaigns/123");
    }
    return (
      <Button variant="success" className="m-1" onClick={handleClick}>
        <FontAwesomeIcon icon={faBullhorn} className="me-2" /> Open Campaign
        Editor
      </Button>
    );
  };

  const CardComponent = (props) => {
    const { sms, email, operations } = props;
    const ops_perc = operations / 10000;
    return (
      <div className="col-md-12 ">
        <div className="row ">
          <div className="col-xl-3 col-lg-6">
            <div className="card l-bg-cherry">
              <div className="card-statistic-3 p-4">
                <div className="card-icon card-icon-large">
                  <i className="fas fa-sms" />
                </div>
                <div className="mb-4">
                  <h5 className="card-title mb-0 text-white">SMS Sent</h5>
                </div>
                <div className="row align-items-center mb-2 d-flex">
                  <div className="col-12">
                    <h2 className="d-flex align-items-center mb-0">{sms}</h2>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="col-xl-3 col-lg-6">
            <div className="card l-bg-blue-dark">
              <div className="card-statistic-3 p-4">
                <div className="card-icon card-icon-large">
                  <i className="fas fa-envelope-open" />
                </div>
                <div className="mb-4">
                  <h5 className="card-title mb-0">Emails Sent</h5>
                </div>
                <div className="row align-items-center mb-2 d-flex">
                  <div className="col-12">
                    <h2 className="d-flex align-items-center mb-0">{email}</h2>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="col-xl-3 col-lg-6">
            <div className="card l-bg-orange-dark">
              <div className="card-statistic-3 p-4">
                <div className="card-icon card-icon-large">
                  <i className="fas fa-dollar-sign" />
                </div>
                <div className="mb-4">
                  <h5 className="card-title mb-0">TTL Amount Spent</h5>
                </div>
                <div className="row align-items-center mb-2 d-flex">
                  <div className="col-12">
                    <h2 className="d-flex align-items-center mb-0">
                      SH {numberWithCommas(smsCost.toFixed(2))}
                    </h2>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div className="col-xl-3 col-lg-6">
            <div className="card l-bg-green-dark">
              <div className="card-statistic-3 p-4">
                <div className="card-icon card-icon-large">
                  <i className="fas fa-robot" />
                </div>
                <div className="mb-4">
                  <h5 className="card-title mb-0">Operations</h5>
                </div>
                <div className="row align-items-center mb-2 d-flex">
                  <div className="col-8">
                    <h2 className="d-flex align-items-center mb-0">
                      {operations}/<small>10,000</small>
                    </h2>
                  </div>
                  <div className="col-4 text-right">
                    <span>{ops_perc.toFixed(2)}%</span>
                  </div>
                </div>
                {/* <div
                  className="progress mt-1 "
                  data-height={8}
                  style={{ height: "8px" }}
                >
                  <ProgressBar
                    size="xl"
                    variant="secondary"
                    now={operations}
                    min={0}
                    max={10000}
                  />
                </div> */}
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  };

  return (
    <>
      <div className="container-fluid mt--6">
        <div className="row justify-content-center">
          <div className="col-lg-10 card-wrapper ct-example">
            {/* <div className="d-block mb-4 mb-md-0">
              <h4>My Lab</h4>
              <Alert variant="secondary" show={true}>
                <div className="d-flex justify-content-between">
                  <div>
                    <FontAwesomeIcon icon={faBullhorn} className="me-1" />
                    <strong>Get started with Campaigns!</strong> Campaigns allow
                    you to send SMS, email, notications or any message via
                    service provider addons.<br></br>
                    You can add logic and customize each message or use our
                    premade templates.
                  </div>
                </div>
              </Alert>
            </div> */}
            <div className="card">
              {/* {JSON.stringify(smsCount)}
            {JSON.stringify(emailCount)} */}
              <Tab.Container defaultActiveKey="dashboard">
                <Row>
                  <Col lg={12}>
                    <Nav
                      fill
                      className="flex-column flex-sm-row p-4"
                      variant="pills"
                    >
                      <Nav.Item>
                        <Nav.Link eventKey="dashboard">
                          <FontAwesomeIcon icon={faPalette} className="me-2" />{" "}
                          Dashboard
                        </Nav.Link>
                      </Nav.Item>
                      <Nav.Item>
                        <Nav.Link eventKey="users" className="mb-sm-3 mb-md-0">
                          <FontAwesomeIcon
                            icon={faLaptopCode}
                            className="me-2"
                          />{" "}
                          Users
                        </Nav.Link>
                      </Nav.Item>
                      <Nav.Item>
                        <Nav.Link eventKey="subscriptions">
                          <FontAwesomeIcon icon={faUser} className="me-2" />{" "}
                          Subscriptions
                        </Nav.Link>
                      </Nav.Item>
                      <Nav.Item>
                        <Nav.Link eventKey="topups">
                          <FontAwesomeIcon icon={faUser} className="me-2" />{" "}
                          Top-ups
                        </Nav.Link>
                      </Nav.Item>
                    </Nav>
                    <Tab.Content>
                      <Tab.Pane eventKey="dashboard">
                        <div className="card-body pt-0  ">
                          <Row className={`align-items-center mb-2`}>
                          
                            <Col lg={12} md={12} xs={12} sm={12}>
                              <CardComponent
                                email={emailCount}
                                sms={smsCount}
                                operations={operations}
                              />
                            </Col>
{/* 
                            <Col
                              lg={12}
                              md={12}
                              xs={12}
                              sm={12}
                              className="mt-4 align-items-center"
                            >
                              <Button
                                variant="secondary"
                                className="mb-1 mr2"
                                size="lg"
                                onClick={() =>
                                  history.push(
                                    "/org/" + props.match.params.org_id + "/sms"
                                  )
                                }
                              >
                                <FontAwesomeIcon
                                  icon={faSms}
                                  className="me-1"
                                />
                                Send Simple SMS
                              </Button>

                              <Button
                                variant="secondary"
                                className="mb-1 ml-1"
                                size="lg"
                                onClick={() =>
                                  history.push(
                                    "/org/" + props.match.params.org_id + "/sms"
                                  )
                                }
                              >
                                <FontAwesomeIcon
                                  icon={faSms}
                                  className="me-1"
                                />
                                Send Simple SMS
                              </Button>
                            </Col> */}
                    
                            <Col lg={6} md={6} xs={12} sm={12} className="mt-4">
                              <h6 className="mb-0">Messages sent over time</h6>
                              <LineChart
                                series={smsCostList}
                                email={EmailCostList}
                              />
                            </Col>
                            <Col lg={6} md={6} xs={12} sm={12} className="mt-4">
                              <h6 className="mb-0">Cost over Time</h6>
                              <BarChart
                                series={smsCostAmount}
                                email={EmailCostAmount}
                              />
                            </Col>
                            {/* <Col lg={8} md={8} xs={12} sm={12} className="mt-4">
                              <h6 className="mb-0">Delivery Status</h6>
                              <SenderBar />
                            </Col>
                            <Col lg={4} md={4} xs={12} sm={12} className="mt-4">
                              <h6 className="mb-0">Messages Per SenderID</h6>
                              <SenderChart />
                            </Col> */}
                          </Row>
                        </div>
                      </Tab.Pane>
                      <Tab.Pane
                        eventKey="users"
                        className="py-4 bg-transparent"
                      >
                        <ListGroup className="list-group-flush list my--3">
                          <AuthUserContext.Consumer>
                            {(authUser) =>
                              authUser != null ? (
                                <ListGroup.Item className="">
                                  <Row className="align-items-center">
                                    <Col className="col-auto">
                                      <FontAwesomeIcon
                                        icon={faUser}
                                        className="me-2"
                                      />
                                    </Col>
                                    <Col className="ms--2">
                                      <h4 className="h6 mb-0">
                                        <a href="#!">{authUser.displayName}</a>
                                      </h4>
                                      <span className={`text-success`}>● </span>
                                      <small>Online</small>
                                    </Col>
                                    <Col className="col-auto">
                                      <Button variant="danger" size="sm">
                                        <FontAwesomeIcon
                                          icon={faTrash}
                                          className="me-1"
                                        />{" "}
                                        Delete Account
                                      </Button>
                                    </Col>
                                  </Row>
                                </ListGroup.Item>
                              ) : (
                                <span className="mb-0 font-small fw-bold">
                                  Anonymous
                                </span>
                              )
                            }
                          </AuthUserContext.Consumer>
                        </ListGroup>
                      </Tab.Pane>
                      <Tab.Pane eventKey="subscriptions" className="py-4">
                        {/* <RankingWidget /> */}
                        <div className="card background-grey p-4">
                          <Button
                            variant="success"
                            className="mb-3 w-100"
                            size="md"
                          >
                            <FontAwesomeIcon
                              icon={faEnvelope}
                              className="me-1"
                            />
                            Free Plan
                          </Button>
                        </div>
                      </Tab.Pane>
                      <Tab.Pane eventKey="topups" className="py-4">
                        {/* <PageVisitsTable /> */}
                        <div className="card background-grey p-4">
                          <TopupTable
                            data={organisation_payments}
                            org_id={props.match.params.org_id}
                            firebase={props.firebase}
                          />
                        </div>
                      </Tab.Pane>
                    </Tab.Content>
                  </Col>
                </Row>
              </Tab.Container>
            </div>
          </div>

          <div className="col-lg-8 card-wrapper mb-3">
            <Card className="pb-0">
              <div className="card-body pb-0 background-grey">
                <Row className="pt-2 align-items-center">
                  <Col md={6}>
                    <Button
                      variant="secondary"
                      className="mb-3 w-100"
                      size="lg"
                      onClick={() =>
                        history.push(
                          "/org/" + props.match.params.org_id + "/sms"
                        )
                      }
                    >
                      <FontAwesomeIcon icon={faSms} className="me-1" />
                      Send Simple SMS
                    </Button>
                  </Col>
                  <Col md={6}>
                    <Button
                      variant="primary"
                      className="mb-3 w-100"
                      size="lg"
                      onClick={() =>
                        history.push(
                          "/org/" + props.match.params.org_id + "/mail/compose"
                        )
                      }
                    >
                      <FontAwesomeIcon icon={faEnvelope} className="me-1" />
                      Compose Simple Email
                    </Button>
                  </Col>
                </Row>
              </div>
            </Card>
          </div>
        </div>
      </div>
    </>
  );
}

const mapStateToProps = (state) => ({
  organisation_campaigns: state.organisation_campaigns,
  organisation_payments: state.organisation_payments,
  message_logs: state.message_logs,
});

const mapDispatchToProps = (dispatch) => {
  return {
    setCampaigns: (campaigns) =>
      dispatch({ type: "SAVE_CAMPAIGNS", campaigns: campaigns }),
    getMessageLogs: (appid) =>
      dispatch({ type: "GET_MESSAGE_LOGS_REQUEST", appid: appid }),
    setOrders: (payments) =>
      dispatch({ type: "SAVE_ORDERS", payments: payments }),
  };
};
// export default StoreSideBar
export default compose(
  connect(mapStateToProps, mapDispatchToProps),
  // withStateHandlers,
  // withHandler,
  withFirebase
)(MainDashboard);
