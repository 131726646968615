import React from "react";
import "./styles.css"

export default class Preview extends React.Component {
  constructor(props) {
    super(props);
  }

  /**
   * Called after mounting the component. Triggers initial update of
   * the iframe
   */
  componentDidMount() {
    this._updateIframe();
  }

  /**
   * Called each time the props changes. Triggers an update of the iframe to
   * pass the new content
   */
  componentDidUpdate() {
    this._updateIframe();
  }

  /**
   * Updates the iframes content and inserts stylesheets.
   * TODO: Currently stylesheets are just added for proof of concept. Implement
   * and algorithm which updates the stylesheets properly.
   */
  _updateIframe() {
    const iframe = this.refs.iframe;
    const document = iframe.contentDocument;
    const head = document.getElementsByTagName("head")[0];
    document.body.innerHTML = this.props.content;

    [
      "https://maxcdn.bootstrapcdn.com/bootstrap/3.3.6/css/bootstrap.min.css",
    ].forEach((url) => {
      const ref = document.createElement("link");
      ref.rel = "stylesheet";
      ref.type = "text/css";
      ref.href = url;
      head.appendChild(ref);
    });
  }

  /**
   * This component renders just and iframe
   */
  render() {
    return <iframe className="iframecont"  style={{ width: "100%", height: "450px"  }} ref="iframe" />;
  }
}
