import React from "react";
import { connect } from "react-redux";
import ReactDOM from "react-dom";
// Import React FilePond\
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { FilePond, registerPlugin, setOptions } from "react-filepond";
import {
  Form,
  Button,
  ButtonGroup,
  InputGroup,
  Col,
} from "@themesberg/react-bootstrap";
// Import FilePond styles
import "filepond/dist/filepond.min.css";

import { faSearch } from "@fortawesome/free-solid-svg-icons";
// Import the Image EXIF Orientation and Image Preview plugins
// Note: These need to be installed separately
import FilePondPluginImageExifOrientation from "filepond-plugin-image-exif-orientation";
import FilePondPluginImagePreview from "filepond-plugin-image-preview";
import "filepond-plugin-image-preview/dist/filepond-plugin-image-preview.css";
import { compose } from "recompose";
import { withFirebase } from "../../../Firebase";
import shortid from "shortid";
// Register the plugins
registerPlugin(FilePondPluginImageExifOrientation, FilePondPluginImagePreview);

class FilePondWidget extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      // Set initial files, type 'local' means this is a file
      // that has already been uploaded to the server (see docs)
      node: null,
      codes: [],
      files: [],
      csvFile: {
        file_name: null,
      },
      file_name: null,
    };

    this.handleProcess = this.handleProcess.bind(this);
    this.handleChangeSelectedSheet = this.handleChangeSelectedSheet.bind(this);
    this.handleChangeStartIndex = this.handleChangeStartIndex.bind(this);
    this.handleGetCsvData = this.handleGetCsvData.bind(this);
    this.handleSaveCsvData = this.handleSaveCsvData.bind(this);
  }

  handleChangeStartIndex(event) {
    let currentCsv = this.state.csvFile;

    console.log(event.target.value);

    this.setState({
      csvFile: { ...currentCsv, start_index: event.target.value },
    });

    // this.state.setCsv({ ...currentCsv, start_index: event.target.value });
  }
  handleChangeSelectedSheet(event) {
    let currentCsv = this.state.csvFile;
    let start_index = event.target.value;
    // console.log();
    // this.state.setCsv({ ...currentCsv, selected_sheet: event.target.value });
    this.setState({ csvFile: { ...currentCsv, selected_sheet: start_index } });
  }

  componentDidMount = function () {};

  componentWillUnmount() {
    this.setState({
      csvFile: {
        file_name: null,
      },
    });
  }

  handleSaveCsvData() {
    let payload = {
      csvParams: this.state.csvFile,
      mode: "save",
      application_id: this.props.appid,
    };
    this.props.saveMenuData(payload);
    console.log("GET CSV ");
  }
  handleGetCsvData() {
    this.props.getMenuData({ ...this.state.csvFile });
    console.log("GET CSV ");
  }
  handleProcess(error, file) {
    if (!error) {
      let payload = JSON.parse(file.serverId);
      this.setState({ csvFile: { ...payload, start_index: "1" } });
      // this.state.setCsv({ ...payload, start_index: "1" });
      console.log(payload);
    }
  }

  handleInit() {
    console.log("FilePond instance has initialised", this.pond);

    if (this.state.file_name) {
      this.setState({
        files: [
          {
            source: this.state.file_name,
            options: {
              type: "local",
            },
          },
        ],
      });
    }

    // uobfay6i
  }

  render() {
    return (
      <div className="App">
        {/* Pass FilePond properties as attributes */}
        {/* {JSON.stringify(this.state.codes)} */}
        <FilePond
          ref={(ref) => (this.pond = ref)}
          files={this.state.files}
          allowMultiple={false}
          server={{
            url: "https://api.bunicom.com",
            process: {
              url: "/upload",
              method: "POST",
              withCredentials: false,
              headers: {},
              timeout: 100000,
              onload: null,
              onerror: null,
              //   ondata: (formData) => {
              //     formData.append("upload_preset", "n6mgm8sq");
              //     return formData;
              //   },
            },
          }}
          //   server={{
          //     process: this.handleProcessFile,s
          //     load: this.handleFileLoaded
          //   }}
          name="filepond"
          oninit={() => this.handleInit()}
          onprocessfile={this.handleProcess}
          //   onremovefile={this.handeleRemoveFile}
          onupdatefiles={(fileItems) => {
            // Set currently active file objects to this.state
            this.setState({
              files: fileItems.map((fileItem) => fileItem.file),
            });
          }}
        />
        {/* {this.state.csvFile.file_name ? (
          <button type="button" className="btn btn-success mb-4">
            {this.state.csvFile.file_name}{" "}
            <span className="badge bg-primary">9</span>
            <span className="sr-only">unread messages</span>
          </button>
        ) : (
          ""
        )} */}

        {this.state.csvFile.sheets == null ? (
          ""
        ) : (
          <div>
            {/* <h6 className="mt-2">Select Sheet</h6> */}
            <Form>
              <div className="row">
                <Col md={6} sm={12}>
                  <Form.Group className="mb-3">
                    <Form.Label>Select sheet name</Form.Label>
                    <Form.Select
                      value={
                        this.state.csvFile.selected_sheet == null ||
                        this.state.csvFile.selected_sheet == ""
                          ? "Default"
                          : this.state.csvFile.selected_sheet
                      }
                      onChange={this.handleChangeSelectedSheet}
                    >
                      <option value="Default">Default</option>
                      {this.state.csvFile.sheets.map((item, key) => (
                        <option key={key} value={item}>
                          {item}
                        </option>
                      ))}
                    </Form.Select>
                  </Form.Group>
                </Col>
                <Col md={6} sm={12}>
                  <Form.Group className="mb-3">
                    <Form.Label>Select the row that has the header</Form.Label>
                    <InputGroup>
                      <InputGroup.Text>
                        <FontAwesomeIcon icon={faSearch} />
                      </InputGroup.Text>
                      <Form.Control
                        type="number"
                        value={this.state.csvFile.start_index}
                        onChange={this.handleChangeStartIndex}
                      />
                    </InputGroup>
                  </Form.Group>
                </Col>
                <Col md={12} sm={12} className={"pt-4"}>
                  <ButtonGroup>
                    <Button
                      variant="outline-info"
                      size="md"
                      onClick={this.handleGetCsvData}
                      disabled={this.props.loading}
                    >
                      See Data Preview
                    </Button>
                    {this.props.bulk_data ? (
                      <Button
                        variant="success"
                        size="md"
                        onClick={this.handleSaveCsvData}
                        disabled={this.props.loading}
                      >
                        Data looks good, Save it!
                      </Button>
                    ) : (
                      ""
                    )}
                  </ButtonGroup>
                </Col>
              </div>
              {/* {JSON.stringify(this.props.bulk_data)} */}
            </Form>
          </div>
        )}
      </div>
    );
  }
}

const mapStateTostate = (state) => ({
  bulk_data: state.bulk_data,
  loading: state.loading,
});

const mapDispatchTostate = (dispatch) => {
  return {
    getMenuData: (data) => dispatch({ type: "GET_DATA_REQUEST", data: data }),
    saveMenuData: (data) => dispatch({ type: "SAVE_DATA_REQUEST", data: data }),
  };
};

// export default connect(mapStateTostate, mapDispatchTostate)(withFirebase(HotelsPage));
export default compose(
  connect(mapStateTostate, mapDispatchTostate),
  // withStateHandlers,
  // withHandler,
  withFirebase
)(FilePondWidget);
