import React, { useState, useEffect } from "react";

import { connect } from "react-redux";
import ScrollToTop from "../../../ScrollToTop";
import { withFirebase } from "../../../Firebase";
import { AuthUserContext } from "../../../Session";

import {
  Col,
  Row,
  Button,
  Modal,
  Card,
  Alert,
  InputGroup,
  FormControl,
  Form,
  ProgressBar
} from "@themesberg/react-bootstrap";


import Axios from "axios";
import { compose } from "recompose";
import InvoicePage from "./Invoice";
const delay = 5;

function OrderPage(props) {
  const [authUser, setAuth] = useState(null);
  const [order, setOrder] = useState(null);
  const [BusinessAccount, setBusinessAccount] = useState(null);

  const [showDefault, setShowDefault] = useState(false);
  const [ModaRef, setModalRef] = useState(React.createRef());
  const [emailConfigStatus, setEmailConfigStatus] = useState(
    "Sending STK Push, please wait ..."
  );
  const handleClose = () => setShowDefault(false);

  useEffect(() => {
    // for (let i = 0; i < 100; i++) {
    //   window.clearInterval(i);
    // }
    let current_order = props.firebase.db
      .collection("organisation_payments")
      .where("order_id", "==", props.match.params.order_id)
      .get()
      .then((querySnapshot) => {
        let order = {};
        querySnapshot.forEach((doc) => {
          // doc.data() is never undefined for query doc snapshots
          //   console.log(doc.id, " => ", doc.data());
          order = { ...doc.data(), id: doc.id };
        });

        setOrder(order);
      })
      .catch((error) => {
        console.log("Error getting documents: ", error);
      });
  });


  useEffect(() => {


    const unsubscribe = props.firebase.auth.onAuthStateChanged((authUser) => {
      if (authUser) {
        setAuth(authUser);

        let current_bsa = props.firebase.db
          .collection("business_accounts")
          .where("uid", "==", authUser.uid)
          .get()
          .then((querySnapshot) => {
            let bsa = {};
            querySnapshot.forEach((doc) => {
              // doc.data() is never undefined for query doc snapshots
              //   console.log(doc.id, " => ", doc.data());
              bsa = { ...doc.data(), id: doc.id };
            });

            setBusinessAccount(bsa);
          })
          .catch((error) => {
            console.log("Error getting documents: ", error);
          });

          let current_order = props.firebase.db
      .collection("organisation_payments")
      .where("order_id", "==", props.match.params.order_id)
      .get()
      .then((querySnapshot) => {
        let order = {};
        querySnapshot.forEach((doc) => {
          // doc.data() is never undefined for query doc snapshots
          //   console.log(doc.id, " => ", doc.data());
          order = { ...doc.data(), id: doc.id };
        });

        setOrder(order);
      })
      .catch((error) => {
        console.log("Error getting documents: ", error);
      });

        // console.log(authUser);
        //Get business account and save details
      } else {
        setAuth(null);
        //save null business account
      }
    });

    return () => {
      unsubscribe();
    };
  }, []);
  // updateOrderStatus

  const refreshOrder = () =>  {

    let current_bsa = props.firebase.db
      .collection("business_accounts")
      .where("uid", "==", authUser.uid)
      .get()
      .then((querySnapshot) => {
        let bsa = {};
        querySnapshot.forEach((doc) => {
          // doc.data() is never undefined for query doc snapshots
          //   console.log(doc.id, " => ", doc.data());
          bsa = { ...doc.data(), id: doc.id };
        });

        setBusinessAccount(bsa);
      })
      .catch((error) => {
        console.log("Error getting documents: ", error);
      });
  }

  const updateOrderStatus = (data) => {
    if (order) {
      if (order.status == "pending") {
        //change status to paid

        let current_order = props.firebase.db
          .collection("organisation_payments")
          .doc(order.id)
          .update({
            status: "paid",
            data: data,
          })
          .then(() => {
            console.log("Document successfully updated!");
          })
          .catch((error) => {
            // The document probably doesn't exist.
            console.error("Error updating document: ", error);
          });

        let current_bsa = props.firebase.db
          .collection("business_accounts")
          .doc(BusinessAccount.id)
          .update({
            credits: BusinessAccount.credits + order.credits,
          })
          .then(() => {
            console.log("Document successfully updated!");
            refreshOrder();
          })
          .catch((error) => {
            // The document probably doesn't exist.
            console.error("Error updating document: ", error);
          });
        //add credits to app
      }
    }
  };

  const PayStatus = () => {

    console.log("let run");

    // setOrder(order)

    console.log(order);

    if (order) {
      if (order.status == "pending") {

        let payload = {
          oid: order.order_id,
        };

        Axios.post(
          "https://api.bunicom.com/transact-confirm",
          payload
        )
          .then((response) => {
            let status = response.data;

            if (status.hasOwnProperty("header_status")) {
              if (status.header_status == 200) {
                updateOrderStatus(response.data);
              }
            }
            //

            console.log(response.data);
          })
          .catch((e) => {
            console.log(e);
          });
      }
    }
  };

  const CheckPayStatus = () => {
    setShowDefault(true);
    setEmailConfigStatus("Confirming Order ...");

    let payload = {
      oid: order.order_id,
    };

    Axios.post(
      "https://api.bunicom.com/transact-confirm",
      payload
    )
      .then((response) => {
        let status = response.data;

        if (status.hasOwnProperty("header_status")) {
          setEmailConfigStatus(response.data.text);

          if (status.header_status == 200) {
            updateOrderStatus(response.data);
            setEmailConfigStatus("Payment successful!!");
          }
          setTimeout(() => {
            setShowDefault(false);
          }, 10000);
        }
        //

        console.log(response.data);
      })
      .catch((e) => {
        setEmailConfigStatus("Something went wrong");
        setTimeout(() => {
          setShowDefault(false);
        }, 2000);
        console.log(e);
      });
  };
  const PushSTK = () => {
    setShowDefault(true);
    setEmailConfigStatus("Sending STK Push, please wait ...");

    let payload = {
      live: "1",
      oid: order.order_id,
      inv: order.order_id,
      amount: order.amount,
      tel: order.phone_number,
      eml: "info@zmc.co.ke",
      curr: "KES",
      p1: "",
      p2: "",
      p3: "",
      p4: "",
    };

    Axios.post(
      "https://api.bunicom.com/transact-mpesa",
      payload
    )
      .then((response) => {
        setEmailConfigStatus(response.data.text);
        //
        setTimeout(() => {
          setShowDefault(false);
        }, 10000);
        console.log(response.data);
      })
      .catch((e) => {
        setShowDefault(false);
        setEmailConfigStatus("Something went wrong");
        console.log(e);
      });
  };

  return (
    <AuthUserContext.Provider value={authUser}>
      <div className="container-fluid mt--6">
        <Modal
          as={Modal.Dialog}
          centered
          show={showDefault}
          ref={ModaRef}
          onHide={handleClose}
        >
          <Modal.Header>
            <Modal.Title className="h6">Payment Status</Modal.Title>
            <Button variant="close" aria-label="Close" onClick={handleClose} />
          </Modal.Header>
          <Modal.Body>
            <p>{emailConfigStatus}</p>
          </Modal.Body>
        </Modal>

        <div className="row justify-content-center">
          <div className="col-lg-8 card-wrapper">
            <div className="d-block mb-4 mb-md-0">
              {/* <Alert variant="secondary" show={true}>
                <div className="d-flex justify-content-between">
                  <div>
                    <FontAwesomeIcon icon={faDatabase} className="me-1" />
                    <strong>Global SMS &amp; Email Configuration!</strong> You
                    can use our predefined SMS &amp; Mail vendors. You cna
                    overwrite any vendor by entering you configurations
                  </div>
                </div>
              </Alert> */}
              ORDERS PAGE
            </div>

            <div className="card mt-2">
              <div className="card-header">
                <div className="row align-items-center">
                  <div className="col">
                    <h5 className="h3 mb-0">
                      Order #{order ? order.order_id : "Pending"}
                    </h5>
                  </div>
                  <div className="col">
                    <div className="justify-content-end">
                      {order ? (
                        order.status == "pending" ? (
                          <>
                            <Button
                              variant="secondary"
                              className="float-end m-1"
                              disabled={order == null}
                              onClick={PushSTK}
                            >
                              Resend STK Push
                            </Button>

                            <Button
                              variant="success"
                              className="float-end m-1"
                              disabled={order == null}
                              onClick={CheckPayStatus}
                            >
                              Check Payment Status
                            </Button>
                          </>
                        ) : (
                          <Button
                            variant="success"
                            className="float-end m-1"
                            disabled
                          >
                            Order Paid
                          </Button>
                        )
                      ) : (
                        ""
                      )}
                    </div>
                  </div>
                </div>
              </div>
              <div className="card-body">
                <div className="row" id="recordsTable">
                  <div className="col-lg-12 ">
                  {order ? (
                        order.status == "pending" ? <ProgressBar variant="success" animated now={100} />: "") : ""
                  }

                    {/* {JSON.stringify(order)} */}

                    {
                       order ? (
                        <InvoicePage user={order.name} date={order.transaction_date} order_no ={order.order_id} sms_rate={order.amount / order.credits} credits={order.credits} amount={order.amount}/>

                       ): ""
                    }

                    
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        {/* {JSON.stringify(BusinessAccount)} */}
      </div>
    </AuthUserContext.Provider>
  );
}

const mapStateToProps = (state) => ({});

const mapDispatchToProps = (dispatch) => {
  return {};
};

export default compose(
  connect(mapStateToProps, mapDispatchToProps),
  withFirebase
)(OrderPage);
