import React, { Component } from "react";
import ReactDOM from "react-dom";
import jexcel from "jexcel";

import { connect } from "react-redux";
import { withRouter } from "react-router-dom";

import "./styles.css";
import "../../../../../node_modules/jexcel/dist/jexcel.css";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

import { Toolkit } from "storm-react-diagrams";
import { Button } from "@themesberg/react-bootstrap";
import { faPlusCircle } from "@fortawesome/free-solid-svg-icons";

class DataEntryTable extends React.Component {
  constructor(props) {
    super(props);

    let that = this;

    this.state = {
      entity: null,
      fields_data: [],
    };

    this.options = {
      data: [["+254", ""]],
      minDimensions: [4, 10],
      columns: [
        { type: "text", title: "Phone", width: 180 },
        { type: "text", title: "B", width: 180 },
        { type: "text", title: "C", width: 180 },
        { type: "text", title: "D", width: 180 },
      ],
      ondeleterow: function (instance) {
        // console.log(that.saveVars());
      },
      updateTable: function (instance, cell, col, row, val, label, cellName) {
        console.log("Some thing changes");
      },
      onchange: function (instance, cell, x, y, value) {
        console.log("Changed");
        that.saveVars();
      },
      onselection: function (instance, x1, y1, x2, y2, origin) {
        var cellName1 = jexcel.getColumnNameFromId([x1, y1]);
        var cellName2 = jexcel.getColumnNameFromId([x2, y2]);

        that.props.onHeadersChange(that.el.getHeaders().split(","));
      },
      onchangeheader: function (instance, cell, x, y, value) {
        console.log("Header Changed");
        that.props.onHeadersChange(that.el.getHeaders().split(","));
        that.saveVars();
      },

      columnDrag: true,
      allowManualInsertRow: true,
      allowManualInsertColumn: true,
      selectionCopy: true,
      allowInsertColumn: true,
      allowDeleteColumn: true,
      allowRenameColumn: true,
      allowComments: true,
      allowExport: true,
      oninsertrow: this.insertedRow,
    };
  }

  componentDidMount = function () {
    this.el = jexcel(ReactDOM.findDOMNode(this).children[0], this.options);

    console.log(this.el.getHeaders());
    this.props.onHeadersChange(this.el.getHeaders().split(","));

    this.refreshUITable();

  };

  resetTable() {
    document.getElementById("data-sheet").innerHTML = "";
    this.options.columns = [
      { type: "text", title: "Phone", width: 100 },
      { type: "text", title: "B", width: 180 },
      { type: "text", title: "C", width: 180 },
    ];
    //Honestly, I have no idea what i did here
  }

  // refreshUITable() {
  //   this.resetTable();
  // }

  componentDidUpdate(prevProps, prevState) {
    this.refreshUITable();
  }

  addRow = function () {
    this.el.insertRow(["", ""]);
  };

  refreshUITable() {
    // this.resetTable();

    let headers = ["Phone", "A"];
    let output = [["", ""]];

    var element = document.getElementById("data-sheet");
    var positionInfo = element.getBoundingClientRect();
    var width = positionInfo.width;

    this.options.tableWidth = width + "px";

  }
  saveVars = function () {
    let records = this.el.getData();
    let headers = this.el.getHeaders().split(",");

    console.log(headers);

    this.props.onHeadersChange(headers);

    let arr_objs_records = [];

    records.forEach((record) => {
      let obj_rec = {};
      let count = 0;
      headers.forEach((item) => {
        obj_rec[item] = record[count];
        count++;
      });

      if (obj_rec[headers[0]] !== "") {
        arr_objs_records.push(obj_rec);
      }
    });

    this.props.onDataChange(arr_objs_records);

    // console.log(arr_objs_records);
  };

  insertedRow = function (instance) {
    console.log(instance);
  };

  render() {
    return (
      <div>
        <div id="data-sheet"></div>
        <div>
          <Button
            variant="outline-primary"
            size="sm"
            className="m-1"
            onClick={() => this.addRow()}
          >
            <FontAwesomeIcon icon={faPlusCircle} className="me-2" /> Add another Row
          </Button>
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {};
};

const mapDispatchToProps = (dispatch) => {
  return {};
};

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(DataEntryTable)
);
