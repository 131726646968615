import React, { Component } from "react";
import ReactDOM from "react-dom";
import jexcel from "jexcel";
import Axios from "axios";

import { connect } from "react-redux";
import { withRouter } from "react-router-dom";

import "./styles.css";
import "../../../../node_modules/jexcel/dist/jexcel.css";

import { Toolkit } from "storm-react-diagrams";

import * as actionTypes from "./../../../store/actions";


import {
  
    Button,
  } from "react-bootstrap";


class SortTable extends React.Component {
  constructor(props) {
    super(props);

    let that = this

    this.state = {
      entity: null,
      fields_data: [],
    };
    
    this.options = {
      data: [['']],
      minDimensions: [2, 5],
        defaultColWidth: 100,
        tableOverflow: true,
        tableWidth: "620px",
      columns: [
        { type: 'dropdown', source: ["Default"],title:'Order By', width:150 },
        { type: "dropdown", title: "Sort Order", source: ["Ascending","Descending"], width: 150},

      ],
      ondeleterow: function (instance) {
    
        // console.log(that.saveDtypes());
        that.saveDtypesNode()
    
      },
      updateTable:function(instance, cell, col, row, val, label, cellName) {
        // var cellName = jexcel.getColumnNameFromId([col,row]);
        if(cellName.includes('A')){
            if(val !== ''){
                // if(parseInt(row) > 0){
                // //  let name_col = that.el.getCell('A'+parseInt(row))
                if(that.el){
                  var elem = that.el
                  //  console.log("Haitus");
                  let sum = parseInt(row)+1
                  // let name_col = elem.getValue('A'+sum)
                  let chain_col = elem.getCell('B'+sum)
                  let chain_value = elem.getValue('B'+sum)
                  //  console.log(name_col);
                   chain_col.style.backgroundColor = '#fff';
                   if(chain_value == ""){
                    chain_col.style.backgroundColor = '#ff0b1b';
                   }
                }
               
      
                // }
              }
        }
       
      },
      onchange: function(instance, cell, x, y, value) {
        
        var cellName = jexcel.getColumnNameFromId([x,y]);

        that.saveDtypesNode()
        },
      columnDrag: true,
      selectionCopy:false,
      allowManualInsertRow: false,
      allowManualInsertColumn: false,
      allowInsertColumn: false,
      allowDeleteColumn: false,
      allowRenameColumn: false,
      allowComments: false,
      allowExport: false,
      oninsertrow: this.insertedRow,
     
    };
  }

  componentDidMount = function () {
    // this.el = jexcel(ReactDOM.findDOMNode(this).children[0], this.options);

    this.saveDtypes() 
    
    

    // let fields_arr = resp.data.map((foo) => {
            
    //     return Object.values(foo)
    //   })
  };

  componentDidUpdate(prevProps, prevState) {
    this.saveDtypes()

    // if(prevProps.env_variables !== this.props.env_variables){
    //     console.log("ENVIRONMENT VARIABLES");
    //     // console.log(prevProps.dtypes);
    //     // console.log(this.props.dtypes);
      
    // }
  }
  saveDtypesNode  = function () {
    let selectNode = this.props.j_node
    let model = this.props.model;
    let currNode = model.getNode(selectNode.id)

    if(selectNode){
      if(selectNode.properties.hasOwnProperty("conditions")){
        let records = this.el.getData();
        let headers = ['order_by','sort_order']
        let arr_objs_records = [];

        records.forEach((record) => {
            let obj_rec = {};
            let count = 0;
            
            headers.forEach((item) => {
              
                obj_rec[item] = record[count]
             
                count++;
            });
            if (obj_rec["order_by"] !== "") {
                arr_objs_records.push(obj_rec);
             }
            
        })

        // console.log(arr_objs_records);
        // dataArr.push([column.column_name,column.condition,column.filter_by,column.x_value,column.env_variable,column.chain]);

        currNode.properties.conditions = arr_objs_records

    }

    }


   

    // currNode.properties.metaData

  }

  saveDtypes = function () {

    let selectNode = this.props.j_node

    let model = this.props.model;
    let currNode = model.getNode(selectNode.id)
    document.getElementById('join-sheet').innerHTML = '';

    if(currNode){

      if(currNode.properties.metaData.hasOwnProperty("columns") ){
        let columns_l = currNode.properties.metaData.columns
        let source_cols_l = []
       
        columns_l.forEach(element => {
            source_cols_l.push(element.column_name)
        });
  
       
  
  
        this.options.columns = [
            { type: 'dropdown', source:source_cols_l,title:'Order By', width:150 },
            { type: "dropdown", title: "Sort Order", source: ["Ascending","Descending"], width: 150},
    
          ]
        
          // this.el.se
          // this.el.setData(0, { type: 'dropdown', source: source_cols,title:'Select Column', width:100 },)
  
      }
      this.el = jexcel(ReactDOM.findDOMNode(this).children[0], this.options);
    if(currNode.properties.hasOwnProperty("conditions")){
        let conditions = currNode.properties.conditions
        if(conditions.length > 0){
          let dataArr = []
  
          conditions.forEach(column => {
            dataArr.push([column.order_by,column.sort_order]);
          });
    
    
          this.el.setData(dataArr)
        }else{
          this.el.setData([[]])
        }
        
      }
    }


  }


  addRow = function () {
    this.el.insertRow(["",""]);
  };

  insertedRow = function (instance) {

    console.log(instance);
  };


  render() {
    return (
      <div>
        <div id="join-sheet"></div>

        <div>
         
          <Button
              aria-pressed={true}
              className="active"
              color="secondary"
              href="#pablo"
              onClick={() => this.addRow()}
              role="button"
              size="lg"
            >
              + Add Row
            </Button>
          
        </div>

        <br />
        <br />
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
  };
};

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(SortTable)
);
