import React, { Fragment } from "react";
import * as _ from "lodash";
import { useHistory } from "react-router-dom";

import { DiagramModel, DiagramWidget, Toolkit } from "storm-react-diagrams";

import { Application } from "../../../../Workflow/Application";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

import { createBrowserHistory } from "history";

import { connect } from "react-redux";

import { withRouter } from "react-router-dom";

import {
  faPlay,
  faSave,
  faSearch,
  faStop,
  faTable,
} from "@fortawesome/free-solid-svg-icons";
import "storm-react-diagrams/dist/style.min.css";

import {
  Col,
  Row,
  Button,
  Dropdown,
  ButtonGroup,
  Tabs,
  Nav,
  Tab,
  Table,
  Form,
  ProgressBar,
  InputGroup,
} from "@themesberg/react-bootstrap";

import Axios from "axios";

import Lottie from "lottie-react-web";

import animation from "./lottie.json";

import { FileNodeModel } from "../../../../Workflow/Connectors/FileInput/FileNodeModel";
import { EntityNodeModel } from "../../../../Workflow/Connectors/Entity/EntityNodeModel";
import { RequestNodeModel } from "../../../../Workflow/Connectors/Request/RequestNodeModel";

import { DataEntryNodeModel } from "../../../../Workflow/Connectors/DataEntry/DataEntryNodeModel";
import { FormNodeModel } from "../../../../Workflow/Connectors/Form/FormNodeModel";
import { SheetsNodeModel } from "../../../../Workflow/Connectors/Sheets/SheetsNodeModel";
import { FilterNodeModel } from "../../../../Workflow/Connectors/Filter/FilterNodeModel";
import { FormulaNodeModel } from "../../../../Workflow/Connectors/Formula/FormulaNodeModel";
import { UnionNodeModel } from "../../../../Workflow/Connectors/Union/UnionNodeModel";
import { UniqueNodeModel } from "../../../../Workflow/Connectors/Unique/UniqueNodeModel";
import { PeekNodeModel } from "../../../../Workflow/Connectors/Peek/PeekNodeModel";
import { SelectNodeModel } from "../../../../Workflow/Connectors/Select/SelectNodeModel";
import { SMSNodeModel } from "../../../../Workflow/Connectors/SMS/SMSNodeModel";
import { CleanupNodeModel } from "../../../../Workflow/Connectors/Cleanup/CleanupNodeModel";
import { GroupNodeModel } from "../../../../Workflow/Connectors/Group/GroupNodeModel";
import { OutputNodeModel } from "../../../../Workflow/Connectors/Output/OutputNodeModel";
import { SortNodeModel } from "../../../../Workflow/Connectors/Sort/SortNodeModel";
import { EmailNodeModel } from "../../../../Workflow/Connectors/Email/EmailNodeModel";
import { SampleNodeModel } from "../../../../Workflow/Connectors/Sample/SampleNodeModel";
import { ReplaceNodeModel } from "../../../../Workflow/Connectors/Replace/ReplaceNodeModel";
import { JoinNodeModel } from "../../../../Workflow/Connectors/Join/JoinNodeModel";
import { TextToColumnNodeModel } from "../../../../Workflow/Connectors/TextToColumn/TextToColumnNodeModel";

//View components
import FormulaNodeComponent from "../../../../Workflow/Connectors/Formula/FormulaNodeComponent";
import EntityNodeComponent from "../../../../Workflow/Connectors/Entity/EntityNodeComponent";
import RequestNodeComponent from "../../../../Workflow/Connectors/Request/RequestNodeComponent";
import DataEntryNodeComponent from "../../../../Workflow/Connectors/DataEntry/DataEntryNodeComponent";
import FormNodeComponent from "../../../../Workflow/Connectors/Form/FormNodeComponent";
import PeekToolComponent from "../../../../Workflow/Connectors/Peek/PeekToolComponent";
import SheetsNodeComponent from "../../../../Workflow/Connectors/Sheets/SheetsNodeComponent";
import SelectNodeComponent from "../../../../Workflow/Connectors/Select/SelectNodeComponent";
import CleanupComponent from "../../../../Workflow/Connectors/Cleanup/CleanupComponent";
import UniqueNodeComponent from "../../../../Workflow/Connectors/Unique/UniqueNodeComponent";
import FilterComponent from "../../../../Workflow/Connectors/Filter/FilterComponent";
import GroupComponent from "../../../../Workflow/Connectors/Group/GroupComponent";
import JoinComponent from "../../../../Workflow/Connectors/Join/JoinComponent";
import SortComponent from "../../../../Workflow/Connectors/Sort/SortComponent";
import EmailComponent from "../../../../Workflow/Connectors/Email/EmailComponent";
import OutputComponent from "../../../../Workflow/Connectors/Output/OutputComponent";
import SMSNodeComponent from "../../../../Workflow/Connectors/SMS/SMSNodeComponent";

import FilePondComp from "../../../../Workflow/FilePondComp";

import DisplayResultsTable from "../../../../Workflow/Models/DisplayResultsTable";

import BuniPallette from "./BuniPallette";
import EnvTable from "./EnvTable";

import Editor from "react-simple-code-editor";

import Highlight, { defaultProps } from "prism-react-renderer";
import theme from "prism-react-renderer/themes/nightOwl";

const styles = {
  root: {
    boxSizing: "border-box",
    borderRadius: "2%",
    fontFamily: '"Dank Mono", "Fira Code", monospace',
    ...theme.plain,
  },
};

const history = createBrowserHistory({ forceRefresh: true });

class Playground extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      task_id: "0",
      fetch_status: true,
      runningState: "running",
      running_node: "",
      activeTab: "1",
      currentWorkflow: {
        env_variables: [{ var_name: "", value: "", type: "text" }],
      },
      workflow_timer: [],
      collections: [],
      zoomLevel: 100,
      workflow: {
        name: "Untitled",
        _id: Toolkit.UID(),
        description: "Hello world",
        collection_id: "default",
        application_id: "default",
        collection_name: "Default",
      },
      project_id: 1,
      company_id: 1,
      styleCondition: "false",
      link_selected: false,
      selectedLink: null,
      selected_node: null,
      env_variables: [{ var_name: "", value: "", type: "text" }],
      nodes: [],
      connections: [],
      display_results: [],
    };

    this.appEngine = new Application();
    this.updateNode = this.updateNode.bind(this);
    this.saveEnvVariables = this.saveEnvVariables.bind(this);

    this.renderLinks = this.renderLinks.bind(this);
    this.renderNode = this.renderNode.bind(this);
    this.renderDiagram = this.renderDiagram.bind(this);
    this.handleChangeSelectedSheet = this.handleChangeSelectedSheet.bind(this);
    this.handleChangeStartIndex = this.handleChangeStartIndex.bind(this);

    this.toggleStyle = this.toggleStyle.bind(this);
  }

  componentDidMount() {
    // window.onpopstate = this.popStateListener;

    this.renderDiagram();

    console.log("Component has mounted");
  }

  renderDiagram() {
    let that = this;

    // let workflow_json = this.props.workflow_payload;

    this.setState({
      nodes: [],
      connections: [],
      env_variables: [{ var_name: "", value: "", type: "text" }],
    });

    this.model = new DiagramModel();
    this.appEngine.diagramEngine.setDiagramModel(this.model);
    console.log("I AM HERE YAD AYADA");
    console.log(this.props.campaignData);

    if (this.props.campaignData) {
      Axios.get(
        `https://api.bunicom.com///get-workflow?_id=${this.props.campaignData.workflowId}`
      ).then((response) => {
        console.log("Workflow found");

        console.log(response.data);

        that.model.addListener({
          nodesUpdated: function (e) {
            console.log("Node have been updated");
            if (e.isCreated) {
              let newNode = {
                nodeType: e.node.type,
                id: e.node.id,
                properties: JSON.parse(
                  JSON.stringify(e.node.properties, that.getCircularReplacer())
                ),
                ports: JSON.parse(
                  JSON.stringify(e.node.ports, that.getCircularReplacer())
                ),
                annotation: "Plugin Initialized",
              };

              that.setState((state, props) => ({
                nodes: state.nodes.concat(newNode),
                node: newNode,
              }));

              // that.props.onAddNodes([])
              // that.props.onAddLinks([])

              // that.props.onAddNode(newNode);
            }
          },
          linksUpdated: function (event) {
            event.link.addListener({
              selectionChanged: function (d) {
                console.log("Link Selected");
                console.log(d);
                if (d.isSelected) {
                  that.setState({ link_selected: true });
                } else {
                  that.setState({ link_selected: false });
                }
              },
              targetPortChanged: function (d) {
                console.log("Target Port Changed");
                console.log(event.link.targetPort);

                if (event.link.targetPort) {
                  if (
                    event.link.targetPort.name == event.link.sourcePort.name
                  ) {
                    console.log("Same as source");
                    let results = event.link.sourcePort.parent.printResults(
                      event.link.sourcePort.parent,
                      event.link.sourcePort.label
                    );
                    let selected_node = {
                      id: event.link.sourcePort.parent.id,
                      nodeType: event.link.sourcePort.parent.type,
                      properties: event.link.sourcePort.parent.properties,
                    };

                    that.setState({
                      display_results: results,
                      selected_node: selected_node,
                      // connections: Lodash.uniq(,'stamp')
                    });
                    console.log("Display port results");
                    console.log(results);

                    //   that.props.onChangeResults(results);
                    //   that.props.onChangeSelectedNode(selected_node);

                    event.link.remove();
                  } else if (event.link.targetPort.in) {
                    // console.log("Different from source");
                    let link = event.link;
                    let linksObject = link.sourcePort["links"];
                    let targetObject = link.targetPort["links"];

                    // console.log(Object.keys(targetObject));
                    // console.log(link.targetPort.parent.properties);

                    if (
                      Object.keys(targetObject).length >
                      link.targetPort.parent.properties.maxLinks
                    ) {
                      event.link.remove();
                    } else {
                      // console.log(d);
                      let newConnection = {
                        stamp:
                          link.sourcePort.parent.id +
                          "" +
                          link.targetPort.parent.id,
                        link_id: link.id,
                        source: {
                          nodeType: link.sourcePort.parent.type,
                          port: link.sourcePort.label,
                          links: Object.keys(linksObject),
                          nodeID: link.sourcePort.parent.id,
                          properties: link.sourcePort.parent.properties,
                        },
                        target: {
                          nodeType: link.targetPort.parent.type,
                          port: link.targetPort.label,
                          links: Object.keys(targetObject),
                          nodeID: link.targetPort.parent.id,
                          properties: link.targetPort.parent.properties,
                        },
                      };
                      let tarNode = that.appEngine.diagramEngine
                        .getDiagramModel()
                        .getNode(link.targetPort.parent.id);
                      tarNode.properties.headers =
                        link.sourcePort.parent.properties.headers;

                      let data = that.state.connections.concat(newConnection);

                      that.setState({
                        connections: _.uniqWith(data, _.isEqual),
                        // connections: Lodash.uniq(,'stamp')
                      });

                      // that.props.onAddLinks(_.uniqWith(data, _.isEqual));

                      that.runWorkflow("execute", "sample"); //TODO Uncomment here
                    }
                  } else {
                    console.log("Dont resort to here");
                    event.link.remove();
                  }
                }
              },

              sourcePortChanged: function (d) {
                // console.log("Source Port");
                // e.remove()
              },
              entityRemoved: function (d) {
                console.log("Link deleted");
                console.log(d.entity);

                let data = that.state.connections.filter(
                  (link) => link.link_id != d.entity.id
                );

                that.setState({
                  connections: _.uniqWith(data, _.isEqual),
                  selected_node: null,
                  // connections: Lodash.uniq(,'stamp')
                });
                //   that.props.onAddLinks(_.uniqWith(data, _.isEqual));
                //   that.props.onChangeSelectedNode(null);
              },
            });
          },
        });

        //   let workflow = response.data;
        let workflow_json = response.data;

        that.setState({
          currentWorkflow: workflow_json,
          workflow: workflow_json,
        });

        if (workflow_json.hasOwnProperty("nodes")) {
          console.log("Yada Yda");

          console.log(workflow_json);
          console.log("Oh Yada");
          workflow_json.nodes.forEach((node) => {
            console.log(node);
            that.renderNode(node, that);
          });
          workflow_json.links.forEach((link) => {
            console.log(link);
            that.renderLinks(link, that);
          });
        }

        if (workflow_json.hasOwnProperty("env_variables")) {
          // that.props.onSaveVars(workflow_json.env_variables);
          this.setState({
            env_variables: workflow_json.env_variables,
          });
        }

        that.runWorkflow("execute", "sample"); //TODO uncomment here
      });
    }
  }

  highlight = (code) => (
    <Highlight {...defaultProps} theme={theme} code={code} language="js">
      {({ className, style, tokens, getLineProps, getTokenProps }) => (
        <Fragment>
          {tokens.map((line, i) => (
            <div {...getLineProps({ line, key: i })}>
              {line.map((token, key) => (
                <span {...getTokenProps({ token, key })} />
              ))}
            </div>
          ))}
        </Fragment>
      )}
    </Highlight>
  );

  renderLinks(link, that) {
    let newlink = null;
    let connection = link;
    let sourcePort = null;
    if (link.source.nodeID !== "genesis") {
      let sourceNode = that.appEngine.diagramEngine
        .getDiagramModel()
        .getNode(link.source.nodeID);
      // .getPort(link.source.port);

      if (sourceNode) {
        sourcePort = sourceNode.getPort(link.source.port);
      }
      // console.log(link.target.nodeID);
      let targetNode = that.appEngine.diagramEngine
        .getDiagramModel()
        .getNode(link.target.nodeID);

      if (targetNode) {
        let targetPort = targetNode.getPort(link.target.port);
        newlink = sourcePort.link(targetPort);
        connection.link_id = newlink.id;
      }

      if (newlink !== null) {
        console.log("yotyoyoyo");

        that.appEngine.diagramEngine.getDiagramModel().addLink(newlink);

        that.forceUpdate();

        let data = that.state.connections.concat(connection);

        that.setState({
          connections: _.uniqWith(data, _.isEqual),
        });
        // that.props.onAddLinks(_.uniqWith(data, _.isEqual));
      }
    }
  }

  renderNode(tool, vm) {
    console.log("Start processing");
    // console.log(type);
    let node = null;
    switch (tool.nodeType) {
      case "File":
        node = new FileNodeModel();
        node.addOutPort("output");
        break;
      case "Entity":
        node = new EntityNodeModel();
        node.addOutPort("output");
        break;

      case "Request":
        node = new RequestNodeModel();
        node.addOutPort("output");
        break;

      case "DataEntry":
        node = new DataEntryNodeModel();
        node.addOutPort("output");
        break;

      case "Form":
        node = new FormNodeModel();
        node.addOutPort("output");
        break;
      case "Sheets":
        node = new SheetsNodeModel();
        node.addOutPort("output");
        break;
      case "Filter":
        node = new FilterNodeModel();
        node.addInPort("input");
        node.addOutPort("true");
        node.addOutPort("false");
        break;
      case "Join":
        node = new JoinNodeModel();
        // node.addInPort("input");
        node.addInPort("left");
        node.addInPort("right");

        node.addOutPort("ljoin");
        node.addOutPort("ijoin");
        node.addOutPort("rjoin");
        break;
      case "Formula":
        node = new FormulaNodeModel();
        node.addInPort("input");
        node.addOutPort("output");
        break;
      case "Union":
        node = new UnionNodeModel();
        node.addInPort("input");
        node.addOutPort("output");
        break;
      case "Select":
        node = new SelectNodeModel();
        node.addInPort("input");
        node.addOutPort("output");
        break;
      case "SMS":
        node = new SMSNodeModel();
        node.addInPort("input");
        node.addOutPort("output");

        break;

      case "Sort":
        node = new SortNodeModel();
        node.addInPort("input");
        node.addOutPort("output");
        break;
      case "Email":
        node = new EmailNodeModel();
        node.addInPort("input");
        node.addOutPort("output");
        break;
      //
      case "Replace":
        node = new ReplaceNodeModel();
        node.addInPort("input");
        node.addOutPort("output");
        break;
      case "TextToColumn":
        node = new TextToColumnNodeModel();
        node.addInPort("input");
        node.addOutPort("output");
        break;

      case "Cleanup":
        node = new CleanupNodeModel();
        node.addInPort("input");
        node.addOutPort("output");
        break;
      case "Group":
        node = new GroupNodeModel();
        node.addInPort("input");
        node.addOutPort("output");
        break;
      case "Sample":
        node = new SampleNodeModel();
        node.addInPort("input");
        node.addOutPort("output");

        break;
      case "Unique":
        node = new UniqueNodeModel();
        node.addInPort("input");
        node.addOutPort("unique");
        node.addOutPort("duplicate");
        break;
      case "Peek":
        node = new PeekNodeModel();
        node.addInPort("input");
        break;
      case "Output":
        node = new OutputNodeModel();
        node.addInPort("input");
        node.addOutPort("output");
        break;
    }
    // let points = this.props.app
    //   .getDiagramEngine()
    //   .getRelativeMousePoint(event);
    if (node != null) {
      node.id = tool.id;

      if (tool.properties) {
        node.x = tool.properties.x;
        node.y = tool.properties.y;

        node.properties = tool.properties;
        node.properties["application_id"] = this.props.campaignData.workflowId;
        node.properties["application_name"] = this.props.campaignData.name;
        node.properties["real_application_id"] = this.props.appid;
      }

      let that = vm;

      node.addListener({
        selectionChanged: function (e) {
          console.log("=============SELECTION HAS CHANGED=============");
          console.log(e.entity);

          // Do something here
          if (e.isSelected) {
            let selected_node = {
              id: e.entity.id,
              nodeType: e.entity.type,
              properties: node.properties,
            };

            e.entity.properties.x = e.entity.x;
            e.entity.properties.y = e.entity.y;

            that.setState({
              selected_node: selected_node,
            });

            console.log(selected_node);

            // that.props.onChangeSelectedNode(selected_node);

            // switch(e.entity.type){
            //     case "Formula":
            //         break
            //     case "Filter":
            //         break
            //     case "File":
            //         break
            //     case "Union":
            //         break
            // }
          } else {
            that.setState({
              selected_node: null,
            });
            // that.props.onChangeSelectedNode(null);
          }
        },
        entityRemoved: function (e) {
          // Do something here
          console.log("nODE REMOVED");

          console.log(e);

          let data = that.state.nodes.filter((node) => node.id != e.entity.id);

          that.setState({
            nodes: _.uniqWith(data, _.isEqual),
            selected_node: null,
            // connections: Lodash.uniq(,'stamp')
          });

          //   that.props.onAddNodes(_.uniqWith(data, _.isEqual));
          //   // that.props.onAddLinks([])
          //   that.props.onChangeSelectedNode(null);

          console.log(data);
        },
      });
      // that.props.app.diagramEngine.getDiagramModel().addNode(node);
      vm.appEngine.getDiagramEngine().getDiagramModel().addNode(node);
    }
  }

  renderPreview() {
    let res = this.state.display_results;

    if (res == null || res == []) {
      return (
        <Table hover className="align-items-center text-center" responsive>
          <thead className="thead-light">
            <tr>
              <th scope="col">No results to display</th>
            </tr>
          </thead>
          {/* <tbody>{previewValues}</tbody> */}
        </Table>
      );
    } else {
      // return (
      // <Jexcel options={this.props.options} />);

      const previewValues = res.map((res, i) => {
        const items = [];

        // for (var key in res) {
        //   if (res.hasOwnProperty(key)) {
        //     items.push(<td key={key}>{JSON.stringify(res[key])}</td>);
        //   }
        // }

        // return <tr key={i}>{items}</tr>;

        for (var key in res) {
          if (res.hasOwnProperty(key)) {
            items.push(res[key]);
          }
        }

        // return <tr key={i}>{items}</tr>;
        return items;
      });
      // console.log("selected node render");
      // console.log(state);

      if (this.state.display_results.length > 0) {
        let items = [];
        // let objKeys = Object.keys(state.display_results[0]);
        // objKeys.forEach((el, i) => {
        //   items.push(<th key={i}>{el}</th>);
        // });

        let objKeys = Object.keys(this.state.display_results[0]);
        objKeys.forEach((el, i) => {
          items.push(el);
        });

        return (
          <DisplayResultsTable columns={items} rows={previewValues} />
          // <Table hover className="align-items-center text-center" responsive>
          //   <thead className="thead-light">
          //     <tr>{items}</tr>
          //   </thead>
          //   <tbody>{previewValues}</tbody>
          // </Table>
        );
      } else {
        return (
          <Table hover className="align-items-center text-center" responsive>
            <thead className="thead-light">
              <tr>
                <th scope="col">No results to display</th>
              </tr>
            </thead>
            {/* <tbody>{previewValues}</tbody> */}
          </Table>
        );
      }

      // console.log(state.selected_node);
    }
  }
  deleteSelectedLink() {
    if (this.state.link_selected) {
      const model = this.appEngine.getDiagramEngine().getDiagramModel();

      const listLink = Object.values(model.getLinks());

      console.log(listLink);

      listLink.forEach((link) => {
        if (link.selected) {
          link.remove();
          this.setState({ link_selected: false });
          this.forceUpdate();
        }
      });
    }
  }

  removeDiagramNode() {
    if (this.state.selected_node) {
      this.appEngine.diagramEngine
        .getDiagramModel()
        .getNode(this.state.selected_node.id)
        .remove();

      //   this.onSaveWorkflow(); //TODO Edit here
    }
  }

  zoomInOut(action) {
    // console.log("I AM HERE SAVING THE WORKFLOW");
    let level = this.state.zoomLevel;

    if (action == "+") {
      if (this.state.zoomLevel == 100) {
        this.setState({ zoomLevel: 100 });
      } else {
        this.setState({ zoomLevel: level + 5 });
      }
    }

    if (action == "-") {
      if (this.state.zoomLevel <= 25) {
        this.setState({ zoomLevel: 25 });
      } else {
        this.setState({ zoomLevel: level - 5 });
      }
    }

    // this.appEngine.diagramEngine.setZoomLevel(25)
    this.appEngine.diagramEngine
      .getDiagramModel()
      .setZoomLevel(this.state.zoomLevel);

    this.forceUpdate();
  }

  toggleStyle = () => {
    console.log("Check style");
    if (this.state.styleCondition == "true") {
      this.setState({ styleCondition: "false" });
    }
    if (this.state.styleCondition == "false") {
      this.setState({ styleCondition: "true" });
    }
  };

  updateNodeForEx(node) {
    console.log("Sent from Child");
    let currNode = this.appEngine.diagramEngine
      .getDiagramModel()
      .getNode(node.id);

    let selected_node = {
      id: currNode.id,
      nodeType: currNode.type,
      properties: currNode.properties,
    };

    // if(selected_node..hasOwnProperty('outputData')){
    //   selected_node.properties.outputData = []
    // }
    this.setState({
      selected_node: selected_node,
    });
    // this.props.onChangeSelectedNode(selected_node);

    let tempArrOfNodes = this.state.nodes;

    //Find index of specific object using findIndex method.
    let objIndex = tempArrOfNodes.findIndex((obj) => obj.id == node.id);

    //Log object to Console.
    console.log("Before update Issues: ", tempArrOfNodes[objIndex]);

    //Update object's name property.
    tempArrOfNodes[objIndex].properties = currNode.properties;

    console.log("After update Issues: ", tempArrOfNodes[objIndex]);

    // this.props.onChangeSelectedNode(null);
    this.setState({
      selected_node: null,
    });

    // console.log("Compare SOMORE");
    // console.log(tempArrOfNodes);
    // console.log( this.props.nodes);

    // console.log(this.appEngine.diagramEngine.getDiagramModel().nodes);
  }

  saveEnvVariables(env_vars) {
    let current_w = { ...this.state.currentWorkflow, env_variables: env_vars };
    this.setState({ currentWorkflow: current_w });
  }
  updateNode(node) {
    console.log("Sent from Child");
    console.log(this.state.selected_node);
    let currNode = this.appEngine.diagramEngine
      .getDiagramModel()
      .getNode(this.state.selected_node.id);

    let selected_node = {
      id: currNode.id,
      nodeType: currNode.type,
      properties: currNode.properties,
    };
    this.setState({
      selected_node: selected_node,
    });
    // this.props.onChangeSelectedNode(selected_node);

    let tempArrOfNodes = this.state.nodes;

    //Find index of specific object using findIndex method.
    let objIndex = tempArrOfNodes.findIndex(
      (obj) => obj.id == selected_node.id
    );

    //Log object to Console.
    console.log("Before update: ", tempArrOfNodes[objIndex]);

    //Update object's name property.
    tempArrOfNodes[objIndex].properties = currNode.properties;
  }

  getCircularReplacer = () => {
    const seen = new WeakSet();
    return (key, value) => {
      if (typeof value === "object" && value !== null) {
        if (seen.has(value)) {
          return;
        }
        seen.add(value);
      }
      return value;
    };
  };

  validateConnectors(node) {
    let run = 0;
    let workingNode = this.appEngine.diagramEngine
      .getDiagramModel()
      .getNode(node.id);

    console.log(node);

    switch (node.nodeType) {
      case "File":
        if (node.properties.file == null) {
          run = 1;
          workingNode.properties.message = "Missing input File";
          workingNode.properties.messages = ["Missing input File"];
          console.log("AHA");
        } else {
          workingNode.properties.message = "Its Okey Dokey";
          workingNode.properties.messages = [];
        }
        return run;
      case "Entity":
        if (node.properties.entity.title == "Default") {
          run = 1;
          workingNode.properties.message = "Please select Entity";
          workingNode.properties.messages = ["Please select an entity"];
        } else {
          workingNode.properties.message = "Its Also Okey Dokey";
          workingNode.properties.messages = [];
        }
        return run;
      // case "SMS":
      //   if (node.properties.entity.title == "Please select a Phone") {
      //     run = 1;
      //     workingNode.properties.message = "Please select a Phone Column";
      //     workingNode.properties.messages = ["Please select an entity"];
      //   } else {
      //     workingNode.properties.message = "Its Also Okey Dokey";
      //     workingNode.properties.messages = [];
      //   }
      //   return run;
      case "Formula":
        if (node.properties.message == "Formula Tool") {
          run = 1;
          workingNode.properties.message = "Please Enter a formula Expression";
          workingNode.properties.messages = [
            "Please Enter a formula Expression",
          ];
        } else if (node.properties.message == "Invalid Syntax") {
          run = 1;
          workingNode.properties.message = "Error compiling formula expression";
          workingNode.properties.messages = [
            "Error compiling formula expression",
          ];
        } else {
          workingNode.properties.message = "Its Also Okey Dokey";
          workingNode.properties.messages = [];
        }
        return run;
    }
    return run;
  }

  worker() {
    let that = this;

    Axios.get(
      "https://api.bunicom.com///progress?task_id=" + this.state.task_id
    )
      .then((response) => {
        let payloadData = response.data;

        console.log("Payload");

        if (payloadData.data.length > 0) {
          var fillpayload = new Promise((resolve, reject) => {
            payloadData.data.forEach((node) => {
              if (node.id !== "genesis") {
                let workingNode = that.appEngine.diagramEngine
                  .getDiagramModel()
                  .getNode(node.id);
                workingNode.properties = node.properties;
              }
            });
          });

          fillpayload.then(() => {
            console.log("Finished loading");

            setTimeout(() => {
              that.setState({
                runningState: "run",
                fetch_status: true,
                running_node: "",
              });
            }, 2000);
          });
        } else {
          if (that.state.fetch_status == false) {
            that.setState({ running_node: payloadData.node });
            setTimeout(that.worker(), 2000);
          }
        }
      })
      .catch((e) => {
        console.log(e);
      });
  }
  //Rn workflow here

  refreshDiagramData(payloadData) {
    if (payloadData) {
      try {
        var fillpayload = new Promise((resolve, reject) => {
          console.log(payloadData);
          console.log("Whaers the data");
          payloadData.forEach((node) => {
            if (node.id !== "genesis") {
              let workingNode = this.appEngine.diagramEngine
                .getDiagramModel()
                .getNode(node.id);
              if (workingNode) {
                workingNode.properties = node.properties;
                this.updateNodeForEx(workingNode);
              }
              // this.updateNode(workingNode);
            }
          });
        });

        fillpayload.then(() => {
          console.log("Finished loading");

          setTimeout(() => {
            this.setState({
              runningState: "run",
              fetch_status: true,
              running_node: "",
            });
          }, 2000);
        });
      } catch (error) {
        this.state.workflow_timer.forEach((timer) => {
          clearInterval(timer);
        });
        this.clearTimers();
      }
    }
  }

  clearTimers = () => {
    this.setState({ workflow_timer: [] });
  };
  setWorkflowTimer = (timer) => {
    this.setState({ workflow_timer: [...this.state.workflow_timer, timer] });
  };

  runWorkflow = (action, run_mode) => {
    // e.preventDefault();

    // let links = Object.keys(this.appEngine.diagramEngine.getDiagramModel().links);
    let that = this;
    console.log("Compare");
    console.log(this.state.nodes);
    console.log(this.appEngine.diagramEngine.getDiagramModel().nodes);
    let inputNodes = this.state.nodes.filter(
      (node) => node.properties.type == "single"
    );
    let cnt = 1;
    let newConns = [];
    let runWorkflow = true;
    let error_count = 0;

    inputNodes.forEach((node) => {
      let newConnection = {
        stamp: "genesis" + "" + node.id,
        link_id: "link" + cnt++,
        source: {
          nodeType: "Start",
          port: "output",
          nodeID: "genesis",
          properties: { input: [], outputData: [] },
        },
        target: {
          // nodeType: node.properties.name,
          nodeType: node.nodeType,
          port: "input",
          nodeID: node.id,
          properties: node.properties,
        },
      };

      console.log("Focus on me");
      console.log(node);

      error_count += this.validateConnectors(node);

      newConns.push(newConnection);
      // let data = that.state.connections.concat(newConnection);
    });

    if (that.state.selected_node !== null) {
      let currNode = this.appEngine.diagramEngine
        .getDiagramModel()
        .getNode(this.state.selected_node.id);
      currNode.selected = false;
      // that.state.onChangeSelectedNode(null);
      that.setState({
        selected_node: null,
      });
    }

    this.state.nodes.forEach((node) => {
      error_count += this.validateConnectors(node);
      // let data = that.state.connections.concat(newConnection);
    });

    console.log("Run Results : " + error_count);

    if (error_count > 0) {
      runWorkflow = false;
    }

    if (runWorkflow && this.state.nodes.length > 0) {
      let newNode = {
        nodeType: "Start",
        id: "genesis",
        nodeID: "genesis",
        properties: { input: [], outputData: [] },
      };
      // Toolkit.UID()
      let payloadNode = [...this.state.nodes, newNode];
      let legacyConnections = [...this.state.connections];
      let payloadConnections = legacyConnections.concat(newConns);

      console.log("====================Current Workflow==================");

      console.log(this.state.currentWorkflow);

      let payload = {
        name: this.state.currentWorkflow.name,
        _id: this.state.currentWorkflow._id,
        collection_id: this.state.currentWorkflow.collection_id,
        application_id: this.state.currentWorkflow.application_id,
        collection_name: this.state.currentWorkflow.collection_name,
        description: this.state.currentWorkflow.description,
        links: payloadConnections,
        nodes: payloadNode,
        // env_variables: this.state.env_variables,
        env_variables: this.state.currentWorkflow.env_variables,
        mode: run_mode,
      };

      if (action == "execute") {
        this.setState({ runningState: "running" });

        console.log("YADA YADA YOU");

        console.log(payload);

        Axios.post("https://api.bunicom.com///execute", payload)
          .then((response) => {
            console.log(that.state.workflow_timer);
            let payloadData = response.data;

            console.log(payloadData);

            // that.refreshDiagramData(payloadData.data);
            that.setState({ runningState: "run", running_node: "" });
            const checkStatus = () => {
              Axios.get(
                "https://api.bunicom.com/status/" + response.data.data
              ).then((resp) => {
                console.log(resp.data);

                if (resp.data.state != "PENDING") {
                  console.log("DONE");
                  let wfl_results = resp.data;

                  if (that.state.workflow_timer) {
                    that.state.workflow_timer.forEach((timer) => {
                      clearInterval(timer);
                    });
                    that.clearTimers();
                  }

                  if (wfl_results.state == "SUCCESS") {
                    that.refreshDiagramData(wfl_results.data.data);
                  }
                }
              });
            };

            that.setWorkflowTimer(setInterval(checkStatus, 2000));
          })
          .catch((e) => {
            that.state.workflow_timer.forEach((timer) => {
              clearInterval(timer);
            });
            that.clearTimers();

            console.log("YADA YADA");
          });

        this.appEngine.diagramEngine.repaintCanvas();
      }
      if (action == "save") {
        console.log("========SAVE WORKFLOW=================");
        console.log(payload);
        // let properties = payload.properties
        // let load = JSON.parse(JSON.stringify(payload, this.getCircularReplacer()))
        // let send_payload = {...load, properties: properties}

        // console.log(send_payload);

        Axios.post("https://api.bunicom.com///save-workflow", payload)
          .then((response) => {
            console.log(response.data);
          })
          .catch((e) => {
            console.log(e);
          });
      }
    } else {
      console.log("Failed, MISSING REQUIRED INFO");

      this.setState({
        runningState: "run",
      });

      this.appEngine.diagramEngine.repaintCanvas();
    }
  };

  saveFileConf = (e, node) => {
    e.preventDefault();

    console.log(node.properties);
    let currNode = this.appEngine.diagramEngine
      .getDiagramModel()
      .getNode(node.id);
    let that = this;

    let payload = {
      ...node.properties,
    };

    Axios.post("https://api.bunicom.com///inputconfigs", payload)
      .then((response) => {
        console.log(response.data);

        currNode.properties.metaData = response.data.metaData;
        currNode.properties.sample = response.data.preview_data;
        currNode.properties.outputData = response.data.preview_data;
        currNode.properties.message = currNode.properties.file;

        // let otherNodes = that.state.nodes.filter((node) =>  node.id != currNode.id)
        // let newNode =  {
        //   nodeType: currNode.type,
        //   id:  currNode.type,
        //   properties: currNode.properties,
        //   ports: JSON.parse(JSON.stringify(currNode.ports, that.getCircularReplacer())),
        //   annotation: "Plugin Initialized"
        // };

        // console.log('other Nodes');
        // console.log();

        // that.setState({
        //   nodes: otherNodes.concat(newNode)
        // })

        let selected_node = {
          id: currNode.id,
          nodeType: currNode.type,
          properties: currNode.properties,
        };
        that.setState({
          selected_node: selected_node,
        });
        // that.props.onChangeSelectedNode(selected_node);
      })
      .catch((e) => {
        console.log(e);
      });
  };
  handleChangeStartIndex(event) {
    let currNode = this.appEngine.diagramEngine
      .getDiagramModel()
      .getNode(this.state.selected_node.id);

    // currNode.properties.outputData = event.target.value.split(',')
    // currNode.properties.start_index = event.target.value
    currNode.properties.start_index = event.target.value;

    let selected_node = {
      id: currNode.id,
      nodeType: currNode.type,
      properties: currNode.properties,
    };
    this.setState({
      selected_node: selected_node,
    });
    // this.props.onChangeSelectedNode(selected_node);
  }
  handleChangeSelectedSheet(event) {
    let currNode = this.appEngine.diagramEngine
      .getDiagramModel()
      .getNode(this.state.selected_node.id);

    // currNode.properties.selected_sheet = event.target.value
    currNode.properties.selected_sheet = event.target.value;

    // this.props.nodes

    let selected_node = {
      id: currNode.id,
      nodeType: currNode.type,
      properties: currNode.properties,
    };
    this.setState({
      selected_node: selected_node,
    });
    // this.props.onChangeSelectedNode(selected_node);
    console.log();
  }

  InputFileWidget(node) {
    return (
      <div className="modal-body">
        <div className="text-left">
          {/* <i className="ni ni-bell-55 ni-3x" /> */}
          {/* <i className={ "fas fa-upload" } /> */}
          <h6 className="heading mt-1">Select Excel File</h6>
          <Form>
            <Row>
              <Col md="12">
                <FilePondComp
                  node={node}
                  model={this.appEngine.diagramEngine.getDiagramModel()}
                  updateNode={this.updateNode}
                  file_name={node.properties.file}
                />
                <Form>
                  {node.properties.sheets == null ? (
                    ""
                  ) : (
                    <Form.Group className="mb-3">
                      <Form.Label>Example select</Form.Label>
                      <Form.Select
                        value={
                          node.properties.selected_sheet == null ||
                          node.properties.selected_sheet == ""
                            ? "Default"
                            : node.properties.selected_sheet
                        }
                        onChange={this.handleChangeSelectedSheet}
                      >
                        <option value="Default">Default</option>
                        {node.properties.sheets.map((item, key) => (
                          <option key={key} value={item}>
                            {item}
                          </option>
                        ))}
                      </Form.Select>
                    </Form.Group>
                  )}

                  <Form.Group className="mb-3">
                    <Form.Label>Header Row</Form.Label>
                    <InputGroup>
                      <InputGroup.Text>
                        <FontAwesomeIcon icon={faSearch} />
                      </InputGroup.Text>
                      <Form.Control
                        type="number"
                        value={node.properties.start_index}
                        onChange={this.handleChangeStartIndex}
                      />
                    </InputGroup>
                  </Form.Group>
                </Form>
              </Col>
            </Row>
          </Form>
          {node.properties.selected_sheet == null ||
          node.properties.start_index == null ? (
            ""
          ) : (
            <Button
              variant="primary"
              size="sm"
              onClick={(e) => this.saveFileConf(e, node)}
            >
              Save
            </Button>
          )}
          {/* <p>{node.properties.headers.join(",")}</p> */}
          <p>{/* <span id="coderea">{ JSON.stringify(node) }</span> */}</p>
        </div>
      </div>
    );
  }

  renderProperties(node) {
    if (node) {
      switch (node.nodeType) {
        case "File":
          return this.InputFileWidget(node);

        case "Join":
          return (
            <JoinComponent
              node={node}
              model={this.appEngine.diagramEngine.getDiagramModel()}
              updateNode={this.updateNode}
              selected_node={this.state.selected_node}
              env_variables={
                this.state.currentWorkflow.hasOwnProperty("env_variables")
                  ? this.state.currentWorkflow.env_variables
                  : null
              }
            />
          );

        case "Sort":
          return (
            <SortComponent
              node={node}
              model={this.appEngine.diagramEngine.getDiagramModel()}
              updateNode={this.updateNode}
              selected_node={this.state.selected_node}
              env_variables={
                this.state.currentWorkflow.hasOwnProperty("env_variables")
                  ? this.state.currentWorkflow.env_variables
                  : null
              }
            />
          );
        case "Email":
          return (
            <EmailComponent
              node={node}
              model={this.appEngine.diagramEngine.getDiagramModel()}
              updateNode={this.updateNode}
              selected_node={this.state.selected_node}
              env_variables={
                this.state.currentWorkflow.hasOwnProperty("env_variables")
                  ? this.state.currentWorkflow.env_variables
                  : null
              }
            />
          );

        case "Filter":
          return (
            <FilterComponent
              node={node}
              model={this.appEngine.diagramEngine.getDiagramModel()}
              updateNode={this.updateNode}
              selected_node={this.state.selected_node}
              env_variables={
                this.state.currentWorkflow.hasOwnProperty("env_variables")
                  ? this.state.currentWorkflow.env_variables
                  : null
              }
            />
          );
        case "Group":
          return (
            <GroupComponent
              node={node}
              model={this.appEngine.diagramEngine.getDiagramModel()}
              updateNode={this.updateNode}
              selected_node={this.state.selected_node}
              env_variables={
                this.state.currentWorkflow.hasOwnProperty("env_variables")
                  ? this.state.currentWorkflow.env_variables
                  : null
              }
            />
          );

        case "Cleanup":
          return (
            <CleanupComponent
              node={node}
              model={this.appEngine.diagramEngine.getDiagramModel()}
              updateNode={this.updateNode}
              selected_node={this.state.selected_node}
              env_variables={
                this.state.currentWorkflow.hasOwnProperty("env_variables")
                  ? this.state.currentWorkflow.env_variables
                  : null
              }
            />
          );
        case "Select":
          return (
            <SelectNodeComponent
              node={node}
              model={this.appEngine.diagramEngine.getDiagramModel()}
              updateNode={this.updateNode}
              selected_node={this.state.selected_node}
              env_variables={
                this.state.currentWorkflow.hasOwnProperty("env_variables")
                  ? this.state.currentWorkflow.env_variables
                  : null
              }
            />
          );
        case "Unique":
          return (
            <UniqueNodeComponent
              node={node}
              model={this.appEngine.diagramEngine.getDiagramModel()}
              updateNode={this.updateNode}
              selected_node={this.state.selected_node}
              env_variables={
                this.state.currentWorkflow.hasOwnProperty("env_variables")
                  ? this.state.currentWorkflow.env_variables
                  : null
              }
            />
          );

        case "Entity":
          return (
            <EntityNodeComponent
              node={node}
              model={this.appEngine.diagramEngine.getDiagramModel()}
              selected_node={this.state.selected_node}
              updateNode={this.updateNode}
              env_variables={
                this.state.currentWorkflow.hasOwnProperty("env_variables")
                  ? this.state.currentWorkflow.env_variables
                  : null
              }
            />
          );

        case "Request":
          return (
            <RequestNodeComponent
              node={node}
              model={this.appEngine.diagramEngine.getDiagramModel()}
              selected_node={this.state.selected_node}
              updateNode={this.updateNode}
              env_variables={
                this.state.currentWorkflow.hasOwnProperty("env_variables")
                  ? this.state.currentWorkflow.env_variables
                  : null
              }
            />
          );

        case "DataEntry":
          return (
            <DataEntryNodeComponent
              node={node}
              model={this.appEngine.diagramEngine.getDiagramModel()}
              updateNode={this.updateNode}
              selected_node={this.state.selected_node}
              env_variables={
                this.state.currentWorkflow.hasOwnProperty("env_variables")
                  ? this.state.currentWorkflow.env_variables
                  : null
              }
            />
          );

        case "Form":
          return (
            <FormNodeComponent
              node={node}
              model={this.appEngine.diagramEngine.getDiagramModel()}
              updateNode={this.updateNode}
              selected_node={this.state.selected_node}
              env_variables={
                this.state.currentWorkflow.hasOwnProperty("env_variables")
                  ? this.state.currentWorkflow.env_variables
                  : null
              }
            />
          );

          case "Peek":
          return (
            <PeekToolComponent
              node={node}
              model={this.appEngine.diagramEngine.getDiagramModel()}
              updateNode={this.updateNode}
              selected_node={this.state.selected_node}
              env_variables={
                this.state.currentWorkflow.hasOwnProperty("env_variables")
                  ? this.state.currentWorkflow.env_variables
                  : null
              }
            />
          );

          
        case "Sheets":
          return (
            <SheetsNodeComponent
              node={node}
              model={this.appEngine.diagramEngine.getDiagramModel()}
              updateNode={this.updateNode}
              selected_node={this.state.selected_node}
              env_variables={
                this.state.currentWorkflow.hasOwnProperty("env_variables")
                  ? this.state.currentWorkflow.env_variables
                  : null
              }
            />
          );

        case "Formula":
          return (
            <FormulaNodeComponent
              node={node}
              model={this.appEngine.diagramEngine.getDiagramModel()}
              updateNode={this.updateNode}
              selected_node={this.state.selected_node}
              env_variables={
                this.state.currentWorkflow.hasOwnProperty("env_variables")
                  ? this.state.currentWorkflow.env_variables
                  : null
              }
            />
          );
        case "Output":
          return (
            <OutputComponent
              node={node}
              model={this.appEngine.diagramEngine.getDiagramModel()}
              updateNode={this.updateNode}
              selected_node={this.state.selected_node}
              env_variables={
                this.state.currentWorkflow.hasOwnProperty("env_variables")
                  ? this.state.currentWorkflow.env_variables
                  : null
              }
            />
          );
        case "SMS":
          return (
            <SMSNodeComponent
              node={node}
              model={this.appEngine.diagramEngine.getDiagramModel()}
              updateNode={this.updateNode}
              selected_node={this.state.selected_node}
              env_variables={
                this.state.currentWorkflow.hasOwnProperty("env_variables")
                  ? this.state.currentWorkflow.env_variables
                  : null
              }
            />
          );
      }
    } else {
      return <p>Workflow</p>;
    }
  }

  renderWorkflowCanvas() {
    return (
      <div className="card-body p-0">
        <div className="row text-center">
          <div className="col-md-12 mb-2">
            <div className="zoom-buttons">
              <button
                type="button"
                className="btn btn-danger btn-secondary float-left mx-2"
                onClick={() => this.zoomInOut("+")}
              >
                <span className="text-white">
                  <i className="fa fa-plus"></i>
                </span>
              </button>

              <button
                type="button"
                className="btn btn-primary float-left mx-1"
                onClick={() => this.zoomInOut("-")}
              >
                <span className="text-white">
                  <i className="fa fa-minus"></i>
                </span>
              </button>
              {/* { JSON.stringify(this.state.nodes, this.getCircularReplacer())} */}
            </div>
            <div className="body">
              <div className="">
                {this.state.runningState == "run" ? (
                  ""
                ) : (
                  <div className="loading-canvas">
                    <div className="container-fluid pt-5">
                      <div className="row justify-content-center">
                        <div className="col-lg-3 card-wrapper">
                          <span className=" text-dark ">
                            <i className="fa fa-history mx-1"></i> Executing
                            Workflow
                          </span>
                          {/* <ProgressBar
                            variant="info"
                            animated
                            now={100}
                          /> */}
                          <Lottie
                            style={{ width: "150px" }}
                            options={{
                              animationData: animation,
                            }}
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                )}
 {/* <div className="config-canvas" onClick={(e) => this.toggleStyle()}>
              <p className="text-white c-text">Config</p>

              <a className="mobile-menu" id="mobile-collapse">
                <span></span>
              </a>
            </div> */}
                <div
                  className="diagram-layer"
                  onDrop={(event) => {
                    let data = JSON.parse(
                      event.dataTransfer.getData("storm-diagram-node")
                    );

                    let node = null;
                    switch (data.type) {
                      case "File":
                        node = new FileNodeModel();
                        node.addOutPort("output");
                        break;

                      case "Entity":
                        node = new EntityNodeModel();
                        node.addOutPort("output");
                        break;

                      case "Request":
                        node = new RequestNodeModel();
                        node.addOutPort("output");
                        break;

                      case "DataEntry":
                        node = new DataEntryNodeModel();
                        node.addOutPort("output");
                        break;
                      case "Form":
                        node = new FormNodeModel();
                        node.addOutPort("output");
                        break;
                      case "Sheets":
                        node = new SheetsNodeModel();
                        node.addOutPort("output");
                        break;
                      case "Filter":
                        node = new FilterNodeModel();
                        node.addInPort("input");
                        node.addOutPort("true");
                        node.addOutPort("false");
                        break;
                      case "Join":
                        node = new JoinNodeModel();
                        // node.addInPort("input");
                        node.addInPort("left");
                        node.addInPort("right");

                        node.addOutPort("ljoin");
                        node.addOutPort("ijoin");
                        node.addOutPort("rjoin");
                        break;
                      case "Formula":
                        node = new FormulaNodeModel();
                        node.addInPort("input");
                        node.addOutPort("output");
                        break;
                      case "Union":
                        node = new UnionNodeModel();
                        node.addInPort("input");
                        node.addOutPort("output");
                        break;
                      case "Select":
                        node = new SelectNodeModel();
                        node.addInPort("input");
                        node.addOutPort("output");
                        break;
                      case "SMS":
                        node = new SMSNodeModel();
                        node.addInPort("input");
                        node.addOutPort("output");

                        break;

                      case "Sort":
                        node = new SortNodeModel();
                        node.addInPort("input");
                        node.addOutPort("output");
                        break;

                      case "Email":
                        node = new EmailNodeModel();
                        node.addInPort("input");
                        node.addOutPort("output");
                        break;

                      // EmailNodeModel
                      case "Replace":
                        node = new ReplaceNodeModel();
                        node.addInPort("input");
                        node.addOutPort("output");
                        break;
                      case "TextToColumn":
                        node = new TextToColumnNodeModel();
                        node.addInPort("input");
                        node.addOutPort("output");
                        break;

                      case "Cleanup":
                        node = new CleanupNodeModel();
                        node.addInPort("input");
                        node.addOutPort("output");
                        break;
                      case "Group":
                        node = new GroupNodeModel();
                        node.addInPort("input");
                        node.addOutPort("output");
                        break;
                      case "Sample":
                        node = new SampleNodeModel();
                        node.addInPort("input");
                        node.addOutPort("output");
                        break;
                      case "Unique":
                        node = new UniqueNodeModel();
                        node.addInPort("input");
                        node.addOutPort("unique");
                        node.addOutPort("duplicate");
                        break;
                      case "Peek":
                        node = new PeekNodeModel();
                        node.addInPort("input");
                        break;
                      case "Output":
                        node = new OutputNodeModel();
                        node.addInPort("input");
                        node.addOutPort("output");
                        break;
                    }

                    let points = this.appEngine
                      .getDiagramEngine()
                      .getRelativeMousePoint(event);
                    node.x = points.x;
                    node.y = points.y;

                    node.properties.x = points.x;
                    node.properties.y = points.y;

                    node.properties["application_id"] =
                      this.props.campaignData.workflowId;
                    node.properties["application_name"] =
                      this.props.campaignData.name;
                    node.properties["real_application_id"] = this.props.appid;
                    // node.nodeType = node.type;

                    let that = this;

                    node.addListener({
                      selectionChanged: function (e) {
                        // Do something here
                        if (e.isSelected) {
                          let selected_node = {
                            id: e.entity.id,
                            nodeType: e.entity.type,
                            properties: e.entity.properties,
                          };

                          e.entity.properties.x = e.entity.x;
                          e.entity.properties.y = e.entity.y;

                          that.setState({
                            selected_node: selected_node,
                          });
                          console.log(selected_node);

                          //    that.props.onChangeSelectedNode(selected_node);

                          // switch(e.entity.type){
                          //     case "Formula":
                          //         break
                          //     case "Filter":
                          //         break
                          //     case "File":
                          //         break
                          //     case "Union":
                          //         break
                          // }
                        } else {
                          that.setState({
                            selected_node: null,
                          });

                          //    that.props.onChangeSelectedNode(null);
                        }
                      },
                      entityRemoved: function (e) {
                        // Do something here
                        console.log("nODE REMOVED");

                        console.log(e);

                        let data = that.state.nodes.filter(
                          (node) => node.id != e.entity.id
                        );

                        that.setState({
                          nodes: _.uniqWith(data, _.isEqual),
                          selected_node: null,
                          // connections: Lodash.uniq(,'stamp')
                        });

                        //  that.props.onAddNodes(_.uniqWith(data, _.isEqual));
                        // that.props.onAddLinks([])
                        //  that.props.onChangeSelectedNode(null);
                        //  console.log(data);
                      },
                    });
                    this.appEngine
                      .getDiagramEngine()
                      .getDiagramModel()
                      .addNode(node);

                    let newNode = {
                      ...node,
                      nodeType: node.type,
                      properties: JSON.parse(
                        JSON.stringify(
                          node.properties,
                          that.getCircularReplacer()
                        )
                      ),
                      ports: JSON.parse(
                        JSON.stringify(node.ports, that.getCircularReplacer())
                      ),
                      annotation: "Plugin Initialized",
                    };

                    this.setState({
                      nodes: [...this.state.nodes, newNode],
                      selected_node: node,
                      // connections: Lodash.uniq(,'stamp')
                    });
                    this.forceUpdate();
                  }}
                  onDragOver={(event) => {
                    event.preventDefault();
                  }}
                >
                  {/* smartRouting={true}  */}
                  <DiagramWidget
                    onPopState={(event) => {
                      // event.preventDefault();
                      console.log("pop stated");
                    }}
                    className="srd-demo-canvas"
                    deleteKeys={[]}
                    allowCanvasZoom={true}
                    diagramEngine={this.appEngine.getDiagramEngine()}
                    allowLooseLinks={false}
                    maxNumberPointsPerLink="0"
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }

  renderResultsPane() {
    return (
      <div className="card-body p-0" id="preview-datatable">
        {this.renderPreview(this.props)}
      </div>
    );
  }
  renderPalletePane() {
    return (
      <div className="card-body py-0 tab-content2">
        <BuniPallette />
      </div>
    );
  }

  renderHeaderPane() {
    return (
      <div className="card-header">
        <div className="row align-items-center">
          <div className="col">
            <h6 className="text-light ls-1 mb-1">
              Workflow powered by Buniflow<sup>TM</sup>
            </h6>
            {/* {JSON.stringify(this.props.campaignData)} */}
            <h5 className="mb-0">
              {this.props.campaignData
                ? this.props.campaignData.name
                : "Untitled"}
            </h5>
          </div>
          <div className="col">
            {/* {JSON.stringify(this.state.selectedLink)} */}
            {/* {JSON.stringify(this.props.showConfigDialog)} */}
            <button
                type="button"
                className="btn btn-primary me-2"
                onClick={(e) => this.toggleStyle()}
              >
                <span className="text-white me-2">
                  <i className="fa fa-palette"></i>
                </span>
                {this.state.styleCondition == "false" ? (
              "Open"
            ) : ("Close") } Configuration Pane
                
              </button>

            {this.state.link_selected ? (
              <button
                type="button"
                className="btn btn-danger"
                onClick={() => this.deleteSelectedLink()}
              >
                Delete selected link
                <span className="text-white ml-2">
                  <i className="fa fa-trash"></i>
                </span>
              </button>
            ) : (
              ""
            )}

            {this.state.selected_node ? (
              <button
                type="button"
                className="btn btn-danger"
                onClick={() => this.removeDiagramNode()}
                disabled={this.state.selected_node == null}
                // onClick={() => this.state.onCollectionDialogClose()}
              >
                <span className="text-white mr-2">
                  <i className="fa fa-trash"></i>
                </span>
                Delete {this.state.selected_node.properties.name} Node
              </button>
            ) : (
              ""
            )}
          </div>

          <div className="col">
            <div className="justify-content-end float-end">
              {this.state.runningState == "running" ? (
                <Button
                  variant="danger"
                  className="float-end m-1"
                  onClick={() => this.setState({ runningState: "run" })}
                >
                  <FontAwesomeIcon icon={faStop} className="me-2" />
                  Stop
                </Button>
              ) : (
                ""
              )}
              <Button
                variant="info"
                className="float-end m-1"
                onClick={(e) => this.runWorkflow("execute", "ex")}
              >
                <FontAwesomeIcon icon={faPlay} className="me-2" />
                {this.state.runningState == "run"
                  ? "Run"
                  : "Running Campaign" + this.state.running_node}
              </Button>

              <Button
                variant="success"
                className="float-end m-1"
                onClick={(e) => this.runWorkflow("save", "sv")}
              >
                <FontAwesomeIcon icon={faSave} className="me-2" />
                {this.state.runningState == "run" ? "Save" : "Saving Campaign"}
              </Button>
            </div>
          </div>
        </div>
      </div>
    );
  }
  render() {
    return (
      <div>
        <Row>
          <div className="col-md-12">
            <Button
              variant="success"
              className="m-1"
              onClick={() =>
                history.push("/org/" + this.props.appid + "/importfile")
              }
            >
              <FontAwesomeIcon icon={faTable} className="me-2" />
              Import Data from Excel/CSV
            </Button>
          </div>
          <div className="col-md-12">
            <div className="card mb-0">
              {this.renderHeaderPane()}
              {this.renderPalletePane()}
              {this.renderWorkflowCanvas()}
              {this.renderResultsPane()}
            </div>
          </div>
        </Row>
        <Row></Row>
        <Row className="p-3 ">
          <div>
            <div className="config-toggle" onClick={(e) => this.toggleStyle()}>
              <p className="text-white c-text"><small>{this.state.styleCondition == "false" ? (
              "Open"
            ) : ("Close") } Configuration Pane</small></p>

              <a className="mobile-menu" id="mobile-collapse">
                <span></span>
              </a>
            </div>
            {/* this.props.selected_node == null */}

            {this.state.styleCondition == "false" ? (
              ""
            ) : (
              <div className="card c-panel config-pane" style={{ zoom: "80%"}}>
                {/* <div className="card-header">
                  <div className="row align-items-center">
                    <div className="col">
                      <h6 className="text-light text-uppercase ls-1 mb-1">
                        Properties
                      </h6>
                      <h5 className="h3 mb-0">
                        {this.state.selected_node == null
                          ? "Workspace"
                          : this.state.selected_node.properties.name}
                      </h5>
                    </div>

                    <div className="col">
                      <div className="justify-content-end"></div>
                    </div>
                  </div>
                </div> */}
                <div className="card-body p-0">
                  <Tabs defaultActiveKey="config" id="uncontrolled-tab-example">
                    <Tab eventKey="config" title="Widget config">
                      <span className="d-block m-t-5">
                        Showing{" "}
                        <code>
                          {this.state.selected_node == null
                            ? "Workspace"
                            : this.state.selected_node.properties.name}{" "}
                        </code>{" "}
                        Properties
                      </span>

                      {/* {tabContent} */}

                      {/* { (this.props.selected_node != null && this.props.selected_node.properties.hasOwnProperty('metaData')) ? JSON.stringify(this.props.selected_node.properties.metaData) : 'Nada Nada' } */}

                      <div>
                        {this.renderProperties(this.state.selected_node)}
                      </div>
                    </Tab>
                    <Tab eventKey="vars" title="Developer">
                      <Row>
                        <Col md={12}>
                          <h5>Environment Variables</h5>
                          <EnvTable
                            env_variables={
                              this.state.currentWorkflow.hasOwnProperty(
                                "env_variables"
                              )
                                ? this.state.currentWorkflow.env_variables
                                : []
                            }
                            onSaveVars={this.saveEnvVariables}
                          />
                          {/* { this.state.currentWorkflow.hasOwnProperty('env_variables') ? JSON.stringify(this.state.currentWorkflow.env_variables) : 'Nada Nada' } */}
                        </Col>
                        <Col md={12}>
                          <h5>GENERATE CODE SNIPPETS</h5>

                          <div>
                            <b>Generated code for cURL</b>
                            <br />
                            <Editor
                              value={`
curl --location --request POST 'https://api.bunicom.com/execute' \n
--header 'Content-Type: application/json' \n
--data-raw '{
"_id":"${this.state.workflow._id}",
"mode":"api",
"env_variables":${
                                this.state.currentWorkflow.hasOwnProperty(
                                  "env_variables"
                                )
                                  ? JSON.stringify(
                                      this.state.currentWorkflow.env_variables
                                    )
                                  : "[]"
                              }
}'
                                `}
                              // theme={theme}
                              // highlight={(code) => highlight(code, languages.js)}
                              // highlight={code => highlight(code, languages.markup, 'markup')}
                              highlight={this.highlight}
                              padding={10}
                              style={styles.root}
                            />
                          </div>
                        </Col>
                      </Row>
                    </Tab>
                  </Tabs>
                </div>
              </div>
            )}
          </div>
        </Row>
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    showConfigDialog: state.showConfigDialog,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    showToolProperties: (flag) =>
      dispatch({ type: "SHOW_TOOL_PROPERTIES", flag: flag }),
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withRouter(Playground));
