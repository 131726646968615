import React from "react";
import ReactDOM from "react-dom";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";

import Axios from "axios";
import * as actionTypes from "./../../../store/actions";

import { Row, Col, Card, Button, Form } from "react-bootstrap";

class SheetsNodeComponent extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      // Set initial files, type 'local' means this is a file
      // that has already been uploaded to the server (see docs)
      node: null,
    };

    this.handleChangeSheetName = this.handleChangeSheetName.bind(this);
  }
  handleAnnotationChange(event, label) {
    const target = event.target;
    const value = target.value;
    const name = target.name;

    console.log(label);
    console.log(value);
    console.log(name);

    let node = this.props.node;
    let model = this.props.model;
    let currNode = model.getNode(node.id);

    currNode.properties[name] = value;

    this.props.updateNode();
    this.forceUpdate();
  }
  handleChangeSheetName(event) {
    console.log();

    console.log(event.target.value);
    let node = this.props.node;
    let model = this.props.model;
    let currNode = model.getNode(node.id);

    currNode.properties.sheetConfigs.sheet_name = event.target.value;

    this.props.updateNode();
  }
  componentDidMount = function () {
    console.log("Mounted");
    console.log(this.props.selected_node);
  };

  render() {
    return (
      <div className="modal-body">
        <div className="text-left">
          <h6 className="heading mt-1">Google Sheets Configs</h6>
          <Form>
            <Row>
              <Col md={12}>
                <p>
                  <b>Step 1: </b>
                  <br />
                  <p>
                    <span id="coderea">
                      To use Google sheets, share your sheet with{" "}
                      <b>butterfly@butterfly-296219.iam.gserviceaccount.com</b>
                    </span>
                  </p>
                  <b>Step 2: </b>
                  <span id="coderea">
                    use the key from the spreadsheet’s url
                  </span>
                  <br /> You can get it here:
                  <span>
                    https://docs.google.com/spreadsheets/d/
                    <span style={{ backgroundColor: "yellow" }}>
                      <b> {"{" + "Your_SheetID_is_here" + "}"}</b>
                    </span>
                    /edit#gid=XXXXXXX
                  </span>
                </p>

                <Form.Group controlId="exampleForm.ControlSelect1">
                  <Form.Label>Enter Google Sheet ID</Form.Label>
                  <Form.Control
                    type="text"
                    value={
                      this.props.selected_node.properties.sheetConfigs
                        .sheet_name
                    }
                    placeholder="Google Sheet ID"
                    className="mb-3"
                    onChange={this.handleChangeSheetName}
                  />
                </Form.Group>
              </Col>
              <Col md={12}>
                <Form.Group controlId="exampleForm.ControlTextarea1">
                  <Form.Label>Comment</Form.Label>
                  <Form.Control
                    as="textarea"
                    rows="3"
                    name="annotation"
                    value={
                      this.props.selected_node["properties"].hasOwnProperty(
                        "annotation"
                      )
                        ? this.props.selected_node["properties"]["annotation"]
                        : ""
                    }
                    onChange={(e) => this.handleAnnotationChange(e, "password")}
                  />
                </Form.Group>
              </Col>
            </Row>
          </Form>
          <p>{/* {node.properties.headers.join(',')} */}</p>
        </div>
      </div>
    );
  }
}
const mapStateToProps = (state) => {
  return {};
};

const mapDispatchToProps = (dispatch) => {
  return {};
};

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(SheetsNodeComponent)
);
