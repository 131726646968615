import React, { useState, useEffect } from "react";
import { Route, Switch, Redirect } from "react-router-dom";

import { Routes } from "../routes";

import { connect } from "react-redux";

import { compose } from "recompose";
// pages
// import Presentation from "./Presentation";
import DashboardOverview from "./himenu/MenuDashboard";
import Settings from "./himenu/pages/Settings";
import Account from "./himenu/index.js";
import Campaigns from "./himenu/pages/Campaigns";
import Campaign from "./himenu/pages/Campaigns/CampaignEditor";
import New from "./himenu/pages/New";
import Developers from "./himenu/pages/Developers";
import Reports from "./himenu/pages/Reports";

//SMS
import ActivityPage from "./himenu/pages/SMS/OpsTemplates";
import InboxPage from "./himenu/pages/SMS/Inbox";
import OptionsPage from "./himenu/pages/SMS/Options";
import OutBoxPage from "./himenu/pages/SMS/Outbox";
import SendSMS from "./himenu/pages/SMS";
import MessagePage from "./himenu/pages/SMS/Message";

//Email

import Compose from "./himenu/pages/Email/Compose";
import SentPage from "./himenu/pages/Email/Sent";
import MailOptionsPage from "./himenu/pages/Email/Options";
import Templates from "./himenu/pages/Templates";

import OrderPage from "./himenu/pages/Topup/OrderPage";
import PayPage from "./himenu/pages/Topup/PaymentPage";
import Topup from "./himenu/pages/Topup";
// import SMS from "./himenu/pages/Outbox/SMS";
import NewTemplate from "./himenu/pages/Templates/NewTemplate";

import DataStores from "./himenu/pages/DataStores";
import Stores from "./himenu/pages/TablePage";
import Import from "./himenu/pages/BulkUpload";

import Signin from "./himenu/SignIn";
import Signup from "./himenu/Signup";
import ForgotPassword from "./himenu/ForgotPassword";
import ResetPassword from "./himenu/ResetPassword";
import Lock from "../pages/examples/Lock";
import NotFoundPage from "../pages/examples/NotFound";
import ServerError from "../pages/examples/ServerError";

// components
import StoreSideBar from "./StoreSideBar";

import Navbar from "./Navbar";
import Footer from "./Footer";
import Preloader from "./Preloader";

import { withFirebase } from "./Firebase";
import { AuthUserContext } from "./Session";

const RouteWithLoader = ({ component: Component, ...rest }) => {
  const [loaded, setLoaded] = useState(false);

  useEffect(() => {
    const timer = setTimeout(() => setLoaded(true), 1000);
    console.log("TADAA");
    return () => clearTimeout(timer);
  }, []);

  return (
    <Route
      {...rest}
      render={(props) => (
        <AuthUserContext.Consumer>
          {(authUser) =>
            authUser == null ? (
              <Redirect
                to={{
                  pathname: "/signin",
                  state: { from: props.location },
                }}
              />
            ) : (
              <>
                <Preloader show={loaded ? false : true} />{" "}
                <Component {...props} />{" "}
              </>
            )
          }
        </AuthUserContext.Consumer>
      )}
    />
  );
};

const RouteWithSidebar = ({ component: Component, ...rest }) => {
  const [loaded, setLoaded] = useState(false);

  useEffect(() => {
    const timer = setTimeout(() => setLoaded(true), 1000);
    return () => clearTimeout(timer);
  }, []);

  const localStorageIsSettingsVisible = () => {
    return localStorage.getItem("settingsVisible") === "false" ? false : true;
  };

  const [showSettings, setShowSettings] = useState(
    localStorageIsSettingsVisible
  );

  const toggleSettings = () => {
    setShowSettings(!showSettings);
    localStorage.setItem("settingsVisible", !showSettings);
  };

  return (
    <Route
      {...rest}
      render={(props) => (
        // <>
        //   {rest.auth == null ? (
        //     <Redirect
        //       to={{
        //         pathname: "/",
        //         state: { from: props.location },
        //       }}
        //     />
        //   ) : (
        <>
          <Preloader show={loaded ? false : true} />
          <StoreSideBar {...props} />

          <main className="content">
            <Navbar {...props} />
            <Component {...props} />
            {/* <Footer toggleSettings={toggleSettings} showSettings={showSettings} /> */}
          </main>
        </>
        //   )}
        // </>
      )}
    />
  );
};

function HomePage(props) {
  const { setCredits } = props;

  const [authUser, setAuth] = useState(null);

  const [appCredits, setappCredits] = useState(10);

  // useEffect(() => {
  //   const unsubscribe = props.firebase.auth.onAuthStateChanged((authUser) => {
  //     if (authUser) {
  //       setAuth(authUser);
  //     } else {
  //       setAuth(null);
  //       //save null business account
  //     }
  //   });

  //   return () => {
  //     unsubscribe();
  //   };
  // }, []);

  useEffect(() => {
    const unsubscribe = props.firebase.auth.onAuthStateChanged((authUser) => {
      if (authUser) {
        setAuth(authUser);

        let current_bsa = props.firebase.db
          .collection("business_accounts")
          .where("uid", "==", authUser.uid)
          .onSnapshot((snapshot) => {
            let credits = 10;

            if (snapshot.size) {
              snapshot.forEach((doc) => {
                console.log("Document data:", doc.data());

                let appData = doc.data();
                credits = appData.credits;
                if (appData.hasOwnProperty("credits")) {
                  credits = appData.credits;
                }
              });
            }

            setCredits(credits);
          })
          .catch((error) => {
            console.log("Error getting documents: ", error);
          });

        // console.log(authUser);
        //Get business account and save details
      } else {
        // setAuth(null);
        //save null business account
      }
    });

    return () => {
      unsubscribe();
    };
  }, [props.firebase]);

  useEffect(() => {
    var docRef = props.firebase.db
      .collection("business_accounts")
      .where("uid", "==", "qbQvzRZf3kg2xObFEZIgdu5rnGj1")
      .get()
      .then((querySnapshot) => {})
      .catch((error) => {
        console.log("Error getting documents: ", error);
      });
  }, [props.firebase]);

  return (
    <Switch>
      {/* <RouteWithLoader exact path={Routes.Presentation.path} component={Presentation} /> */}
      <Route
        path={Routes.Signin.path}
        exact
        name="Sign In"
        render={(props) => (
          <AuthUserContext.Consumer>
            {(authUser) =>
              authUser != null ? (
                <Redirect
                  to={{
                    pathname: "/org/" + authUser.uid,
                    state: { from: props.location },
                  }}
                />
              ) : (
                <Signin {...props} />
              )
            }
          </AuthUserContext.Consumer>
        )}
      />

      <Route
        path={Routes.Signup.path}
        exact
        name="Sign Up"
        render={(props) => (
          <AuthUserContext.Consumer>
            {(authUser) =>
              authUser != null ? (
                <Redirect
                  to={{
                    pathname: "/org/" + authUser.uid,
                    state: { from: props.location },
                  }}
                />
              ) : (
                <Signup {...props} />
              )
            }
          </AuthUserContext.Consumer>
        )}
      />
      <Route exact path={Routes.Account.path} component={Account} />

      {/* <RouteWithLoader
        exact
        
        component={Signin}
        authUser={props.authUser}
      /> */}

      {/* pages */}
      <RouteWithSidebar
        exact
        path={Routes.DashboardOverview.path}
        component={DashboardOverview}
      />
      <RouteWithSidebar
        exact
        path={Routes.Campaigns.path}
        component={Campaigns}
      />
      <RouteWithSidebar
        exact
        path={Routes.Campaign.path}
        component={Campaign}
        auth={authUser}
      />

      {/* SMS  */}
      <RouteWithSidebar
        exact
        path={Routes.Outbox.path}
        component={OutBoxPage}
      />
      <RouteWithSidebar
        exact
        path={Routes.Message.path}
        component={MessagePage}
      />
      <RouteWithSidebar
        exact
        path={Routes.Activity.path}
        component={ActivityPage}
      />
      <RouteWithSidebar exact path={Routes.Inbox.path} component={InboxPage} />
      <RouteWithSidebar exact path={Routes.SMS.path} component={SendSMS} />
      <RouteWithSidebar
        exact
        path={Routes.SMSOptions.path}
        component={OptionsPage}
      />

      {/* Email  */}
      <RouteWithSidebar exact path={Routes.Compose.path} component={Compose} />
      <RouteWithSidebar
        exact
        path={Routes.Templates.path}
        component={Templates}
      />
      <RouteWithSidebar exact path={Routes.Sent.path} component={SentPage} />

      <RouteWithSidebar
        exact
        path={Routes.MailOptions.path}
        component={MailOptionsPage}
      />

      <RouteWithSidebar exact path={Routes.Topup.path} component={Topup} />
      <RouteWithSidebar exact path={Routes.Order.path} component={OrderPage} />
      <RouteWithSidebar exact path={Routes.Pay.path} component={PayPage} />
      <RouteWithSidebar exact path={Routes.New.path} component={New} />
      <RouteWithSidebar
        exact
        path={Routes.Developers.path}
        component={Developers}
      />
      <RouteWithSidebar exact path={Routes.Reports.path} component={Reports} />
      <RouteWithSidebar
        exact
        path={Routes.DataStores.path}
        component={DataStores}
      />
      <RouteWithSidebar exact path={Routes.Stores.path} component={Stores} />

      <RouteWithSidebar exact path={Routes.Import.path} component={Import} />
      <RouteWithSidebar
        exact
        path={Routes.Settings.path}
        component={Settings}
      />

      <RouteWithSidebar
        exact
        path={Routes.NewTemplate.path}
        component={NewTemplate}
      />
      <RouteWithLoader
        exact
        path={Routes.NotFound.path}
        component={NotFoundPage}
      />

      <Redirect to={Routes.NotFound.path} />
    </Switch>
  );
}

// export default HomePage;

const mapStateToProps = (state) => ({
  credits: state.credits,
});

const mapDispatchToProps = (dispatch) => {
  return {
    setCredits: (credits) =>
      dispatch({ type: "SET_CREDITS", credits: credits }),
  };
};

// export default connect(mapStateToProps, mapDispatchToProps)(withFirebase(HotelsPage));
export default compose(
  connect(mapStateToProps, mapDispatchToProps),
  // withStateHandlers,
  // withHandler,
  withFirebase
)(HomePage);
