import React, { useState, useEffect } from "react";
import SimpleBar from "simplebar-react";
import { useLocation } from "react-router-dom";
import { CSSTransition } from "react-transition-group";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

import { connect } from "react-redux";

import {
  faCog,
  faHome,
  faTimes,
  faPuzzlePiece,
  faDatabase,
  faPaperPlane,
  faBullhorn,
  faTags,
  faSms,
  faEnvelope,
  faHistory,
  faFileAlt,
} from "@fortawesome/free-solid-svg-icons";

import {
  Nav,
  Badge,
  Image,
  Button,
  Dropdown,
  Accordion,
  Navbar,
} from "@themesberg/react-bootstrap";
import { Link } from "react-router-dom";

import { Routes } from "../routes";
// import ReactHero from "../assets/img/logo.png";
import ReactHero from "../assets/img/logo2.png";

import { AuthUserContext } from "./Session";
import SignOutButton from "./himenu/SignOut";

function StoreSideBar(props = {}) {
  const location = useLocation();
  const { pathname } = location;
  const [show, setShow] = useState(false);
  const showClass = show ? "show" : "";

  useEffect(() => {
    console.log("Thi is the BSA");
    console.log(props);
  });

  const onCollapse = () => setShow(!show);

  const CollapsableNavItem = (props) => {
    const { eventKey, title, icon, children = null } = props;
    const defaultKey = pathname.indexOf(eventKey) !== -1 ? eventKey : "sms";

    return (
      <Accordion as={Nav.Item} defaultActiveKey={defaultKey}>
        <Accordion.Item eventKey={eventKey}>
          <Accordion.Button
            as={Nav.Link}
            className="d-flex justify-content-between align-items-center"
          >
            <span>
              <span className="sidebar-icon">
                <FontAwesomeIcon icon={icon} />{" "}
              </span>
              <span className="sidebar-text">{title}</span>
            </span>
          </Accordion.Button>
          <Accordion.Body className="multi-level">
            <Nav className="flex-column">{children}</Nav>
          </Accordion.Body>
        </Accordion.Item>
      </Accordion>
    );
  };

  const CollapsableNavItem2 = (props) => {
    const { eventKey, title, icon, children = null } = props;
    const defaultKey = pathname.indexOf(eventKey) !== -1 ? eventKey : "email";

    return (
      <Accordion as={Nav.Item} defaultActiveKey={defaultKey}>
        <Accordion.Item eventKey={eventKey}>
          <Accordion.Button
            as={Nav.Link}
            className="d-flex justify-content-between align-items-center"
          >
            <span>
              <span className="sidebar-icon">
                <FontAwesomeIcon icon={icon} />{" "}
              </span>
              <span className="sidebar-text">{title}</span>
            </span>
          </Accordion.Button>
          <Accordion.Body className="multi-level">
            <Nav className="flex-column">{children}</Nav>
          </Accordion.Body>
        </Accordion.Item>
      </Accordion>
    );
  };

  const NavItem = (props) => {
    const {
      title,
      link,
      external,
      target,
      icon,
      image,
      badgeText,
      badgeBg = "secondary",
      badgeColor = "primary",
      name,
    } = props;
    const classNames = badgeText
      ? "d-flex justify-content-start align-items-center justify-content-between"
      : "";

    let navItemClassName = link === pathname ? "active" : "";

    const linkProps = external ? { href: link } : { as: Link, to: link };

    return (
      <Nav.Item className={navItemClassName} onClick={() => setShow(false)}>
        <Nav.Link {...linkProps} target={target} className={classNames}>
          <span>
            {icon ? (
              <span className="sidebar-icon">
                <FontAwesomeIcon icon={icon} />{" "}
              </span>
            ) : null}
            {image ? (
              <Image
                src={image}
                width={20}
                height={20}
                className="sidebar-icon svg-icon"
              />
            ) : null}

            <span className="sidebar-text">{title}</span>
          </span>
          {badgeText ? (
            <Badge
              pill
              bg={badgeBg}
              text={badgeColor}
              className="badge-md notification-count ms-2"
            >
              {badgeText}
            </Badge>
          ) : null}
        </Nav.Link>
      </Nav.Item>
    );
  };

  return (
    <>
      <Navbar
        expand={false}
        collapseOnSelect
        variant="dark"
        className="navbar-theme-primary px-4 d-md-none"
      >
        <Navbar.Brand
          className="me-lg-5"
          as={Link}
          to={Routes.DashboardOverview.path}
        >
          <Image src={ReactHero} className="navbar-brand-light" />
        </Navbar.Brand>
        <Navbar.Toggle
          as={Button}
          aria-controls="main-navbar"
          onClick={onCollapse}
        >
          <span className="navbar-toggler-icon" />
        </Navbar.Toggle>
      </Navbar>
      <CSSTransition timeout={300} in={show} classNames="sidebar-transition">
        <SimpleBar
          className={`collapse ${showClass} sidebar d-md-block bg-primary text-white`}
        >
          <div className="sidebar-inner px-4 pt-3">
            <div className="user-card d-flex d-md-none align-items-center justify-content-between justify-content-md-center pb-4">
              <div className="d-flex align-items-center">
                <div className="user-avatar lg-avatar me-4">A</div>
                <div className="d-block">
                  <h6>Hi, </h6>
                  <SignOutButton />
                </div>
              </div>
              <Nav.Link
                className="collapse-close d-md-none"
                onClick={onCollapse}
              >
                <FontAwesomeIcon icon={faTimes} />
              </Nav.Link>
            </div>
            <Nav className="flex-column pt-3 pt-md-0">
              <div className="">
                <div className="row">
                  <div className="col-12 d-flex align-items-center justify-content-between justify-content-md-center pb-2">
                    <Image src={ReactHero} width={80} height={80} />
                  </div>

                  <div className="col-12 d-flex align-items-center justify-content-between justify-content-md-center pb-2">
                    {" "}
                    <h4>Bunicom</h4>{" "}
                  </div>
                </div>
              </div>

              {/* <NavItem
                title="BuniComms"
                link={Routes.Account.path}
              /> */}

              <AuthUserContext.Consumer>
                {(authUser) =>
                  authUser != null ? (
                    <>
                      <NavItem
                        title="Dashboard"
                        icon={faHome}
                        link={"/org/" + authUser.uid}
                      />
                      <NavItem
                        title="Create an Automation"
                        icon={faBullhorn}
                        link={"/org/" + authUser.uid + "/campaigns"}
                        name="campaigns"
                      />
                      <CollapsableNavItem
                        eventKey="sms"
                        title="SMS"
                        icon={faSms}
                      >
                        <NavItem
                          title="Send Simple SMS"
                          link={"/org/" + authUser.uid + "/sms"}
                        />
                         {/* <NavItem
                          title="Saved SMS Templates"
                          link={"/org/" + authUser.uid + "/sms/activities"}
                        /> */}
                        <NavItem
                          title="Outbox"
                          link={"/org/" + authUser.uid + "/sms/outbox"}
                        />
                        <NavItem
                          title="Inbox"
                          link={"/org/" + authUser.uid + "/sms/inbox"}
                        />
                        <NavItem
                          title="SMS Options"
                          link={"/org/" + authUser.uid + "/sms/options"}
                        />
                      </CollapsableNavItem>

                      <CollapsableNavItem
                        eventKey="email"
                        title="Email"
                        icon={faEnvelope}
                      >
                        <NavItem
                          title="Compose an Email"
                          link={"/org/" + authUser.uid + "/mail/compose"}
                        />
                        <NavItem
                          title="Sent"
                          link={"/org/" + authUser.uid + "/mail/sent"}
                        />
                        <NavItem
                          title="Email Templates"
                          link={"/org/" + authUser.uid + "/mail/templates"}
                        />
                        <NavItem
                          title="Configurations"
                          link={"/org/" + authUser.uid + "/mail/options"}
                        />
                      </CollapsableNavItem>

                      <NavItem
                        title="Data Stores"
                        icon={faDatabase}
                        link={"/org/" + authUser.uid + "/datastores"}
                      />
                      <NavItem
                        title="Settings"
                        icon={faCog}
                        link={"/org/" + authUser.uid + "/settings"}
                      />
                      <Dropdown.Divider className="my-3 border-indigo" />
                      <NavItem
                        title="Top-ups"
                        icon={faPaperPlane}
                        link={"/org/" + authUser.uid + "/topup"}
                      />

                      {/* <NavItem
                        title="Whats New"
                        icon={faTags}
                        link={"/org/" + authUser.uid + "/what-is-new"}
                      /> */}

                      <NavItem
                        title="For Developers"
                        icon={faTags}
                        link={"/org/" + authUser.uid + "/developers"}
                      />
                    </>
                  ) : (
                    ""
                  )
                }
              </AuthUserContext.Consumer>

              <Dropdown.Divider className="my-3 border-indigo" />
              <SignOutButton />
            </Nav>
          </div>
        </SimpleBar>
      </CSSTransition>
    </>
  );
}

const mapStateToProps = (state) => ({
  org_id: state.org_id,
});

const mapDispatchToProps = (dispatch) => {
  return {};
};

// export default StoreSideBar
export default connect(mapStateToProps, mapDispatchToProps)(StoreSideBar);
