import {FormPortModel} from "./FormPortModel";
import {AbstractPortFactory, Toolkit} from "storm-react-diagrams";

export class FormPortFactory extends AbstractPortFactory {
    constructor() {
        super("unknown");
    }
    getNewInstance(initialConfig) {
        return new FormPortModel(true, Toolkit.UID(), "unknown");
    }
}
