import { NodeModel, DefaultPortModel, Toolkit } from "storm-react-diagrams";
import * as _ from "lodash";

export class EmailNodeModel extends NodeModel {
    constructor() {
        super("Email");
        this.name = "";
        this.properties = {
            input: [],
            maxLinks: 1,
            name: 'Email Widget',
            type: 'multiple',
            outputData: [],
            message: "Email Tool",
            messages: [],
            metaData: {},
            annotation: "Send an email",
            configs: {
                "smtp": "server1.noc254.com",
                "port": 465,
                "username": "smtp_username",
                "password": "smtp_password",
               "mode": "ssl",
                "emailColumn": "Default",
                "disableSend": false,
                "editor_mode": "basic",
                "bulk":  false,
                "body": "Bulk message sent from BuniEmail",
                "subject": "Edit Email Subject",
                "sender":"info@zmc.co.ke",
                "sender_name": "ZMC",
                "requires_authorization": true,
                "template_id": null
            }
        }
    }
   
    addOutPort(label) {
        return this.addPort(new DefaultPortModel(false, label, label));
    }
    addInPort(label) {
         
        return this.addPort(new DefaultPortModel(true, label, label));
    }
    printResults(node, port){

        // console.log(node);
        // console.log(port);
    
        // return node.properties.outputData
        switch(port){
            case 'input':
              return node.properties.input == null ? []: node.properties.input
            case 'output':
              return node.properties.outputData == null ? []: node.properties.outputData
          }
    
      }

    updateName = (e) => {
         this.name = e.target.innerHTML;
         console.log(this.name);
    };

    serialize() {
        return _.merge(super.serialize(), {
            scene_name: this.name
        });
    }
    deSerialize(object, engine) {
        super.deSerialize(object, engine);
        this.name = object.scene_name;
    }
    getInPorts() {
        return _.filter(this.ports, portModel => {
            return portModel.in;
        });
    }

    getOutPorts() {
        return _.filter(this.ports, portModel => {
            return (!portModel.in && !portModel.next);
        });
    }
}
