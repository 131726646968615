import React, { Component } from "react";
import ReactDOM from "react-dom";
import jexcel from "jexcel";
import Axios from "axios";

import { connect } from "react-redux";
import { withRouter } from "react-router-dom";

import "./styles.css";
import "../../../../node_modules/jexcel/dist/jexcel.css";

import { Toolkit } from "storm-react-diagrams";

import * as actionTypes from "./../../../store/actions";


class CleanupTable extends React.Component {
  constructor(props) {
    super(props);

    let that = this

    this.state = {
      entity: null,
      fields_data: [],
    };
    
    this.options = {
      data: [['']],
      columns: [
        { type: 'checkbox', title:'Active', width:120 },
        { type: "text", title: "Column name", width: 200 },
        // { type: "dropdown", title: "Rename column", width: 100, source:['==','>=','<=','!='], filter: that.dropdownFilter },
        

      ],
      ondeleterow: function (instance) {
    
        // console.log(that.saveDtypes());
        that.saveDtypesNode()
    
      },
      onchange: function(instance, cell, x, y, value) {
        console.log("Changed");
        that.saveDtypesNode()
  },
      columnDrag: true,
      allowManualInsertRow: false,
      allowManualInsertColumn: false,
      allowInsertRow: false,
      allowInsertColumn: false,
      allowDeleteRow: false,
      allowDeleteColumn: false,
      allowRenameColumn: false,
      allowComments: false,
      allowExport: false,
      oninsertrow: this.insertedRow,
     
    };
  }

  componentDidMount = function () {
    this.el = jexcel(ReactDOM.findDOMNode(this).children[0], this.options);
    this.saveDtypes()   

    // let fields_arr = resp.data.map((foo) => {
            
    //     return Object.values(foo)
    //   })
  };

  componentDidUpdate(prevProps, prevState) {

    this.saveDtypes()
    // if(prevProps.dtypes !== this.props.dtypes){
    //     console.log("ENVIRONMENT VARIABLES");
    //     console.log(prevProps.dtypes);
    //     console.log(this.props.dtypes);
    // //     let fields_arr =this.props.env_variables.map((foo) => {
            
    // //     return Object.values(foo)
    // //   })
    // //   console.log(fields_arr);
    // //   this.el.setData(fields_arr)

      
    // }
  }

  
  // dropdownFilter = function(instance, cell, c, r, source) {
  //   var value = instance.jexcel.getValueFromCoords(c - 1, r);
  //   // console.log("I am logging the instance");
  //   // alert(value);

  //   // return ['Java','Apples','Bananas','Oranges'];
     
  //     if (value == 'object') {
  //         return ['contains','startswith','is equal'];
  //     } else if (value == 'int64') {
  //         return ['Carrots'];
  //     } else {
  //         return source;
  //     }
  // }

  addRow = function () {
    this.el.insertRow([true,"",]);
  };

  saveDtypesNode  = function () {
    let selectNode = this.props.dtypes_
    let model = this.props.model;
    let currNode = model.getNode(selectNode.id)

    // if(selectNode.properties.columns.hasOwnProperty("columns")){
        

        console.log(this.el.getData());
        let records = this.el.getData();
        // let headers = ['column_name']
        // let arr_objs_records = [];
        let selected_columns = [];

        records.forEach((record) => {
            // let obj_rec = {};
            if(record[0]){
              selected_columns.push(record[1])
            }
            // headers.forEach((item) => {
             
            //     obj_rec[item] = record[count]

              
            // });
            // // if (obj_rec["var_name"] !== "") {
            //     arr_objs_records.push(obj_rec);
            // }
            
        })

        // console.log(arr_objs_records);

        // currNode.properties.columns.columns = arr_objs_records

        // console.log(currNode.properties.columns.columns);

        if(currNode.properties.hasOwnProperty("selected_columns")){
          currNode.properties.selected_columns = selected_columns
        }
    // }

    // currNode.properties.metaData

  }
  saveDtypes = function () {

    let selectNode = this.props.dtypes_

    let model = this.props.model;
    let currNode = model.getNode(selectNode.id)

    // currNode.properties.outputData = event.target.value.split(',')
    console.log("=================SELECT TOOOL CONFIGS MOUNTED CYCLE=================");
    // console.log(currNode.properties);
    if(currNode){
      if(currNode.properties.metaData.hasOwnProperty("columns")){

        let columns = selectNode.properties.metaData.columns
        let dataArr = []
        columns.forEach(column => {
          let active = true
            if(currNode.properties.hasOwnProperty("selected_columns")){
              if(!currNode.properties.selected_columns.includes(column.column_name)){
                active = false
              }
            }

            dataArr.push([active,column.column_name]);
        });

        this.el.setData(dataArr)
    }
    // console.log();


    // console.log(currNode.properties);

    }
   
  };

  insertedRow = function (instance) {
    console.log(instance);
  };


  render() {
    return (
      <div>
        <div></div>

        <div>
          {/* <input
            type="button"
            value="+ Environment variable"
            onClick={() => this.addRow()}
          ></input> */}
          
        </div>

        <br />
        <br />
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
   
  };
};

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(CleanupTable)
);
