

export default [
    {
        _id: "fb9b784d-f5d8-4fbc-9bcf-a1efeb6bb6ef",
        application_id: "5fe3413625dce5c223e2eda7",
        collection_id: "5ff09052c246c18feb4d2403",
        collection_name: "Awesome Collection",
        created_at: "2021-01-06T08:47:24.948Z",
        description: "",
        modified_at: "2021-01-06T08:47:24.948Z",
        name: "Awesome Workflow 12",
        env_variables: [{ var_name: "", value: "", type: "text" }],
        links: [
          {
            stamp:
              "c379b500-e3b1-4d73-a0e9-1208119d94a26c556fc6-827d-4a75-a8b5-c4f5130931f7",
            link_id: "62d9ded6-79af-420b-b660-ada6151052fe",
            source: {
              nodeType: "DataEntry",
              port: "output",
              links: ["26a96b3e-1b5e-4b08-8263-bb20d12bb75e"],
              nodeID: "c379b500-e3b1-4d73-a0e9-1208119d94a2",
              properties: {
                annotation: "",
                entry_data: [],
                headers: [],
                input: [
                  {
                    Email: "mike@mail.com",
                    "First Name": "Mike",
                    Phone: "0713591452",
                  },
                ],
                message: "DataEntry Input",
                messages: [],
                metaData: {
                  columns: [
                    { column_name: "Email", data_type: "object" },
                    { column_name: "First_Name", data_type: "object" },
                    { column_name: "Phone", data_type: "int64" },
                  ],
                  shape: [1, 3],
                },
                name: "DataEntry Tool",
                outputData: [
                  {
                    Email: "mike@mail.com",
                    First_Name: "Mike",
                    Phone: 713591452,
                  },
                ],
                type: "single",
                x: 215,
                y: 105,
              },
            },
            target: {
              nodeType: "SMS",
              port: "input",
              links: ["26a96b3e-1b5e-4b08-8263-bb20d12bb75e"],
              nodeID: "6c556fc6-827d-4a75-a8b5-c4f5130931f7",
              properties: {
                input: [],
                maxLinks: 1,
                headers: [],
                type: "multiple",
                name: "Buni SMS",
                outputData: [],
                message: "SMS Tool",
                columns: {},
                smsConfigs: {
                  vendors: [{ name: "Africas Talking", var_key: "at" }],
                  vendor: "at",
                  vendorConfigs: {
                    at: {
                      bulk: "bulk",
                      username: "michaelngugin",
                      apiKey:
                        "e7d0887a58ef17a69cdc8b63e9d474f1a98f97fb64c431eec7d5223f33fc0437",
                      phoneColumn: "",
                      message: "",
                      disableSend: "true",
                    },
                  },
                },
                annotation: "",
                selected_columns: [],
                datatypes: { float: ["Quantity", "Price"], string: [] },
                messages: [],
                metaData: {},
                x: 447,
                y: 132,
              },
            },
          },
          {
            stamp: "genesisc379b500-e3b1-4d73-a0e9-1208119d94a2",
            link_id: "link1",
            source: {
              nodeType: "Start",
              port: "output",
              nodeID: "genesis",
              properties: { input: [], outputData: [] },
            },
            target: {
              nodeType: "DataEntry",
              port: "input",
              nodeID: "c379b500-e3b1-4d73-a0e9-1208119d94a2",
              properties: {
                annotation: "",
                entry_data: [],
                headers: [],
                input: [
                  {
                    Email: "mike@mail.com",
                    "First Name": "Mike",
                    Phone: "0713591452",
                  },
                ],
                message: "DataEntry Input",
                messages: [],
                metaData: {
                  columns: [
                    { column_name: "Email", data_type: "object" },
                    { column_name: "First_Name", data_type: "object" },
                    { column_name: "Phone", data_type: "int64" },
                  ],
                  shape: [1, 3],
                },
                name: "DataEntry Tool",
                outputData: [
                  {
                    Email: "mike@mail.com",
                    First_Name: "Mike",
                    Phone: 713591452,
                  },
                ],
                type: "single",
                x: 215,
                y: 105,
              },
            },
          },
          {
            stamp: "genesisc379b500-e3b1-4d73-a0e9-1208119d94a2",
            link_id: "link2",
            source: {
              nodeType: "Start",
              port: "output",
              nodeID: "genesis",
              properties: { input: [], outputData: [] },
            },
            target: {
              nodeType: "DataEntry",
              port: "input",
              nodeID: "c379b500-e3b1-4d73-a0e9-1208119d94a2",
              properties: {
                annotation: "",
                entry_data: [],
                headers: [],
                input: [
                  {
                    Email: "mike@mail.com",
                    "First Name": "Mike",
                    Phone: "0713591452",
                  },
                ],
                message: "DataEntry Input",
                messages: [],
                metaData: {
                  columns: [
                    { column_name: "Email", data_type: "object" },
                    { column_name: "First_Name", data_type: "object" },
                    { column_name: "Phone", data_type: "int64" },
                  ],
                  shape: [1, 3],
                },
                name: "DataEntry Tool",
                outputData: [
                  {
                    Email: "mike@mail.com",
                    First_Name: "Mike",
                    Phone: 713591452,
                  },
                ],
                type: "single",
                x: 215,
                y: 105,
              },
            },
          },
        ],
        mode: "ex",
        nodes: [
          {
            nodeType: "DataEntry",
            id: "c379b500-e3b1-4d73-a0e9-1208119d94a2",
            properties: {
              annotation: "",
              entry_data: [],
              headers: [],
              input: [
                {
                  Email: "mike@mail.com",
                  "First Name": "Mike",
                  Phone: "0713591452",
                },
              ],
              message: "DataEntry Input",
              messages: [],
              metaData: {
                columns: [
                  { column_name: "Email", data_type: "object" },
                  { column_name: "First_Name", data_type: "object" },
                  { column_name: "Phone", data_type: "int64" },
                ],
                shape: [1, 3],
              },
              name: "DataEntry Tool",
              outputData: [
                { Email: "mike@mail.com", First_Name: "Mike", Phone: 713591452 },
              ],
              type: "single",
              x: 215,
              y: 105,
            },
            ports: {
              output: {
                listeners: {},
                id: "output",
                locked: false,
                type: "output",
                selected: false,
                name: "output",
                links: {},
                isInput: false,
                label: "output",
                parent: {
                  listeners: {
                    "1c6f7893-6556-4692-8911-72cf8ca87fb5": {},
                    "f07d5888-c9d1-4ea7-a3e6-ea609de6ca74": {},
                  },
                  id: "c379b500-e3b1-4d73-a0e9-1208119d94a2",
                  locked: false,
                  type: "DataEntry",
                  selected: false,
                  x: 215,
                  y: 105,
                  extras: {},
                  name: "DataEntry",
                  properties: {
                    annotation: "",
                    entry_data: [],
                    headers: [],
                    input: [
                      {
                        Email: "mike@mail.com",
                        "First Name": "Mike",
                        Phone: "0713591452",
                      },
                    ],
                    message: "DataEntry Input",
                    messages: [],
                    metaData: {
                      columns: [
                        { column_name: "Email", data_type: "object" },
                        { column_name: "First_Name", data_type: "object" },
                        { column_name: "Phone", data_type: "int64" },
                      ],
                      shape: [1, 3],
                    },
                    name: "DataEntry Tool",
                    outputData: [
                      {
                        Email: "mike@mail.com",
                        First_Name: "Mike",
                        Phone: 713591452,
                      },
                    ],
                    type: "single",
                    x: 215,
                    y: 105,
                  },
                },
              },
            },
            annotation: "Plugin Initialized",
          },
          {
            nodeType: "DataEntry",
            id: "c379b500-e3b1-4d73-a0e9-1208119d94a2",
            properties: {
              annotation: "",
              entry_data: [],
              headers: [],
              input: [
                {
                  Email: "mike@mail.com",
                  "First Name": "Mike",
                  Phone: "0713591452",
                },
              ],
              message: "DataEntry Input",
              messages: [],
              metaData: {
                columns: [
                  { column_name: "Email", data_type: "object" },
                  { column_name: "First_Name", data_type: "object" },
                  { column_name: "Phone", data_type: "int64" },
                ],
                shape: [1, 3],
              },
              name: "DataEntry Tool",
              outputData: [
                { Email: "mike@mail.com", First_Name: "Mike", Phone: 713591452 },
              ],
              type: "single",
              x: 215,
              y: 105,
            },
            ports: {
              output: {
                listeners: {},
                id: "output",
                locked: false,
                type: "output",
                selected: false,
                name: "output",
                links: {},
                isInput: false,
                label: "output",
                parent: {
                  listeners: {
                    "d0efc1f9-5ab8-4471-aa77-0536dc95183a": {},
                    "5f696e33-fda8-4c09-89b6-fb69348f4238": {},
                  },
                  id: "c379b500-e3b1-4d73-a0e9-1208119d94a2",
                  locked: false,
                  type: "DataEntry",
                  selected: false,
                  x: 215,
                  y: 105,
                  extras: {},
                  name: "DataEntry",
                  properties: {
                    annotation: "",
                    entry_data: [],
                    headers: [],
                    input: [
                      {
                        Email: "mike@mail.com",
                        "First Name": "Mike",
                        Phone: "0713591452",
                      },
                    ],
                    message: "DataEntry Input",
                    messages: [],
                    metaData: {
                      columns: [
                        { column_name: "Email", data_type: "object" },
                        { column_name: "First_Name", data_type: "object" },
                        { column_name: "Phone", data_type: "int64" },
                      ],
                      shape: [1, 3],
                    },
                    name: "DataEntry Tool",
                    outputData: [
                      {
                        Email: "mike@mail.com",
                        First_Name: "Mike",
                        Phone: 713591452,
                      },
                    ],
                    type: "single",
                    x: 215,
                    y: 105,
                  },
                },
              },
            },
            annotation: "Plugin Initialized",
          },
          {
            nodeType: "SMS",
            id: "6c556fc6-827d-4a75-a8b5-c4f5130931f7",
            properties: {
              annotation: "",
              columns: {},
              datatypes: { float: ["Quantity", "Price"], string: [] },
              headers: [],
              input: [
                { Email: "mike@mail.com", First_Name: "Mike", Phone: 713591452 },
              ],
              maxLinks: 1,
              message: "success",
              messages: [],
              metaData: {
                columns: [
                  { column_name: "Email", data_type: "object" },
                  { column_name: "First_Name", data_type: "object" },
                  { column_name: "Phone", data_type: "int64" },
                ],
                shape: [1, 3],
              },
              name: "Buni SMS",
              outputData: [
                {
                  Email: "mike@mail.com",
                  First_Name: "Mike",
                  Phone: 713591452,
                  SMS: "Hi Mike",
                  Status: "Sample Mode",
                },
              ],
              selected_columns: [],
              smsConfigs: {
                vendor: "at",
                vendorConfigs: {
                  at: {
                    apiKey:
                      "e7d0887a58ef17a69cdc8b63e9d474f1a98f97fb64c431eec7d5223f33fc0437",
                    bulk: "single",
                    disableSend: "false",
                    message: "Hi [First_Name]",
                    phoneColumn: "Phone",
                    username: "michaelngugin",
                  },
                },
                vendors: [{ name: "Africas Talking", var_key: "at" }],
              },
              type: "multiple",
              x: 448,
              y: 134,
            },
            ports: {
              input: {
                listeners: {},
                id: "a2a7c66f-fa7e-418f-8ae3-d836982f61cd",
                locked: false,
                type: "default",
                selected: false,
                name: "input",
                links: {},
                in: true,
                label: "input",
                parent: {
                  listeners: {
                    "28602f09-957b-42bd-9d9b-d5a431ccb835": {},
                    "204f805d-d964-4833-be40-174699331760": {},
                  },
                  id: "6c556fc6-827d-4a75-a8b5-c4f5130931f7",
                  locked: false,
                  type: "SMS",
                  selected: false,
                  x: 448,
                  y: 134,
                  extras: {},
                  name: "",
                  properties: {
                    annotation: "",
                    columns: {},
                    datatypes: { float: ["Quantity", "Price"], string: [] },
                    headers: [],
                    input: [
                      {
                        Email: "mike@mail.com",
                        First_Name: "Mike",
                        Phone: 713591452,
                      },
                    ],
                    maxLinks: 1,
                    message: "success",
                    messages: [],
                    metaData: {
                      columns: [
                        { column_name: "Email", data_type: "object" },
                        { column_name: "First_Name", data_type: "object" },
                        { column_name: "Phone", data_type: "int64" },
                      ],
                      shape: [1, 3],
                    },
                    name: "Buni SMS",
                    outputData: [
                      {
                        Email: "mike@mail.com",
                        First_Name: "Mike",
                        Phone: 713591452,
                        SMS: "Hi Mike",
                        Status: "Sample Mode",
                      },
                    ],
                    selected_columns: [],
                    smsConfigs: {
                      vendor: "at",
                      vendorConfigs: {
                        at: {
                          apiKey:
                            "e7d0887a58ef17a69cdc8b63e9d474f1a98f97fb64c431eec7d5223f33fc0437",
                          bulk: "single",
                          disableSend: "false",
                          message: "Hi [First_Name]",
                          phoneColumn: "Phone",
                          username: "michaelngugin",
                        },
                      },
                      vendors: [{ name: "Africas Talking", var_key: "at" }],
                    },
                    type: "multiple",
                    x: 448,
                    y: 134,
                  },
                },
              },
              output: {
                listeners: {},
                id: "d8a234ab-bc5c-4025-a034-bc57e685bdbb",
                locked: false,
                type: "default",
                selected: false,
                name: "output",
                links: {},
                in: false,
                label: "output",
              },
            },
            annotation: "Plugin Initialized",
          },
          {
            nodeType: "Start",
            id: "genesis",
            nodeID: "genesis",
            properties: { input: [], outputData: [] },
          },
        ],
      }
    
]