import React from "react";
import ReactDOM from "react-dom";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";

import axios from "axios";
import {
  Row,
  Col,
  Card,
  Form,
  Button,
  InputGroup,
  Modal,
  FormControl,
  DropdownButton,
  Dropdown,
} from "react-bootstrap";

import { Tabs, Tab } from "react-bootstrap";

import ReactQuill from "react-quill";
import "react-quill/dist/quill.snow.css";

import ExampleContainer from "./ExampleContainer";

class EmailComponent extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      // Set initial files, type 'local' means this is a file
      // that has already been uploaded to the server (see docs)
      node: null,
      show_email: props.selected_node["properties"]["configs"]["sender"],
      templates: [],
      show: false,
      template_body: `
      <b>No Template content availbale</b>
      `,
    };

    this.handleInputChange = this.handleInputChange.bind(this);
    this.handleEditorChange = this.handleEditorChange.bind(this);
    this.goToTemplate = this.goToTemplate.bind(this);
    this.preview = this.preview.bind(this);

    this.handleChangeSelectedEntity = this.handleChangeSelectedEntity.bind(
      this
    );
  }

  goToTemplate(template_id) {
    console.log(template_id);
    this.props.history.push(
      "/org/" + this.props.match.params.org_id + "/template/" + template_id
    );
  }

  preview(preview) {
    let html = this.state.templates.filter((x) => x._id == preview);

    if (html.length > 0) {
      let template = html[0];
      if (template.hasOwnProperty("html")) {
        this.setState({ template_body: template.html });
      }
      this.setState({ show: true });
    }
  }

  handleChangeSelectedEntity(event) {
    console.log();
    console.log(this.state.templates);
    let node = this.props.node;
    let model = this.props.model;
    let currNode = model.getNode(node.id);

    // currNode.properties.outputData = event.target.value.split(',')

    // console.log(currNode.properties.entity);

    if (event.target.value === "Default") {
      currNode.properties.message = "error";
      currNode.properties.messages = ["Please select an Entity"];
    } else {
      currNode.properties.message = "success";
      
      currNode.properties.configs["template_id"] = this.state.templates[
        event.target.value
      ]._id;
    }

    this.props.updateNode();
  }

  handleInputChange(event, label) {
    const target = event.target;
    const value = target.type === "checkbox" ? target.checked : target.value;
    const name = target.name;

    console.log(label);
    console.log(value);
    console.log(name);
    if (name == "bulk") {
      this.setState({ show_email: value });
    }

    let node = this.props.node;
    let model = this.props.model;
    let currNode = model.getNode(node.id);

    currNode.properties.configs[name] = value;

    this.props.updateNode();
    this.forceUpdate();
  }

  handleAnnotationChange(event, label) {
    const target = event.target;
    const value = target.value;
    const name = target.name;

    console.log(label);
    console.log(value);
    console.log(name);

    let node = this.props.node;
    let model = this.props.model;
    let currNode = model.getNode(node.id);

    currNode.properties[name] = value;

    this.props.updateNode();
    this.forceUpdate();
  }

  handleEditorChange(event, label) {
    const value = event;

    let node = this.props.node;
    let model = this.props.model;
    let currNode = model.getNode(node.id);

    currNode.properties.configs[label] = value;

    this.props.updateNode();
  }
  componentDidMount = function () {
    console.log("Mounted");
    console.log(this.props.selected_node);

    let appid = this.props.match.params.org_id;

    this.getTemplates(appid);
  };

  getTemplates(id) {
    //get email templates via appid
    let that = this;
    axios
      .get(
        "https://api.bunicom.com/get-templates?application_id=" +
          id
      )
      .then((response) => {
        // console.log(response.data);
        that.setState({ templates: response.data });
      });
  }
  renderEmail(node) {
    return <Col md={12}></Col>;
  }

  render() {
    let selected_node = this.props.selected_node;
    return (
      <div className="modal-body">
        <div className="text-left">
          {/* <h6 className="heading mt-1">Select Node Configs</h6> */}
          <Form>
            <div className="row">
              <Col md={12} className="mt-3">
                <h5 className="mt-0">Compose Email</h5>
                <hr />

                <Form.Group className="mb-3">
                  <Form.Label>
                    Subject <span style={{ color: "red" }}>*</span>
                  </Form.Label>
                  <FormControl
                    type="text"
                    name="subject"
                    value={selected_node["properties"]["configs"]["subject"]}
                    onChange={(e) => this.handleInputChange(e, "subject")}
                  />
                </Form.Group>

                <Form.Group className="mb-3">
                  <Form.Label>
                    Select Email Column <span style={{ color: "red" }}>*</span>
                  </Form.Label>
                  <Form.Control
                    as="select"
                    name="emailColumn"
                    value={
                      selected_node["properties"]["configs"]["emailColumn"]
                    }
                    onChange={(e) => this.handleInputChange(e, "emailColumn")}
                  >
                    <option value="Default">Default</option>
                    {selected_node.properties.metaData.hasOwnProperty("columns")
                      ? selected_node.properties.metaData.columns.map(
                          (item, key) => (
                            <option key={key} value={item.column_name}>
                              {item.column_name}
                            </option>
                          )
                        )
                      : ""}
                    {selected_node.properties.metaData.hasOwnProperty("columns")
                      ? ""
                      : ""}
                  </Form.Control>
                </Form.Group>

                <Form.Group className="mb-3">
                  <Form.Label>Editor mode</Form.Label>
                  <div className="row">
                    <Col md={4}>
                      <Form.Check
                        custom
                        type="radio"
                        label="Basic"
                        value="basic"
                        name="editor_mode"
                        checked={
                          selected_node["properties"]["configs"].hasOwnProperty(
                            "editor_mode"
                          )
                            ? selected_node["properties"]["configs"][
                                "editor_mode"
                              ] === "basic"
                            : false
                        }
                        onChange={(e) =>
                          this.handleInputChange(e, "editor_mode")
                        }
                        id="mode11"
                      />
                    </Col>
                    <Col md={4}>
                      <Form.Check
                        custom
                        type="radio"
                        label="Advanced"
                        value="advanced"
                        checked={
                          selected_node["properties"]["configs"].hasOwnProperty(
                            "editor_mode"
                          )
                            ? selected_node["properties"]["configs"][
                                "editor_mode"
                              ] === "advanced"
                            : false
                        }
                        onChange={(e) =>
                          this.handleInputChange(e, "editor_mode")
                        }
                        name="editor_mode"
                        id="mode21"
                      />
                    </Col>
                  </div>
                </Form.Group>

                {selected_node["properties"]["configs"].hasOwnProperty(
                  "editor_mode"
                ) ? (
                  selected_node["properties"]["configs"]["editor_mode"] ===
                  "basic" ? (
                    <Form.Group className="mb-3">
                      <Form.Label>
                        Compose <span style={{ color: "red" }}>*</span>
                      </Form.Label>
                      <ReactQuill
                        theme="snow"
                        type="text"
                        value={selected_node["properties"]["configs"]["body"]}
                        // value={template_body}
                        onChange={(e) => this.handleEditorChange(e, "body")}
                      />
                    </Form.Group>
                  ) : (
                    <Form.Group className="mb-3">
                      <Form.Label>Pick a Template</Form.Label>
                      <Form.Control
                        as="select"
                        name="sheet"
                        value={
                          selected_node["properties"]["configs"].hasOwnProperty(
                            "editor_mode"
                          )
                            ? selected_node["properties"]["configs"][
                                "template_id"
                              ] == "Default"
                              ? "Default"
                              : this.state.templates
                                  .map(function (e) {
                                    return e._id;
                                  })
                                  .indexOf(
                                    selected_node["properties"]["configs"][
                                      "template_id"
                                    ]
                                  )
                            : "Default"
                        }
                        onChange={this.handleChangeSelectedEntity}
                      >
                        <option value="Default">Default</option>
                        {this.state.templates.map((item, key) => (
                          <option key={key} value={key}>
                            {item.title}
                          </option>
                        ))}
                      </Form.Control>
                      {this.state.templates.length > 0 ? (
                        <>
                          <Button
                            className="mt-3"
                            onClick={() =>
                              this.goToTemplate(
                                selected_node["properties"]["configs"][
                                  "template_id"
                                ]
                              )
                            }
                          >
                            Go to template
                          </Button>
                          <Button
                            className="mt-3"
                            onClick={() =>
                              this.preview(
                                selected_node["properties"]["configs"][
                                  "template_id"
                                ]
                              )
                            }
                          >
                            Preview
                          </Button>
                        </>
                      ) : (
                        ""
                      )}
                    </Form.Group>
                  )
                ) : (
                  <Form.Group className="mb-3">
                    <Form.Label>
                      Compose <span style={{ color: "red" }}>*</span>
                    </Form.Label>
                    <ReactQuill
                      theme="snow"
                      type="text"
                      value={selected_node["properties"]["configs"]["body"]}
                      // value={template_body}
                      onChange={(e) => this.handleEditorChange(e, "body")}
                    />
                  </Form.Group>
                )}

                <Modal
                  show={this.state.show}
                  onHide={() => this.setState({ show: false })}
                  dialogClassName="modal-90w preview"
                >
                  <Modal.Header closeButton>
                    <Modal.Title id="example-custom-modal-styling-title">
                      Template Preview
                    </Modal.Title>
                  </Modal.Header>
                  <Modal.Body>
                    <ExampleContainer content={this.state.template_body} />
                  </Modal.Body>
                </Modal>

                <Form.Group className="mb-3">
                  <Form.Check
                    custom
                    type="checkbox"
                    label=" Activate Send Email (Live)"
                    checked={
                      selected_node["properties"]["configs"]["disableSend"]
                    }
                    onChange={(e) => this.handleInputChange(e, "disableSend")}
                    name="disableSend"
                    id="disableSend"
                  />
                </Form.Group>

                <Form.Group controlId="exampleForm.ControlTextarea1">
                  <Form.Label>Add Comment</Form.Label>
                  <Form.Control
                    as="textarea"
                    rows="3"
                    name="annotation"
                    value={
                      selected_node["properties"].hasOwnProperty("annotation")
                        ? selected_node["properties"]["annotation"]
                        : ""
                    }
                    onChange={(e) => this.handleAnnotationChange(e, "password")}
                  />
                </Form.Group>

                {/* <Form.Group className="mb-3">
                <Form.Check
                  custom
                  type="checkbox"
                  value="true"
                  label="Send Bulk"
                  disabled
                  checked={
                    selected_node["properties"]["configs"]["bulk"]
                  }
                  onChange={(e) => this.handleInputChange(e, "bulk")}
                  name="disable"
                  id="mode11"
                />
                </Form.Group> */}
              </Col>
            </div>
          </Form>
          <p>{/* {node.properties.headers.join(',')} */}</p>
          <p>
            {/* <span id="coderea">{ JSON.stringify(this.props.node) }</span> */}
          </p>
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {};
};

const mapDispatchToProps = (dispatch) => {
  return {};
};

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(EmailComponent)
);
