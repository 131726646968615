import React from "react";
import ReactDOM from "react-dom";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";

import Axios from "axios";
import * as actionTypes from "./../../../store/actions";

import { Row, Col, Card, Button, Form } from "react-bootstrap";

class OutputComponent extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      // Set initial files, type 'local' means this is a file
      // that has already been uploaded to the server (see docs)
      node: null,
      entities: [],
    };

    this.handleChangeSelectedEntity = this.handleChangeSelectedEntity.bind(
      this
    );
    this.handleChangeSelectedWriteMode = this.handleChangeSelectedWriteMode.bind(
      this
    );
    this.handleChangeSelectedRetrievalMode = this.handleChangeSelectedRetrievalMode.bind(
      this
    );
    this.handleChangeSelectedOperation = this.handleChangeSelectedOperation.bind(
      this
    );

    this.handleInputChange = this.handleInputChange.bind(this);
  }

  handleInputChange(event, label) {
    const target = event.target;
    const value = target.type === "checkbox" ? target.checked : target.value;
    const name = target.name;

    console.log(label);
    console.log(value);
    console.log(name);

    let node = this.props.node;
    let model = this.props.model;
    let currNode = model.getNode(node.id);

    if (target.type === "checkbox") {
      currNode.properties[name] = value;
    } else {
      currNode.properties["configuration"][name] = value;
    }

    this.props.updateNode();
    this.forceUpdate();
  }

  handleChangeSelectedEntity(event) {
    console.log();
    console.log(this.state.entities);
    let node = this.props.node;
    let model = this.props.model;
    let currNode = model.getNode(node.id);

    // currNode.properties.outputData = event.target.value.split(',')
    currNode.properties.configuration = {
      title: this.state.entities[event.target.value].title,
      appid: this.props.match.params.org_id,
      _id: this.state.entities[event.target.value]._id,
    };

    console.log(currNode.properties.configuration);

    if (event.target.value === "Default") {
      currNode.properties.message = "Please select an Entity";
    }

    this.props.updateNode();
  }

  handleChangeSelectedWriteMode(event) {
    console.log(event.target.value);
    console.log(this.state.entities);
    let node = this.props.node;
    let model = this.props.model;
    let currNode = model.getNode(node.id);

    currNode.properties.otherConfigs.disabled = event.target.value;

    this.props.updateNode();
  }

  handleChangeSelectedRetrievalMode(event) {
    console.log(event.target.value);
    console.log(this.state.entities);
    let node = this.props.node;
    let model = this.props.model;
    let currNode = model.getNode(node.id);

    currNode.properties.otherConfigs.retrieval_mode = event.target.value;

    this.props.updateNode();
  }

  handleChangeSelectedOperation(event) {
    console.log(event.target.value);
    console.log(this.state.entities);
    let node = this.props.node;
    let model = this.props.model;
    let currNode = model.getNode(node.id);

    currNode.properties.otherConfigs.operation = event.target.value;

    this.props.updateNode();
  }

  handleAnnotationChange(event, label) {
    const target = event.target;
    const value = target.value;
    const name = target.name;

    console.log(label);
    console.log(value);
    console.log(name);

    let node = this.props.node;
    let model = this.props.model;
    let currNode = model.getNode(node.id);

    currNode.properties[name] = value;

    this.props.updateNode();
    this.forceUpdate();
  }

  componentDidMount() {
    let that = this;

    Axios.get(
      "https://api.bunicom.com///get-entities?application_id=" +
        this.props.match.params.org_id
    ).then((response) => {
      console.log("====================");
      console.log(response.data);
      console.log("====================");

      let entities = response.data;

      // that.props.onGetEntities(entities);
      that.setState({ entities: entities });
    });
  }

  render() {
    return (
      <div className="modal-body">
        <div className="text-left">
          <Form>
            <Row>
              <h5 className="mt-3">
                Create new data store or Choose existing data store
              </h5>
              <hr />
              <Col md={12} sm={12}>
                <Form.Group className="mb-3">
                  <Form.Check
                    label="Create new data store"
                    id="checkbox1"
                    name="createDataStore"
                    htmlFor="checkbox1"
                    checked={this.props.node.properties["createDataStore"]}
                    onChange={(e) =>
                      this.handleInputChange(e, "createDataStore")
                    }
                  />
                </Form.Group>
              </Col>

              {this.props.node.properties["createDataStore"] ? (
                <Form.Group className="mb-3">
                  <Form.Label>New Table</Form.Label>
                  <Form.Control
                    type="text"
                    placeholder="Enter table name here"
                    name="title"
                    value={this.props.node.properties["configuration"]["title"]}
                    onChange={(e) => this.handleInputChange(e, "title")}
                  />
                </Form.Group>
              ) : (
                <Form.Group controlId="exampleForm.ControlSelect1">
                  <Form.Label>Choose Data Store</Form.Label>
                  <Form.Control
                    as="select"
                    name="sheet"
                    value={
                      this.props.node.properties.configuration.title == ""
                        ? ""
                        : this.state.entities
                            .map(function (e) {
                              return e.title;
                            })
                            .indexOf(
                              this.props.node.properties.configuration.title
                            )
                    }
                    onChange={this.handleChangeSelectedEntity}
                  >
                    <option>
                        -- Select Table -- 
                      </option>
                    {this.state.entities.map((item, key) => (
                      <option key={key} value={key}>
                        {item.title}
                      </option>
                    ))}
                  </Form.Control>
                </Form.Group>
              )}
              <Col md={12}>
                <h5 className="mt-3">Preview data results</h5>
                <hr />
                <Form.Group>
                  <Form.Check
                    inline
                    custom
                    type="radio"
                    value="updated"
                    checked={
                      this.props.node.properties.otherConfigs.retrieval_mode ===
                      "updated"
                    }
                    onChange={this.handleChangeSelectedRetrievalMode}
                    label="Retrieve only Updates"
                    name="supportedRadio1"
                    id="supportedRadio21"
                  />
                  <Form.Check
                    inline
                    custom
                    type="radio"
                    value="all"
                    onChange={this.handleChangeSelectedRetrievalMode}
                    checked={
                      this.props.node.properties.otherConfigs.retrieval_mode ===
                      "all"
                    }
                    label="Retrive all results"
                    name="supportedRadio1"
                    id="supportedRadio22"
                  />
                </Form.Group>
                <h5 className="mt-2">CRUD operation</h5>
                <hr />
                <Form.Group>
                  <Form.Check
                    custom
                    type="radio"
                    value="upsert"
                    label="Insert/Update operation"
                    checked={
                      this.props.node.properties.otherConfigs.operation ===
                      "upsert"
                    }
                    onChange={this.handleChangeSelectedOperation}
                    name="supportedRadios"
                    id="supportedRadio3"
                  />
                  <Form.Check
                    custom
                    type="radio"
                    value="delete"
                    label="Delete operation"
                    checked={
                      this.props.node.properties.otherConfigs.operation ===
                      "delete"
                    }
                    onChange={this.handleChangeSelectedOperation}
                    name="supportedRadios"
                    id="supportedRadio4"
                  />
                </Form.Group>
                {this.props.node.properties.configuration.title == "" ? (
                  ""
                ) : (
                  <>
                    <h5 className="mt-5">Disable Write to Database</h5>
                    <hr />
                    <Form.Group>
                      <Form.Check
                        inline
                        custom
                        type="radio"
                        value="true"
                        checked={
                          this.props.node.properties.otherConfigs.disabled ===
                          "true"
                        }
                        onChange={this.handleChangeSelectedWriteMode}
                        label="Enable Write"
                        name="supportedRadio12"
                        id="supportedRadio211"
                      />
                      <Form.Check
                        inline
                        custom
                        type="radio"
                        value="false"
                        onChange={this.handleChangeSelectedWriteMode}
                        checked={
                          this.props.node.properties.otherConfigs.disabled ===
                          "false"
                        }
                        label="Disable All writes(Readonly)"
                        name="supportedRadio12"
                        id="supportedRadio221"
                      />
                    </Form.Group>
                  </>
                )}
              </Col>
              <Col md={12} className="mt-3">
                <Form.Group controlId="exampleForm.ControlTextarea1">
                  <Form.Label>Add Comment</Form.Label>
                  <Form.Control
                    as="textarea"
                    rows="3"
                    name="annotation"
                    value={
                      this.props.node["properties"].hasOwnProperty("annotation")
                        ? this.props.node["properties"]["annotation"]
                        : ""
                    }
                    onChange={(e) =>
                      this.handleAnnotationChange(e, "annotation")
                    }
                  />
                </Form.Group>
              </Col>
            </Row>
          </Form>
          <p>{/* {node.properties.headers.join(',')} */}</p>
          <p>
            {/* <span id="coderea">{ JSON.stringify(this.props.node) }</span> */}
          </p>
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {};
};

const mapDispatchToProps = (dispatch) => {
  return {
    // onGetEntities: (entities) =>
    //   dispatch({ type: actionTypes.SAVE_ENTITIES, entities: entities }),
  };
};

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(OutputComponent)
);
