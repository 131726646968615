import React, { useState, useEffect } from "react";

import { useHistory } from "react-router-dom";
import { Route } from "react-router-dom";
import { NavLink } from "react-router-dom";
// reactstrap components

import Axios from "axios";

import { connect } from "react-redux";
import {
  Row,
  Col,
  Card,
  Table,
  Tabs,
  Tab,
  Button,
  Form,
  ProgressBar,
  Modal,
  InputGroup,
  FormControl,
  Toast,
  Alert,
} from "react-bootstrap";

import BootstrapTable from "react-bootstrap-table-next";
import cellEditFactory from "react-bootstrap-table2-editor";
import paginationFactory from "react-bootstrap-table2-paginator";

import DataEntry from "./DataEntry";

import "./styles.css";
import { faDatabase, faArrowLeft, faPlus } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";


function BackButton(props) {
  const history = useHistory();

  function handleClick() {
    history.push("/org/"+props.appid+"/datastores");
  }

  return (
    <Button variant="himenu-accent" size="sm" onClick={handleClick}>
      <FontAwesomeIcon icon={faArrowLeft} className="me-1" /> Go back to datastores
    </Button>
  );
}

function TablePage(props) {
  const {
    // application,
    resultsSet,
    clearMessage,
    message,
    api_fields,
    currentEntity,
    fetchDataSets,
    updateDataRecord,
    onShowFieldDialog,
    showFieldDialog,
    getTable,
    tableRecordsData,
    loading,
    saveField,
  } = props;

  useEffect(() => {
    // Your code here
    document.title = `Data Tables Page`;

    // console.log("==================PARAMS==============");
    // console.log(props.);

    getTable(props.match.params.table);
  }, []);
  const field_name = useFormInput("");
  const data_type = useFormInput("");
  const required = useFormInput("");

  const handleHideFieldModal = () => {
    onShowFieldDialog(false);
  };

  const handleShowFieldModal = () => {
    field_name.onReset();
    data_type.onReset();
    required.onReset();

    clearMessage();
    onShowFieldDialog(true);
  };
  const handleAddField = () => {
    let data = {
      entity_id: currentEntity._id,
      field_name: field_name.value,
      field_type: data_type.value,
      required: required.value,
      unique: "",
      default: "",
    };

    let payload = {
      data: [data],
      entity: currentEntity,
    };

    const prevValues = api_fields.filter((res, i) => {
      return res.dataField == field_name.value && field_name != "_id";
    });

    console.log(prevValues);
    console.log(api_fields);

    if (prevValues.length < 1) {
      saveField(payload);
    }
    //
    // console.log([data]);/
    // onLoginRequest(usr)
  };

  const cellEdit = cellEditFactory({
    mode: "click",
    // blurToSave: true,
    afterSaveCell: (row) => {
      console.log(row);
    },
  });

  const onTableChange = (type, { cellEdit }) => {
    // Handle cell editing
    if (type === "cellEdit") {
      const { rowId, dataField, newValue } = cellEdit;
      let payload = { _id: rowId };
      payload[dataField] = newValue;

      let payloadData = {
        dataset: payload,
        entity: currentEntity,
      };
      // updateRow(payloadData)

      updateDataRecord(payloadData);
      // clearMessage()
    }
  };
  useEffect(() => {
    // Your code here
    document.title = `Table Page`;
  }, []);

  return (
    <>
      {loading ? <ProgressBar animated now={100} /> : ""}

      <Modal
        show={showFieldDialog}
        size="lg"
        aria-labelledby="contained-modal-title-vcenter"
        centered
        backdrop="static"
        keyboard={false}
        onHide={handleHideFieldModal}
      >
        <Modal.Header>
          <Modal.Title id="contained-modal-title-vcenter">
            {/* <NavIcon items={{ icon: "fa fa-code-branch" }} /> */}
            Create new field (Column)
          </Modal.Title>
          <Button
            variant="close"
            aria-label="Close"
            onClick={handleHideFieldModal}
          />
        </Modal.Header>
        <Modal.Body>
          <Row>
            <Col md={12}>
              {message ? (
                <div className="alert alert-warning" role="alert">
                  <span className="alert-icon">
                    <i className="fa fa-exclamation-triangle"></i>
                  </span>
                  <span className="alert-text">
                    <strong>Message!</strong> {message}{" "}
                  </span>
                </div>
              ) : (
                ""
              )}
            </Col>
            {/* {JSON.stringify()} */}

            <Col md={6}>
              <Form.Group controlId="exampleForm.ControlTextarea1">
                <Form.Label>Let's start with a title for your Field</Form.Label>

                <FormControl
                  type="text"
                  className="form-control"
                  {...field_name}
                  required
                  autoComplete="new-password"
                  placeholder="Awesome Field"
                />
              </Form.Group>
            </Col>
            <Col md={6}>
              <Form.Group controlId="exampleForm.ControlSelect1">
                <Form.Label>Pick DataType</Form.Label>
                <Form.Control as="select" name="sheet" {...data_type}>
                  <option value="Default">Default</option>
                  <option>String</option>
                  <option>Number</option>
                  <option>HTML</option>
                </Form.Control>
              </Form.Group>
            </Col>

            <div className="col-md-12">
              Required?
              <br />
              <label className="custom-toggle">
                <input type="checkbox" {...required} />
                <span
                  className="custom-toggle-slider rounded-circle"
                  data-label-off="No"
                  data-label-on="Yes"
                ></span>
              </label>
            </div>
          </Row>
        </Modal.Body>
        <Modal.Footer>
          <Button onClick={handleHideFieldModal}>Close</Button>
          {loading ? (
            <button className="btn btn-primary my-4" disabled>
              Creatin Field...
            </button>
          ) : (
            <button
              type="button"
              className="btn btn-outline-info  float-right"
              onClick={handleAddField}
              disabled={field_name.value == ""}
              // onClick={() => this.props.onWorkflowClose()}
            >
              <span className="text-white mr-2">
                <i className="fa fa-plus"></i>
              </span>
              Create Field
            </button>
          )}
          {/* <Button disabled={title.value === ''} onClick={handleSaveApplication}>Save</Button> */}
        </Modal.Footer>
      </Modal>

      <div className="container-fluid mt--6">
        <div className="row justify-content-center">
          <div className="col-lg-10 card-wrapper">
          <div className="d-flex justify-content-between flex-wrap flex-md-nowrap align-items-center py-1">
              <BackButton appid={props.match.params.appid} />
            </div>
            <div className="d-block mb-md-0">
              <h4>{currentEntity ? currentEntity.title : ""} Table</h4>
              {/* <Alert variant="secondary" show={true}>
                <div className="d-flex justify-content-between">
                  <div>
                    <FontAwesomeIcon icon={faDatabase} className="me-1" />
                    <strong>Get started with store tables!</strong> A table is a collection of related data held in a table format within a Data store
                  </div>
                </div>
              </Alert> */}
            </div>
            
            <div className="card">
              <div className="card-header">
                <div className="row align-items-center">
                  <div className="col col-3">
                    <h5 className="h3 mb-0">Bulk data entry</h5>
                  </div>

                  <div className="col">
                    <Button
                      variant="secondary"
                      className="float-end m-1"
                      onClick={handleShowFieldModal}
                    >
                      <FontAwesomeIcon icon={faPlus} className="me-2" />
                      Add New Field
                    </Button>
                  </div>
                </div>
              </div>

              <div className="card-body">
                <div className="row" id="recordsTable">
                  <DataEntry />
                </div>
              </div>
            </div>
          </div>

          <div className="col-lg-10 card-wrapper mt-3">
            <div className="card  table-wrapper table-responsive shadow-sm">
              <div className="card-header">
                <div className="row align-items-center">
                  <div className="col">
                    <h5 className="h3 mb-0">
                      Results{" "}
                      {tableRecordsData
                        ? "(" + tableRecordsData.length + ")"
                        : ""}{" "}
                    </h5>
                  </div>
                </div>
              </div>

              <Toast
                show={loading}
                style={{
                  position: "absolute",
                  top: 10,
                  left: 10,
                }}
              >
                <Toast.Header>
                  {/* <img src="holder.js/20x20?text=%20" className="rounded mr-2" alt="" /> */}
                  <strong className="mr-auto">Message</strong>
                  <small>just now</small>
                </Toast.Header>
                <Toast.Body> Processing request</Toast.Body>
              </Toast>

              <div className="card-body">
                <div
                  className="row align-items-center"
                  style={{ width: "100%" }}
                >
                  {/* {JSON.stringify(tableRecordsData)}
                  {JSON.stringify(api_fields)} */}

                  {api_fields && !loading ? (
                    <BootstrapTable
                      keyField="_id"
                      style={{ overflowX: "scroll" }}
                      data={tableRecordsData}
                      columns={api_fields}
                      cellEdit={cellEdit}
                      remote={{
                        filter: false,
                        pagination: false,
                        sort: false,
                        cellEdit: true,
                      }}
                      onTableChange={onTableChange}
                      pagination={paginationFactory()}
                    />
                  ) : (
                    "No Data to Display"
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

const useFormInput = (initialValue) => {
  const [value, setValue] = useState(initialValue);

  const handleChange = (e) => {
    setValue(e.target.value);
  };

  function handleReset() {
    setValue("");
  }
  return {
    value,
    onChange: handleChange,
    onReset: handleReset,
  };
};

const mapStateToProps = (state) => {
  return {
    loading: state.loading,
    message: state.message,
    // application: state.application,

    resultsSet: state.resultsSet,
    currentEntity: state.currentEntity,
    api_fields: state.api_fields,
    tableRecordsData: state.tableRecordsData,
    showFieldDialog: state.showFieldDialog,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    saveField: (field) => dispatch({ type: "NEW_FIELD_REQUEST", field: field }),
    getTable: (id) => dispatch({ type: "GET_TABLE_REQUEST", _id: id }),
    updateDataRecord: (load) =>
      dispatch({ type: "UPDATE_TABLE_RECORD_REQUEST", load: load }),
    clearMessage: () => dispatch({ type: "CLEAR_MSG", clear: true }),
    onShowFieldDialog: (flag) =>
      dispatch({ type: "SHOW_FIELD_DIALOG", dialog: flag }),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(TablePage);
