import React, { useState, useEffect } from "react";

import { useHistory } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faAt,
  faCheck,
  faCog,
  faDatabase,
  faDownload,
  faEye,
  faHome,
  faMailBulk,
  faPalette,
  faPallet,
  faRocket,
  faSearch,
  faSwatchbook,
  faTrash,
} from "@fortawesome/free-solid-svg-icons";
import {
  Col,
  Row,
  Form,
  ProgressBar,
  Button,
  ButtonGroup,
  Breadcrumb,
  InputGroup,
  Dropdown,
  Nav,
  Alert,
  Tab,
  Card,
  OverlayTrigger,
  Modal,
  Tooltip,
} from "@themesberg/react-bootstrap";

import Lottie from "lottie-react-web";

import animation from "./lottie.json";

import shortid from "shortid";

import axios from "axios";

import { withRouter } from "react-router-dom";

import DefaultImg from "../../../../assets/img/default1.png";

import { connect } from "react-redux";
import PreviewModal from "./PreviewModal";
import Preview from "./Preview";

import "./cards.css";

shortid.characters(
  "0123456789abcdefghijklmnopqrstuvwxyzABCDEFGHIJKLMNOPQRSTUVWXYZ$@"
);

function TemplateButton(props) {
  const history = useHistory();

  function handleClick() {
    history.push("/org/" + props.appid + "/template/" + props.template_id);
  }

  return (
    <Button
      variant="outline-secondary"
      className="mb-3 w-100"
      onClick={handleClick}
    >
      <FontAwesomeIcon icon={faRocket} className="me-1" /> Edit template
    </Button>
    // <Button variant="success" size="md" onClick={handleClick}>
    //   <FontAwesomeIcon icon={faAt} className="me-2" /> Create new Template
    // </Button>
  );
}

function SMSButton(props) {
  const history = useHistory();

  function handleClick() {
    history.push("/org/" + props.appid + "/sms");
  }

  return (
    <Button variant="success" size="md" onClick={handleClick}>
      <FontAwesomeIcon icon={faPalette} className="me-2" />
      Send SMS
    </Button>
  );
}

function OutboxPage(props) {
  const {
    templatesData,
    message,
    loading,
    AlltemplatesData,
    getTemplates,
    getAllTemplates,
  } = props;
  const [show, setShow] = useState(false);
  // const [templates, setTemplates] = useState([]);
  // const [allTemplates, setAllTemplates] = useState([]);
  const [data, setData] = useState(null);
  const history = useHistory();

  const [showDefault, setShowDefault] = useState(false);
  const [showCopy, setShowCopy] = useState(false);
  const [ModaRef, setModalRef] = useState(React.createRef());
  const handleClose = () => setShowDefault(false);
  const [currentTemplate, setCurrentTemplate] = useState(null);
  const name = useFormInput("");
  const description = useFormInput("");
  const [templateStatus, settemplateStatus] = useState(
    "Saving template, please wait ..."
  );

  useEffect(() => {
    // Your code here
    document.title = `My Templates - Bunicom Studio`;

    getTemplates(props.match.params.org_id);

    getAllTemplates();
  }, []);

  const setShowModal = (data) => {
    setShow(true);
    setData(data);
  };

  const setTemplateThis = (template) => {
    // setShow(true);
    // setData(data);

    const template_id = shortid.generate();
    console.log(template);

    let payload = {
      ...template,
      application_id: props.match.params.org_id,
      type: "Email",
      description: template.description + " (copy)",
      title: template.title + " (copy)",
      privacy: "private",
      _id: template_id,
    };

    settemplateStatus("saving a copy, please wait ...");

    setShowCopy(true);

    axios
      .post("https://api.bunicom.com/save-template", payload)
      .then((response) => {
        getTemplates(props.match.params.org_id);
        handleClose();

        settemplateStatus("Template Copy saved successfully");

        setTimeout(() => {
          setShowCopy(false);
          history.push(
            "/org/" + props.match.params.org_id + "/template/" + template_id
          );
        }, 1000);
      })
      .catch((e) => {
        handleClose();
        setShowCopy(false);
        settemplateStatus("Failed to save template copy. Please try again");
      });

    console.log(payload);
  };

  const saveTemplatePayload = (payload) => {
    
    axios
      .post("https://api.bunicom.com/save-template", payload)
      .then((response) => {
        getTemplates(props.match.params.org_id);
        handleClose();
        history.push(
          "/org/" + props.match.params.org_id + "/template/" + payload._id
        );
      })
      .catch((e) => {
        handleClose();
      });
  };

  const deleteTemplatePayload = (payload) => {

    console.log(payload)

    // settemplateStatus("Deleting template, please wait ...");

    setShowCopy(true);

    settemplateStatus("Still in development, Coming soon");

    setTimeout(() => {
      setShowCopy(false);
    }, 1000);

    // axios
    //   .post("https://api.bunicom.com/delete-template?_id"+payload)
    //   .then((response) => {
    //     getTemplates(props.match.params.org_id);
    //     history.push(
    //       "/org/" + props.match.params.org_id + "/template/" + payload._id
    //     );
    //   })
    //   .catch((e) => {
    //     console.log("There was an error deleting template");
    //   });
  };

  const onSubmit = (event) => {
    event.preventDefault();
    // CreateNewCampaign();

    const template_id = shortid.generate();

    let payload = {
      title: name.value,
      description: description.value,
      type: "Email",
      usage: "Newsletter",
      mode: "free",
      privacy: "private",
      _id: template_id,
      application_id: props.match.params.org_id,
    };

    saveTemplatePayload(payload);

    //Save template here
  };

  return (
    <>
      <Modal
        as={Modal.Dialog}
        centered
        show={showCopy}
        ref={ModaRef}
        onHide={handleClose}
      >
        <Modal.Header>
          <Modal.Title className="h6">{templateStatus}</Modal.Title>
          <Button variant="close" aria-label="Close" onClick={handleClose} />
        </Modal.Header>
      </Modal>
      <Modal
        as={Modal.Dialog}
        centered
        show={showDefault}
        ref={ModaRef}
        onHide={handleClose}
      >
        <Modal.Header>
          <Modal.Title className="h6"> Create new Template</Modal.Title>
          <Button variant="close" aria-label="Close" onClick={handleClose} />
        </Modal.Header>
        <Modal.Body>
          <form className="mt-1" onSubmit={onSubmit}>
            <Form.Group id="email" className="mb-4">
              <Form.Label>Let's start with the template name</Form.Label>
              <InputGroup>
                {/* <InputGroup.Text>
                          <FontAwesomeIcon icon={faEnvelope} />
                        </InputGroup.Text> */}
                <Form.Control
                  autoFocus
                  name="name"
                  {...name}
                  type="text"
                  placeholder="Awesome Template"
                  required
                />
              </InputGroup>
            </Form.Group>
            <Form.Group id="email" className="mb-4">
              <Form.Label>Describe your template</Form.Label>
              <InputGroup>
                {/* <InputGroup.Text>
                          <FontAwesomeIcon icon={faEnvelope} />
                        </InputGroup.Text> */}
                <Form.Control
                  name="name"
                  {...description}
                  type="text"
                  placeholder="Enter description here ... "
                />
              </InputGroup>
            </Form.Group>

            <div className="d-flex justify-content-between flex-wrap flex-md-nowrap align-items-center py-4">
              <Button variant="danger" onClick={handleClose}>
                Close
              </Button>
              <Button variant="info" className="ms-auto" type="submit">
                Lets do this
              </Button>
            </div>
          </form>
        </Modal.Body>
      </Modal>

      <PreviewModal show={show} setShow={setShow} content={data} />
      <div className="container-fluid mt--6">
        <div className="row justify-content-center">
          <div className="col-lg-10 card-wrapper">
            {/* <div className="d-block mb-4 mb-md-0">
              <Alert variant="success" show={true}>
                <div className="d-flex justify-content-between">
                  <div>
                    <FontAwesomeIcon icon={faDatabase} className="me-1" />
                    <strong>Message Logs!</strong> All messages sent
                  </div>
                </div>
              </Alert>
            </div> */}
            <div className="table-settings mb-4">
              {/* {JSON.stringify(message_logs)} */}
              <Row className="justify-content-between align-items-center">
                <Col xs={8} md={6} lg={3} xl={4}>
                  {/* <InputGroup>
                    <InputGroup.Text>
                      <FontAwesomeIcon icon={faSearch} />
                    </InputGroup.Text>
                    <Form.Control type="text" placeholder="Search" />
                  </InputGroup> */}
                  <ButtonGroup>
                    {/* <ComposeButton appid={props.match.params.org_id} /> */}
                    <Button
                      variant="secondary"
                      size="md"
                      onClick={() => setShowDefault(true)}
                    >
                      <FontAwesomeIcon icon={faAt} className="me-2" /> Create
                      New Template
                    </Button>
                    {/* <SMSButton appid={props.match.params.org_id} /> */}
                  </ButtonGroup>
                </Col>
                <Col xs={4} md={2} xl={1} className="ps-md-0 text-end">
                  <Dropdown as={ButtonGroup}>
                    <Dropdown.Toggle
                      split
                      as={Button}
                      variant="link"
                      className="text-dark m-0 p-0"
                    >
                      <span className="icon icon-sm icon-gray">
                        <FontAwesomeIcon icon={faCog} />
                      </span>
                    </Dropdown.Toggle>
                    <Dropdown.Menu className="dropdown-menu-xs dropdown-menu-right">
                      <Dropdown.Item className="fw-bold text-dark">
                        Show
                      </Dropdown.Item>
                      <Dropdown.Item className="d-flex fw-bold">
                        10{" "}
                        <span className="icon icon-small ms-auto">
                          <FontAwesomeIcon icon={faCheck} />
                        </span>
                      </Dropdown.Item>
                      <Dropdown.Item className="fw-bold">20</Dropdown.Item>
                      <Dropdown.Item className="fw-bold">30</Dropdown.Item>
                    </Dropdown.Menu>
                  </Dropdown>
                </Col>
              </Row>
            </div>

            <div className="card">
              {/* {JSON.stringify(smsCount)}
            {JSON.stringify(emailCount)} */}
              <Tab.Container defaultActiveKey="dashboard">
                <Row>
                  <Col lg={12}>
                    <Nav
                      // fill
                      className="p-4"
                      variant="pills"
                    >
                      <Nav.Item>
                        <Nav.Link eventKey="dashboard">
                          <FontAwesomeIcon
                            icon={faSwatchbook}
                            className="me-2"
                          />{" "}
                          My Templates
                        </Nav.Link>
                      </Nav.Item>
                      <Nav.Item>
                        <Nav.Link eventKey="online" className="mb-sm-3 mb-md-0">
                          <FontAwesomeIcon icon={faPalette} className="me-2" />{" "}
                          Browse Online Templates
                        </Nav.Link>
                      </Nav.Item>
                    </Nav>
                    <Tab.Content>
                      <Tab.Pane eventKey="dashboard">
                        <div className="card-body">
                          

                          <div className="row align-items-center">
                            {loading ? (
                              <Lottie
                                style={{ width: "150px" }}
                                options={{
                                  animationData: animation,
                                }}
                              />
                            ) : (
                              ""
                            )}

<ul>
                            {templatesData.map((template, i) => {
                              return (
                                <li
                                  class="booking-card"
                                  
                                >
                                  <div class="book-container">
                                  {template.hasOwnProperty("html") ? (
                                      <Preview content={template.html} />
                                    ) : (
                                      <Card.Img
                                        variant="top"
                                        src={
                                          template.hasOwnProperty("previewUrl")
                                            ? template.previewUrl
                                            : DefaultImg
                                        }
                                      />
                                    )}
                                    {/* <Preview content={template.html} /> */}
                                    <div class="informations-container">
                                      <div className="wrapper">
                                      <h2 class="title">  {template.title}</h2>
                                   
                                    <p class="price"></p>
                                    <div class="more-information">
                                    <div class="info-and-date-container">
                                      <Button
                                        variant="outline-success"
                                        className="mb-3 w-100"
                                        onClick={() =>
                                          setShowModal(template.html)
                                        }
                                      >
                                        <FontAwesomeIcon
                                          icon={faEye}
                                          className="me-1"
                                        />{" "}
                                        Preview
                                      </Button>
                                      </div>
                                      <div class="info-and-date-container">


                                      <TemplateButton
                                        appid={props.match.params.org_id}
                                        template_id={template._id}
                                      />
                                        {/* <div class="box info">
                                          <p>Edit template</p>  
                                        </div>
                                        <div class="box date">
                                          <p>Preview</p>
                                        </div> */}
                                      </div>

                                      <div class="info-and-date-container">
                                      <Button
                                        variant="danger"
                                        className="mb-3 w-100"
                                        onClick={() =>
                                          deleteTemplatePayload(template._id)
                                        }
                                      >
                                        <FontAwesomeIcon
                                          icon={faTrash}
                                          className="me-1"
                                        />{" "}
                                        Delete template
                                      </Button>
                                        </div>
                                      <p class="sub-title">
                                    {template.description}
                                    </p>
                                    </div>

                                      </div>
                                   
                                  </div>
                                  </div>
                                 
                                </li>
                              );
                            })}

                          </ul>
                          
                          </div>
                        </div>
                      </Tab.Pane>
                      <Tab.Pane eventKey="online">
                        <div className="card-body">
                          <div className="container-fluid mt--6">
                            <div className="row justify-content-center">
                              <div className="col-lg-12 card-wrapper">
                                <Card className="pb-0">
                                  <div className="card-body pb-0 background-grey">
                                    <Row className="pt-2 align-items-center">
                                      <Col md={4}>
                                        <Button
                                          variant="primary"
                                          className="mb-3 w-100"
                                          size="lg"
                                          onClick={() => setShowDefault(true)}
                                        >
                                          <FontAwesomeIcon
                                            icon={faRocket}
                                            className="me-1"
                                          />{" "}
                                          BUILD TEMPLATE FROM SCRATCH
                                        </Button>
                                      </Col>
                                      <Col md={2}>
                                        <Form.Group className="mb-4">
                                          <Form.Label>MODE</Form.Label>
                                          <Form.Check
                                            label="PREMIUM"
                                            id="checkbox1"
                                            htmlFor="checkbox1"
                                          />
                                          <Form.Check
                                            checked
                                            label="FREE"
                                            id="checkbox2"
                                            htmlFor="checkbox2"
                                          />
                                        </Form.Group>
                                      </Col>
                                      <Col md={6}>
                                        <Form.Group id="email" className="mb-4">
                                          <Form.Label>USAGE</Form.Label>
                                          <InputGroup>
                                            <Form.Control
                                              as="select"
                                              name="emailColumn"
                                            >
                                              <option value="Activation">
                                                Activation
                                              </option>
                                              <option value="User-acquisition">
                                                User Acquisition
                                              </option>
                                              <option value="Ecommerce">
                                                E-commerce
                                              </option>
                                              <option value="Newsletter">
                                                Newsletter
                                              </option>
                                              <option value="Notification">
                                                Notication
                                              </option>
                                            </Form.Control>
                                          </InputGroup>
                                        </Form.Group>
                                      </Col>
                                    </Row>
                                  </div>
                                </Card>
                              </div>
                            </div>
                          </div>
                          <div className="row align-items-center">
                            <Row className="p-4">
                              {/* {JSON.stringify(allTemplates)} */}
                              <ul>
                            {AlltemplatesData.map((template, i) => {
                              return (
                                <li
                                  class="booking-card"
                                  
                                >
                                  <div class="book-container">
                                  {template.hasOwnProperty("html") ? (
                                      <Preview content={template.html} />
                                    ) : (
                                      <Card.Img
                                        variant="top"
                                        src={
                                          template.hasOwnProperty("previewUrl")
                                            ? template.previewUrl
                                            : DefaultImg
                                        }
                                      />
                                    )}
                                    {/* <Preview content={template.html} /> */}
                                    <div class="informations-container">
                                      <div className="wrapper">
                                      <h2 class="title">  {template.title}</h2>
                                   
                                    <p class="price"></p>
                                    <div class="more-information">
                                    <div class="info-and-date-container">
                                      <Button
                                        variant="outline-success"
                                        className="mb-3 w-100"
                                        onClick={() =>
                                          setShowModal(template.html)
                                        }
                                      >
                                        <FontAwesomeIcon
                                          icon={faEye}
                                          className="me-1"
                                        />{" "}
                                        Preview
                                      </Button>
                                      </div>
                                      <div class="info-and-date-container">


                                      <Button
                                          variant="secondary"
                                          className="mb-3 w-100"
                                          onClick={() =>
                                            setTemplateThis(template)
                                          }
                                        >
                                          <FontAwesomeIcon
                                            icon={faRocket}
                                            className="me-1"
                                          />{" "}
                                          Use this Template
                                        </Button>
                                       
                                        {/* <div class="box info">
                                          <p>Edit template</p>  
                                        </div>
                                        <div class="box date">
                                          <p>Preview</p>
                                        </div> */}
                                      </div>
                                      <p class="sub-title">
                                    {template.description}
                                    </p>
                                      <p className="fs-7 text-gray-700 text-center">
                                          Made with
                                          <span
                                            role="img"
                                            aria-label="gratitude"
                                          >
                                            💙
                                          </span>{" "}
                                          by SB
                                        </p>
                                    </div>

                                      </div>
                                   
                                  </div>
                                  </div>
                                 
                                </li>
                              );
                            })}

                          </ul>
                          
                            </Row>
                          </div>
                        </div>
                      </Tab.Pane>
                    </Tab.Content>
                  </Col>
                </Row>
              </Tab.Container>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

const useFormInput = (initialValue) => {
  const [value, setValue] = useState(initialValue);

  const handleChange = (e) => {
    setValue(e.target.value);
  };

  function handleReset() {
    setValue("");
  }
  return {
    value,
    onChange: handleChange,
    onReset: handleReset,
  };
};

const mapStateToProps = (state) => {
  return {
    templatesData: state.templatesData,
    AlltemplatesData: state.AlltemplatesData,
    loading: state.loading,
    message: state.message,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    getTemplates: (id) =>
      dispatch({ type: "GET_TEMPLATES_REQUEST", application_id: id }),
    getAllTemplates: () => dispatch({ type: "GET_ALL_TEMPLATES_REQUEST" }),
  };
};

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(OutboxPage)
);
