import React from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";

import CleanupTable from "./CleanupTable";
import {
  Row,
  Col,
  Card,
  Form,
  Button,
  InputGroup,
  FormControl,
} from "@themesberg/react-bootstrap";

class CleanupComponent extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      // Set initial files, type 'local' means this is a file
      // that has already been uploaded to the server (see docs)
      node: null,
      isGoing: true,
      case: "default",
    };

    this.handleInputChange = this.handleInputChange.bind(this);
  }

  handleAnnotationChange(event, label) {
    const target = event.target;
    const value = target.value;
    const name = target.name;

    console.log(label);
    console.log(value);
    console.log(name);

    let node = this.props.node;
    let model = this.props.model;
    let currNode = model.getNode(node.id);

    currNode.properties[name] = value;

    this.props.updateNode();
    this.forceUpdate();
  }

  handleInputChange(event, label) {
    const target = event.target;
    const value = target.type === "checkbox" ? target.checked : target.value;
    const name = target.name;

    let node = this.props.node;
    let model = this.props.model;
    let currNode = model.getNode(node.id);

    currNode.properties.cleanupConfigs[name] = value;

    this.props.updateNode();
  }

  componentDidMount = function () {
    console.log("Mounted");
    console.log(this.props.selected_node);
  };

  render() {
    let selected_node = this.props.selected_node;
    return (
      <div className="modal-body">
        <div className="text-left">
          <Form>
            <Row>
              <Col md={12}>
                <CleanupTable
                  dtypes_={selected_node}
                  model={this.props.model}
                />
              </Col>
              <Col md={12}>
                <h5 className="mt-0">Replace NaN entries</h5>
                <hr />
                <InputGroup className="mb-3">
                  {/* <InputGroup.Prepend>
                    <InputGroup.Checkbox
                      name="replace_empty_string"
                      
                    />
                  </InputGroup.Prepend> */}
                  <Form.Check
                    name="replace_empty_string"
                    label="Replace with an empty string (String Columns)"
                    id="checkbox1"
                    checked={
                      selected_node["properties"]["cleanupConfigs"][
                        "replace_empty_string"
                      ]
                    }
                    onChange={(e) =>
                      this.handleInputChange(e, "replace_empty_string")
                    }
                    htmlFor="checkbox1"
                  />
                  
                </InputGroup>

                <InputGroup className="mb-3">
                  <Form.Check
                    name="replace_empty_number"
                    label="Replace with Zeros (Numerical Fields Columns)"
                    checked={
                      selected_node["properties"]["cleanupConfigs"][
                        "replace_empty_number"
                      ]
                    }
                    onChange={(e) =>
                      this.handleInputChange(e, "replace_empty_number")
                    }
                  />
                 
                </InputGroup>

                <InputGroup className="mb-3">
                  <Form.Check
                    name="drop_na_values"
                    checked={
                      selected_node["properties"]["cleanupConfigs"][
                        "drop_na_values"
                      ]
                    }
                    onChange={(e) =>
                      this.handleInputChange(e, "drop_na_values")
                    }
                    label="Drop all rows with any NA values"
                  />
                 
                </InputGroup>

                <h5 className="mt-0">Remove characters</h5>
                <hr />

                <InputGroup className="mb-3">
                  <Form.Check
                    name="trailing_spaces"
                    checked={
                      selected_node["properties"]["cleanupConfigs"][
                        "trailing_spaces"
                      ]
                    }
                    onChange={(e) =>
                      this.handleInputChange(e, "trailing_spaces")
                    }
                    label="Leading and trailing spaces"
                  />
                 
                </InputGroup>
                <InputGroup className="mb-3">
                  <Form.Check
                      name="whitespaces"
                      checked={
                        selected_node["properties"]["cleanupConfigs"][
                          "whitespaces"
                        ]
                      }
                      onChange={(e) => this.handleInputChange(e, "whitespaces")}
                      label="All Whitespaces"
                    />
               
                </InputGroup>
                <InputGroup className="mb-3">
                  <Form.Check
                      name="numbers"
                      checked={
                        selected_node["properties"]["cleanupConfigs"]["numbers"]
                      }
                      onChange={(e) => this.handleInputChange(e, "numbers")}
                      label="Numbers"
                    />
                  
                </InputGroup>
                <InputGroup className="mb-3">
                  <Form.Check
                      name="letters"
                      checked={
                        selected_node["properties"]["cleanupConfigs"]["letters"]
                      }
                      onChange={(e) => this.handleInputChange(e, "letters")}
                      label="Letters"
                    />
                
                </InputGroup>
                <InputGroup className="mb-3">
                  <Form.Check
                      name="punctuations"
                      checked={
                        selected_node["properties"]["cleanupConfigs"][
                          "punctuations"
                        ]
                      }
                      onChange={(e) =>
                        this.handleInputChange(e, "punctuations")
                      }
                      label="Punctuations"
                    />
                  
                </InputGroup>
                <InputGroup className="mb-3">
                  <Form.Check
                      name="symbols"
                      checked={
                        selected_node["properties"]["cleanupConfigs"]["symbols"]
                      }
                      onChange={(e) => this.handleInputChange(e, "symbols")}
                      label="Symbols"
                    />
                  
                </InputGroup>

                <h5 className="mt-0">Change Case</h5>
                <hr />
                <Form.Check
                  custom
                  type="radio"
                  value="default"
                  label="Default"
                  checked={
                    selected_node["properties"]["cleanupConfigs"]["cases"] ==
                    "default"
                  }
                  onChange={(e) => this.handleInputChange(e, "cases")}

                  name="cases"
                  id="mode1"
                />
                <Form.Check
                  custom
                  type="radio"
                  value="uppercase"
                  label="Upper Case"
                  checked={
                    selected_node["properties"]["cleanupConfigs"]["cases"] ==
                    "uppercase"
                  }
                  onChange={(e) => this.handleInputChange(e, "cases")}
                  name="cases"
                  id="mode2"
                />
                <Form.Check
                  custom
                  type="radio"
                  value="lowercase"
                  label="Lower Case"
                  checked={
                    selected_node["properties"]["cleanupConfigs"]["cases"] ==
                    "lowercase"
                  }
                  onChange={(e) => this.handleInputChange(e, "cases")}
                  name="cases"
                  id="mode3"
                />
                <Form.Check
                  custom
                  type="radio"
                  value="capitalize"
                  label="Capitalize"
                  checked={
                    selected_node["properties"]["cleanupConfigs"]["cases"] ==
                    "capitalize"
                  }
                  onChange={(e) => this.handleInputChange(e, "cases")}
                  name="cases"
                  id="mode4"
                />
              </Col>

              <Col md={12}>
                <Form.Group controlId="exampleForm.ControlTextarea1">
                  <Form.Label>Comment</Form.Label>
                  <Form.Control
                    as="textarea"
                    rows="3"
                    name="annotation"
                    value={
                      selected_node["properties"].hasOwnProperty("annotation")
                        ? selected_node["properties"]["annotation"]
                        : ""
                    }
                    onChange={(e) => this.handleAnnotationChange(e, "password")}
                  />
                </Form.Group>
              </Col>
            </Row>
          </Form>
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {};
};

const mapDispatchToProps = (dispatch) => {
  return {};
};

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(CleanupComponent)
);
