import React from "react";
import ReactDOM from "react-dom";

import { createStore,  applyMiddleware, compose } from "redux";
import { Provider } from "react-redux";
import { BrowserRouter as Router } from "react-router-dom";

// core styles
import "./scss/volt.scss";

// vendor styles
import "@fortawesome/fontawesome-free/css/all.css";
import "react-datetime/css/react-datetime.css";
import Firebase, { FirebaseContext } from "./components/Firebase";
import App from "./components/App";

import { createBrowserHistory } from "history";

import createSagaMiddleware from "redux-saga";

import reducer from "./store/reducer";

import sagas from "./store/sagas";

// create the saga middleware
const sagaMiddleware = createSagaMiddleware();

// const store = createStore(reducer);


// create a redux store with our reducer above and middleware
let store = createStore(
  reducer,
  compose(applyMiddleware(sagaMiddleware))
);

// run the saga
sagaMiddleware.run(sagas);

const history = createBrowserHistory();


ReactDOM.render(
  <React.StrictMode>
    <FirebaseContext.Provider value={new Firebase()}>
        <Provider store={store}>
        {/* <BrowserRouter basename={config.basename}> */}

        <Router history={history}>
          {/* basename="/datta-able" */}
          <App />
        </Router>
        {/* </BrowserRouter> */}
      </Provider>
    </FirebaseContext.Provider>
  </React.StrictMode>,

  document.getElementById("root")
);
