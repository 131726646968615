import React, { useState, useEffect } from "react";
import { Redirect } from "react-router-dom";

import { connect } from "react-redux";
import HomePage from "../HomePage";
import ScrollToTop from "../ScrollToTop";
import { withFirebase } from "../Firebase";
import { AuthUserContext } from "../Session";
import "./style.css";

import { compose } from "recompose";

function App(props) {
  const { authUser, setAuth, setBusinessAccount } = props;

  useEffect(() => {
    // for (let i = 0; i < 100; i++) {
    //   window.clearInterval(i);
    // }
  }, []);

  useEffect(() => {
    const unsubscribe = props.firebase.auth.onAuthStateChanged((authUser) => {
      if (authUser) {
        setAuth(authUser);

        // console.log(authUser);
        //Get business account and save details
        let current_bsa = props.firebase.db
          .collection("business_accounts")
          .where("uid", "==", authUser.uid)
          .get()
          .then((querySnapshot) => {
            let bsa = {};
            querySnapshot.forEach((doc) => {
              // doc.data() is never undefined for query doc snapshots
              console.log(doc.id, " => ", doc.data());
              bsa = { ...doc.data(), id: doc.id };
            });

            setBusinessAccount(bsa);
          })
          .catch((error) => {
            console.log("Error getting documents: ", error);
          });
      } else {
        setAuth(null);
        //save null business account
      }
    });

    return () => {
      unsubscribe();
    };
  });

  return (
    <AuthUserContext.Provider value={authUser}>
      <div>
        <ScrollToTop />
        {/* {authUser == null ? (
          <Redirect
            to={{
              pathname: "/",
              state: { from: props.location },
            }}
          />
        ) : (
          
        )} */}
        <HomePage />
      </div>
    </AuthUserContext.Provider>
  );
}

const mapStateToProps = (state) => ({
  authUser: state.authUser,
});

const mapDispatchToProps = (dispatch) => {
  return {
    setAuth: (authUser) => dispatch({ type: "SAVE_USER", authUser: authUser }),
    setBusinessAccount: (bsa) => dispatch({ type: "SAVE_BSA", bsa: bsa }),
  };
};

export default compose(
  connect(mapStateToProps, mapDispatchToProps),
  withFirebase
)(App);

// class App extends Component {
//   constructor(props) {
//     super(props);

//     this.state = {
//       authUser: null,
//     };
//   }

//   componentDidMount() {
//     this.listener = this.props.firebase.auth.onAuthStateChanged((authUser) => {
//       authUser
//         ? this.setState({ authUser })
//         : this.setState({ authUser: null });
//     });
//   }

//   componentWillUnmount() {
//     this.listener();
//   }

//   render() {
//     return (
//       <AuthUserContext.Provider value={this.state.authUser}>
//           <div>
//             <ScrollToTop />
//             <HomePage />
//           </div>
//       </AuthUserContext.Provider>
//     );
//   }
// }

// export default withFirebase(App);
