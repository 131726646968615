import React, { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import ToolPallette from "./ToolPallette";
import Playground from "./Playground";
import {
  Col,
  Row,
  Button,
  Dropdown,
  ButtonGroup,
  Tabs,
  Nav,
  Tab,
  Breadcrumb,
  InputGroup,
  Alert,
} from "@themesberg/react-bootstrap";
import workflows from "../../../../data/workflows";
import "./style.css";
import {
  faCogs,
  faDatabase,
  faArrowLeft,
} from "@fortawesome/free-solid-svg-icons";

import { withFirebase } from "../../../Firebase";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

const INITIAL_DATA = null;
function CampaignEditor(props) {
  const [campaignData, setcampaignData] = useState(INITIAL_DATA);
  let campaign_id = props.match.params.campaign_id;

  useEffect(() => {
    document.title = `Canvas - Campaign Page`;

    if (campaign_id) {
      var docRef = props.firebase.db
        .collection("organisation_campaigns")
        .doc(campaign_id);

      docRef
        .get()
        .then((doc) => {
          if (doc.exists) {
            console.log("Document data:", doc.data());
            setcampaignData(doc.data());
          } else {
            // doc.data() will be undefined in this case
            console.log("No such document!");
          }
        })
        .catch((error) => {
          console.log("Error getting document:", error);
        });

      return () => {
        // setLoading(true)
        setcampaignData(null);
      };
    }
  }, [props.firebase, campaign_id]);

  function BackButton(props) {
    const history = useHistory();

    function handleClick() {
      history.push("/org/" + props.appid + "/campaigns");
    }

    return (
      <Button variant="himenu-accent" size="sm" onClick={handleClick}>
        <FontAwesomeIcon icon={faArrowLeft} className="me-1" /> Go back to
        campaigns
      </Button>
    );
  }
  return (
    <>
      <div className="container-fluid mt--6">
        <div className="row justify-content-center">
          <div className="col-lg-12 card-wrapper">
         
            <div className="d-flex justify-content-between flex-wrap flex-md-nowrap align-items-center py-4">
              <BackButton appid={props.match.params.org_id} />
            </div>
            {campaignData ? (
                    <Playground
                      workflow_payload={workflows[0]}
                      campaignData={campaignData}
                      appid={props.match.params.org_id}
                    />
                  ) : (
                    "Processing"
                  )}
          
          </div>
        </div>
      </div>
    </>
  );
}

export default withFirebase(CampaignEditor);
// export default CampaignEditor;
