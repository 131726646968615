import React, { useState, useEffect, useCallback } from "react";

import { useHistory } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faAt,
  faCheck,
  faCog,
  faDatabase,
  faEye,
  faHome,
  faMailBulk,
  faSearch,
  faSms, faAngleDoubleLeft, faAngleDoubleRight
} from "@fortawesome/free-solid-svg-icons";

import {
  Col,
  Row,
  Form,
  Button,
  ButtonGroup,
  Breadcrumb,
  InputGroup,
  Dropdown,
  Alert,
  Card, Pagination  
} from "@themesberg/react-bootstrap";

import { withRouter } from "react-router-dom";

import { connect } from "react-redux";

import ReactDataGrid from "@inovua/reactdatagrid-community";
import "@inovua/reactdatagrid-community/index.css";

import '@inovua/reactdatagrid-community/base.css'
import '@inovua/reactdatagrid-community/theme/blue-light.css'

const gridStyle = { minHeight: 550, marginTop: 10 };


const columns = [
//   { name: "_id", header: "#", 
//   render: ({ value }) => <span>---</span>
// },
  { name: "_id", maxWidth: 40, header: "_id", defaultVisible: false },
  { name: "title",   header: "Title", render: ({ value }) => value == "-" ? "EMAIL-AUTO" : value },

  { name: "description",   header: "Description" },
//   { name: "data_source_type", header: "data_source_type" },
  { name: "text_content",  header: "text_content" },
  { name: "template_type",  header: "Type" },
//   { name: "design_template_id",  header: "design_template_id"
// },
  { name: "status",  header: "Status" },
  { name: "created_on",  header: "Date Created" },
  { name: "modified_on",  header: "Modified On" }
 
];

const loadData = ({ skip, limit, sortInfo, groupBy, filterValue  }) => {
  const url =
    "https://api.bunicom.com/get-templates-ops?" +
    'skip='+skip+'&template_type=SMS&limit='+limit+(groupBy && groupBy.length?'&groupBy='+groupBy:'')+'&sortInfo='+JSON.stringify(sortInfo) + '&filterBy='+JSON.stringify(filterValue)
    // "&skip=" +
    // skip +
    // "&limit=" +
    // limit +
    // "&sortInfo=" +
    // JSON.stringify(sortInfo);

  return fetch(url).then((response) => {
    const totalCount = response.headers.get("X-Total-Count");
    //   for (var pair of response.headers.entries()) {
    //     console.log(pair[0]+ ': '+ pair[1]);
    //  }
    return response.json().then((data) => {
      return Promise.resolve({ data, count: parseInt(totalCount) });
    });
  });
};

function ComposeButton(props) {
  const history = useHistory();

  function handleClick() {
    history.push("/org/" + props.appid + "/sms/msg/"+props.id);
    console.log("Soon");
  }

  return (
    <Button variant="light" size="md" onClick={handleClick}>
      View SMS
    </Button>
  );
}

function SMSButton(props) {
  const history = useHistory();

  function handleClick() {
    history.push("/org/" + props.appid + "/sms");
  }

  return (
    <Button variant="outline-secondary" size="md" onClick={handleClick}>
      <FontAwesomeIcon icon={faMailBulk} className="me-2" />
      Send SMS
    </Button>
  );
}

function OpsPage(props) {
  const history = useHistory();
  const [defaultSortInfo, setdefaultSortInfo] = useState({
    app_id: props.match.params.org_id,
  });
  const [sortInfo, setSortInfo] = useState({
    app_id: props.match.params.org_id,
  });

  const [columnData,setColumnData] = useState(columns)

  const dataSource = useCallback(loadData, []);

  const saveSortInfos = (data) => {
    setSortInfo({ ...data, app_id: props.match.params.org_id });
  };

  useEffect(() => {

    let cols =  [
    //   { name: "_id", header: "#",
    //   render: ({ value }) => <ComposeButton id={value} appid={props.match.params.org_id} />
    // },
    { name: "_id", maxWidth: 40, header: "_id", defaultVisible: false },
    { name: "title",   header: "Title", render: ({ value }) => value == "-" ? "EMAIL-AUTO" : value },
  
    { name: "description",   header: "Description" },
    // { name: "data_source_type", header: "data_source_type" },
    { name: "text_content",  header: "text_content" },
    { name: "template_type",  header: "Type" },
//     { name: "design_template_id",  header: "design_template_id"
//   },
    { name: "status",  header: "Status" },
    { name: "created_on",  header: "Date Created" },
    { name: "modified_on",  header: "Modified On" }
    ];

    setColumnData(cols)

  }, []);


  const CustomPagination = (props) => {
    const [activeItem, setActiveItem] = React.useState(2);
    const { totalPages = 5, size = "md", withIcons = false, disablePrev = false } = props;
  
    const onPrevItem = () => {
      const prevActiveItem = activeItem === 1 ? activeItem : activeItem - 1;
      setActiveItem(prevActiveItem);
    };
  
    const onNextItem = (totalPages) => {
      const nextActiveItem = activeItem === totalPages ? activeItem : activeItem + 1;
      setActiveItem(nextActiveItem);
    };
  
    const items = [];
    for (let number = 1; number <= totalPages; number++) {
      const isItemActive = activeItem === number;
  
      const handlePaginationChange = () => {
        setActiveItem(number);
      };
  
      items.push(
        <Pagination.Item active={isItemActive} key={number} onClick={handlePaginationChange}>
          {number}
        </Pagination.Item>
      );
    };
  
    return (
      <Pagination size={size} className="mt-3">
        <Pagination.Prev disabled={disablePrev} onClick={onPrevItem}>
          {withIcons ? <FontAwesomeIcon icon={faAngleDoubleLeft} /> : "Previous"}
        </Pagination.Prev>
        {items}
        <Pagination.Next onClick={() => onNextItem(totalPages)}>
          {withIcons ? <FontAwesomeIcon icon={faAngleDoubleRight} /> : "Next"}
        </Pagination.Next>
      </Pagination>
    );
  };
  
  

  return (
    <>
      <div className="container-fluid mt--6">
        <div className="row justify-content-center">
          <div className="col-lg-12 card-wrapper">
            <div className="d-block mb-4 mb-md-0">
              <Alert variant="secondary" show={true}>
                <div className="d-flex justify-content-between">
                  <div>
                    <FontAwesomeIcon icon={faDatabase} className="me-1" />
                    <strong>Simple SMS Saved Templates</strong>
                  </div>
                </div>
              </Alert>
            </div>

            <ReactDataGrid
              idProperty="id"
              style={gridStyle}
              columns={columnData}
              pagination
              theme="blue-light"
              defaultSortInfo={defaultSortInfo}
              sortInfo={sortInfo}
              onSortInfoChange={saveSortInfos}
              dataSource={dataSource}
              defaultLimit={20}
            />
{/* <CustomPagination withIcons /> */}
            
          </div>
        </div>
      </div>
      {/* <div className="d-flex justify-content-between flex-wrap flex-md-nowrap align-items-center py-4">
        <div className="d-block mb-4 mb-md-0">
          <Breadcrumb
            className="d-none d-md-inline-block"
            listProps={{ className: "breadcrumb-dark breadcrumb-transparent" }}
          >
            <Breadcrumb.Item>
              <FontAwesomeIcon icon={faHome} />
            </Breadcrumb.Item>
            <Breadcrumb.Item>BuniComms</Breadcrumb.Item>
            <Breadcrumb.Item active>Transactions</Breadcrumb.Item>
          </Breadcrumb>
          <h4>Sent Messages</h4>
          <p className="mb-0">Your web analytics dashboard template.</p>
        </div>
        <div className="btn-toolbar mb-2 mb-md-0">
          <ButtonGroup>
            <Button variant="outline-primary" size="sm">
              Share
            </Button>
            <Button variant="outline-primary" size="sm">
              Export
            </Button>
          </ButtonGroup>
        </div>
      </div> */}
    </>
  );
}
const mapStateToProps = (state) => {
  return {
    message_logs: state.message_logs,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {};
};

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(OpsPage)
);
