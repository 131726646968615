import React from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";

import { Row, Col, Form, Alert } from "@themesberg/react-bootstrap";
import shortid from "shortid";
import HeaderTable from "./HeaderTable";
import BodyTable from "./BodyTable";
shortid.characters(
  "0123456789abcdefghijklmnopqrstuvwxyzABCDEFGHIJKLMNOPQRSTUVWXYZ$@"
);

class RequestNodeComponent extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      // Set initial files, type 'local' means this is a file
      // that has already been uploaded to the server (see docs)
      node: null,
      entities: [],
      showTags: [
        {
          title: "default",
          value: "data",
        },
      ],
    };

    this.handleInputChange = this.handleInputChange.bind(this);
  }

  handleInputChange(event, label) {
    const target = event.target;
    const value = target.type === "checkbox" ? target.checked : target.value;
    const name = target.name;

    console.log(label);
    console.log(value);
    console.log(name);

    let node = this.props.node;
    let model = this.props.model;
    let currNode = model.getNode(node.id);

    if (label == "_username" || label == "_password") {
      currNode.properties.toolConfig["authorization_data"][name] = value;
    } else {
      currNode.properties.toolConfig[name] = value;
    }

    this.props.updateNode();
    this.forceUpdate();
  }

  handleSelectInputChange(event, label) {
    const target = event.target;
    const value = target.type === "checkbox" ? target.checked : target.value;
    const name = target.name;

    console.log(label);
    console.log(value);
    console.log(name);

    let node = this.props.node;
    let model = this.props.model;
    let currNode = model.getNode(node.id);

    let arrUpdated = [
      ...this.state.showTags,
      { title: shortid.generate(), value: value },
    ];

    this.setState({ showTags: arrUpdated });

    currNode.properties.toolConfig[name].push(value);

    this.props.updateNode();
    this.forceUpdate();
  }
  handleBodyChange(event, lbl) {
    const target = event.target;
    const value = target.value;

    console.log(value);

    let node = this.props.node;
    let model = this.props.model;
    let currNode = model.getNode(node.id);

    currNode.properties.toolConfig[lbl] = value;

    this.props.updateNode();
    this.forceUpdate();
  }
  handleAnnotationChange(event, label) {
    const target = event.target;
    const value = target.value;
    const name = target.name;

    console.log(label);
    console.log(value);
    console.log(name);

    let node = this.props.node;
    let model = this.props.model;
    let currNode = model.getNode(node.id);

    currNode.properties[name] = value;

    this.props.updateNode();
    this.forceUpdate();
  }
  setShow(value, elem) {
    if (this.state.showTags.length == 1) {
      
      alert(
        "Minimum Node Limit! If you want to select the parent node, please deselect normalize json"
      );
    } else {
      let visibleTags = this.state.showTags.filter((e) => {
        return e.title != elem;
      });

      this.setState({ showTags: visibleTags });
      let filteredTags = visibleTags.map((i) => {
        return i["value"];
      });

      // console.log(visibleTags);

      let node = this.props.node;
      let model = this.props.model;
      let currNode = model.getNode(node.id);

      currNode.properties.toolConfig["normalize_field"] = filteredTags;

      this.props.updateNode();
      this.forceUpdate();
    }
  }

  componentDidMount() {
    let showTagsArr = [];

    this.props.node.properties["toolConfig"]["normalize_field"].forEach(
      (element) => {
        showTagsArr.push({ title: shortid.generate(), value: element });
      }
    );

    if (showTagsArr.length < 1) {
      showTagsArr.push({ title: shortid.generate(), value: "data" });
    }

    this.setState({ showTags: showTagsArr });
  }

  saveUpdate(headers) {
    console.log("Log this to console - Update and save node");
    let node = this.props.node;
    let model = this.props.model;
    let currNode = model.getNode(node.id);

    currNode.properties.toolConfig["headers"] = headers;

    this.props.updateNode();
    this.forceUpdate();
  }

  saveUpdateBody(body) {
    console.log("Log this to console - Update and save node");
    let node = this.props.node;
    let model = this.props.model;
    let currNode = model.getNode(node.id);

    currNode.properties.toolConfig["body"] = body;
    currNode.properties.toolConfig["body_string"] = JSON.stringify(body);

    this.props.updateNode();
    this.forceUpdate();
  }
  render() {
    return (
      <div className="modal-body">
        <div className="text-left">
          {/* <h6 className="heading mt-1">Request Tool Properties</h6> */}
          <Form>
            <Row>
              <Col md={12} sm={12}>
                <Form.Group className="mb-3">
                  <Form.Label>URL</Form.Label>
                  <Form.Control
                    type="text"
                    placeholder=""
                    name="url"
                    value={this.props.node.properties["toolConfig"]["url"]}
                    onChange={(e) => this.handleInputChange(e, "url")}
                  />
                </Form.Group>
              </Col>
              <Col md={12} sm={12} className="mt-2">
                <Form.Group className="mb-3">
                  <Form.Label>Method</Form.Label>
                  <Form.Select
                    name="method"
                    value={this.props.node.properties["toolConfig"]["method"]}
                    onChange={(e) => this.handleInputChange(e, "method")}
                  >
                    <option value="GET">GET</option>
                    <option value="POST">POST</option>
                  </Form.Select>
                </Form.Group>
              </Col>

              <Col md={12} sm={12}>
                <Form.Group className="mb-1">
                  <Form.Label>HTTP headers (Optional)</Form.Label>
                  <HeaderTable
                    node={this.props.selected_node}
                    model={this.props.model}
                    env_variables={this.props.env_variables}
                    onSaveHeader={(h) => this.saveUpdate(h)}
                  />
                </Form.Group>
                {/* <small>Example:<br /> Header-Name-1: value1<br />Header-Name-2: value2</small>  */}
              </Col>

              {this.props.node.properties["toolConfig"]["method"] == "POST" ? (
                <>
                  <Col md={12} sm={12}>
                    <Form.Group className="mb-1">
                      <Form.Label>Body Type</Form.Label>
                      <Form.Select
                        name="body_type"
                        value={
                          this.props.node.properties[
                            "toolConfig"
                          ].hasOwnProperty("body_type")
                            ? this.props.node.properties["toolConfig"][
                                "body_type"
                              ]
                            : "form-data"
                        }
                        onChange={(e) => this.handleInputChange(e, "body_type")}
                      >
                        <option value="raw">JSON</option>
                        <option value="form-data">FORM-DATA</option>
                      </Form.Select>
                    </Form.Group>
                  </Col>

                  {this.props.node.properties["toolConfig"].hasOwnProperty(
                    "body_type"
                  ) ? (
                    <Col md={12} sm={12} className="mt-2">
                      <Form.Group className="mb-3">
                        <Form.Label>HTTP message body (Optional)</Form.Label>
                        {this.props.node.properties["toolConfig"][
                          "body_type"
                        ] == "form-data" ? (
                          <BodyTable
                            node={this.props.selected_node}
                            model={this.props.model}
                            env_variables={this.props.env_variables}
                            onSaveBody={(h) => this.saveUpdateBody(h)}
                          />
                        ) : (
                          <>
                            <Form.Control
                              as="textarea"
                              rows="3"
                              name="body"
                              value={
                                this.props.node.properties[
                                  "toolConfig"
                                ].hasOwnProperty("body_string")
                                  ? this.props.node.properties["toolConfig"][
                                      "body_string"
                                    ]
                                  : ""
                              }
                              onChange={(e) =>
                                this.handleBodyChange(e, "body_string")
                              }
                            />
                            <small>
                              Example: {'{"name":"John","age":30}'} (with HTTP
                              header Content-Type: application/json)
                            </small>
                          </>
                        )}
                      </Form.Group>
                    </Col>
                  ) : (
                    <BodyTable
                      node={this.props.selected_node}
                      model={this.props.model}
                      env_variables={this.props.env_variables}
                      onSaveBody={(h) => this.saveUpdateBody(h)}
                    />
                  )}
                </>
              ) : (
                ""
              )}

              {/* <Col md={12} className="mt-2">
                <Form.Group controlId="exampleForm.ControlTextarea1">
                  <Form.Label>HTTP message body (Optional)</Form.Label>
                  <Form.Control
                    as="textarea"
                    rows="3"
                    name="annotation"
                    value={
                      this.props.node["properties"].hasOwnProperty("annotation")
                        ? this.props.node["properties"]["annotation"]
                        : ""
                    }
                    onChange={(e) =>
                      this.handleAnnotationChange(e, "annotation")
                    }
                  />
                </Form.Group>
                
              </Col> */}

              {/* <Col md={12} className="mt-2">
                <Form.Group controlId="exampleForm.ControlTextarea1">
                  <Form.Label>HTTP message headers (Optional)</Form.Label>
                  <Form.Control
                    as="textarea"
                    rows="3"
                    name="annotation"
                    value={
                      this.props.node["properties"].hasOwnProperty("annotation")
                        ? this.props.node["properties"]["annotation"]
                        : ""
                    }
                    onChange={(e) =>
                      this.handleAnnotationChange(e, "annotation")
                    }
                  />
                </Form.Group>
                <small>Example:<br /> Header-Name-1: value1<br />Header-Name-2: value2</small>
              </Col> */}

              <Col md={12} sm={12} className="mt-2">
                <h6 className="">HTTP Basic authentication (Optional)</h6>
                <Row>
                  <Col md={6} sm={6} className="mt-2">
                    <Form.Group className="mb-3">
                      <Form.Label>User</Form.Label>
                      <Form.Control
                        type="text"
                        placeholder="user"
                        name="username"
                        value={
                          this.props.node.properties["toolConfig"][
                            "authorization_data"
                          ]["username"]
                        }
                        onChange={(e) => this.handleInputChange(e, "_username")}
                      />
                    </Form.Group>
                  </Col>
                  <Col md={6} sm={6} className="mt-2">
                    <Form.Group className="mb-3">
                      <Form.Label>Password</Form.Label>
                      <Form.Control
                        type="password"
                        placeholder="password"
                        name="password"
                        value={
                          this.props.node.properties["toolConfig"][
                            "authorization_data"
                          ]["password"]
                        }
                        onChange={(e) => this.handleInputChange(e, "_password")}
                      />
                    </Form.Group>
                  </Col>
                </Row>
              </Col>

              <Col md={12} sm={12} className="mt-2">
                <Form.Group className="mb-3">
                  <Form.Check
                    label="Normalize response data"
                    id="checkbox1"
                    name="normalize"
                    htmlFor="checkbox1"
                    checked={
                      this.props.node.properties["toolConfig"]["normalize"]
                    }
                    onChange={(e) => this.handleInputChange(e, "normalize")}
                  />
                </Form.Group>
              </Col>

              {this.props.node.properties.toolConfig.normalize ? (
                <Col md={12} sm={12} className="mt-2">
                  <Form.Group className="mb-3">
                    <Form.Label>Field to normalize</Form.Label>
                    {this.state.showTags.map((item, key) => (
                      <Alert
                        variant="success"
                        key={key}
                        onClose={() => this.setShow(false, item.title)}
                        dismissible
                      >
                        <Alert.Heading>{item.value}</Alert.Heading>
                      </Alert>
                    ))}

                    <Form.Select
                      name="normalize_field"
                      value={
                        this.props.node.properties["toolConfig"][
                          "normalize_field"
                        ][
                          this.props.node.properties["toolConfig"][
                            "normalize_field"
                          ].length - 1
                        ]
                      }
                      onChange={(e) =>
                        this.handleSelectInputChange(e, "normalize_field")
                      }
                    >
                       <option value="default">
                                Default
                              </option>
                      {this.props.node.properties.metaData.hasOwnProperty(
                        "columns"
                      )
                        ? this.props.node.properties.metaData.columns.map(
                            (item, key) => (
                              <option key={key} value={item.column_name}>
                                {item.column_name}
                              </option>
                            )
                          )
                        : ""}
                    </Form.Select>
                  </Form.Group>
                </Col>
              ) : (
                ""
              )}

              <Col md={12} className="mt-2">
                <Form.Group controlId="exampleForm.ControlTextarea1">
                  <Form.Label>Add Comment</Form.Label>
                  <Form.Control
                    as="textarea"
                    rows="3"
                    name="annotation"
                    value={
                      this.props.node["properties"].hasOwnProperty("annotation")
                        ? this.props.node["properties"]["annotation"]
                        : ""
                    }
                    onChange={(e) =>
                      this.handleAnnotationChange(e, "annotation")
                    }
                  />
                </Form.Group>
              </Col>
            </Row>
          </Form>
          <p>{/* {node.properties.headers.join(',')} */}</p>
          {/* <p>
            <span id="coderea">
              {JSON.stringify(this.props.node.properties.metaData)}
            </span>
          </p> */}
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {};
};

const mapDispatchToProps = (dispatch) => {
  return {
    // onGetEntities: (entities) =>
    //   dispatch({ type: actionTypes.SAVE_ENTITIES, entities: entities }),
  };
};

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(RequestNodeComponent)
);
