import * as actionTypes from "./actions";

const initialState = {
  isOpen: [], //for active default menu
  organisation_campaigns: [], //For hotels and restaraunts
  organisation_payments: null,
  csvFile: {
    sheets: null,
    selected_sheet: null,
    file: null,
    file_name: null,
    start_index: "1",
  },
  credits: 10,
  org_id: null,
  loading: null,
  message: null,
  bulk_data: null,
  showConfigDialog: false,
  hotel_menus: [],
  bsa: null,
  authUser: null,
  pond_image: null,
  menu_items: [],
  workflow: null,
  showTableDialog: false,
  fields: [[]],
  api_fields: null,
  application: null,
  tableData: [],
  message_logs: [],
  tableRecordsData: [],
  allTemplates: [],
  templatesData: [],
  AlltemplatesData: [],
  templates: [],
  resultsSet: {
    data: [{ id: "Hello", name: "Hello World", price: "1000" }],
    columns: [
      { dataField: "_id", text: "ID" },
      { dataField: "Name", text: "Name" },
      { dataField: "Email", text: "Email" },
      { dataField: "Phone", text: "Phone" },
    ],
  },
};

const reducer = (state = initialState, action) => {
  let trigger = [];
  let open = [];

  switch (action.type) {
    case actionTypes.SAVE_CAMPAIGNS:
      return {
        ...state,
        organisation_campaigns: action.campaigns,
      };
    
      case actionTypes.SAVE_ORDERS:
        return {
          ...state,
          organisation_payments: action.payments,
        };

        case actionTypes.SHOW_TOOL_PROPERTIES:
        return {
          ...state,
          showConfigDialog: action.flag
        };
    case actionTypes.LOGIN_REQUEST:
    case actionTypes.LOGOUT_REQUEST:
    case actionTypes.GET_DATA_REQUEST:
    case actionTypes.GET_TABLE_REQUEST:
    case actionTypes.GET_TABLES_REQUEST:
      case actionTypes.NEW_TABLE_REQUEST:

    case actionTypes.GET_TEMPLATE_REQUEST:
    case actionTypes.GET_TEMPLATES_REQUEST:
    case actionTypes.GET_ALL_TEMPLATES_REQUEST:
    case actionTypes.NEW_TEMPLATE_REQUEST:
    case actionTypes.GET_TABLE_RECORD_REQUEST:
    case actionTypes.GET_TABLE_RECORDS_REQUEST:
    case actionTypes.NEW_TABLE_RECORD_REQUEST:
    case actionTypes.UPDATE_TABLE_RECORD_REQUEST:
      return {
        ...state,
        loading: true,
        message: null,
      };

    case actionTypes.SAVE_DATA:
      return {
        ...state,
        bulk_data: action.bulk_data,
        loading: false,
      };
    
      case 'SET_CREDITS':
      return {
        ...state,
        credits: action.credits,
      };

    case actionTypes.NEW_TABLE_SUCCESS:
      return {
        ...state,
        loading: false,
        // application: action.application,
        tableData: [...state.tableData, action.table],
      };

    case actionTypes.NEW_TEMPLATE_SUCCESS:
      return {
        ...state,
        loading: false,
        // application: action.application,
        templatesData: [...state.templatesData, action.template],
      };

    case actionTypes.NEW_TABLE_RECORD_SUCCESS:
      return {
        ...state,
        loading: false,
        // application: action.application,
        tableRecordsData: [...state.tableRecordsData, action.table],
      };

    case actionTypes.UPDATE_TABLE_RECORD_SUCCESS:
      return {
        ...state,
        loading: false,
      };

    case actionTypes.NEW_FIELD_SUCCESS:
      return {
        ...state,
        loading: false,
        // application: action.application,
        api_fields: [...state.api_fields, action.field],
      };

    case actionTypes.GET_TABLE_SUCCESS:
      return {
        ...state,
        loading: false,
        currentEntity: action.table,
      };
    case actionTypes.GET_TABLES_SUCCESS:
      return {
        ...state,
        loading: false,
        tableData: action.tableData,
      };

    case actionTypes.GET_TEMPLATE_SUCCESS:
      return {
        ...state,
        loading: false,
        currentTemplate: action.template,
      };

    case actionTypes.GET_TEMPLATES_SUCCESS:
      return {
        ...state,
        loading: false,
        templatesData: action.templatesData,
      };

    case actionTypes.GET_ALL_TEMPLATES_SUCCESS:
      return {
        ...state,
        loading: false,
        AlltemplatesData: action.AlltemplatesData,
      };

    case actionTypes.GET_TABLE_RECORD_SUCCESS:
    case actionTypes.GET_TABLE_RECORDS_SUCCESS:
      return {
        ...state,
        loading: false,
        tableRecordsData: action.table_records,
      };

    case actionTypes.GET_FIELD_SUCCESS:
    case actionTypes.GET_FIELDS_SUCCESS:
      return {
        ...state,
        loading: false,
        api_fields: action.fields,
      };

    case actionTypes.GET_MESSAGE_LOGS_SUCCESS:
      return {
        ...state,
        loading: false,
        message_logs: action.message_logs,
        message: null,
      };
    case actionTypes.CLEAR_MSG:
      return {
        ...state,
        message: null,
      };
    case actionTypes.SAVE_USER:
      return {
        ...state,
        authUser: action.authUser,
      };

    case actionTypes.GET_WORKFLOW_REQUEST:
    case actionTypes.NEW_WORKFLOW_REQUEST:
      return {
        ...state,
        loading: true,
        message: null,
      };
    case "SAVED_IMPORT":
      return {
        ...state,
        loading: false,
        message: null,
      };
    case actionTypes.GET_WORKFLOW_SUCCESS:
    case "SAVED_IMPORT":
      return {
        ...state,
        loading: false,
        message: null,
      };
    case actionTypes.NEW_WORKFLOW_FAILURE:
    case actionTypes.GET_WORKFLOW_FAILURE:
    case actionTypes.GET_MESSAGE_LOGS_FAILURE:
      return {
        ...state,
        loading: false,
        message: action.error.message,
      };

    //save user to store
    case actionTypes.LOGIN_SUCCESS:
    case actionTypes.REGISTER_WITH_EMAIL_SUCCESS:
      return {
        ...state,
        loading: false,
        user: action.user,
        loggedIn: true,
      };

    case actionTypes.SHOW_FIELD_DIALOG:
      return {
        ...state,
        showFieldDialog: action.dialog,
      };

    case actionTypes.SHOW_TABLE_DIALOG:
      return {
        ...state,
        showTableDialog: action.dialog,
      };

    case actionTypes.LOGIN_FAILURE:
    case actionTypes.REGISTER_WITH_EMAIL_FAILURE:
    case actionTypes.LOGOUT_FAILURE:
    case "IMPORT_FAILURE":
      return {
        ...state,
        loading: false,
        message: action.error.message,
      };

    case actionTypes.PASSWORD_FORGET_SUCCESS:
      return {
        ...state,
        message: "LINK.PASSWORD_RESET.SENT",
      };

    case actionTypes.GET_TABLE_FAILURE:
    case actionTypes.GET_TABLES_FAILURE:
    case actionTypes.GET_TEMPLATE_FAILURE:
    case actionTypes.GET_TEMPLATES_FAILURE:
    case actionTypes.GET_ALL_TEMPLATES_FAILURE:
    case actionTypes.GET_TABLE_RECORD_FAILURE:
    case actionTypes.GET_TABLE_RECORDS_FAILURE:
    case actionTypes.GET_FIELD_FAILURE:
    case actionTypes.GET_FIELDS_FAILURE:
      return {
        ...state,
        loading: false,
        message: action.error.message,
      };
    case actionTypes.LOGOUT_SUCCESS:
      return {
        ...state,
        loading: false,
        loggedIn: false,
        user: null,
      };
    default:
      return state;
  }
};

export default reducer;
