import * as React from "react";
import * as SRD from "storm-react-diagrams";
import { SMSNodeModel } from "./SMSNodeModel";
import { SMSNodeWidget } from "./SMSNodeWidget";


export class SMSNodeFactory extends SRD.AbstractNodeFactory {
    constructor() {
        super("SMS");
    }

    generateReactWidget(diagramEngine, node) {
        return <SMSNodeWidget node={node} />;
    }

    getNewInstance() {
        return new SMSNodeModel();
    }
}


