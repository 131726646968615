import React from "react";
import ReactDOM from "react-dom";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";

import Axios from "axios";
import * as actionTypes from "./../../../store/actions";

import {
  Row,
  Col,
  Card,
  Button,
  Form,
} from "react-bootstrap";

class FormNodeComponent extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
        // Set initial files, type 'local' means this is a file
        // that has already been uploaded to the server (see docs)
        node: null,
        entities: []
      };

      this.handleChangeSelectedEntity = this.handleChangeSelectedEntity.bind(this);
  }

  handleAnnotationChange(event, label) {
    const target = event.target;
    const value = target.value;
    const name = target.name;

    console.log(label);
    console.log(value);
    console.log(name);

    let node = this.props.node;
    let model = this.props.model;
    let currNode = model.getNode(node.id);

    currNode.properties[name] = value;

    this.props.updateNode();
    this.forceUpdate();
  }

  handleChangeSelectedEntity(event){
    console.log();
    console.log(this.state.entities);
    let node = this.props.node;
    let model = this.props.model;
    let currNode = model.getNode(node.id)

    // currNode.properties.outputData = event.target.value.split(',')
    // currNode.properties.entity = {
    //                   title: this.props.entities[event.target.value].title,
    //                   _id: this.props.entities[event.target.value]._id
    //               };
    currNode.properties.env_variable = event.target.value

    console.log(currNode.properties.entity);
    

    if(event.target.value === "Default"){
      currNode.properties.message = "Please select an Variable"
    }

    this.props.updateNode()



  }
  componentDidMount = function() {

    console.log("Mounted");
    console.log(this.props.selected_node);

   
  };
 
  render() {
    return (
        <div className="modal-body">
      <div className="text-left">
        <h6 className="heading mt-1">Entity Node Configs</h6>
        <Form>
        <Row>
            
            <Col md={12}>
              <Form.Group controlId="exampleForm.ControlSelect1">
                <Form.Label>Select Environemt Variable</Form.Label>
                <Form.Control as="select"
                        name="sheet"
                        value={ this.props.node.properties.env_variable == 'Default' ? 'Default': this.props.node.properties.env_variable  }
                        onChange={this.handleChangeSelectedEntity}
                      >
                        <option value="Default">Default</option>
                        {this.props.env_variables ? this.props.env_variables.map((item, key) => (
                          <option key={key} value={item.var_name}>
                            {item.var_name}
                          </option>
                        ) ) : ("")}
                    </Form.Control>
              </Form.Group>
            </Col>

            <Col md={12}>
                <Form.Group controlId="exampleForm.ControlTextarea1">
                  <Form.Label>Comment</Form.Label>
                  <Form.Control
                    as="textarea"
                    rows="3"
                    name="annotation"
                    value={
                      this.props.selected_node["properties"].hasOwnProperty(
                        "annotation"
                      )
                        ? this.props.selected_node["properties"]["annotation"]
                        : ""
                    }
                    onChange={(e) => this.handleAnnotationChange(e, "password")}
                  />
                </Form.Group>
              </Col>
           
          </Row>
        </Form>
         <p>
         {/* {node.properties.headers.join(',')} */}
        </p>
       <p>
       {/* <span id="coderea">{ JSON.stringify(this.props.env_variables) }</span> */}
       </p>
        
      </div>
    </div>
      );
  }
}
const mapStateToProps = (state) => {
  return {
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
   
  };
};

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(FormNodeComponent)
);

