import * as React from "react";
import * as SRD from "storm-react-diagrams";
import { EntityNodeModel } from "./EntityNodeModel";
import { EntityNodeWidget } from "./EntityNodeWidget";


export class EntityNodeFactory extends SRD.AbstractNodeFactory {
    constructor() {
        super("Entity");
    }

    generateReactWidget(diagramEngine, node) {
        return <EntityNodeWidget node={node} />;
    }

    getNewInstance() {
        return new EntityNodeModel();
    }
}


