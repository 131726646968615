import React, { useState, useEffect } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faCog,
  faEnvelopeOpen,
  faMoneyBill,
  faUserShield,
} from "@fortawesome/free-solid-svg-icons";

import { useHistory } from "react-router-dom";
import { faUserCircle } from "@fortawesome/free-regular-svg-icons";

import {
  Row,
  Col,
  Nav,
  Form,
  Image,
  Navbar,
  Dropdown,
  Container,
  ListGroup,
  Modal,
  InputGroup,
  Popover,
  Button,
  OverlayTrigger,
  FormControl
} from "@themesberg/react-bootstrap";

import NOTIFICATIONS_DATA from "../data/notifications";
import Profile3 from "../assets/img/team/avatar.png";

import SignOutButton from "./himenu/SignOut";

import { connect } from "react-redux";

import { compose } from "recompose";

import { AuthUserContext } from "./Session";

import { withFirebase } from "./Firebase";

import hmacSHA256 from "crypto-js/hmac-sha256";
import Base64 from "crypto-js/enc-base64";
import CryptoJS from "crypto-js";
import shortid from "shortid";


import axios from "axios";

function NavBar(props) {

  const {credits} = props
  const history = useHistory();
  const sms_csp = useFormInput("BuniComSMS");
  const [SenderIds, setSenderIds] = useState(null);
  // const [SenderIdCredits, setSenderIdCredits] = useState(0);
  
  const [BusinessAccount, setBusinessAccount] = useState(null);

  const [notifications, setNotifications] = useState(NOTIFICATIONS_DATA);

  const [authUser, setAuth] = useState(null);

  let org_id = props.match.params.org_id

  useEffect(() => {
    console.log("Sender ID Has Changed has changed");
    console.log(sms_csp.value);

    // let current_senderIds = props.firebase.db
    //             .collection("organisation_senderids")
    //             .where("orgId", "==", props.match.params.org_id)
    //             .get()
    //             .then((querySnapshot) => {
    //               let senderId = [];
    //               querySnapshot.forEach((doc) => {
    //                 // doc.data() is never undefined for query doc snapshots
    //                 console.log(doc.id, " => ", doc.data());

    //                 let senderId_data = doc.data()

    //                 if(senderId_data["sender_name"] == sms_csp.value ){
    //                   // console.log("==========SMS Credits==============");
    //                   // console.log(senderId_data["sms_rate"]);
    //                   // console.log(senderId_data["sms_credits"]);
    //                   setSenderIdCredits(senderId_data["sms_credits"])
    //                 }
    //                 // senderId.push({ ...doc.data(), id: doc.id });
    //               });

    //               // setSenderIds(senderId);

    //               // getConfigs(props.match.params.org_id);
    //             })
    //             .catch((error) => {
    //               console.log("Error getting documents: ", error);
    //             });
  ;
  }, [sms_csp.value]);

 
  useEffect(() => {
    const unsubscribe = props.firebase.auth.onAuthStateChanged((authUser) => {
      if (authUser) {
        setAuth(authUser);

        // Get business account and save details

        let current_bsa = props.firebase.db
          .collection("business_accounts")
          .where("uid", "==", authUser.uid)
          .get()
          .then((querySnapshot) => {
            let bsa = {};
            querySnapshot.forEach((doc) => {
              // doc.data() is never undefined for query doc snapshots
              //   console.log(doc.id, " => ", doc.data());
              bsa = { ...doc.data(), id: doc.id };
            });

            if (BusinessAccount == null) {
              setBusinessAccount(bsa);
              let current_senderIds = props.firebase.db
                .collection("organisation_senderids")
                .where("orgId", "==", authUser.uid)
                .get()
                .then((querySnapshot) => {
                  let senderId = [];
                  querySnapshot.forEach((doc) => {
                    // doc.data() is never undefined for query doc snapshots
                    console.log(doc.id, " => ", doc.data());
                    senderId.push({ ...doc.data(), id: doc.id });
                  });

                  setSenderIds(senderId);

                  getConfigs(props.match.params.org_id);
                })
                .catch((error) => {
                  console.log("Error getting documents: ", error);
                });
            }

            
          })
          .catch((error) => {
            console.log("Error getting documents: ", error);
          });
      } else {
        setAuth(null);
        //save null business account
      }
    });

    return () => {
      unsubscribe();
    };
  });



  const getConfigs = (app_id) => {
    axios
      .get(
        "https://api.bunicom.com/get-configs?application_id=" +
          app_id
      )
      .then((response) => {
        console.log(response.data);

        if (response.data.length > 0) {
          let configs = response.data[0];

          updateFields(configs);
        }
        // history.push("/org/" + props.match.params.org_id + "/EmailConfigs")
      })
      .catch((e) => {
        console.log(e.message);
      });
  };

  function updateFields(configs) {

    console.log("=============THIS WILL UPDATE FIELDS=================");
    console.log(configs);
    console.log("==============================");
    sms_csp.onReset(configs["sms_csp"]);

    // if (configs.hasOwnProperty("sms_csp")) {
    //   sms_csp.onReset(configs["sms_csp"]);
    // }
  }


  const areNotificationsRead = notifications.reduce(
    (acc, notif) => acc && notif.read,
    true
  );


  const markNotificationsAsRead = () => {
    setTimeout(() => {
      setNotifications(notifications.map((n) => ({ ...n, read: true })));
    }, 300);
  };

  const rechargeCredit = () => {
    // CreateNewOrder()
    history.push("/org/" + org_id + "/pay");
  };

  const Notification = (props) => {
    const { link, sender, image, time, message, read = false } = props;
    const readClassName = read ? "" : "text-danger";

    return (
      <ListGroup.Item action href={link} className="border-bottom border-light">
        <Row className="align-items-center">
          <Col className="col-auto">
            <Image
              src={image}
              className="user-avatar lg-avatar rounded-circle"
            />
          </Col>
          <Col className="ps-0 ms--2">
            <div className="d-flex justify-content-between align-items-center">
              <div>
                <h4 className="h6 mb-0 text-small">{sender}</h4>
              </div>
              <div className="text-end">
                <small className={readClassName}>{time}</small>
              </div>
            </div>
            <p className="font-small mt-1 mb-0">{message}</p>
          </Col>
        </Row>
      </ListGroup.Item>
    );
  };

  return (
    <Navbar variant="dark" expanded className="ps-0 pe-2 pb-0">
      <Container fluid className="px-0">
        <div className="d-flex justify-content-between w-100">
          <div className="d-flex align-items-center">
            <Form.Group id="password" className="mb-4">
              <InputGroup>
              
              <Form.Control
                            as="select"
                            name="sms_csp"
                            {...sms_csp}
                            disabled
                          >
                            <option value="BuniComSMS">BuniComSMS</option>

                            {SenderIds
                            ? SenderIds.map((item, key) => (
                                <option key={key} value={item.sender_name}>
                                  {item.sender_name}
                                </option>
                              ))
                            : ""}
                            {/* <option value="ssl">MobileSasa</option> */}
                          </Form.Control>
                <InputGroup.Text>
                  <FontAwesomeIcon icon={faMoneyBill} className="me-1" /> CREDITS {" "}
                  {credits}
                </InputGroup.Text>

                <Button variant="secondary" size="md" onClick={rechargeCredit}>
                  Top Up Credit
                </Button>
              </InputGroup>
            </Form.Group>
          </div>
          <Nav className="align-items-center">
            {/* <Dropdown as={Nav.Item} onToggle={markNotificationsAsRead} >
              <Dropdown.Toggle as={Nav.Link} className="text-dark icon-notifications me-lg-3">
                <span className="icon icon-sm">
                  <FontAwesomeIcon icon={faBell} className="bell-shake" />
                  {areNotificationsRead ? null : <span className="icon-badge rounded-circle unread-notifications" />}
                </span>
              </Dropdown.Toggle>
              <Dropdown.Menu className="dashboard-dropdown notifications-dropdown dropdown-menu-lg dropdown-menu-center mt-2 py-0">
                <ListGroup className="list-group-flush">
                  <Nav.Link href="#" className="text-center text-primary fw-bold border-bottom border-light py-3">
                    Notifications
                  </Nav.Link>

                  {notifications.map(n => <Notification key={`notification-${n.id}`} {...n} />)}

                  <Dropdown.Item className="text-center text-primary fw-bold py-3">
                    View all
                  </Dropdown.Item>
                </ListGroup>
              </Dropdown.Menu>
            </Dropdown> */}

            <Dropdown as={Nav.Item}>
              <Dropdown.Toggle as={Nav.Link} className="pt-1 px-0">
                <div className="media d-flex align-items-center">
                  <Image
                    src={Profile3}
                    className="user-avatar md-avatar rounded-circle"
                  />
                  <div className="media-body ms-2 text-dark align-items-center d-none d-lg-block">
                    <AuthUserContext.Consumer>
                      {(authUser) =>
                        authUser != null ? (
                          <span className="mb-0 font-small fw-bold">
                            {authUser.displayName}
                            {/* {JSON.stringify()} */}
                          </span>
                        ) : (
                          <span className="mb-0 font-small fw-bold">
                            Anonymous
                          </span>
                        )
                      }
                    </AuthUserContext.Consumer>
                  </div>
                </div>
              </Dropdown.Toggle>
              <Dropdown.Menu className="user-dropdown dropdown-menu-right mt-2">
              <Dropdown.Item className="fw-bold">
                  <FontAwesomeIcon icon={faUserCircle} className="me-2" /> <AuthUserContext.Consumer>
                      {(authUser) =>
                        authUser != null ? (
                          <span className="mb-0 font-small fw-bold">
                           {authUser.email}

                          </span>
                        ) : (
                          <span className="mb-0 font-small fw-bold">
                            Anonymous
                          </span>
                        )
                      }
                    </AuthUserContext.Consumer>
                </Dropdown.Item>
                {/* <Dropdown.Item className="fw-bold">
                  <FontAwesomeIcon icon={faUserCircle} className="me-2" /> My
                  Profile
                </Dropdown.Item> */}
                <AuthUserContext.Consumer>
                      {(authUser) =>
                        authUser != null ? (
                          <Dropdown.Item className="fw-bold" link={"/org/" + authUser.uid + "/settings"}>
                  <FontAwesomeIcon icon={faCog} className="me-2" /> Settings
                </Dropdown.Item>
                        ) : (
                          <Dropdown.Item className="fw-bold">
                          <FontAwesomeIcon icon={faCog} className="me-2" /> Settings unavalil
                        </Dropdown.Item>
                        )
                      }
                    </AuthUserContext.Consumer>
               
                <Dropdown.Item className="fw-bold">
                  <FontAwesomeIcon icon={faEnvelopeOpen} className="me-2" />{" "}
                  Messages
                </Dropdown.Item>
                <Dropdown.Item className="fw-bold">
                  <FontAwesomeIcon icon={faUserShield} className="me-2" />{" "}
                  Support
                </Dropdown.Item>

                <Dropdown.Divider />

                <SignOutButton />
              </Dropdown.Menu>
            </Dropdown>
          </Nav>
        </div>
      </Container>
    </Navbar>
  );
}

const useFormInput = (initialValue) => {
  const [value, setValue] = useState(initialValue);

  const handleChange = (e) => {
    setValue(e.target.value);
  };

  function handleReset(val) {
    setValue(val);
  }
  return {
    value,
    onChange: handleChange,
    onReset: handleReset,
  };
};

const mapStateToProps = (state) => ({
  credits : state.credits
});

const mapDispatchToProps = (dispatch) => {
  return {};
};

// export default connect(mapStateToProps, mapDispatchToProps)(withFirebase(HotelsPage));
export default compose(
  connect(mapStateToProps, mapDispatchToProps),
  // withStateHandlers,
  // withHandler,
  withFirebase
)(NavBar);
