import * as React from "react";
import * as SRD from "storm-react-diagrams";
import { DataEntryNodeModel } from "./DataEntryNodeModel";
import { DataEntryNodeWidget } from "./DataEntryNodeWidget";


export class DataEntryNodeFactory extends SRD.AbstractNodeFactory {
    constructor() {
        super("DataEntry");
    }

    generateReactWidget(diagramEngine, node) {
        return <DataEntryNodeWidget node={node} engine={diagramEngine} />;
    }

    getNewInstance() {
        return new DataEntryNodeModel();
    }
}


