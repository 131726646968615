import React, { Component } from "react";
import ReactDOM from "react-dom";
import jexcel from "jexcel";
import Axios from "axios";

import { connect } from "react-redux";
import { withRouter } from "react-router-dom";

import "./styles.css";
import "../../../../../node_modules/jexcel/dist/jexcel.css";


class DataEntry extends React.Component {
  constructor(props) {
    super(props);
    var data = [[""]];

    this.options = {
      data: data,
      defaultColWidth: 180,
      tableOverflow: true,
      tableWidth: "820px",
      columns: [{ type: "text", title: "_id", readOnly: true, width: 1 }],
      columnDrag: true,
      selectionCopy: true,
      allowManualInsertRow: false,
      allowManualInsertColumn: false,
      allowInsertColumn: false,
      allowDeleteColumn: false,
      allowRenameColumn: false,
      allowComments: false,
      allowExport: false,
      oninsertrow: this.insertedRow,
  
    };
  }

  componentDidMount = function () {
    this.el = jexcel(ReactDOM.findDOMNode(this).children[0], this.options);
  };


  resetTable() {
    document.getElementById("rows-sheet").innerHTML = "";
    this.options.columns =  [{ type: "text", title: "_id", readOnly: true, width: 1 }]
    //Honestly, I have no idea what i did here
    
  }

  refreshUITable() {
    this.resetTable();

    if(this.props.fields){
        if (this.props.fields.length > 0) {
            let cols = [];
      
            console.log(this.props.fields.length);
      
            var element = document.getElementById('recordsTable');
              var positionInfo = element.getBoundingClientRect();
              var height = positionInfo.height;
              var width = positionInfo.width;
      
            this.props.fields.forEach((col) => {
                if(col.dataField != "_id"){
                    cols.push({ type: "text", title: col.dataField, width: 180});
                }
            });
            this.options.columns = cols;
            
            this.options.minDimensions = [this.props.fields.length > 1 ? this.props.fields.length - 1: 1, 5];
            this.options.tableWidth = width > 100? width+"px": "820px"
      
            this.el = jexcel(ReactDOM.findDOMNode(this).children[0], this.options);
            this.el.setData([[]]);
          }

    }

   
  }

  componentDidUpdate(prevProps, prevState) {
    /**
     * this is the initial render
     * without a previous prop change
     */
    //  if(prevProps == undefined) {
    //      return false
    //  }

    /**
     * new Project in town ?
     */

    // console.log("updated model");
    // console.log(prevProps);
    // console.log(this.props);
    this.refreshUITable()

  }

  addRow = function () {
    this.el.insertRow(["", "", "", "", ""]);
  };
  clearRows() {
    this.el.setData([[""]]);
    // this.addRow()
  }
  saveFields = function () {
    console.log(this.el.getData());

    let that = this;

    let records = this.el.getData();
    let headers = [];
    this.props.fields.forEach((col) => {
        if(col.dataField != "_id"){
      headers.push(col.dataField);

        }
    });
    let arr_objs_records = [];

    console.log("==============RECORDS==============");
    console.log(headers);

    records.forEach((record) => {
      let obj_rec = {};
      let count = 0;
      headers.forEach((item) => {
        obj_rec[item] = record[count];
        count++;
      });
      if (record[0] !== "") {
      //   //remove _id attr, it will be autopopulated by python

        // delete obj_rec["_id"];
        arr_objs_records.push(obj_rec);
      }
    });

    if (arr_objs_records.length > 0) {
      console.log(arr_objs_records);

      let payload = {
          records: arr_objs_records,
          entity: this.props.currentEntity
      }
      //   let payload = {
    //     dataset: arr_objs_records,
    //     entity: this.props.currentEntity,
    //   };


      this.props.onSaveRecords(payload)

    //   let payload = {
    //     dataset: arr_objs_records,
    //     entity: this.props.currentEntity,
    //   };

    //   Axios.post("https://api.bunicom.com///save-entity-records", payload)
    //     .then((response) => {
    //       console.log(response.data);
    //       that.clearRows();
    //     })
    //     .catch((e) => {
    //       console.log(e);
    //     });
    }
  };

  insertedRow = function (instance) {
    console.log(instance);
  };


  render() {
    return (
      <div>
        <div id="rows-sheet"></div>
        <br />
        <br />

        <div>
        <button
            className="btn-square btn btn-info"
            type="button"
            disabled
            onClick={() => this.addRow()}
          >
            Add Record
          </button>
          <button
            className="btn-square btn btn-info"
            type="button"
            onClick={() => this.saveFields()}
          >
            Save Records
          </button>
         
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    fields: state.api_fields,
    currentEntity: state.currentEntity,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    onSaveRecords: (load) =>
      dispatch({ type: "NEW_TABLE_RECORD_REQUEST", payload: load }),
  };
};

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(DataEntry)
);
