import React, { useRef, useState, useEffect } from "react";

import { useHistory } from "react-router-dom";
import DateTimePicker from "react-datetime-picker";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faCheck,
  faCog,
  faDatabase,
  faArrowLeft,
  faSearch,
  faMailBulk,
  faUserAlt,
  faCodeBranch,
  faSms,
  faTable,
  faTag,
} from "@fortawesome/free-solid-svg-icons";

import "html5-device-mockups/dist/device-mockups.min.css";

import SMSTable from "./SMSTable";

import axios from "axios";

import { connect } from "react-redux";
import { withFirebase } from "../../../Firebase";

import { compose } from "recompose";

import {
  Col,
  Container,
  Row,
  Form,
  Button,
  ButtonGroup,
  Breadcrumb,
  InputGroup,
  Modal,
  Dropdown,
  Alert,
  Nav,
  ProgressBar,
  Tab,
} from "@themesberg/react-bootstrap";

import "./sms.css";

import ReactTextareaAutocomplete from "@webscopeio/react-textarea-autocomplete";
import "@webscopeio/react-textarea-autocomplete/style.css";

import Axios from "axios";

import Lottie from "lottie-react-web";

import animation from "./sending2.json";
import success from "./sending.json";
import failed from "./failed.json";

import ReactDataGrid from "@inovua/reactdatagrid-community";
import "@inovua/reactdatagrid-community/index.css";

import "@inovua/reactdatagrid-community/base.css";
import "@inovua/reactdatagrid-community/theme/blue-light.css";

function BackButton(props) {
  const history = useHistory();

  function handleClick() {
    history.push("/org/" + props.appid + "/outbox");
  }

  return (
    <Button variant="light" size="sm" onClick={handleClick}>
      <FontAwesomeIcon icon={faArrowLeft} className="me-1" /> Go back to outbox
    </Button>
  );
}

const exportHtml = (e) => {
  e.preventDefault();
};

const Item = ({ entity: { name, char } }) => <div>{`${name}`}</div>;

const gridStyle = { maxHeight: 207 };

const SMSPage = (props) => {
  let history = useHistory();

  const [value, setValue] = useState(null);
  const [showDefault, setShowDefault] = useState(false);
  const [createDataStore, setCreateDataStore] = useState(false);
  const [loadingPeek, setLoadingPeek] = useState(false);

  const [SenderIds, setSenderIds] = useState(null);

  const [workflows, setWorkflows] = useState(null);
  const [selected_workflow_id, setSelectedWorkflow] = useState(null);

  const [dataTables, setDataTables] = useState(null);
  const [selected_dt_id, setSelectedDt] = useState(null);
  const [data_table_data, setDtData] = useState(null);
  const [data_table_columns, setDtDataColumns] = useState(null);
  const [dt_headers, setDtHeaders] = useState(null);

  const sms_csp = useFormInput("BuniComSMS");
  const phone_column = useFormInput("Null");

  const [BusinessAccount, setBusinessAccount] = useState(null);
  const [ModaRef, setModalRef] = useState(React.createRef());
  const [headers, setHeaders] = useState(null);
  const [data_Records, setDataRecords] = useState(null);
  const [msg_preview, setMsgPreview] = useState("");
  const [message_value, setMessageValue] = useState("");
  const [wf_message_value, setWFMessageValue] = useState("");

  const [sending_status, setSendingStatus] = useState("sending");
  const [send_later, setSendLater] = useState(false);
  const [authUser, setAuth] = useState(null);

  const [date_value, setDateValue] = useState(new Date());
  const [workflowTimers, setWorkflowTimers] = useState([]);

  const [emailStatus, setemailStatus] = useState(
    "Sending Text Messages, please wait ..."
  );

  const handleClose = () => setShowDefault(false);

  useEffect(() => {
    // Your code here
    document.title = `Send an SMS`;
    getWorkflows(props.match.params.org_id);
  }, []);

  useEffect(() => {
    const unsubscribe = props.firebase.auth.onAuthStateChanged((authUser) => {
      if (authUser) {
        setAuth(authUser);

        // Get business account and save details

        let current_bsa = props.firebase.db
          .collection("business_accounts")
          .where("uid", "==", authUser.uid)
          .get()
          .then((querySnapshot) => {
            let bsa = {};
            querySnapshot.forEach((doc) => {
              // doc.data() is never undefined for query doc snapshots
              //   console.log(doc.id, " => ", doc.data());
              bsa = { ...doc.data(), id: doc.id };
            });

            if (BusinessAccount == null) {
              setBusinessAccount(bsa);
              let current_senderIds = props.firebase.db
                .collection("organisation_senderids")
                .where("orgId", "==", authUser.uid)
                .get()
                .then((querySnapshot) => {
                  let senderId = [];
                  querySnapshot.forEach((doc) => {
                    // doc.data() is never undefined for query doc snapshots
                    console.log(doc.id, " => ", doc.data());
                    senderId.push({ ...doc.data(), id: doc.id });
                  });

                  setSenderIds(senderId);

                  getConfigs(props.match.params.org_id);
                })
                .catch((error) => {
                  console.log("Error getting documents: ", error);
                });
            }
          })
          .catch((error) => {
            console.log("Error getting documents: ", error);
          });
      } else {
        setAuth(null);
        //save null business account
      }
    });

    return () => {
      unsubscribe();
    };
  });

  useEffect(() => {
    // setWorkflowTimer([])
    return () => {
      console.log("Component is unmounting");
      clearTimers();
    };
  }, []);

  const getConfigs = (app_id) => {
    axios
      .get("https://api.bunicom.com/get-configs?application_id=" + app_id)
      .then((response) => {
        console.log(response.data);

        if (response.data.length > 0) {
          let configs = response.data[0];

          updateFields(configs);
        }
        // history.push("/org/" + props.match.params.org_id + "/EmailConfigs")
      })
      .catch((e) => {
        console.log(e.message);
      });
  };

  const getWorkflows = (app_id) => {
    axios
      .get("https://api.bunicom.com//get-workflows?application_id=" + app_id)
      .then((response) => {
        // console.log(response.data);

        if (response.data.length > 0) {
          let workflow_data = response.data;

          let workflows_updated = [];

          workflow_data.forEach((workflow) => {
            let flat_workflow = {};

            for (var key in workflow) {
              if (workflow.hasOwnProperty(key)) {
                if (key == "nodes") {
                  let nodes = workflow[key];
                  let peekNodes = [];
                  nodes.forEach((node) => {
                    if (node.hasOwnProperty("nodeType")) {
                      // console.log(node["nodeType"]);
                      let nodeType = node["nodeType"];
                      if (nodeType == "Peek") {
                        peekNodes.push({
                          nodeType: node["nodeType"],
                          id: node["id"],
                          properties: node["properties"],
                        });
                      }
                    }
                  });

                  if (peekNodes.length > 0) {
                    flat_workflow = {
                      _id: workflow["_id"],
                      name: workflow["name"],
                      nodes: peekNodes,
                    };

                    workflows_updated.push(flat_workflow);
                  }
                }
              }
            }
          });
          console.log("Run Once");
          console.log(workflows_updated);

          setWorkflows(workflows_updated);

          // setWorkflows(configs);
        }
        // history.push("/org/" + props.match.params.org_id + "/EmailConfigs")
      })
      .catch((e) => {
        console.log(e.message);
      });
  };

  function updateFields(configs) {
    console.log("=============THIS WILL UPDATE FIELDS=================");
    console.log(configs);
    console.log("==============================");
    sms_csp.onReset(configs["sms_csp"]);

    // if (configs.hasOwnProperty("sms_csp")) {
    //   sms_csp.onReset(configs["sms_csp"]);
    // }
  }

  const message = useFormInput("");
  const title = useFormInput("");
  const check = useFormInput(false);

  function handleInputChange(event) {
    const target = event.target;
    const value = target.value;
    const name = target.name;

    console.log(value);
    // setCreateDataStore(!createDataStore)
  }

  const onSubmit = (event) => {
    event.preventDefault();
    SendTextMessages();
  };

  const onSubmitWf = (event) => {
    event.preventDefault();
    RunWorkflowTextMessages();
  };

  const setWorkflowTimer = (timer) => {

    let arr = workflowTimers
    
    let timers = [...arr, timer];
    setWorkflowTimers(timers);
    console.log("-----------------TIMERS");
    console.log(timers);
  };

  const clearTimers = () => {
    console.log("Clearing timers");

    if (workflowTimers.length > 0) {
      workflowTimers.forEach((timer) => {
        console.log("ClearTimers");
        clearInterval(timer);
      });
    }

    setWorkflowTimers([]);
  };

  const runWorkflow = (w_id) => {
    let payload = {
      _id: w_id,
      mode: "api",
      env_variables: [{ var_name: "data", value: [], type: "text" }],
    };

    Axios.post("https://api.bunicom.com///execute", payload)
      .then((response) => {
       
        console.log("Show Workflow Timers");
        let interval_timer = setInterval(
          () => {

            setWorkflowTimers([...workflowTimers, interval_timer]);
            
            Axios.get("https://api.bunicom.com/status/" + response.data.data)
              .then((resp) => {
                console.log(resp.data);
  
                if (resp.data.state != "PENDING") {
                  console.log("DONE = Workflow Timers");
                  let wfl_results = resp.data;
                  console.log("==================");
                  console.log(workflowTimers);
                  console.log("==================");
  
  
                  if (workflowTimers.length > 0) {
                    workflowTimers.forEach((timer) => {
                      console.log("ClearTimers");
                      clearInterval(timer);
                    });
                  }
  
                  // clearTimers();
  
                  clearInterval(interval_timer);
  
                  if (wfl_results.state == "SUCCESS") {
                    // that.refreshDiagramData(wfl_results.data.data);
                    let peekNodes = wfl_results.data;
                    setDataTables(peekNodes.data);
                    setLoadingPeek(false);
                  }
                }
              })
              .catch((e) => {
                setLoadingPeek(false);
              });
          }
          , 2000)
        
        console.log(workflowTimers);

      })
      .catch((e) => {
        if (workflowTimers.length > 0) {
          workflowTimers.forEach((timer) => {
            clearInterval(timer);
          });
        }

        clearTimers();

        console.log("YADA YADA");
      });
  };

  const RunWorkflowTextMessages = () => {
    setShowDefault(true);
    setemailStatus("Sending SMS's, Please wait");
    setSendingStatus("sending");

    let env_variables = [
      {
        var_name: "data",
        value: [
          {
            apikey: props.match.params.org_id,
            peek_id: selected_dt_id,
            shortcode:
              sms_csp.value == "advanta" ? "BuniComSMS" : sms_csp.value,
            message: message_value,
            phoneColumn: phone_column.value,
          },
        ],
        type: "text",
      },
    ];

    let payload = {
      _id: selected_workflow_id,
      mode: "ops",
      env_variables: env_variables,
    };

    console.log(payload);

    var myHeaders = new Headers();
    myHeaders.append("Content-Type", "application/json");

    var raw = JSON.stringify(payload);

    var requestOptions = {
      method: "POST",
      headers: myHeaders,
      body: raw,
      redirect: "follow",
    };

    fetch("https://butterfly-analytics.herokuapp.com/execute", requestOptions)
      .then((response) => response.text())
      .then((result) => {
        setSendingStatus("success");
        setemailStatus("Messages Sent Successfully");

        console.log(result);

        setTimeout(() => {
          setShowDefault(false);
          setMessageValue("");
        }, 30000);
      })
      .catch((error) => {
        console.log("error", error);

        setemailStatus("Something went wrong");

        setSendingStatus("failed");

        setTimeout(() => {
          setShowDefault(false);
        }, 30000);
      });
  };

  const SendTextMessages = () => {
    setShowDefault(true);
    setemailStatus("Sending SMS's, Please wait");
    setSendingStatus("sending");

    let payload = {
      headers: headers,
      data_records: data_Records,
      message_pattern: message_value,
      apikey: props.match.params.org_id,
      shortcode: sms_csp.value == "at" ? "BuniComSMS" : sms_csp.value,
    };

    if (send_later) {
      var dateString =
        date_value.getUTCFullYear() +
        "-" +
        ("0" + (date_value.getUTCMonth() + 1)).slice(-2) +
        "-" +
        ("0" + date_value.getUTCDate()).slice(-2) +
        " " +
        ("0" + (date_value.getUTCHours() + 3)).slice(-2) +
        ":" +
        ("0" + date_value.getUTCMinutes()).slice(-2) +
        ":" +
        ("0" + date_value.getUTCSeconds()).slice(-2);

      console.log(dateString);
      payload["timeToSend"] = dateString;
    }

    // console.log(payload);

    Axios.post("https://api.bunicom.com/sendbulksms", payload)
      .then((response) => {
        if (response.data.messages.length > 0) {
          setemailStatus(response.data.messages.join(", "));

          setSendingStatus("failed");
        } else {
          setSendingStatus("success");

          setemailStatus("Messages Sent Successfully");
        }

        setTimeout(() => {
          setShowDefault(false);

          setMessageValue("");
        }, 30000);
      })
      .catch((e) => {
        setemailStatus("Something went wrong");

        setSendingStatus("failed");

        setTimeout(() => {
          setShowDefault(false);
        }, 30000);

        console.log(e);
      });
  };

  function handleMessageChange(event) {
    const target = event.target;

    const value = target.value;

    setMessageValue(value);

    PreviewData(data_Records, headers);
  }

  function handleSendLater() {
    setSendLater(!send_later);
  }

  function handleDragOver(event) {
    event.preventDefault();
    // setCreateDataStore(!createDataStore)
  }

  function onChangeDateValue(value) {
    setDateValue(value);

    var dateString =
      value.getUTCFullYear() +
      "-" +
      ("0" + (value.getUTCMonth() + 1)).slice(-2) +
      "-" +
      ("0" + value.getUTCDate()).slice(-2) +
      " " +
      ("0" + (value.getUTCHours() + 3)).slice(-2) +
      ":" +
      ("0" + value.getUTCMinutes()).slice(-2) +
      ":" +
      ("0" + value.getUTCSeconds()).slice(-2);

    console.log(dateString);
  }

  const handleDrop = (e, status) => {
    let id = e.dataTransfer.getData("id");

    let value = message_value + "" + e.dataTransfer.getData("id");
    // message.onReset(value);
    setMessageValue(value);

    PreviewData(data_Records, headers);
  };

  const handleChangeHeaders = (headers) => {
    setHeaders(headers);
    PreviewData(data_Records, headers);
  };

  const handleDataChange = (headers) => {
    setDataRecords(headers);
    PreviewData(data_Records, headers);
  };

  const handleDragStart = (e, name) => {
    e.dataTransfer.setData("id", name);
  };

  const handleChangeSelectedTable = (event) => {
    let dt_id = event.target.value;
    setSelectedDt(dt_id);

    console.log(dt_id);

    let selectedDataTable_index = dataTables
      .map(function (e) {
        return e.peek_id;
      })
      .indexOf(dt_id);

    console.log(dataTables[selectedDataTable_index]);

    let outputData = dataTables[selectedDataTable_index]["data"];
    // let outputData = []

    setDtData(outputData);

    if (outputData.length > 0) {
      setDtHeaders(Object.keys(outputData[0]));

      let headers = Object.keys(outputData[0]);

      let curr_dt_columns = [];

      headers.forEach((header) => {
        let item = {
          name: header,
          header: header,
          minWidth: 50,
          defaultFlex: 2,
        };
        curr_dt_columns.push(item);
      });

      setDtDataColumns(curr_dt_columns);
    }
  };

  const handleChangeSelectedEntity = (event) => {
    let workflow_id = event.target.value;
    setSelectedWorkflow(workflow_id);
    setSelectedDt(null);
    setDtDataColumns(null);
    setDtHeaders(null);

    let selectedWorkflow_index = workflows
      .map(function (e) {
        return e._id;
      })
      .indexOf(workflow_id);

    // console.log("Selected Workflow");
    // console.log(selectedWorkflow_index);

    setLoadingPeek(true);

    runWorkflow(workflow_id);

    // let peekNodes = workflows[selectedWorkflow_index]["nodes"];

    // setDataTables(peekNodes);
  };

  const appendHeader = (e, header) => {
    let value = message_value + "{{" + header + "}}";
    setMessageValue(value);
  };
  const PreviewData = (data_array, headers) => {
    let message_preview = message_value;

    if (data_array) {
      if (data_array.length > 0) {
        let first_record = data_array[0];
        headers.forEach((header) => {
          console.log("Replace");
          console.log("{{" + header + "}}");
          console.log(first_record[header]);
          message_preview = message_preview
            .split("{{" + header + "}}")
            .join(first_record[header]);

          console.log(message_preview);
        });
      }
    }
    setMsgPreview(message_preview);

  };

  return (
    <>
      <Modal
        as={Modal.Dialog}
        centered
        show={showDefault}
        ref={ModaRef}
        onHide={handleClose}
      >
        <Modal.Header>
          <Modal.Title className="h6">Request Status</Modal.Title>

          <Button variant="close" aria-label="Close" onClick={handleClose} />
        </Modal.Header>
        <Modal.Body>
          <div className="d-flex justify-content-between flex-wrap flex-md-nowrap align-items-center">
            <p>{emailStatus}</p>

            <p>
              {sending_status == "sending" ? (
                <Lottie
                  style={{ width: "150px" }}
                  options={{
                    animationData: animation,
                  }}
                />
              ) : (
                ""
              )}

              {sending_status == "success" ? (
                <Lottie
                  style={{ width: "150px" }}
                  options={{
                    animationData: success,
                  }}
                />
              ) : (
                ""
              )}
              {sending_status == "failed" ? (
                <Lottie
                  style={{ width: "150px" }}
                  options={{
                    animationData: failed,
                  }}
                />
              ) : (
                ""
              )}
            </p>
          </div>
        </Modal.Body>
      </Modal>
      <div className="container-fluid mt--6 p-small-0">
        <div className="row justify-content-center">
          <div className="col-lg-10 card-wrapper">
            <Row className="justify-content-between align-items-center">
              <Col xs={12} md={12} lg={12} xl={12}>
                {/* <BackButton appid={props.match.params.org_id} /> */}
              </Col>
            </Row>
            <div className="card mt-2">
              <div className="card-body">
                <Tab.Container defaultActiveKey="visual_design">
                  <Row>
                    <Col lg={12}>
                      <Nav
                        fill
                        variant="pills"
                        className="flex-column flex-sm-row"
                      >
                        <Nav.Item>
                          <Nav.Link
                            eventKey="visual_design"
                            className="mb-sm-3 mb-md-0"
                          >
                            <FontAwesomeIcon icon={faTable} className="me-2" />{" "}
                            Bulk SMS with Manual Data Sheet
                          </Nav.Link>
                        </Nav.Item>
                        <Nav.Item>
                          <Nav.Link
                            eventKey="code_friendly"
                            className="mb-sm-3 mb-md-0"
                          >
                            <FontAwesomeIcon
                              icon={faCodeBranch}
                              className="me-2"
                            />{" "}
                            Bulk SMS with Workflow
                          </Nav.Link>
                        </Nav.Item>
                        <Nav.Item>
                          <Nav.Link
                            eventKey="user_experience"
                            className="mb-sm-3 mb-md-0"
                          >
                            <FontAwesomeIcon
                              icon={faDatabase}
                              className="me-2"
                            />{" "}
                            Bulk SMS with Data Sources
                          </Nav.Link>
                        </Nav.Item>
                      </Nav>
                      <Tab.Content>
                        <Tab.Pane eventKey="visual_design" className="py-2">
                          <>
                            {/* <div className="card-body">
                              <div className="card-header">
                                <div className="row align-items-center">
                                  <div className="col">
                                    <h5 className="h5 mb-0">
                                      Send an SMS to multiple recipients
                                    </h5>
                                  </div>
                                  <div className="col d-small-none">
                                    <div className="justify-content-end">
                                      <ButtonGroup className=" float-end">
                                        <Button
                                          variant="secondary"
                                          size="md"
                                          onClick={() =>
                                            history.push(
                                              "/org/" +
                                                props.match.params.org_id +
                                                "/campaigns"
                                            )
                                          }
                                        >
                                          Start a Workflow Campaign
                                        </Button>
                                      </ButtonGroup>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div> */}
                            <div className="card-body">
                              <Row>
                                <Col
                                  xs={12}
                                  sm={12}
                                  md={6}
                                  lg={8}
                                  xl={8}
                                  id="datarow"
                                >
                                  <form className="mt-1" onSubmit={onSubmit}>
                                    <Row>
                                      <Form.Group as={Row} className="mb-3">
                                        <Form.Label column sm="12">
                                          SMS From:
                                        </Form.Label>
                                        <Col sm="12">
                                          <Form.Control
                                            as="select"
                                            name="sms_csp"
                                            {...sms_csp}
                                          >
                                            <option value="BuniComSMS">
                                              BuniComSMS
                                            </option>

                                            {SenderIds
                                              ? SenderIds.map((item, key) => (
                                                  <option
                                                    key={key}
                                                    value={item.sender_name}
                                                  >
                                                    {item.sender_name}
                                                  </option>
                                                ))
                                              : ""}
                                            {/* <option value="ssl">MobileSasa</option> */}
                                          </Form.Control>
                                        </Col>
                                      </Form.Group>
                                      <Col md={12} sm={12}>
                                        <Form.Group className="mb-3">
                                          <Form.Label>Recipients</Form.Label>
                                          <br />
                                          <small>
                                            Use the sheet below to enter your
                                            recipients. Make sure the first
                                            column is the recipients mobile
                                            number
                                          </small>
                                          <div className="table-records">
                                            <p className="text-capitalize">
                                              <b>
                                                Double Click or Double Touch on
                                                cell to edit Phone Number
                                              </b>
                                            </p>
                                            <SMSTable
                                              onHeadersChange={
                                                handleChangeHeaders
                                              }
                                              onDataChange={handleDataChange}
                                            />
                                          </div>
                                          {/*  */}
                                        </Form.Group>
                                      </Col>

                                      <Form.Group className="mb-3">
                                        <br />
                                        <b>
                                          Click or Drag n Drop the pills below
                                          to add dynamic columns
                                        </b>
                                        <p className="pt-1">
                                          {headers
                                            ? headers.map((header, i) => {
                                                return (
                                                  <Button
                                                    className="me-2"
                                                    variant="outline-secondary"
                                                    size="lg"
                                                    key={i}
                                                    //
                                                    draggable
                                                    onDragStart={(e) =>
                                                      handleDragStart(
                                                        e,
                                                        "{{" + header + "}}"
                                                      )
                                                    }
                                                    onClick={(e) =>
                                                      appendHeader(e, header)
                                                    }
                                                  >
                                                    <FontAwesomeIcon
                                                      icon={faTag}
                                                      className="me-2"
                                                    />
                                                    <span>{header}</span>
                                                  </Button>
                                                );
                                              })
                                            : ""}
                                        </p>
                                        <Form.Label>
                                          Type your message in the box below
                                        </Form.Label>
                                        <ReactTextareaAutocomplete
                                          className="form-control my-textarea mt-2"
                                          value={message_value}
                                          onChange={handleMessageChange}
                                          // {...message}
                                          onDragOver={handleDragOver}
                                          onDrop={handleDrop}
                                          onBlur={() =>
                                            PreviewData(data_Records, headers)
                                          }
                                          loadingComponent={() => (
                                            <span>Loading</span>
                                          )}
                                          // afterWhitespace={true}
                                          // allowWhitespace={true}
                                          placeholder="Type a new message here ..."
                                          rows="4"
                                          trigger={{
                                            ":": {
                                              dataProvider: (token) => {
                                                return [
                                                  {
                                                    name: "Phone",
                                                    char: "{{Phone}}",
                                                  },
                                                ];
                                              },
                                              component: Item,
                                              output: (item, trigger) =>
                                                item.char,
                                            },
                                          }}
                                        />

                                        <span>
                                          {message_value.length} / 145
                                          Characters
                                        </span>
                                        {message_value.length > 145 ? (
                                          <Alert
                                            variant="danger"
                                            className="p-0"
                                          >
                                            <p>
                                              Text messages longer than 145
                                              characters cost two credits
                                            </p>
                                          </Alert>
                                        ) : (
                                          ""
                                        )}
                                      </Form.Group>

                                      <Col md={12} sm={12}>
                                        <Form.Group className="mb-3">
                                          <Form.Check
                                            label="Schedule the message"
                                            id="checkbox1"
                                            name="send_later"
                                            htmlFor="checkbox1"
                                            checked={send_later}
                                            onChange={handleSendLater}
                                          />
                                        </Form.Group>
                                      </Col>

                                      {send_later ? (
                                        <Col md={12} sm={12}>
                                          <Form.Group className="mb-3">
                                            <Form.Label>
                                              Select or type in a date and time
                                              to send
                                            </Form.Label>
                                            <br />
                                            <DateTimePicker
                                              onChange={onChangeDateValue}
                                              value={date_value}
                                              format="y-MM-dd HH:mm:s"
                                              disableClock={false}
                                            />
                                          </Form.Group>

                                          {/* {JSON.stringify(date_value)} */}
                                        </Col>
                                      ) : (
                                        ""
                                      )}

                                      <Col md={12} sm={12}>
                                        <Button
                                          variant={
                                            send_later ? "secondary" : "success"
                                          }
                                          className="ms-auto"
                                          disabled={
                                            data_Records == null ||
                                            message_value == ""
                                          }
                                          type="submit"
                                        >
                                          {send_later
                                            ? "Schedule Message"
                                            : "Send Now"}
                                        </Button>
                                      </Col>

                                      <Col md={12} sm={12}>
                                        <br />
                                        <b>Some useful Tips</b>
                                        <br />
                                        <small>
                                          1. Click or Touch and hold column to
                                          display options (New column, rename
                                          column etc.) <br />
                                          2. Click or drag any column tag (in
                                          blue) to add dynamic columns
                                        </small>
                                      </Col>
                                    </Row>
                                  </form>
                                </Col>
                                <Col
                                  xs={12}
                                  sm={12}
                                  md={6}
                                  lg={4}
                                  xl={4}
                                  className="d-small-none"
                                >
                                  <div class="device-wrapper">
                                    <div
                                      class="device"
                                      data-device="iPhone6"
                                      data-orientation="portrait"
                                      data-color="white"
                                    >
                                      <div class="screen">
                                        <div class="chat">
                                          <div class="yours messages addon">
                                            <div class="message main">
                                              <small>{sms_csp.value}</small>
                                            </div>
                                            <div class="message last">
                                              <small>
                                                {data_Records
                                                  ? ""
                                                  : "2547XXXXXXXX"}

                                                {data_Records ? (
                                                  <textarea
                                                    className="txtbx"
                                                    value={
                                                      msg_preview
                                                        ? msg_preview
                                                        : ""
                                                    }
                                                    rows="5"
                                                  ></textarea>
                                                ) : (
                                                  ""
                                                )}
                                              </small>
                                            </div>
                                          </div>
                                        </div>
                                      </div>
                                      <div class="button"></div>
                                    </div>
                                  </div>
                                  <Button
                                    variant="success w-100 mt-2"
                                    className="ms-auto"
                                    disabled={data_Records == null}
                                    type="submit"
                                    onClick={() =>
                                      PreviewData(data_Records, headers)
                                    }
                                  >
                                    Refresh Preview
                                  </Button>
                                </Col>
                              </Row>
                            </div>
                          </>
                        </Tab.Pane>
                        <Tab.Pane eventKey="code_friendly" className="py-2">
                          <>
                            <form className="mt-1" onSubmit={onSubmitWf}>
                              <Row>
                                <Form.Group as={Row} className="mb-3">
                                  <Form.Label column sm="12">
                                    SMS From:
                                  </Form.Label>
                                  <Col sm="12">
                                    <Form.Control
                                      as="select"
                                      name="sms_csp"
                                      {...sms_csp}
                                    >
                                      <option value="BuniComSMS">
                                        BuniComSMS
                                      </option>

                                      {SenderIds
                                        ? SenderIds.map((item, key) => (
                                            <option
                                              key={key}
                                              value={item.sender_name}
                                            >
                                              {item.sender_name}
                                            </option>
                                          ))
                                        : ""}
                                      {/* <option value="ssl">MobileSasa</option> */}
                                    </Form.Control>
                                  </Col>
                                </Form.Group>

                                <Form.Group as={Row} className="mb-3">
                                  <Form.Label column sm="12">
                                    Select Workflow:
                                  </Form.Label>
                                  <Col sm="12">
                                    {/* workflows
                                  .map(function (e) {
                                    return e._id;
                                  })
                                  .indexOf(
                                    selected_workflow_id
                                  ) */}
                                    <Form.Control
                                      as="select"
                                      name="workflow_id"
                                      value={
                                        selected_workflow_id
                                          ? selected_workflow_id
                                          : "Null"
                                      }
                                      onChange={handleChangeSelectedEntity}
                                    >
                                      <option value="Null">
                                        -SELECT WORKFLOW-
                                      </option>

                                      {workflows
                                        ? workflows.map((item, key) => (
                                            <option key={key} value={item._id}>
                                              {item.name}
                                            </option>
                                          ))
                                        : ""}
                                      {/* <option value="ssl">MobileSasa</option> */}
                                    </Form.Control>
                                  </Col>
                                </Form.Group>
                                {selected_workflow_id ? (
                                  <Form.Group as={Row} className="mb-3">
                                    <Form.Label column sm="12">
                                      Select Data Table:
                                    </Form.Label>
                                    <Col sm="12">
                                      <Form.Control
                                        as="select"
                                        name="dt_id"
                                        value={
                                          selected_dt_id
                                            ? selected_dt_id
                                            : "Null"
                                        }
                                        onChange={handleChangeSelectedTable}
                                      >
                                        <option value="Null">
                                          -SELECT DATA TABLE-
                                        </option>

                                        {dataTables
                                          ? dataTables.map((item, key) => (
                                              <option
                                                key={key}
                                                value={item.peek_id}
                                              >
                                                {item.message}
                                              </option>
                                            ))
                                          : ""}
                                        {/* <option value="ssl">MobileSasa</option> */}
                                      </Form.Control>
                                      {loadingPeek ? (
                                        <ProgressBar
                                          variant="success"
                                          animated
                                          now={100}
                                        />
                                      ) : (
                                        ""
                                      )}
                                    </Col>
                                  </Form.Group>
                                ) : (
                                  ""
                                )}

                                {selected_dt_id ? (
                                  <Form.Group as={Row} className="mb-3">
                                    <Form.Label column sm="12">
                                      Preview Data:
                                    </Form.Label>
                                    <Col sm="12">
                                      {data_table_columns ? (
                                        <ReactDataGrid
                                          idProperty="id"
                                          columns={data_table_columns}
                                          dataSource={data_table_data}
                                          style={gridStyle}
                                        />
                                      ) : (
                                        "No Results to display. Run workflow to get preview data"
                                      )}

                                      {/* <ReactDataGrid
                                        idProperty="id"
                                        columns={data_table_columns}
                                        dataSource={data_table_data}
                                        style={gridStyle}
                                      /> */}
                                      {/* {JSON.stringify(data_table_columns)}
                                      {JSON.stringify(data_table_data)} */}
                                    </Col>
                                  </Form.Group>
                                ) : (
                                  ""
                                )}

                                {dt_headers ? (
                                  <Form.Group className="mb-3">
                                    {/* {JSON.stringify(msg_preview)} */}
                                    {/* {JSON.stringify(data_Records)} */}
                                    <Form.Label column sm="12">
                                      Select Mobile Phone Column:
                                    </Form.Label>

                                    <Col sm="12">
                                      <Form.Control
                                        as="select"
                                        name="phone_column"
                                        {...phone_column}
                                      >
                                        <option value="Null">
                                          -Select Phone Column-
                                        </option>

                                        {dt_headers
                                          ? dt_headers.map((item, key) => (
                                              <option key={key} value={item}>
                                                {item}
                                              </option>
                                            ))
                                          : ""}
                                        {/* <option value="ssl">MobileSasa</option> */}
                                      </Form.Control>
                                    </Col>

                                    {phone_column.value == "Null" ? (
                                      " "
                                    ) : (
                                      <>
                                        <br />
                                        <b>
                                          Click or Drag n Drop the pills below
                                          to add dynamic columns
                                        </b>
                                        <p className="pt-1">
                                          {dt_headers
                                            ? dt_headers.map((header, i) => {
                                                return (
                                                  <Button
                                                    className="me-2"
                                                    variant="outline-secondary"
                                                    size="lg"
                                                    key={i}
                                                    //
                                                    draggable
                                                    onDragStart={(e) =>
                                                      handleDragStart(
                                                        e,
                                                        "{{" + header + "}}"
                                                      )
                                                    }
                                                    onClick={(e) =>
                                                      appendHeader(e, header)
                                                    }
                                                  >
                                                    <FontAwesomeIcon
                                                      icon={faTag}
                                                      className="me-2"
                                                    />
                                                    <span>{header}</span>
                                                  </Button>
                                                );
                                              })
                                            : ""}
                                        </p>
                                        <Form.Label>
                                          Type your message in the box below
                                        </Form.Label>
                                        <ReactTextareaAutocomplete
                                          className="form-control my-textarea mt-2"
                                          value={message_value}
                                          onChange={handleMessageChange}
                                          // {...message}
                                          onDragOver={handleDragOver}
                                          onDrop={handleDrop}
                                          onBlur={() =>
                                            PreviewData(
                                              data_table_data,
                                              dt_headers
                                            )
                                          }
                                          loadingComponent={() => (
                                            <span>Loading</span>
                                          )}
                                          // afterWhitespace={true}
                                          // allowWhitespace={true}
                                          placeholder="Type a new message here ..."
                                          rows="4"
                                          trigger={{
                                            ":": {
                                              dataProvider: (token) => {
                                                return [
                                                  {
                                                    name: "Phone",
                                                    char: "{{Phone}}",
                                                  },
                                                ];
                                              },
                                              component: Item,
                                              output: (item, trigger) =>
                                                item.char,
                                            },
                                          }}
                                        />

                                        <span>
                                          {message_value.length} / 145
                                          Characters
                                        </span>
                                        {message_value.length > 145 ? (
                                          <Alert
                                            variant="danger"
                                            className="p-0"
                                          >
                                            <p>
                                              Text messages longer than 145
                                              characters cost two credits
                                            </p>
                                          </Alert>
                                        ) : (
                                          ""
                                        )}

                                        <Col md={12} sm={12} className="mt-3">
                                          <Button
                                            variant={
                                              send_later
                                                ? "secondary"
                                                : "success"
                                            }
                                            className="ms-auto"
                                            disabled={
                                              data_table_data == null ||
                                              message_value == ""
                                            }
                                            type="submit"
                                          >
                                            {send_later
                                              ? "Schedule Message"
                                              : "Send Now"}
                                          </Button>
                                        </Col>
                                      </>
                                    )}
                                  </Form.Group>
                                ) : (
                                  ""
                                )}
                              </Row>
                            </form>
                          </>
                        </Tab.Pane>
                        <Tab.Pane eventKey="user_experience" className="py-4">
                          <p>Coming Soon.</p>
                        </Tab.Pane>
                      </Tab.Content>
                    </Col>
                  </Row>
                </Tab.Container>
              </div>
            </div>
            <div className="card mt-2"></div>
          </div>
        </div>
      </div>
    </>
  );
};

const useFormInput = (initialValue) => {
  const [value, setValue] = useState(initialValue);

  const handleChange = (e) => {
    setValue(e.target.value);
  };

  function handleReset(val) {
    setValue(val);
  }
  return {
    value,
    onChange: handleChange,
    onReset: handleReset,
  };
};

const mapStateToProps = (state) => ({});

const mapDispatchToProps = (dispatch) => {
  return {};
};

export default compose(
  connect(mapStateToProps, mapDispatchToProps),
  withFirebase
)(SMSPage);
