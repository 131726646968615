import * as React from "react";
import * as SRD from "storm-react-diagrams";
import { FormNodeModel } from "./FormNodeModel";
import { FormNodeWidget } from "./FormNodeWidget";


export class FormNodeFactory extends SRD.AbstractNodeFactory {
    constructor() {
        super("Form");
    }

    generateReactWidget(diagramEngine, node) {
        return <FormNodeWidget node={node} />;
    }

    getNewInstance() {
        return new FormNodeModel();
    }
}


