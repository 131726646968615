import * as React from "react";
import "./tray.css";

export class TrayItemWidget extends React.Component {
    render() {
        return (
            <div style={{
                float: "left",
                textAlign: "center",
                padding: '7px',
                
              }}
              className="shortcut-item grab"
              >
            <div
            style={{
                background: this.props.color
            }}
               
                draggable={true}
                onDragStart={event => {
                    
                    // event.target.style.cursor = 'move';
                    event.dataTransfer.setData("storm-diagram-node", JSON.stringify(this.props.model));
                }}
                className={ "tray-item icon icon-shape text-white shadow shortcut-media avatar rounded-circle" }
            >
                <i className={ "fa fa-" + this.props.icon }  style={{
              fontSize: "1.5em",
              fontWeight: "bolder"
            }} />
                {/* {this.props.name} */}
            </div>
            <p className="ls-1 mb-1"><small className="mt-0"> {this.props.name}</small></p>

            {/* <a href="#!" className="col-4 shortcut-item">
                    <span className="shortcut-media avatar rounded-circle bg-gradient-orange">
                      <i className="ni ni-email-83"></i>
                    </span>
                    <small>Email</small>
                  </a> */}
            </div>
        );
    }
}
