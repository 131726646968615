import React, { Component } from "react";
import Chart from "react-apexcharts";

class BarChart extends Component {
  constructor(props) {
    super(props);

    this.state = {
      options: {
        chart: {
          id: "basic-bar",
          type: 'line'
        },
        xaxis: {
          categories: [
            "Jan",
            "Feb",
            "Mar",
            "Apr",
            "May",
            "Jun",
            "Jul",
            "Aug",
            "Sep",
            "Oct",
            "Nov",
            "Dec",
          ],
        },
      },
      series: [
        {
            name: "SMS Cost (Ksh)",
            data: [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0],
          },
          {
            name: "Email Cost (Ksh)",
            data: [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0],
          },
      ],
    };
  }

  componentDidMount = function () {
    let data = [
        {
            name: "SMS Cost (Ksh)",
            data: this.props.series,
          },
          {
            name: "Email Cost (Ksh)",
            data: this.props.email,
          },
    ];

    this.setState({
      series: data,
    });
  };
  componentDidUpdate(prevProps, prevState) {
    if (prevProps.series !== this.props.series) {
      console.log("ENVIRONMENT VARIABLES");
      console.log(prevProps.series);
      console.log(this.props.series);

      let data = [
        {
          name: "SMS Cost (Ksh)",
          data: this.props.series,
        },
        {
            name: "Email Cost (Ksh)",
            data: this.props.email,
          },
      ];

      this.setState({
        series: data,
      });
    }
  }

  render() {
    return (
      <div className="Chart">
        <div className="row">
          <div className="mixed-chart">
            <Chart
              options={this.state.options}
              series={this.state.series}
              type="bar"
              style={{
                width:"100%"
            }}
            />
          </div>
        </div>
      </div>
    );
  }
}

export default BarChart;
